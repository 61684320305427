export const getAddressPayloadFromGoogleAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const { address_components } = place;
    if (!address_components) {
        return {

        };
    }
    let postal_code = null;
    for (let comp of address_components) {
        if ((comp.types || []).includes("postal_code")) {
            postal_code = comp.long_name;

            break;
        }
    }

    return {
        address_1: place.name,
        zip: postal_code,
        geometry_location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        }
    };
}

export const generateFullAddress = (address1, address2, city, state, zip, countryCode) => {
    // Filter out any null, undefined, or empty values
    const addressParts = [address1, address2, city, `${state} ${zip}`, countryCode].filter(Boolean);

    // Join the parts with a comma and space
    return addressParts.join(", ");
}

export const parseFullAddress = (fullAddress) => {
    if (!fullAddress) {
        return {
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip: "",
            country: ""
        }
    }

    const components = fullAddress.split(",").map(part => part.trim());
    if (components.length < 4) {
        return {
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip: "",
            country: ""
        }
    }

    if (components.length === 4) {
        console.log("components[2]", components[2], components[2].split(" ")[0])
        return {
            address_1: components[0] || "",
            address_2: "",
            city: components[1] || "",
            state: components[2] ? components[2].split(" ")[0] : "",
            zip: components[2] ? components[2].split(" ")[1] : "",
            country: components[3] || ""
        }
    }

    const result = {
        address_1: components[0] || "",
        address_2: components[1] || "",
        city: components[2] || "",
        state: components[3] ? components[2].split(" ")[0] : "",
        zip: components[3] ? components[2].split(" ")[1] : "",
        country: components[4] || ""
    };

    return result;
}

export const convertToArray = (commaSeparatedString) => {
    if (!commaSeparatedString) {
        return [];
    }
    return commaSeparatedString.split(",").map(item => item.trim());
}