import DriversList from "../drivers/DriversList";
import AddFormDialog from "../communications/AddFormDialog";
import EditFormDialog from "../communications/EditFormDialog";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import InvoiceList from "../invoice/InvoiceList";
import InvoiceContextProvider from "../../../contexts/InvoiceContext";
export default function PanelInvoice() {
    const { _globalData } = useContext(GlobalContext);

    return <InvoiceContextProvider>
        <InvoiceList />
       
    </InvoiceContextProvider>
}