import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';

export const options = [
    {
        value: 'most_efficient',
        label: 'Most efficient',
    },
    {
        value: 'maximum_distribution_quickest_time',
        label: 'Maximum distribution/Quickest time',
    },
    {
        value: 'load_balance_most_efficient',
        label: 'Load balance most efficient',
    },
];

export default function OptimizationModeInput() {
    const { optimization_mode, _route_optimize_dispatch } = useRouteOptimizeContext();
    // console.log("optimization_mode", optimization_mode)

    const handleChange = (event) => {
        _route_optimize_dispatch({
            type: "SET_OPTIMIZATION_MODE",
            payload: event.target.value
        })
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '57ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="outlined-select-optimization-mode"
                    select
                    label="Optimization mode"
                    defaultValue="maximum_distribution_quickest_time"
                    helperText="Please select optimization mode"
                    value={optimization_mode}
                    onChange={handleChange}
                >
                    {
                        options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </div>
        </Box>
    );
}
