import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

import { Formik, Form } from "formik";
import { Box } from '@material-ui/core';
import AdminValidation from '../../../utils/form/validations/AdminValidation';

import { CircularProgress, Grid, Typography } from '@mui/material';
import ModalClose from '../../common/ModalClose';

import useGlobalContext from '../../../hooks/useGlobalContext';
import { deepCopy } from '../../../utils/utils';
import { refreshToken } from '../../../api/auth';
import { registerUser } from '../../../api/user';
import { toast } from 'react-toastify';

export default function AddFormDialog() {
    const [open, setOpen] = useState(false);
    const { _globalData, _globalDispatch, getOwner, admins } = useGlobalContext();
    const {  current_user: user = {} } = _globalData;


    function handleClose() {
        setOpen(false);
    }

    async function handleClickOpen() {
        const _res = await refreshToken();

        if (_res.success) {
            const { user = {} } = _res;

            const { plan_details = {} } = user;

            const { no_of_admins = 0 } = plan_details;

            if (no_of_admins > admins.getTotalAdmins()) {
                setOpen(true);
            }
            else {
                _globalDispatch({
                    type: "OPEN_BILLING_MODAL",
                })
                console.log("Add option to upgrade plan admin 47 line")
            }
            _globalDispatch({
                type: "SET_CURRENT_USER",
                payload: user
            })

        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h6">
                    Admins ({admins.getTotalAdmins()}/{user.plan_details.no_of_admins??0})
                </Typography>
                <Button variant="contained" onClick={handleClickOpen} sx={{ float: 'right' }}>
                    <AddIcon />
                    Add New Admin

                </Button>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>New Admin</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            phone: "",
                            email: "",
                            // password: "",
                            // confirm_password: ""
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const payload = deepCopy(values);
                            // const owner = getOwner() || {};
                            // const { organization_settings = {}, accessibility_settings = {}, driver_app_settings = {}, plan_details = {} } = owner;
                            payload.role = "admin"
                            // payload.organization_settings = organization_settings
                            // payload.accessibility_settings = accessibility_settings
                            // payload.driver_app_settings = driver_app_settings
                            // payload.plan_details = plan_details

                            const _res = await registerUser(payload);

                            if (_res.success) {
                                resetForm();

                                const { admins } = _globalData;

                                admins.addAdminToList(_res.data)

                                setOpen(false);
                                _globalDispatch({
                                    type: "INCREASE_REFRESH_COUNTER"
                                });

                                toast.success("Admin added successfully.")
                            }else{
                                toast.error(_res.msg)
                            }
                            setSubmitting(false);

                        }}
                        validationSchema={AdminValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        <Grid container spacing={2}>
                                            {/* Name */}
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="First Name"
                                                    name="first_name"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.first_name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.first_name && props.touched.full_name}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Last Name"
                                                    name="last_name"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.last_name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.last_name && props.touched.last_name}
                                                />
                                            </Grid>

                                            {/* Phone */}
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Phone"
                                                    name="phone"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.phone}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.phone && props.touched.phone}
                                                />
                                            </Grid>
                                            {/* Email */}
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.email}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.email && props.touched.email}

                                                />
                                            </Grid>

                                            {/* <Grid item xs={6}>
                                                <TextField
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.password && props.touched.password}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Confirm password"
                                                    name="confirm_password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.confirm_password && props.touched.confirm_password}
                                                />
                                            </Grid> */}

                                            {
                                                isSubmitting && <Box style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%"
                                                }}>
                                                    <CircularProgress />
                                                </Box>
                                            }

                                            <Grid item xs={12}>
                                                <Box textAlign='center'>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        Create
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}
