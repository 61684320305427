import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React from 'react'
import { ASSIGNED_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS, INTRANSIT_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../../../constants/task_status'
import useGlobalContext from '../../../hooks/useGlobalContext'
import { deepCopy } from '../../../utils/utils'

const statusOptions = [
	{
		id: "unassigned",
		value: UNASSIGNED_TASK_STATUS,
		label: "Unassgigned"
	},
	{
		id: "assigned",
		value: ASSIGNED_TASK_STATUS,
		label: "Assigned"
	},
	{
		id: "in-transit",
		value: INTRANSIT_TASK_STATUS,
		label: "In Transit"
	},
	{
		id: "succeeded",
		value: COMPLETED_TASK_STATUS,
		label: "Succeeded"
	},
	{
		id: "failed",
		value: FAILED_TASK_STATUS,
		label: "Failed"
	},
]

export default function StatusSelect() {
	const { _globalDispatch, selectedStatus } = useGlobalContext();

	const handleCheckUncheck = (event) => {
		const payload = statusOptions.filter(s => s.id === event.target.id)[0].value

		const preSelectedStatus = deepCopy(selectedStatus)
		let updatedStatus = preSelectedStatus;
		const isAlreadyExistedStatus = preSelectedStatus.filter(s => s === payload)

		if (isAlreadyExistedStatus.length > 0) {
			updatedStatus = preSelectedStatus.filter(p => p !== payload)
		} else {
			updatedStatus.push(payload)
		}
		_globalDispatch({
			type: "SET_SELECTED_STATUS",
			payload: updatedStatus
		})
	}
	return (
		<Box>
			<FormGroup>
				{
					statusOptions.map(s => <FormControlLabel
						key={s.id}
						control={<Checkbox
							checked={selectedStatus.includes(s.value)}
							id={s.id}
							onChange={handleCheckUncheck} />}
						label={s.label}
						labelPlacement='start'
					/>)
				}

			</FormGroup>
		</Box>
	)
}
