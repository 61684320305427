import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment-timezone"
import { MOMENT_OUTPUT_DB } from '../../constants/moment-date-formats';
import useGlobalContext from '../../hooks/useGlobalContext';
import useExportContext from '../../hooks/useExportContext';

export default function DateSelect() {
    const { dateStart, dateEnd, _exportDispatch } = useExportContext();

    return <Grid container spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={6}>
                <DesktopDatePicker
                    label="Start"
                    inputFormat="MM/DD/YYYY"
                    maxDate={dateEnd}
                    value={dateStart}
                    onChange={(newVal) => {
                        // setFieldValue("delivery_date", newVal)
                        const d = moment(newVal.$d).format(MOMENT_OUTPUT_DB);
                        _exportDispatch({
                            type: "SET_DATE_START",
                            payload: d
                        })

                    }}
                    renderInput={(params) => <TextField
                        fullWidth
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}

                    />
                    }
                />

            </Grid>

            <Grid item xs={6}>
                <DesktopDatePicker
                    label="End"
                    inputFormat="MM/DD/YYYY"
                    value={dateEnd}
                    minDate={dateStart}
                    // disableFuture={true}
                    onChange={(newVal) => {
                        const d = moment(newVal.$d).format(MOMENT_OUTPUT_DB);
                        _exportDispatch({
                            type: "SET_DATE_END",
                            payload: d
                        })
                    }}
                    renderInput={(params) => <TextField
                        fullWidth
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}

                    />
                    }
                />
            </Grid>
        </LocalizationProvider>
    </Grid>
}
