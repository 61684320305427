import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';


import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import VerificationComplete from './pages/VerificationComplete';
import ErrorVerification from './pages/ErrorVerification';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import DashboardAppNew from './pages/DashboardAppNew';
// Custom additions
import { useAuthHelper } from './utils/auth';
import DriversPublicLocation from './pages/DriversPublicLocation';
import DriverPasswordReset from './pages/PasswordReset';
import ResetPassword from './pages/ResetPassword';

export default function Router() {

	const { isLoggedIn } = useAuthHelper();

	const location = useLocation();

	function AuthRoute({ children }) {
		// return children
		console.log("location.state", location.state)
		if (location.state && location.state.history === "login") {
			return children
		}

		return isLoggedIn() ? <Navigate to="/dashboard/app" /> : children
	}

	function PrivateRoute({ children }) {
		if (location.state && location.state.history === "login") {
			return children
		}

		console.log("Are we even here L66")

		return isLoggedIn() ? children : <Navigate to="/login" />
	}

	return useRoutes([
		{
			path: '/dashboard',
			element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
			children: [
				{
					path: 'app', element: <DashboardApp />
				},
			],
		},
		{
			path: '/dashboard',
			children: [
				{
					path: 'app-new', element: <DashboardAppNew />
				}
			],
		},
		{
			path: '/drivers',
			element: <DashboardLayout />,
			children: [
				{
					path: 'position/:driver_id/:task_id', element: <DriversPublicLocation />
				},
			],
		},
		{
			path: '/',
			element: <LogoOnlyLayout />,
			children: [
				{
					path: 'reset-password/:user_id/:token',
					element: <ResetPassword />
				},
				{
					path: 'driver-reset-password/:user_id/:token',
					element: <DriverPasswordReset />
				},
				{ path: 'login', element: <AuthRoute><Login /></AuthRoute> },
				{ path: 'register', element: <AuthRoute><Register /></AuthRoute> },
				{ path: 'forgot-password', element: <AuthRoute><ForgotPassword /></AuthRoute> },
				{ path: 'verification-complete', element: <AuthRoute><VerificationComplete /></AuthRoute> },
				{ path: 'error-verification', element: <AuthRoute><ErrorVerification /></AuthRoute> },
				{ path: '404', element: <NotFound /> },
				{ path: '/', element: <Navigate to={isLoggedIn() ? "/dashboard/app" : '/login'} /> },
				{ path: '*', element: <NotFound /> }
			]
		},
		{
			path: '*', element: <NotFound />
		}
	]);
}
