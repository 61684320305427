import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MOMENT_OUTPUT_DISPLAY_TIME, MOMENT_OUTPUT_DISPLAY_TIME_24, timezone } from '../../constants/moment-date-formats';
import { FormLabel, Grid } from '@mui/material';
import CustomTimePicker from '../common/CustomTimePicker';
import { deepCopy } from '../../utils/utils';
import { addHoursMinutesAmPm } from '../../utils/date';
import useGlobalContext from '../../hooks/useGlobalContext';

export default function GlobalStartEndTime() {
    const { _route_optimize_dispatch, global_start_time, global_end_time } = useRouteOptimizeContext();
    const { chosenDate, _globalDispatch } = useGlobalContext();
    const [complete_before_details, setCompleteBeforeDetails] = React.useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });

    const [complete_after_details, setCompleteAfterDetails] = React.useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
            {/* <TimePicker
                label="Global start time"
                value={global_start_time}
                onChange={(newVal) => {
                    if (newVal) {
                        console.log("newVal.tz(timezone)", newVal.format(MOMENT_OUTPUT_DISPLAY_TIME_24))
                        _route_optimize_dispatch({
                            type: "SET_GLOBAL_START_TIME",
                            payload: newVal
                        });
                    }
                }}
                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            /> */}
            <FormLabel>Global start time</FormLabel>
            <Box my={2}></Box>

            <CustomTimePicker
                initialVal={complete_after_details}
                onChange={(val) => {
                    const prev = deepCopy(complete_after_details)
                    if (val.type === "hour") {
                        prev.hour = val.hour
                    } else if (val.type === "minute") {
                        prev.minute = val.minute
                    } else if (val.type === "ampm") {
                        prev.ampm = val.ampm;
                    }

                    setCompleteAfterDetails(prev);

                    const _complete_after = addHoursMinutesAmPm(chosenDate, prev.hour, prev.minute, prev.ampm);
                    // console.log("_complete_after", _complete_after)
                    // _globalDispatch({
                    //     type: "SET_CHOSEN_TIME_FROM",
                    //     payload: _complete_after.tz(timezone),
                    // });

                    console.log("Global start time", _complete_after.format())

                    _route_optimize_dispatch({
                        type: "SET_GLOBAL_START_TIME",
                        payload: _complete_after
                    });

                }}
            />
        </Grid>

        <Grid item xs={6}>
            {/* <TimePicker
                label="Global end time"
                value={global_end_time}
                onChange={(newVal) => {
                    if (newVal) {
                        console.log("newVal.tz(timezone)", newVal.format(MOMENT_OUTPUT_DISPLAY_TIME_24))
                        _route_optimize_dispatch({
                            type: "SET_GLOBAL_END_TIME",
                            payload: newVal
                        });
                    }
                }}
                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            /> */}
            <FormLabel>Global end time</FormLabel>
            <Box my={2}></Box>
            <CustomTimePicker
                initialVal={complete_before_details}
                onChange={(val) => {
                    const prev = deepCopy(complete_before_details)
                    if (val.type === "hour") {
                        prev.hour = val.hour
                    } else if (val.type === "minute") {
                        prev.minute = val.minute
                    } else if (val.type === "ampm") {
                        prev.ampm = val.ampm;
                    }

                    setCompleteBeforeDetails(prev);

                    const _complete_before = addHoursMinutesAmPm(chosenDate, prev.hour, prev.minute, prev.ampm);
                    console.log("Global end time", _complete_before.format())
   
                    _route_optimize_dispatch({
                        type: "SET_GLOBAL_END_TIME",
                        payload: _complete_before
                    });
                }}
            />
        </Grid>
    </LocalizationProvider>
    );
}
