import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import LayersIcon from '@mui/icons-material/Layers';
import { useNavigate } from 'react-router-dom';
import CreateTaskButton from '../components/sidebarbuttons/CreateTaskButton';
import ExportButton from '../components/export/ExportButton';
import ImportButton from '../components/sidebarbuttons/ImportButton';
import SettingsButton from '../components/sidebarbuttons/SettingsButton';
import { useAuthHelper } from '../utils/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import ExportModal from '../components/modals/ExportModal';
import ExportButtons from '../components/sidebarbuttons/ExportButtons';


const Sidebar = () => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  const { handleLogout } = useAuthHelper();

  return (
    <List sx={{ marginTop:'60px', height: '100vh',width:'30vh' }}>
      {/* <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick('/')}>
          <ListItemIcon>
            <HomeIcon />
   

          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick('/tables')}>
          <ListItemIcon>
            <BorderAllIcon />
          </ListItemIcon>
          <ListItemText primary="Tables" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick('/charts')}>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Charts" />
        </ListItemButton>
      </ListItem> */}
      {/* <CreateTaskButton/> */}
      {/* <ExportButton/> */}
      {/* <ImportButton/> */}
      <SettingsButton/>
        {/* <ExportButtons /> */}
        <ListItem disablePadding>
        <ListItemButton  onClick={handleLogout}>
          <ListItemIcon>
          <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
        </ListItem>
     
                     
                      
                       
    </List>
  );
};

export default Sidebar;