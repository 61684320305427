import DriverEntity from "./DriverEntity";
import TaskEntity from "./TaskEntity";

class TasksListEntity {
    constructor(tasks = [], alltasks = []) {
        // console.log("tasks from TasksListEntity", tasks)
        this.tasks = tasks.map(t => new TaskEntity(t)).map((t, index) => new TaskEntity(Object.assign({}, t, { ref: index })))
        
        // console.log("this.tasks", this.tasks)
        this.alltasks = alltasks.map(t => new TaskEntity(t)).map((t, index) => new TaskEntity(Object.assign({}, t, { ref: index })))
    }

    reset(tasks) {
        // console.log("Reset tasks")
        this.tasks = tasks.map((t, index) => new TaskEntity(Object.assign({}, t, { id: index + 1 })));
    }

    update(task) {
        this.tasks = this.tasks.map(t => {
            const tEntity = new TaskEntity(task)
            if (t.getId() === tEntity.getId()) {
                t = tEntity
            }

            return t;
        })

    }

    // reIndex() {
    //     this.tasks = this.tasks.map((t, index) => new TaskEntity(Object.assign({}, t, { id: index + 1 })));
    // }

    add(task) {
        this.tasks = [...this.tasks, new TaskEntity(task)];
    }

    remove(task) {
        this.tasks = this.tasks.filter(t => t.getId() !== task._id)
    }

    getTasks() {
        return this.tasks || [];
    }

    getValidAddressTasks() {
        return this.tasks.sort(function (a, b) {
            return a.sort_number - b.sort_number;
        }).filter(t => t.isValidAddress())
    }

    getMarkers() {
        let validTasks = this.getValidAddressTasks();

        const _markers = [];

        for (let t of validTasks) {
            _markers.push({
                _id: t.getId(),
                center: t.getLatLng(),
                address: t.getAddress(),
                fillColor: t.getMarkerColor(),
                taskStatus: t.getStatusLabel(),
                label: t.getMarkerLabel(),
                index: t.getIndex() || "",
                task: t,
                title: t.getAddress(),
                ref: t.ref
            })
        }

        // console.log("_markers", _markers)

        return _markers;
    }

    //https://stackoverflow.com/questions/47907534/using-map-to-add-incrementing-values-to-js-objects
    getUnAssignedTasks() {
        this.tasks = this.tasks.sort(function (a, b) {
            return a.sort_number - b.sort_number;
        });

        // console.log("this.tasks from L83", this.tasks)
        const unassignedTasks = this.tasks
            .filter(t => t.isUnAssigned())
        // console.log("unassignedTasks from entity", unassignedTasks)
        return unassignedTasks;
    }

    addNewTask(task) {
        this.tasks = [...this.tasks, new TaskEntity(task)]
    }

    getAssignedTasksListByDrivers(drivers = []) {
        // console.log("drivers L95", drivers)
        if (drivers.length < 1) {
            return [];
        }

        let _ret = [];
        // get assigned tasks
        // sort
        this.tasks = this.tasks.sort(function (a, b) {
            return a.sort_number - b.sort_number;
        });

        for (let d of drivers) {
            let _tempTasks = this.tasks.filter(t => {
                let tDriver = t.getDriver();
                if (tDriver) {
                    if (tDriver.getId() === d.getId() && t.isAssignedOrTransit()) {
                        return t
                    }
                }

            });

            _tempTasks = _tempTasks.map((t, index) => new TaskEntity(Object.assign({}, t, { id: index + 1 })));
            for (let t of _tempTasks) {
                this.update(t)
            }

            _ret.push({
                driver: d,
                tasks: _tempTasks
            });

        }

        // online 
        const online_drivers = _ret.filter(d => {
            if (!d.driver) {
                return false;
            }
            const dEntity = new DriverEntity(d.driver);

            return dEntity.isOnline();
        });

        // offline drivers
        const offline_drivers = _ret.filter(d => {
            if (d.driver) {
                const dEntity = new DriverEntity(d.driver);

                return !dEntity.isOnline();
            }

            return false;

        });

        return [...online_drivers, ...offline_drivers];
    }

    getAssignedNonFailedDrivers(drivers = []) {
        if (drivers.length < 1) {
            return [];
        }

        let _ret = [];
        // get assigned tasks
        // sort
        this.tasks = this.tasks.sort(function (a, b) {
            return a.sort_number - b.sort_number;
        });

        for (let d of drivers) {
            let _tempTasks = this.tasks.filter(t => {
                let tDriver = t.getDriver();
                if (tDriver) {
                    if (tDriver.getId() === d.getId() && t.isAssignedOrTransitOrCompleted()) {
                        return t
                    }
                }

            });

            _tempTasks = _tempTasks.map((t, index) => new TaskEntity(Object.assign({}, t, { id: index + 1 })));
            for (let t of _tempTasks) {
                this.update(t)
            }

            _ret.push({
                driver: d,
                tasks: _tempTasks
            })

        }

        return _ret;
    }

    getOrigin() {
        if (this.tasks.length < 1) {
            return null
        }

        return this.tasks[0].getLatLng()
    }

    getDestination() {
        if (this.tasks.length < 1) {
            return null
        }

        return this.tasks[this.tasks.length - 1].getLatLng()
    }

    getTableData() {
        const _tableData = [];
        // console.log("this.tasks", this.tasks)
        for (let task of this.tasks) {
            _tableData.push({
                _id: task._id,
                assigned_to: task.getDriverName(),
                destination_address: task.getAddressShort(),
                recipient_name: task.getRecipientName(),
                task_status: task.getStatusLabel(),
                task_status_marker_color: task.getMarkerColor(),
                created_at: task.getCreatedTime(),
                updated_at: task.getUpdatedTime(),

            })
        }

        return _tableData;
    }

    getTaskById(id) {
        return this.tasks.filter(t => t._id === id)
    }

    getTasksByDriver(driver_id) {
        return this.tasks.filter(t => t.getDriver() && t.getDriver()._id === driver_id)
    }

    hasOngoingTaskForDriver(driver_id) {
        return (this.tasks.filter(t => t.getDriver() && t.getDriver()._id === driver_id && t.isTransit()) || []).length > 0
    }

    hasOngoingCompletedTaskForDriver(driver_id) {
        return (this.tasks.filter(t =>
            t.getDriver()
            && t.getDriver()._id === driver_id
            && (t.isTransit() || t.isCompleted())
        )
            || []).length > 0
    }

    getTodayTotalTasks() {
        return (this.tasks || []).length
    }
    getAllTasks() {
        return (this.alltasks || []).length
    }

}

export default TasksListEntity;