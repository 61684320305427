import AdminsList from "../admins/AdminsList";
import AddFormDialog from "../admins/AddFormDialog";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function PanelAdmins() {
    const { _globalData } = useContext(GlobalContext);

    return <>
     <AddFormDialog />
        <AdminsList />
      
    </>
}