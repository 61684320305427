import * as yup from "yup";
require("yup-phone");

const phoneNumberRegEx = /^[0-1]{2}[0-9]{9}/;
const PasswordRegEx = /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const TaskValidation = yup.object().shape({
    recipient_phone: yup
        .string()
        // .phone()
        .required("Required !"),
    recipient_email: yup
        .string()
        .email(),
    recipient_name: yup
        .string()
        .required("Required !"),

    destination_address: yup
        .string()
        .required("Required !"),
    delivery_date: yup
        .string()
        .required("Required !"),
});

export default TaskValidation;
