class DispatcherEntity {
    constructor(details = {}) {
        this._id = details._id
        this.first_name = details.first_name
        this.last_name = details.last_name
        this.full_name = `${details.first_name} ${details.last_name}`
        this.email = details.email
        this.owner = details.owner
        this.phone = details.phone
        this.teams = details.teams
        this.role = details.role
    }

    getId() {
        return this._id;
    }

    getName() {
        return this.full_name;
    }

    getPhone() {
        return this.phone;
    }

    getPayload() {
        return {
            _id: this._id,
            full_name: this.full_name,
            phone: this.phone,
            email: this.email,
            owner: this.owner,
            role: this.role
        }

    }

}

export default DispatcherEntity;