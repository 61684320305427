import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DISABLED_LABEL, DISABLED_VALUE, ENABLED_LABEL, ENABLED_VALUE, ENABLED_WITH_WARNING_LABEL, ENABLED_WITH_WARNING_VALUE, REQUIRED_LABEL, REQUIRED_VALUE } from '../../../constants/task_completion_options';

export default function TaskCompletionOption({ val = '', setting_key, handleSettingChange }) {
    const [age, setAge] = React.useState(val);

    const handleChange = (event) => {
        setAge(event.target.value);
        handleSettingChange(setting_key, event.target.value)
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <Select
                    labelId="task-completion-options-label"
                    id="task-completion-options"
                    value={age}
                    onChange={handleChange}
                    style={{ height: "36px", fontSize: "12pxs" }}
                >
                    <MenuItem style={{ fontSize: "12px" }} value={DISABLED_VALUE}>{DISABLED_LABEL}</MenuItem>
                    <MenuItem style={{ fontSize: "12px" }} value={ENABLED_VALUE}>{ENABLED_LABEL}</MenuItem>
                    {/* <MenuItem style={{ fontSize: "12px" }} value={ENABLED_WITH_WARNING_VALUE}>{ENABLED_WITH_WARNING_LABEL}</MenuItem> */}
                    <MenuItem style={{ fontSize: "12px" }} value={REQUIRED_VALUE}>{REQUIRED_LABEL}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
