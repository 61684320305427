import {
    Grid,
    FormLabel,
    FormGroup,
    Typography
} from '@mui/material';

import useGlobalContext from '../../../hooks/useGlobalContext';

const TaskView = () => {
    const { chosenTask = {} } = useGlobalContext();
    console.log("chosenTask", chosenTask)

    return (<>
        <FormGroup sx={{ my: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormLabel><h5>Recipient</h5></FormLabel>
                    <Typography>{chosenTask.getRecipientPhone()}</Typography>
                    <Typography>{chosenTask.getRecipientName()}</Typography>
                </Grid>


                <Grid item xs={6}>
                    <FormLabel><h5>Assigned To</h5></FormLabel>
                    <Typography>{chosenTask.getDriverName()}</Typography>
                    <Typography>{chosenTask.getDriverPhone()}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel><h5>Destination Address</h5></FormLabel>
                    <Typography>{chosenTask.getDestinationAddress()}</Typography>
                </Grid>
                {
                    chosenTask.recipient_note && <Grid item xs={12}>
                        <FormLabel><h5>Recipient Note</h5></FormLabel>
                        <Typography>{chosenTask.getRecipientNote()}</Typography>
                    </Grid>
                }

                <Grid item xs={6}>
                    <FormLabel><h5>Delivery Date </h5></FormLabel>
                    <Typography>{chosenTask.isCompleted() ? chosenTask.getCompletedTime() : chosenTask.getDeliveryDate()}</Typography>
                </Grid>
                {
                    chosenTask.start_time &&
                    <Grid item xs={6}>
                        <FormLabel><h5>Start Time</h5></FormLabel>
                        <Typography>{chosenTask.getStartTime()}</Typography>
                    </Grid>
                }

                {
                    chosenTask.completed_time &&
                    <Grid item xs={6}>
                        <FormLabel><h5>Completed Time</h5></FormLabel>
                        <Typography>{chosenTask.getCompletedTime()}</Typography>
                    </Grid>
                }

                <Grid item xs={6}>
                    <FormLabel><h5>Status</h5></FormLabel>
                    <Typography>{chosenTask.getStatusLabel()}</Typography>
                </Grid>

                {
                    chosenTask.completion_note && <Grid item xs={12}>
                        <FormLabel><h5>Success note</h5></FormLabel>
                        <Typography>{chosenTask.completion_note}</Typography>
                    </Grid>
                }

                {
                    chosenTask.failure_note && <Grid item xs={12}>
                        <FormLabel><h5>Failure note</h5></FormLabel>
                        <Typography>{chosenTask.failure_note}</Typography>
                    </Grid>
                }

{
                    chosenTask.failure_reason && <Grid item xs={12}>
                        <FormLabel><h5>Failure reason</h5></FormLabel>
                        <Typography>{chosenTask.failure_reason}</Typography>
                    </Grid>
                }

                {
                    chosenTask.file_url && chosenTask.file_url.length > 0 && <Grid item xs={12}>
                        <FormLabel><h5>Delivery images</h5></FormLabel>
                    </Grid>
                }
                {
                    chosenTask.file_url && chosenTask.file_url.map(img => <Grid item xs={3}><a href={img.uri} target='_blank'><img src={img.uri} width={200} height={"auto"} /></a></Grid>)

                }

                {
                    chosenTask.handed_to_customer && <Grid item xs={6}>
                        <FormLabel><h5>HANDED TO CUSTOMER</h5></FormLabel>
                        <Typography>{chosenTask.handed_to_customer ? 'YES' : ''}</Typography>
                    </Grid>
                }
                {
                    chosenTask.signature_img && <Grid item xs={12}>
                        <FormLabel><h5>Signature</h5></FormLabel>
                        <a href={chosenTask.signature_img} target='_blank'><img src={chosenTask.signature_img} width={200} height={200} /></a>
                    </Grid>

                }

            </Grid>
        </FormGroup >
    </>)
}

export default TaskView;
