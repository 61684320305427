import { useContext } from "react";
import { MapContext } from "../contexts/MapContext";
import TasksListEntity from "../entities/TasksListEntity";

export default function useMapContext() {
    const { _mapContext, _mapDispatch } = useContext(MapContext)
    const {
        trafficMode = false,
        clusterTasks = new TasksListEntity([]),
        infoWindowPosition = null,
        showInfoWindow = false,
        satelliteMode = false
    } = _mapContext;

    return {
        _mapContext,
        _mapDispatch,
        trafficMode,
        satelliteMode,
        clusterTasks,
        showInfoWindow,
        infoWindowPosition
    }
}