import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import useGlobalContext from '../../hooks/useGlobalContext';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import { toast } from 'react-toastify';

const getItemStyle = (isDragging, draggableStyle, taskDetails = {}) => ({
	userSelect: "none",
	padding: 0,
	margin: 0,

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "none",
	border: taskDetails.destination_address_latlng ? "none" : "1px solid red",

	// styles we need to apply on draggables
	...draggableStyle
});

const PrimaryListText = ({ d }) => {
	const timeWindowText = d.getTimeWindowText();
	const etaTime = d.getEtaTime();
	return <div>
		<Typography>{d.getAddressShort()}</Typography>
		<Typography>{`${d.getRecipientName()} | ${d.getDestinationCity()}`}</Typography>
		{
			timeWindowText && <Typography sx={{ fontSize: "8px !important" }}>{timeWindowText}</Typography>
		}

		{
			etaTime && <Typography sx={{ fontSize: "8px !important" }}>{`ETA: ${etaTime}`}</Typography>
		}

	</div>
}

const AddressList = ({ data = [], driver, unassigned = false, lineRefs = [] }) => {
	const [filteredData, setFilteredData] = useState(data)

	useEffect(() => {
		setFilteredData(data)
	}, [data]);


	const { multiSelectTasks, _globalDispatch, tasks } = useGlobalContext();
	const [ishover, sethover] = useState(false);


	const timer = React.useRef()

	const handleSingleClick = (e, d) => {
		// return false;
		//Shift select
		if (e.shiftKey) {
			const preTask = multiSelectTasks.findLast(t => t.task_status === d.task_status);
			if (!preTask) {
				_globalDispatch({
					type: "SET_SELECTED_TASKS",
					payload: d
				});

				return false;
			}

			// d is currenlt clicked 
			//d.sort_number > preTask.sort_number // Downward
			//d.sort_number < preTask.sort_number // Upward
			// console.log("d", d);
			// console.log("preTask", preTask);
			let newlySelectedTasks = []
			if (d.sort_number > preTask.sort_number) {
				newlySelectedTasks = tasks.tasks
					.filter(t => {
						// console.log("preTask", preTask.sort_number, d.sort_number)
						if (preTask.driver._id === undefined && d.driver._id === undefined) {
							return t.driver._id === undefined;
						} else {
							if (preTask.driver && d.driver && t.driver) {
								if (preTask.driver._id === d.driver._id) {
									return d.driver._id === t.driver._id;
								} else {
									return !t.driver._id
								}
							}
						}


					})
					.filter(t => t.sort_number >= preTask.sort_number && t.sort_number <= d.sort_number)

			} else {
				// console.log(" tasks.tasks",  tasks.tasks)
				newlySelectedTasks = tasks.tasks
					// .filter(t => { return preTask.driver ? (preTask.driver._id === t.driver._id) : false })
					.filter(t => {
						// console.log("preTask", preTask.sort_number, d.sort_number)
						if (preTask.driver._id === undefined && d.driver._id === undefined) {
							return t.driver._id === undefined;
						} else {
							if (preTask.driver && d.driver && t.driver) {
								if (preTask.driver._id === d.driver._id) {
									return d.driver._id === t.driver._id;
								} else {
									return !t.driver._id
								}
							}
						}


					})
					.filter(t => t.sort_number <= preTask.sort_number && t.sort_number >= d.sort_number)
			}

			// console.log("newlySelectedTasks", newlySelectedTasks)

			for (let newTask of newlySelectedTasks) {
				// console.log("yo here")
				_globalDispatch({
					type: "SET_SELECTED_TASKS",
					payload: newTask
				})
			}

			return false;
		} else if (e.metaKey || e.ctrlKey) {
			// MAC command key
			//GENERAL ctrl key
			_globalDispatch({
				type: "SET_SELECTED_TASKS",
				payload: d
			})
		}
		else {

			//SET_AS_SELECTED_TASKS
			_globalDispatch({
				type: "SET_AS_SELECTED_TASKS",
				payload: [d]
			})

			_globalDispatch({
				type: "SET_INFOWINDOW",
				payload: d
			})
		}

		if (!driver) {
			_globalDispatch({
				type: "SET_POLYLINE_TASKS",
				payload: null
			})

			return false;
		}

		_globalDispatch({
			type: "SET_POLYLINE_TASKS",
			payload: [{ driver, data: filteredData }]
		})
	}

	const enablePolylineTasks = (e, d) => {
		if (!d.destination_address_latlng) {
			toast.error("Invalid address. Please update the address.")
			_globalDispatch({
				type: "OPEN_TASK_VIEW_FORM",
				chosenTask: d
			})
			return false;
		}

		clearTimeout(timer.current);

		if (e.detail === 1) {

			timer.current = setTimeout(() => { handleSingleClick(e, d) }, 200)
		} else if (e.detail === 2) {
			_globalDispatch({
				type: "OPEN_TASK_VIEW_FORM",
				chosenTask: d
			})
		}
	}

	return (
		<>
			{
				filteredData.map((d, index) => (
					<Draggable

						key={d._id}
						draggableId={d._id.toString()}
						index={index}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={getItemStyle(
									snapshot.isDragging,
									provided.draggableProps.style,
									d
								)}
							>
								<ListItem

									sx={{
										'&:hover': {
											backgroundColor: 'lightgrey', // Change this to your desired color
										},
									}}
									key={d.getId()}
									onClick={(e) => {
										console.log("d", d)
										enablePolylineTasks(e, d);
									}}
									ref={lineRefs.current[d.ref]}
									id={`${d.getId()}`}

									style={{

										"cursor": "pointer",
										marginBottom: "4px",
										border: multiSelectTasks.filter(m => m._id === d._id).length > 0 ? "1px solid green" : "",
										backgroundColor: multiSelectTasks.filter(m => m._id === d._id).length > 0 ? ishover ? "grey" : "white" : "",
									}}
									className={multiSelectTasks.filter(m => m._id === d._id).length > 0 ? "custom-selected-tasks" : ""}
								>
									{
										!unassigned && <Typography style={{ fontSize: "11px", paddingRight: "10px" }}>{d.getIndex()}</Typography>
									}

									<RoomIcon fontSize='12' sx={{ color: d.getMarkerColor(), marginRight: "8px" }} />
									<ListItemText

										className='small-text'
										primary={<PrimaryListText d={d} />}
										secondaryTypographyProps={{
											color: "#282D32"
										}}
										primaryTypographyProps={{
											color: "#282D32"
										}}
										onContextMenu={(e) => {
											e.preventDefault();
											_globalDispatch({
												type: "OPEN_TASK_OPTIONS_FORM",
												chosenTask: d,
												taskOptionsModalMode: multiSelectTasks.length > 1 ? "multi" : "single"
											});
										}}
									/>

								</ListItem>
							</div>
						)}
					</Draggable>
				))
			}
		</>

	);
}

export default AddressList;
