import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Box, IconButton, CircularProgress, Grid, Autocomplete  } from "@mui/material";
import { useState } from "react";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import useGlobalContext from '../../../hooks/useGlobalContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { deepCopy } from '../../../utils/utils'
import { deleteTeam, updateTeam } from '../../../api/team'
import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import TeamEntity from '../../../entities/TeamEntity'

import { toast } from 'react-toastify'
import ModalClose from '../../common/ModalClose'
import { confirmAlert } from 'react-confirm-alert'; // Import

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'



import { Formik, Form } from 'formik'


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'none',
        },
        '&:hover fieldset': {
            borderColor: 'none',
        },
        '&.Mui-focused fieldset': {
            borderWidth: 0.1
        },
    },
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "100%" }}>
    <CssTextField
        id="search-bar"
        className="text"
        onInput={(e) => {
            setSearchQuery(e.target.value);
        }}
        fullWidth
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
    />
</Box>
);

export default function TeamsList() {
    const [open, setOpen] = useState(false)

    const [searchQuery, setSearchQuery] = useState("");
    const { _globalDispatch, teams, chosenTeam: chosenItem,chosenTeam, admins, drivers, dispatchers, hubs } = useGlobalContext();

    const data = teams.getTeams();
    const driverOptions = drivers.getDrivers();
	const dispatcherOptions = dispatchers.getDispatchers();
	const hubsOption = hubs.getHubs();

	console.log("chosenTeam", chosenTeam)
	console.log("driverOptions", driverOptions)
	console.log("teams", teams)

	// const onLoad = useCallback((auto) => {
	// 	setAutoComplete(auto)
	// }, []);

	function handleClose() {
		setOpen(false)
	}

	function handleClickOpen(item) {
		console.log('item',item);
        _globalDispatch({
            type: "SET_CHOSEN_TEAM",
            payload: item
        })
		setOpen(true)
	}

	const handleDeleteConfirmation = async (item) => {
     
		confirmAlert({
			customUI: ({ onClose }) => {
			  return (
				<div className="custom-ui__overlay">
				  <div className="custom-ui">
					<div className="custom-ui__header">
					  <h1>Are you sure?</h1>
					</div>
					<div className="custom-ui__body">
					  <p>Do you really want to delete? This process cannot be undone.</p>
					</div>
					<div className="custom-ui__footer">
					  <button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
					  <button
						className="custom-ui__button custom-ui__button--confirm"
						onClick={() => {
						  handleDelete(item);
						  onClose();
						}}
					  >
						Yes, Delete it!
					  </button>
					</div>
				  </div>
				</div>
			  );
			}
		  });
	};

	const handleDelete = async (item) => {
		const _res = await deleteTeam(item._id);
		if (_res.success) {
			// update tasks list

			teams.removeTeamFromList(item)

			_globalDispatch({
				type: "RESET_CHOSEN_TEAM"
			});

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			toast("Team deleted succesfully.")
		}
	}

    // console.log("chosenItem", chosenItem)

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) => d.team_name.toLowerCase().includes(query.toLowerCase()));
        }
    };

    const dataFiltered = filterData(searchQuery, data);
	

    return (<>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {chosenTeam?<Dialog open={open} onClose={handleClose} maxWidth='sm'>
				<ModalClose handleClose={handleClose} />
				<DialogTitle>{`Edit team: ${chosenTeam.team_name}`}</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							userId: chosenTeam._id || '',
							team_name: chosenTeam.team_name || '',
							drivers: chosenTeam.driver_ids || [],
							dispatchers: chosenTeam.dispatchers || [],
							hub: chosenTeam.hub || {}

						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							const { _id } = chosenTeam;
							const _payload = deepCopy(values);
							_payload._id = _id;

							setSubmitting(true)
							const _res = await updateTeam(_id, _payload)

							if (_res.success) {
								toast('Team details updated successfully.');
								// setOpen(false);

								teams.updateSelectedTeam(_res.data);

								_globalDispatch({
									type: "SET_CHOSEN_TEAM",
									payload: new TeamEntity(_payload)
								})

								_globalDispatch({
									type: "INCREASE_REFRESH_COUNTER"
								});
							}

							setSubmitting(false)
						}}
					// validationSchema={AdminValidation}
					>
						{props => {
							const { values, isSubmitting, handleSubmit, setFieldValue } = props

							return (
								<Form>
									<Grid container spacing={2}>
										{/* Name */}
										<Grid item xs={12}>
											<TextField
												label='Team Name'
												name='team_name'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.team_name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.team_name && props.touched.team_name}
											/>
										</Grid>

										<Grid item xs={12}>
											<FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
												<Autocomplete
													sx={{ margin: 0 }}
													id="drivers"
													name="Drivers"
													options={driverOptions}
													multiple={true}
													getOptionLabel={option => option.name || ""}
													isOptionEqualToValue={(option, value) => option._id === value._id}
													onChange={(e, value) => {
														setFieldValue(
															"drivers",
															value !== null ? value : null
														);
													}}
													value={values?.drivers}
													renderOption={(props, option) => {
														return (
															<li {...props} key={option._id}>
																{option.name}
															</li>
														);
													}}
													renderInput={params => (
														<TextField
															margin="normal"
															label="Driver"
															fullWidth
															name="driver"
															{...params}
														/>
													)}
												/>
											</FormControl>
										</Grid>

										<Grid item xs={12}>
											<FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
												<Autocomplete
													sx={{ margin: 0 }}
													id="dispatchers"
													name="Dispatchers"
													options={dispatcherOptions}
													multiple={true}
													getOptionLabel={option => `${option.first_name} ${option.last_name}` || ""}
													isOptionEqualToValue={(option, value) => option._id === value._id}
													onChange={(e, value) => {
														setFieldValue(
															"dispatchers",
															value !== null ? value : null
														);
													}}
													value={values?.dispatchers}
													renderOption={(props, option) => {
														return (
															<li {...props} key={option._id}>
																{`${option.first_name} ${option.last_name}`}
															</li>
														);
													}}
													renderInput={params => (
														<TextField
															margin="normal"
															label="Dispatcher"
															fullWidth
															name="dispatcher"
															{...params}
														/>
													)}
												/>
											</FormControl>
										</Grid>

										<Grid item xs={12} width={"100%"}>
											<FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
												<Autocomplete
													sx={{ margin: 0 }}
													id="hub"
													name="Hub"
													options={hubsOption}
													multiple={false}
													getOptionLabel={option => option.hub_name || ""}
													isOptionEqualToValue={(option, value) => option._id === value._id}
													onChange={(e, value) => {
														setFieldValue(
															"hub",
															value !== null ? value : null
														);
													}}

													value={values?.hub}
													renderOption={(props, option) => {
														return (
															<li {...props} key={option._id}>
																{`${option.hub_name}`}
															</li>
														);
													}}
													renderInput={params => (
														<TextField
															margin="normal"
															label="Hub"
															fullWidth
															name="hub"
															{...params}
														/>
													)}
												/>
											</FormControl>
										</Grid>

										<Grid item xs={12}>

											{isSubmitting && (
												<Box
													style={{
														position: 'absolute',
														top: '50%',
														left: '50%'
													}}
												>
													<CircularProgress />
												</Box>
											)}
										</Grid>
										<Grid item xs={12}>
											<Box textAlign='center'>
												<Button
													variant='contained'
													type='submit'
													color='primary'
													onClick={handleSubmit}
													disabled={isSubmitting}
												>
													Update
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</DialogContent>
			</Dialog>:''}
        {/* <List sx={{ maxHeight: 300, bgcolor: 'background.paper', overflow: 'scroll' }}>
            {
                dataFiltered.map(item => <ListItem key={item._id}>
                    <ListItemText
                        primary={item.team_name}
                        // secondary={item.full_address}
                        onClick={() => {
                            _globalDispatch({
                                type: "SET_CHOSEN_TEAM",
                                payload: item
                            })
                        }}
                        style={{
                            cursor: "pointer",
                            backgroundColor: chosenItem && chosenItem !== null && chosenItem._id === item._id ? "rgb(145 158 171 / 80%)" : ""
                        }}
                    />
                </ListItem>)
            }
        </List> */}
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell align="left">Team Name</TableCell>
            <TableCell align="left">Drivers</TableCell>
            <TableCell align="left">Dispatchers</TableCell>
            <TableCell align="left">Hub</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFiltered && dataFiltered.map((item,i) => (
            <TableRow
              key={item._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               {i+1}
              </TableCell>
             
              <TableCell align="left">{item.team_name}</TableCell>
              <TableCell align="left">
              {item?item.driver_ids.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      padding: '4px 8px',
                      marginRight: '4px',
                      marginBottom: '4px',
                    }}
                  >
                    {tag.name}
                  </span>)):''}
              </TableCell>
              <TableCell align="left">
              {item?item.dispatcher_ids.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      padding: '4px 8px',
                      marginRight: '4px',
                      marginBottom: '4px',
                    }}
                  >
                    {tag.first_name} {tag.last_name}
                  </span>)):''}
              </TableCell>
              <TableCell align="left">
              {item.hub_id?item.hub_id.hub_name:''}
              </TableCell>
              <TableCell align="left"> 
              <IconButton edge="end" aria-label="edit" onClick={() => handleClickOpen(item)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(item)}>
              <DeleteIcon />
              </IconButton>
         </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    )
}