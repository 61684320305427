import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import { useTheme } from '@mui/styles';
import ForgotPasswordForm from '../sections/auth/login/ForgotPasswordForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const ContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	// minHeight: '60vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
	const smUp = useResponsive('up', 'sm');

	const mdUp = useResponsive('up', 'md');

	const theme = useTheme();

	return (
		<Page title="Login" isAuthPage={true}>
			<RootStyle>
				<Container maxWidth="sm" >
					<Card style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
						<Card style={{ marginTop: 70, borderTopLeftRadius: 50, borderTopRightRadius: 50, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
							<ContentStyle>
								<Typography variant="h4" gutterBottom align="center">
									Operation Track System
								</Typography>

								<Typography sx={{ color: 'text.secondary', mb: 5 }} align="center">
									There was an error while verifying your token.Please contact administrator.
								</Typography>

								


								<Typography variant="body2" align="center" sx={{ mt: 3 }}>
									Already have an account?{' '}
									<Link variant="subtitle2" component={RouterLink} to="/login">
										Get started
									</Link>
								</Typography>

							</ContentStyle>
						</Card>
					</Card>
				</Container>
			</RootStyle>
		</Page>
	);
}
