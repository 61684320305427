import { Box, Grid, TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import useGlobalContext from "../hooks/useGlobalContext";
import DashboardTableView from '../components/dashboard/DashboardTableView';
import MapContextProvider from "../contexts/MapContext";
import GoogleMapContainer from "../components/map/GoogleMapContainer";
import TrafficLayerOption from "../components/map/TrafficLayerOption";
import SatelliteOption from "../components/map/SatelliteOption";
import ViewClusterTasksModal from "../components/modals/ViewCluserTasksModal";
import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { useCallback, useState } from "react";
import { getAddressPayloadFromGoogleAddress } from "../utils/addressUtil";
import { mapInitOptions } from "../constants/map";

const DashboardWrap = () => {
	const { dashboardView, _globalDispatch } = useGlobalContext();
	const [autocomplete, setAutoComplete] = useState(undefined);
	const { isLoaded } = useJsApiLoader(mapInitOptions);

	const onLoad = useCallback((auto) => {
		setAutoComplete(auto)
	}, []);
	return (
		<Grid item xs={12} sm={12} md={12}>
			{
				dashboardView === "table" ? <DashboardTableView /> : <MapContextProvider>
					<GoogleMapContainer />
					{/* Address search bar */}
					{
						isLoaded &&
						<Box sx={{
							position: "absolute",
							bottom: 70,
							right: "10%"
						}}>
							<AddressAutoComplete

								onLoad={onLoad}
								onPlaceChanged={() => {
									// console.log("autocomplete", autocomplete)
									const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

									const formatted_address = autocomplete.getPlace().formatted_address;
									const destination_address_latlng = addressPayload.geometry_location;

									_globalDispatch({
										type: "SET_CUSTOM_CENTER",
										payload: {
											location: destination_address_latlng,
											title: formatted_address,
											isHub: false,
											hideMarker: false
										}
									})
								}}
							>
								<TextField
									defaultValue={""}
									sx={{
										'& .MuiOutlinedInput-root': {
											borderRadius: '50px', // Round the edges
											'& fieldset': {
												border: 'none', // Remove the outer border
											},
											'&:hover fieldset': {
												border: 'none', // Remove the border on hover
											},
											'&.Mui-focused fieldset': {
												border: 'none', // Remove the border on focus
											},
											height: '40px', // Custom height for the container
											paddingRight: '10px',
											'& input': {
												padding: '10px 14px', // Adjust padding for text
											},
										},
										'& .MuiInputLabel-root': {
											display: 'none', // Hide the label
										},
										width: '300px',
										backgroundColor: '#FFF', // White background to match the style
									}}
									fullWidth
									label="Enter a location"
									name="destination_address"
									variant="outlined"
									margin="normal"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</AddressAutoComplete>
						</Box>
					}


					<Box sx={{
						position: "absolute",
						top: 80,
						right: "16%"
					}}>
						<Grid container spacing={2} direction="row">
							<Grid item xs={6}>
								<TrafficLayerOption />
							</Grid>
							<Grid item xs={6}>
								<SatelliteOption />
							</Grid>
						</Grid>
					</Box>

					<ViewClusterTasksModal />
				</MapContextProvider>
			}

		</Grid>

	)
}

export default DashboardWrap;