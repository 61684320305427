import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';
import {
	Button,
	TextField,
	Autocomplete,
	Box
} from '@mui/material';
import {  updateTask } from '../../api/task';
import { ASSIGNED_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../../constants/task_status';

export default function TaskSelectionActionModal() {
	const { multiAssignTasksModalOpen, drivers, tasks, multiSelectTasks, chosenTask, _globalDispatch, clearModal } = useGlobalContext();
	const [chosenDriver, setChosenDriver] = React.useState(null)
	let driverOptions = [];
	if (drivers) {
		driverOptions = drivers.getDrivers();
	}

	const handleClose = () => {

		_globalDispatch({
			type: "CLOSE_MULTI_TASK_ASSIGN_FORM"
		})
	};

	const getTaskIds = () => {
		let _ids = [];
		if (multiSelectTasks.length > 0) {
			for (let task of multiSelectTasks) {
				_ids.push(task.getId())
			}
		} else {
			_ids.push(chosenTask.getId())
		}

		return _ids;
	}

	const handleMultiAssign = async function () {
		// find ids of selected tasks
		let _ids = getTaskIds();

		const payload = {
			_ids,
			driver: chosenDriver.getPayload(),
			task_status: ASSIGNED_TASK_STATUS
		}

		let sort_number = 10;

		if (payload.driver) {
			const chosenDriversTasks = tasks.getTasksByDriver(payload.driver._id);
			if (chosenDriversTasks.length > 0) {
				const lastTasks = chosenDriversTasks[chosenDriversTasks.length - 1]
				sort_number = lastTasks.sort_number;
			}
		}

		// const _res = await multiAssign(payload)
		let tmpPayload = undefined;
		console.log("multiSelectTasks", multiSelectTasks)
		if (multiSelectTasks.length > 0) {
			for (let task of multiSelectTasks) {
				sort_number += 1;
				tmpPayload = {
					...task,
					driver: chosenDriver,
					task_status: ASSIGNED_TASK_STATUS,
					sort_number,
					multi: true
				}

				tasks.update(tmpPayload)

				updateTask(task._id, tmpPayload);
			}

			// Send notification to driver APP

			
			// multiAassignPushNotification({
			// 	task_ids: pluck(multiSelectTasks, "_id"),
			// 	tasks: multiSelectTasks,
			// 	driver: chosenDriver
			// });
		} else {
			tmpPayload = {
				...chosenTask,
				driver: chosenDriver,
				task_status: ASSIGNED_TASK_STATUS,
				sort_number: sort_number + 1
			}

			tasks.update(tmpPayload);
			updateTask(chosenTask._id, tmpPayload)

		}

		clearModal();
	}

	const handleMultiUnAssign = async function () {
		const unAssignedTasks = tasks.getUnAssignedTasks()

		let defaultSortNum = 99;
		if (unAssignedTasks.length > 0) {
			const maxSortNumObj = unAssignedTasks.reduce(function (prev, current) {
				return (prev.sort_number > current.sort_number) ? prev : current
			}) //returns object

			if (maxSortNumObj) {
				defaultSortNum = maxSortNumObj.sort_number
			}
		}

		// return false;
		let tmpPayload = undefined;

		if (multiSelectTasks.length > 0) {
			for (let task of multiSelectTasks) {
				defaultSortNum += defaultSortNum;
				tmpPayload = {
					...task,
					driver: null,
					task_status: UNASSIGNED_TASK_STATUS,
					sort_number: defaultSortNum
				}
				tasks.update(tmpPayload)
				updateTask(task._id, tmpPayload);
			}
		} else {
			tmpPayload = {
				...chosenTask,
				driver: null,
				task_status: UNASSIGNED_TASK_STATUS,
				sort_number: defaultSortNum + 1
			}

			tasks.update({
				...chosenTask,
				driver: null,
				task_status: UNASSIGNED_TASK_STATUS
			})

			updateTask(chosenTask._id, tmpPayload);
		}

		clearModal();
	}

	return (
		<Dialog
			open={multiAssignTasksModalOpen}
			onClose={handleClose}
			scroll="paper"
		>
			<ModalClose handleClose={handleClose} />
			<DialogTitle id="scroll-dialog-title">Assign Tasks</DialogTitle>
			<DialogContent dividers={true}>
				<Box width={300}>
					<Autocomplete
						sx={{ margin: 0 }}
						id="driver"
						name="driver"
						options={driverOptions}
						getOptionLabel={option => option.name || ""}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						onChange={(e, value) => {
							setChosenDriver(value)
						}}

						renderOption={(props, option) => {
							return (
								<li {...props} key={option._id}>
									{option.name}
								</li>
							);
						}}
						renderInput={params => (
							<TextField
								margin="normal"
								label="Driver"
								fullWidth
								name="driver"
								{...params}
							/>
						)}
					/>
					<Box textAlign={"center"}>
						<Button
							variant='outlined'
							size='small'
							onClick={handleMultiUnAssign}

						>Unassign
						</Button>
						<Button
							variant='contained'
							size='small'
							disabled={chosenDriver === null}
							onClick={handleMultiAssign}
							sx={{ "marginLeft": "12px" }}
						>Assign
						</Button>
					</Box>

				</Box>

			</DialogContent>
		</Dialog>
	);
}