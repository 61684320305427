import AdminEntity from "./AdminEntity";

class AdminsListEntity {
    constructor(admins) {
        this.admins = admins.map(d => new AdminEntity(d))
    }

    getAdmins() {
        return this.admins;
    }

    getTotalAdmins() {
        return (this.admins || []).length
    }

    updateSelectedAdmin(admin) {
        this.admins = this.admins.map(d => {
            const dEntity = new AdminEntity(admin)
            if (d.getId() === dEntity.getId()) {
                d = dEntity
            }

            return d;
        })
    }

    addAdminToList(admin) {
        // console.log("admin", admin)
        this.admins = [...this.admins, new AdminEntity(admin)];
        // console.log("this.admins", this.admins)
    }

    removeAdminFromList(admin) {
        this.admins = this.admins.filter(d => d.getId() !== admin._id)
    }

    getAdminByName(name) {
        return this.admins.find(d => d.getName() === name)
    }

    getAdminById(id) {
        return this.admins.find(d => d.getId() === id)
    }
}

export default AdminsListEntity;