import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import { useTheme } from '@mui/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const ContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	// minHeight: '60vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
	const smUp = useResponsive('up', 'sm');

	const mdUp = useResponsive('up', 'md');

	const theme = useTheme();

	return (
		<Page title="Login" isAuthPage={true}>
			<RootStyle>
				<Container maxWidth="sm" >
					<Card style={{backgroundColor:theme.palette.primary.main,borderTopLeftRadius:0,borderTopRightRadius:0}}>
					<Card style={{ marginTop: 70,borderTopLeftRadius:50,borderTopRightRadius:50,borderBottomLeftRadius:0,borderBottomRightRadius:0 }}>
						<ContentStyle>
							<Typography variant="h4" gutterBottom align="center">
								Sign in to Operation Track System
							</Typography>

							<Typography sx={{ color: 'text.secondary', mb: 5 }} align="center">Enter your details below.</Typography>

							<LoginForm />

							{!smUp && (
								<Typography variant="body2" align="center" sx={{ mt: 3 }}>
									Don’t have an account?{' '}
									<Link variant="subtitle2" component={RouterLink} to="/register">
										Get started
									</Link>
								</Typography>
							)}
						</ContentStyle>
					</Card>
					</Card>
				</Container>
			</RootStyle>
		</Page>
	);
}
