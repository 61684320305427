import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { DARK_COLOR,  WHITE_COLOR,} from '../../constants/colors';

const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: theme.spacing(2),
    background: WHITE_COLOR
}));

export default CustomAccordionDetails;