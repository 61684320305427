import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../settings/TabPanel';
import { GlobalContext } from '../../../contexts/GlobalContext';
import DateSelect from './DateSelect';
import StatusSelect from './StatusSelect';

const tabs = [
    {
        label: "Date",
        component: <DateSelect />
    },
    {
        label: "Status",
        component: <StatusSelect />
    },
]

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function FilterVerticalTabs() {
    const [value, setValue] = React.useState(0);
    const { _globalDispatch } = React.useContext(GlobalContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        _globalDispatch({
            type: "RESET_CHOSEN_DRIVER"
        })
    };

    return (
        <Box
            sx={{ bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {
                    tabs.map((t, i) => <Tab key={t.label} label={t.label} {...a11yProps(i)} />)
                }

            </Tabs>

            {
                tabs.map((t, i) => <TabPanel key={t.label} value={value} index={i} style={{ width: "100%" }} >
                    {t.component}
                </TabPanel>)
            }
        </Box>
    );
}