import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	DirectionsService,
	DirectionsRenderer,
	TrafficLayer,
} from '@react-google-maps/api';
import { driverMarkerIcon, mapCenter, mapInitOptions, markerIcon } from '../../constants/map';

import useMapContext from '../../hooks/useMapContext';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { getTaskDetails } from '../../api/task';
import { getDriverDetails } from '../../api/driver';
import { COMPLETED_TASK_STATUS } from '../../constants/task_status';

const containerStyle = {
	width: "100%",
	height: "100vh"
};

function GoogleMapDriversLocation() {
	const { driver_id, task_id } = useParams();

	const mapRef = useRef(null);
	const { isLoaded } = useJsApiLoader(mapInitOptions);

	const { trafficMode } = useMapContext()
	const [directionResponse, setDirectionResponse] = useState(null);

	const [taskDetails, setTaskDetails] = useState(null);
	const [driverDetails, setDriverDetails] = useState(null);

	// Google Map center
	const center = useMemo(() => (mapCenter),
		[]);

	// Google Map options
	const options = useMemo(
		() => ({
			mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
			disableDefaultUI: true,
			streetViewControl: false,
			fullscreenControl: false,
			zoomControl: true
		}),
		[]
	);

	// Google map directions callback
	const directionsCallback = useCallback((response) => {
		if (response !== null) {
			if (response.status === 'OK') {
				// console.log("response", response)
				setDirectionResponse(response)
			} else {
				// console.log('response: ', response)
			}
		}
	}, [])

	// Google map onload
	const onLoad = useCallback((map) => (mapRef.current = map), []);

	const fethDriversLocation = async () => {
		const _driver_details_response = await getDriverDetails(driver_id);
		if (_driver_details_response.success) {
			setDriverDetails(_driver_details_response.data)
		}
	}

	/**
	 * Get task and driver details initially
	 */
	useEffect(() => {
		(async function () {
			const _task_details_response = await getTaskDetails(task_id);
			if (_task_details_response.success) {
				setTaskDetails(_task_details_response.data)
			}

			await fethDriversLocation()
		})()

	}, []);

	useEffect(() => {
		// console.log("chosenData from useEffect", chosenDate)
		const comInterval = setInterval(fethDriversLocation, 2000); //This will refresh the data at regularIntervals of refreshTime
		return () => clearInterval(comInterval) //Clear interval on component unmount to avoid memory leak
	}, [])

	// if task is not available or completed,return null
	if (!taskDetails || taskDetails.task_status >= COMPLETED_TASK_STATUS) {
		return null
	}

	if (!driverDetails) {
		return null;
	}

	if (!isLoaded) return null;

	return <Box>
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={driverDetails.current_location}
			defaultZoom={10}
			zoom={10}
			options={options}
			onLoad={onLoad}
			onZoomChanged={() => {
				if (mapRef && mapRef.current) {
				}

			}}
			onIdle={() => {
				// console.log("Google map is idle")
			}}
		>
			{/* Traffic layer */}
			{
				trafficMode && <TrafficLayer />
			}

			{/* Driver Marker */}
			<Marker
				position={
					driverDetails.current_location
				}
				// icon={driverMarkerIcon}
				// icon={
				// 	{
				// 		url: require("../../icons/livelocation.png"),
				// 		scaledSize: new window.google.maps.Size(64, 64),
				// 	}
				// }

				icon={{
					path: window.google.maps.SymbolPath.CIRCLE,
					strokeColor: "#367CE7",
					scale: 4
				}}
				title={driverDetails.name}
			/>

			{/* Destination Marker */}
			<Marker
				position={
					taskDetails.destination_address_latlng
				}
				icon={markerIcon("", "intransit")}
				title={taskDetails.recipient_name}
			/>

			{/* {
				!directionResponse && <DirectionsService
					// required
					options={{
						destination: taskDetails.destination_address_latlng,
						origin: driverDetails.current_location,
						travelMode: "DRIVING",
						optimizeWaypoints: true,
					}}

					// required
					callback={directionsCallback}
					// optional
					onLoad={directionsService => {
						// console.log('DirectionsService onLoad directionsService: ', directionsService)
					}}
					// optional
					onUnmount={directionsService => {
						// console.log('DirectionsService onUnmount directionsService: ', directionsService)
					}}

				/>
			}

			{
				directionResponse && <DirectionsRenderer
					// required
					options={{
						directions: directionResponse,
						suppressMarkers: true
					}}
					// optional
					onLoad={directionsRenderer => {
						// console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
					}}
					// optional
					onUnmount={directionsRenderer => {
						// console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
					}}
				/>
			} */}
		</GoogleMap>
	</Box>
}

export default GoogleMapDriversLocation;