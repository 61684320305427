import { postRequest } from "./client";

/**
 * 
 * @param {*} delivery_date 
 * @param {*} statusIn 
 * @param {*} owner_id 
 * @returns 
 */
export const getDashboardData = async (delivery_date, statusIn = [], owner_id = null) => {
    // for exception
    statusIn.push(1.5)
    return postRequest('/api/frontend/all-data', { delivery_date, statusIn, owner_id });
}

/**
 * 
 * @param {*} delivery_date 
 * @param {*} statusIn 
 * @returns 
 */
export const refreshDriversTasks = async (delivery_date, statusIn = []) => {
    // for exception
    statusIn.push(1.5)
    return postRequest('/api/frontend/refresh-drivers-tasks', { delivery_date, statusIn });
}