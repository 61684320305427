import DriverEntity from "./DriverEntity";
import TeamEntity from "./TeamEntity";

class TeamsListEntity {
    constructor(teams) {
        this.teams = teams.map(d => new TeamEntity(d)).map(d => {
            d.driver_ids.map(t => new DriverEntity(t))
            return d;
        })
    }

    getTeams() {
        return this.teams;
    }

    getTotalTeams() {
        return (this.teams || []).length || 0
    }

    updateSelectedTeam(team) {
        this.teams = this.teams.map(d => {
            const dEntity = new TeamEntity(team)
            if (d.getId() === dEntity.getId()) {
                d = dEntity
            }

            return d;
        })
    }

    addTeamToList(team) {
        // console.log("team", team)
        this.teams = [...this.teams, new TeamEntity(team)];
        // console.log("this.teams", this.teams)
    }

    removeTeamFromList(team) {
        this.teams = this.teams.filter(d => d.getId() !== team._id)
    }

    getTeamByName(name) {
        return this.teams.find(d => d.getName() === name)
    }

    getTeamById(id) {
        return this.teams.find(d => d.getId() === id)
    }
}

export default TeamsListEntity;