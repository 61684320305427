import HubEntity from "./HubEntity";

class HubsListEntity {
    constructor(hubs) {
        this.hubs = hubs.map(d => new HubEntity(d))
    }

    getHubs() {
        return this.hubs;
    }

    getTotalHubs() {
        return (this.hubs || []).length
    }

    updateSelectedHub(hub) {
        this.hubs = this.hubs.map(d => {
            const dEntity = new HubEntity(hub)
            if (d.getId() === dEntity.getId()) {
                d = dEntity
            }

            return d;
        })
    }

    addHubToList(hub) {
        // console.log("hub", hub)
        this.hubs = [...this.hubs, new HubEntity(hub)];
        // console.log("this.hubs", this.hubs)
    }

    removeHubFromList(hub) {
        this.hubs = this.hubs.filter(d => d.getId() !== hub._id)
    }

    getHubByName(name) {
        return this.hubs.find(d => d.getName() === name)
    }

    getHubById(id) {
        return this.hubs.find(d => d.getId() === id)
    }
}

export default HubsListEntity;