import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import { useAuthHelper } from '../../../utils/auth';
import { loginRequest } from '../../../api/auth';

import Alert from '@mui/material/Alert';
import { _setTimezoneLocalStorage } from '../../../utils/utils';
// ----------------------------------------------------------------------

export default function LoginForm() {
	const [apiError, setAPIError] = useState('');
	const { handleLogin } = useAuthHelper();

	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
		password: Yup.string().required('Password is required'),
	});

	const defaultValues = {
		email: '',
		password: '',
		remember: true,
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (values) => {
		try {
			setAPIError('');
			const res = await loginRequest(
				values.email,
				values.password
			);

			if (res.success === true) {
				if (res.user) {
					console.log("navigating to dashboard")

					_setTimezoneLocalStorage(res.user)
					handleLogin(res.user);

					navigate('/dashboard/app', { replace: true, state: { history: 'login' } });
				}
			}
			else {
				setAPIError(res.msg)
			}
		} catch (err) {
			setAPIError('Something went wrong. Please try again.');
			console.log("Err", err);
		}

	};

	const handleForgotPassword = async () => {
		navigate('/forgot-password', { replace: true, state: { history: 'login' } });
	}

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				<RHFTextField name="email" label="Email address" defaultValue="Prefilled Data" InputLabelProps={{
					shrink: true,
				}} />

				<RHFTextField
					name="password"
					label="Password"
					type={showPassword ? 'text' : 'password'}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			{
				apiError && <Alert severity="error">{apiError}</Alert>
			}

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
				<Link
					style={{
						cursor: "pointer"
					}}
					onClick={handleForgotPassword}
					variant="subtitle2" underline="hover" sx={{ ml: 2 }}>
					Forgot password?
				</Link>
			</Stack>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<LoadingButton fullwidth size="large" type="submit" variant="contained" loading={isSubmitting}>
					Login
				</LoadingButton>
			</div>
		</FormProvider>
	);
}
