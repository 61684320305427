import { Button, Grid, Box } from '@mui/material'
import React from 'react'
import useGlobalContext from '../../hooks/useGlobalContext'
import ClearIcon from '@mui/icons-material/Clear';
import Stack from '@mui/material/Stack';
import { confirmAlert } from 'react-confirm-alert'; // Import

export default function MultiTaskSelected() {
    const { multiSelectTasks, _globalDispatch, current_user } = useGlobalContext();
    // if (multiSelectTasks.length < 1) {
    //     return null
    // }

    const openAssignForm = () => {
        _globalDispatch({
            type: "OPEN_MULTI_TASK_ASSIGN_FORM"
        })
    }

    return (<Box style={{
        position: "absolute",
        top: 87,
        left: "42%"
    }}
    >
        <Grid container spacing={2} width={300}>
            <Grid item xs={6} sm={6} md={8}>
                {
                    multiSelectTasks.length > 0 && <Box bgcolor="green" sx={{ paddingX: 1 }}>
                        <Stack direction="row" alignItems={"center"}>
                            <Button variant='text' onClick={openAssignForm} sx={{ width: "220px", color: "white" }}>
                                {`${multiSelectTasks.length} Tasks selected`}
                            </Button>
                            <ClearIcon
                                style={{ cursor: "pointer", color: "white" }}
                                onClick={() => {

                                    _globalDispatch({
                                        type: "CLEAR_SELECTED_TASKS"
                                    })

                                    _globalDispatch({
                                        type: "SET_POLYLINE_TASKS",
                                        payload: null
                                    })

                                    _globalDispatch({
                                        type: "SET_INFOWINDOW",
                                        payload: null
                                    })
                                }}
                            />
                        </Stack>
                    </Box>
                }

            </Grid>
            {
                current_user && current_user.plan_details && current_user.plan_details.basic_route_optimization && <Grid item xs={6} sm={6} md={4}>
                    <Box bgcolor="green" sx={{ paddingX: 1 }}>
                        <Stack direction="row" alignItems={"center"}>


                            <Button variant='text' onClick={() => {
                                if (multiSelectTasks.length < 2) {
                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <div className="custom-ui__overlay">
                                                    <div className="custom-ui">
                                                        <div className="custom-ui__header">
                                                            <h2>No tasks selected</h2>
                                                        </div>
                                                        <div className="custom-ui__body">
                                                            <p>Please make sure to select at least 2 tasks to optimize</p>
                                                        </div>
                                                        <div
                                                            className="custom-ui__footer"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center', // Center horizontally
                                                                alignItems: 'center',    // Center vertically (optional)
                                                                padding: '10px',
                                                            }}
                                                        >
                                                            <button
                                                                className="custom-ui__button custom-ui__button--confirm"
                                                                style={{
                                                                    backgroundColor: '#ff4d4f', // Red alert color
                                                                    color: '#fff',
                                                                    padding: '10px 20px',
                                                                    fontSize: '16px',
                                                                    border: 'none',
                                                                    borderRadius: '4px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    onClose();
                                                                }}
                                                            >
                                                                Okay
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    });

                                    return false;
                                }


                                _globalDispatch({
                                    type: "OPEN_ROUTE_OPTIMIZE_FORM"
                                });

                            }} sx={{ color: "white" }}>
                                Optimize
                            </Button>

                        </Stack>
                    </Box>


                </Grid>

            }

        </Grid>
    </Box>
    )
}
