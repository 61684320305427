export const extractDriver = (driver = {}) => {
    if (!driver) {
        return {}
    }

    const {
        _id = null,
        name,
        phone,
        address,
        address_latlng,
        email,
        vehicle_type,
        vehicle_capacity,
        vehicle_capacity_type,
        vehicle_model,
        vehicle_license_plate,
        vehicle_color,
        online_status,
        current_location,
        last_active,
        timewindow,
        cost_per_hour,
        cost_per_km
    } = driver;

    return {
        _id,
        name,
        phone,
        address,
        address_latlng,
        email,
        vehicle_type,
        vehicle_capacity,
        vehicle_capacity_type,
        vehicle_model,
        vehicle_license_plate,
        vehicle_color,
        online_status,
        current_location,
        last_active,
        cost_per_hour,
        cost_per_km,
        timewindow
    }
}

export const extractAdmin = (driver = {}) => {
    if (!driver) {
        return {};
    }

    const {
        _id,
        full_name,
        phone,
        email,
        owner,
        role
    } = driver;

    return {
        _id,
        full_name,
        phone,
        email,
        owner,
        role
    }
}

export const extractUser = (user = {}) => {
    if (!user) {
        return {};
    }

    const {
        _id,
        first_name,
        last_name,
        full_name,
        phone,
        email,
        plan_details,
        organization_settings,
        accessibility_settings,
        driver_app_settings,
        owner,
        user_image,
        role
    } = user;

    return {
        _id,
        first_name,
        last_name,
        full_name,
        phone,
        email,
        plan_details,
        organization_settings,
        accessibility_settings,
        driver_app_settings,
        user_image,
        owner,
        role
    }
}