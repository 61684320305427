import * as React from 'react';
import { useCallback } from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button'
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Formik, Form } from 'formik'

import { Box, Grid, CircularProgress, IconButton } from "@mui/material";
import { toast } from 'react-toastify'

import { useState } from "react";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useGlobalContext from '../../../hooks/useGlobalContext';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { deepCopy } from '../../../utils/utils'
import { deleteHub, updateHub } from '../../../api/hub'
import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ModalClose from '../../common/ModalClose'

import HubEntity from '../../../entities/HubEntity'
import '../deletemodal.css'



const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: 'none',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'none',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'none',
		},
		'&:hover fieldset': {
			borderColor: 'none',
		},
		'&.Mui-focused fieldset': {
			borderWidth: 0.1
		},
	},
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "100%" }}>
	<CssTextField
		id="search-bar"
		className="text"
		onInput={(e) => {
			setSearchQuery(e.target.value);
		}}
		fullWidth
		label="Search"
		variant="outlined"
		placeholder="Search..."
		size="small"
	/>
</Box>
);

export default function HubsList() {
	const [open, setOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState("");
	const { _globalDispatch, hubs, chosenHub: chosenItem, chosenHub, teams } = useGlobalContext();

	const { isLoaded } = useJsApiLoader(mapInitOptions);
	const [autocomplete, setAutoComplete] = useState(undefined);

	// const driverOptions = drivers.getDrivers();
	const teamsOption = teams.getTeams();

	// console.log("teamsOption", teamsOption)
	// console.log("chosenHub", chosenHub)

	const onLoad = useCallback((auto) => {
		setAutoComplete(auto)
	}, []);

	function handleClose() {
		setOpen(false)
	}

	function handleClickOpen(item) {
		_globalDispatch({
			type: "SET_CHOSEN_HUB",
			payload: item
		})
		setOpen(true)
	}

	const handleDeleteConfirmation = async (item) => {


		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-ui__overlay">
						<div className="custom-ui">
							<div className="custom-ui__header">
								<h1>Are you sure?</h1>
							</div>
							<div className="custom-ui__body">
								<p>Do you really want to delete? This process cannot be undone.</p>
							</div>
							<div className="custom-ui__footer">
								<button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
								<button
									className="custom-ui__button custom-ui__button--confirm"
									onClick={() => {
										handleDelete(item);
										onClose();
									}}
								>
									Yes, Delete it!
								</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	const handleDelete = async (item) => {
		const _res = await deleteHub(item._id);
		if (_res.success) {
			// update tasks list

			hubs.removeHubFromList(item)

			_globalDispatch({
				type: "RESET_CHOSEN_HUB"
			});

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			toast("Hub deleted succesfully.")
		}
	}


	const data = hubs.getHubs();

	// console.log("chosenItem", chosenItem)

	const filterData = (query, data) => {
		if (!query) {
			return data;
		} else {
			return data.filter((d) => d.hub_name.toLowerCase().includes(query.toLowerCase()));
		}
	};

	const dataFiltered = filterData(searchQuery, data);

	return (<>
		<SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

		{chosenHub ? <Dialog open={open} onClose={handleClose} maxWidth='sm'>
			<ModalClose handleClose={handleClose} />
			<DialogTitle>{`Edit hub: ${chosenHub.hub_name}`}</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={{
						userId: chosenHub._id || '',
						hub_name: chosenHub.hub_name || '',
						full_address: chosenHub.full_address || '',
						full_address_latlng: chosenHub.full_address_latlng || '',
						teams: chosenHub.teams || {}

					}}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const { _id } = chosenHub;
						const _payload = deepCopy(values);
						_payload._id = _id;

						setSubmitting(true)
						const _res = await updateHub(_id, _payload)

						if (_res.success) {
							toast('Hub details updated successfully.');
							// setOpen(false);

							hubs.updateSelectedHub(_payload);

							_globalDispatch({
								type: "SET_CHOSEN_HUB",
								payload: new HubEntity(_payload)
							})

							_globalDispatch({
								type: "INCREASE_REFRESH_COUNTER"
							});
						}

						setSubmitting(false)
					}}
				// validationSchema={AdminValidation}
				>
					{props => {
						const { values, isSubmitting, handleSubmit, setFieldValue } = props

						return (
							<Form>
								<Grid container spacing={2}>
									{/* Name */}
									<Grid item xs={12}>
										<TextField
											label='Hub Name'
											name='hub_name'
											fullWidth
											variant='outlined'
											margin='normal'
											value={values.hub_name}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={props.errors.hub_name && props.touched.hub_name}
										/>
									</Grid>

									<Grid item xs={12}>
										{
											isLoaded && <AddressAutoComplete
												onLoad={onLoad}
												onPlaceChanged={() => {

													const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

													setFieldValue("full_address", autocomplete.getPlace().formatted_address)

													setFieldValue("full_address_latlng", addressPayload.geometry_location);

													addUpdateAddress(addressPayload);
												}}
											>
												<TextField
													fullWidth
													label="Full Address"
													name="full_address"
													variant="outlined"
													margin="normal"
													value={values.full_address}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													error={props.errors.full_address && props.touched.full_address}

												/>
											</AddressAutoComplete>
										}
									</Grid>

									{/* <Grid item xs={12}>
											<FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
												<Autocomplete
													sx={{ margin: 0 }}
													id="teams"
													name="Teams"
													options={teamsOption}
													multiple={true}
													getOptionLabel={option => option.team_name || ""}
													isOptionEqualToValue={(option, value) => option._id === value._id}
													onChange={(e, value) => {
														setFieldValue(
															"teams",
															value !== null ? value : null
														);
													}}
													value={values?.teams}
													renderOption={(props, option) => {
														return (
															<li {...props} key={option._id}>
																{option.team_name}
															</li>
														);
													}}
													renderInput={params => (
														<TextField
															margin="normal"
															label="Teams"
															fullWidth
															name="teams"
															{...params}
														/>
													)}
												/>
											</FormControl>
										</Grid> */}

									<Grid item xs={12}>

										{isSubmitting && (
											<Box
												style={{
													position: 'absolute',
													top: '50%',
													left: '50%'
												}}
											>
												<CircularProgress />
											</Box>
										)}
									</Grid>
									<Grid item xs={12}>
										<Box textAlign='center'>
											<Button
												variant='contained'
												type='submit'
												color='primary'
												onClick={handleSubmit}
												disabled={isSubmitting}
											>
												Update
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Form>
						)
					}}
				</Formik>
			</DialogContent>
		</Dialog> : ''}
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 450 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>S.No</TableCell>
						<TableCell align="left"> Name</TableCell>
						<TableCell align="left">Address</TableCell>
						<TableCell align="left">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dataFiltered && dataFiltered.map((item, i) => (
						<TableRow
							key={item._id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row">
								{i + 1}
							</TableCell>

							<TableCell align="left">{item.hub_name}</TableCell>
							<TableCell align="left">{item.full_address}</TableCell>
							<TableCell align="left">
								<IconButton edge="end" aria-label="edit" onClick={() => handleClickOpen(item)}>
									<EditIcon />
								</IconButton>
								<IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(item)}>
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</>
	)
}