import moment from "moment-timezone"
import { MOMENT_DATETIME_OUTPUT_DB, MOMENT_OUTPUT_DB, MOMENT_OUTPUT_SS, getTimezone, timezone } from "../constants/moment-date-formats"
import { APP_MODE } from "../config";
export const getToday = () => {
    const dev_mode = APP_MODE;
    const timezone = getTimezone()
    switch (dev_mode.toUpperCase()) {
        case "LOCAL":
            return "2023-07-19"
        default:
            return moment().tz(timezone).format(MOMENT_OUTPUT_DB)
    }
}

export const getNow = () => {
    const timezone = getTimezone()
    return moment().tz(timezone).format(MOMENT_OUTPUT_SS)
}

export const getChosenDate = () => {
    const local_chosen_date = localStorage.getItem("@chosen_date");
    if (local_chosen_date) {
        return local_chosen_date
    }

    return getToday();
}

export const getFormattedDateWithTime = (dateString, hour_min) => {
    if (!hour_min) {
        return null
    }

    console.log("hour_min", hour_min)

    const _h_m_ = hour_min.split(":");
    // Parse the given date
    let date = moment(dateString);

    console.log("_h_m", _h_m_)

    // Set the time to 10:00
    date.set({ hour: _h_m_[0], minute: _h_m_[1], second: 0, millisecond: 0 });

    // Format the date in local time
    return date.format("YYYY-MM-DD HH:mm");
}

export const addHoursMinutesAmPm = (date, hoursToAdd, minutesToAdd, ampm) => {
    let time = moment(date).startOf('day'); // Start with the given date at midnight

    // Set the hours and minutes
    time = time.add(hoursToAdd, 'hours').add(minutesToAdd, 'minutes');

    // Adjust for AM/PM
    if (ampm === 'PM' && hoursToAdd < 12) {
        time = time.add(12, 'hours'); // Convert to PM if needed
    } else if (ampm === 'AM' && hoursToAdd === 12) {
        time = time.subtract(12, 'hours'); // Convert 12 AM to 0 hours
    }

    // Combine with the original date
    return moment(date).hour(time.hour()).minute(time.minute());
}

export const getHourMinuteAmPm = (dateString) => {
    const date = moment(dateString, MOMENT_DATETIME_OUTPUT_DB).tz(timezone);

    // Extract hour, minute, and AM/PM
    const hour = date.format('hh');        // 12-hour format without leading 0 for single-digit hours
    const minute = date.format('mm');      // Minutes with leading 0
    const ampm = date.format('A');         // AM or PM

    return { hour: parseInt(hour), minute: parseInt(minute), ampm };
}

