import * as yup from "yup";
require("yup-phone");

const AdminEditValidation = yup.object().shape({
    first_name: yup
        .string()
        .min(3, "Too Short !")
        .max(30, "Too Long !")
        .required("Required !"),
    last_name: yup
        .string()
        .min(3, "Too Short !")
        .max(30, "Too Long !")
        .required("Required !"),

    email: yup.string().email("Enter a Vaid Email").required("Email is Required"),
    phone: yup
        .string()
        // .phone()
        .required("Required !"),

    password: yup.string(),
    confirm_password: yup.string().when("password", {
        is: value => value,
        then: yup.string()
            .required()
            .oneOf([yup.ref("password"), null], "Must match new password."),
        otherwise: yup.string()
    })

});

export default AdminEditValidation;
