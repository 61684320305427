import { useEffect, useState } from 'react'

export default function useFetchData(fetchData, filter = {}, dependencies = []) {
    const [fetchingState, setFetchingState] = useState(0);
    const [data, setData] = useState([]);
    useEffect(() => {
        (async function () {
            setFetchingState(1);
            const _res = await fetchData(filter);
            if (_res.success) {
                setData(_res.data)
            }

            setFetchingState(2);
        })()
    }, [...dependencies])

    return {
        fetchingState,
        data
    }
}
