import * as yup from "yup";
require("yup-phone");

const YupValidation = yup.object().shape({
    name: yup
        .string()
        .min(3, "Too Short !")
        .max(30, "Too Long !")
        .required("Required !"),

    email: yup.string().email("Enter a Vaid Email").required("Email is Required"),
    phone: yup
        .string()
        .phone()
        .required("Required !"),

    // confirm_password: yup.string().when("password", {
    //     is: value => value,
    //     then: yup.string()
    //         .required()
    //         .oneOf([yup.ref("password"), null], "Must match new password."),
    //     otherwise: yup.string()
    // })
});

export default YupValidation;
