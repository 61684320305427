import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COMPLETED_TASK_STATUS, ASSIGNED_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../../constants/task_status';
import useExportContext from '../../hooks/useExportContext';
import useGlobalContext from '../../hooks/useGlobalContext';

export default function DriverSelect() {
    const [status, setStatus] = React.useState('');
    // useExpo
    const { chosenDriver, _exportDispatch } = useExportContext();
    const { drivers } = useGlobalContext();
    console.log("drivers", drivers)
    const handleChange = (event) => {
        setStatus(event.target.value);

        _exportDispatch({
            type: "SET_CHOSEN_DRIVER",
            payload: event.target.value
        })
    };

    return (
        <Box sx={{ minWidth: 120 }} my={2}>
            <FormControl fullWidth>
                <InputLabel>Drivers</InputLabel>
                <Select
                    value={chosenDriver}
                    label="Drivers"
                    onChange={handleChange}
                >
                    <MenuItem value="all">All Drivers</MenuItem>
                    {
                        drivers.getDrivers().map(d => <MenuItem key={d.getId()} value={d.getId()}>{d.getName()}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    );
}