import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/BorderColor';
import { IconButton, Typography } from '@mui/material';

import Avatar from '@mui/material/Avatar';

import { Box, CircularProgress, Grid } from "@mui/material";
import { useState, useCallback } from "react";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import { GlobalContext } from '../../../contexts/GlobalContext';

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Formik, Form } from 'formik'
import DriverValidation from '../../../utils/form/validations/DriverValidation'
import VehicleSelect from './VehicleSelect'
import { deleteDriver, updateDriver } from '../../../api/driver'
import { toast } from 'react-toastify'
import ModalClose from '../../common/ModalClose'
import DriverEntity from '../../../entities/DriverEntity'
import { confirmAlert } from 'react-confirm-alert'; // Import
import useGlobalContext from '../../../hooks/useGlobalContext'

import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil'
import { addUpdateAddress } from '../../../api/address'
import { deepCopy } from '../../../utils/utils'
import VehicleCapacityType from './VehicleCapacityTypeSelect'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: 'none',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'none',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'none',
		},
		'&:hover fieldset': {
			borderColor: 'none',
		},
		'&.Mui-focused fieldset': {
			borderWidth: 0.1
		},
	},
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "100%" }}>
	<CssTextField
		id="search-bar"
		className="text"
		onInput={(e) => {
			setSearchQuery(e.target.value);
		}}
		fullWidth
		label="Search"
		variant="outlined"
		placeholder="Search..."
		size="small"

	/>
</Box>
);

export default function DriversList() {
	const { isLoaded } = useJsApiLoader(mapInitOptions);
	const [autocomplete, setAutoComplete] = useState(undefined);

	const onLoad = useCallback((auto) => {
		setAutoComplete(auto)
	}, []);
	const [open, setOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState("");
	const { _globalData, _globalDispatch } = React.useContext(GlobalContext);
	const { chosenDriver, getOwner, tasks } = useGlobalContext();


	const { drivers, chosenDriver: chosenItem } = _globalData;

	const data = drivers.getDrivers();

	const filterData = (query, data) => {
		if (!query) {
			return data;
		} else {
			return data.filter((d) => d.name.toLowerCase().includes(query.toLowerCase()));
		}
	};
	function handleClose() {
		setOpen(false)
	}

	const dataFiltered = filterData(searchQuery, data);

	const handleDeleteConfirmation = async (item) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-ui__overlay">
						<div className="custom-ui">
							<div className="custom-ui__header">
								<h1>Are you sure?</h1>
							</div>
							<div className="custom-ui__body">
								<p>Do you really want to delete? This process cannot be undone.</p>
							</div>
							<div className="custom-ui__footer">
								<button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
								<button
									className="custom-ui__button custom-ui__button--confirm"
									onClick={() => {
										handleDelete(item);
										onClose();
									}}
								>
									Yes, Delete it!
								</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	const handleDelete = async (item) => {
		// console.log(`Delete item with id: ${id}`);
		_globalDispatch({
			type: "SET_CHOSEN_DRIVER",
			payload: item
		})

		let id = item._id;

		const _res = await deleteDriver(id);
		if (_res.success) {
			// update tasks list
			if (_res.tasks) {
				for (let t of _res.tasks) {
					tasks.update(t)
				}
			}

			drivers.removeDriverFromList(item)

			_globalDispatch({
				type: "RESET_CHOSEN_DRIVER"
			});

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			toast("Driver deleted succesfully.")
		}
	};


	const handleEdit = (item) => {
		console.log('item', item);
		_globalDispatch({
			type: "SET_CHOSEN_DRIVER",
			payload: item
		})
		setOpen(true)
	};

	// console.log("dataFiltered", dataFiltered)

	return (<>
		<SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
		{
			chosenDriver ? <Dialog open={open} onClose={handleClose} maxWidth='sm'>

				<ModalClose handleClose={handleClose} />
				<DialogTitle>{`Edit Driver: ${chosenDriver.name || ''}`}</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							userId: chosenDriver._id || '',
							name: chosenDriver.name || '',
							phone: chosenDriver.phone || '',
							address: chosenDriver.address || '',
							address_latlng: chosenDriver.address_latlng || '',
							email: chosenDriver.email || '',
							vehicle_type: chosenDriver.vehicle_type || "car",
							vehicle_capacity: chosenDriver.vehicle_capacity || '',
							vehicle_capacity_type: chosenDriver.vehicle_capacity_type || 'number',
							vehicle_model: chosenDriver.vehicle_model || '',
							vehicle_license_plate: chosenDriver.vehicle_license_plate || '',
							vehicle_color: chosenDriver.vehicle_color || '',
							cost_per_hour: chosenDriver.cost_per_hour || '',
							cost_per_km: chosenDriver.cost_per_km || '',
							cost_per_mile: chosenDriver.cost_per_km ? (chosenDriver.cost_per_km * 1.60934).toFixed(2) : '',
						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							const { _id } = chosenDriver;
							const driverPayload = deepCopy(values);
							driverPayload._id = _id;
							driverPayload.owner = getOwner();

							setSubmitting(true)
							const _res = await updateDriver(_id, driverPayload)

							if (_res.success) {
								toast('Driver details updated successfully.');
								// setOpen(false);

								drivers.updateSelectedDriver(driverPayload);

								_globalDispatch({
									type: "SET_CHOSEN_DRIVER",
									payload: new DriverEntity(driverPayload)
								})

								_globalDispatch({
									type: "INCREASE_REFRESH_COUNTER"
								});
							} else {
								toast.error(_res.msg)
							}

							setSubmitting(false)
						}}
						validationSchema={DriverValidation}
					>
						{props => {
							const { values, isSubmitting, handleSubmit, setFieldValue } = props

							return (
								<Form>
									{/* Name */}
									<TextField
										label='Name'
										name='name'
										fullWidth
										variant='outlined'
										margin='normal'
										value={values.name}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										error={props.errors.name && props.touched.name}
									/>

									{/* Phone */}
									<TextField
										label='Phone'
										name='phone'
										fullWidth
										variant='outlined'
										margin='normal'
										value={values.phone}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										error={props.errors.phone && props.touched.phone}
									/>

									{
										isLoaded && <AddressAutoComplete
											onLoad={onLoad}
											onPlaceChanged={() => {
												const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

												setFieldValue("address", autocomplete.getPlace().formatted_address)

												setFieldValue("address_latlng", addressPayload.geometry_location);

												addUpdateAddress(addressPayload);
											}}
										>
											<TextField
												fullWidth
												label="Address"
												name="address"
												variant="outlined"
												margin="normal"
												value={values.address}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.address && props.touched.address}

											/>
										</AddressAutoComplete>
									}

									{/* Email */}
									<TextField
										label='Email'
										name='email'
										type='email'
										fullWidth
										variant='outlined'
										margin='normal'
										value={values.email}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										error={props.errors.email && props.touched.email}
									/>

									<Grid container spacing={2}>
										<Grid item xs={12} marginTop={1}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													_globalDispatch({
														type: "OPEN_TIMEWINDOW_MODAL"
													})
												}}
											>
												Schedule
											</Button>
										</Grid>
										<Grid item xs={6}>
											<VehicleSelect
												chosenVal={values.vehicle_type}
												callBack={(newVal) => {
													setFieldValue("vehicle_type", newVal)
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											{/* Vehicle Model */}
											<TextField
												label='Vehicle Model'
												name='vehicle_model'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.vehicle_model}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={
													props.errors.vehicle_model && props.touched.vehicle_model
												}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<VehicleCapacityType
												chosenVal={values.vehicle_capacity_type}
												callBack={(newVal) => {
													setFieldValue("vehicle_capacity_type", newVal)
												}}
											/>
										</Grid>
										<Grid item xs={6}>

											<TextField
												label='Vehicle Capacity'
												name='vehicle_capacity'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.vehicle_capacity}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={
													props.errors.vehicle_capacity &&
													props.touched.vehicle_capacity
												}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												label='Vehicle License Plate'
												name='vehicle_license_plate'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.vehicle_license_plate}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={
													props.errors.vehicle_license_plate &&
													props.touched.vehicle_license_plate
												}
											/>
										</Grid>

										<Grid item xs={6}>
											<TextField
												label='Vehicle Color'
												name='vehicle_color'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.vehicle_color}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={
													props.errors.vehicle_color && props.touched.vehicle_color
												}
											/>
										</Grid>
									</Grid>
									{/* Cost */}
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<TextField
												label='Cost per hour'
												name='cost_per_hour'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.cost_per_hour}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={
													props.errors.cost_per_hour &&
													props.touched.cost_per_hour
												}
											/>
										</Grid>

										<Grid item xs={4}>
											<TextField
												label='Cost per kilometer'
												name='cost_per_km'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.cost_per_km}
												// onChange={props.handleChange}
												onChange={val => {
													setFieldValue("cost_per_km", val.currentTarget.value)
													setFieldValue("cost_per_mile", (val.currentTarget.value * 1.60934).toFixed(2))
												}}
												onBlur={props.handleBlur}
												error={
													props.errors.cost_per_km && props.touched.cost_per_km
												}
											/>
										</Grid>

										<Grid item xs={4}>
											<TextField
												label='Cost per mile'
												name='cost_per_mile'
												type='text'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.cost_per_mile}
												// onChange={props.handleChange}
												onChange={val => {
													// setFieldValue("cost_per_mile", val)
													// setFieldValue("cost_per_km", val * 1.60934)
													setFieldValue("cost_per_mile", val.currentTarget.value)
													setFieldValue("cost_per_km", (val.currentTarget.value / 1.60934).toFixed(2))
												}}
												onBlur={props.handleBlur}
												error={
													props.errors.cost_per_mile && props.touched.cost_per_mile
												}
											/>
										</Grid>
									</Grid>


									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												label="Password"
												name="password"
												type="password"
												fullWidth
												variant="outlined"
												margin="normal"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.password && props.touched.password}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												label="Confirm password"
												name="confirm_password"
												type="password"
												fullWidth
												variant="outlined"
												margin="normal"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.confirm_password && props.touched.confirm_password}
											/>
										</Grid>
									</Grid>

									{isSubmitting && (
										<Box
											style={{
												position: 'absolute',
												top: '50%',
												left: '50%'
											}}
										>
											<CircularProgress />
										</Box>
									)}

									<Box textAlign='center'>
										<Button
											variant='contained'
											type='submit'
											color='primary'
											onClick={handleSubmit}
											disabled={isSubmitting}
										>
											Update
										</Button>
									</Box>
								</Form>
							)
						}}
					</Formik>
				</DialogContent>
			</Dialog> : ''}

		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 450 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>S.No</TableCell>
						<TableCell align="left">Driver's Name</TableCell>
						<TableCell align="left">Mobile Number</TableCell>
						<TableCell align="left">Vehicle Type</TableCell>
						<TableCell align="left">Vehicle Number </TableCell>
						<TableCell align="left">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dataFiltered && dataFiltered.map((item, i) => (
						<TableRow
							key={item._id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row">
								{i + 1}
							</TableCell>
							<TableCell align="left" sx={{ width: '200px' }}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Avatar src={item.driver_image} alt={item.name} sx={{ marginRight: '8px' }} />
									<Typography variant="body1" >
										{item.name}
									</Typography>
								</Box></TableCell>
							<TableCell align="left">{item.phone}</TableCell>
							<TableCell align="left">{item.vehicle_type}</TableCell>
							<TableCell align="left">{item.vehicle_license_plate}</TableCell>
							<TableCell align="left">
								<IconButton edge="end" aria-label="edit" onClick={() => handleEdit(item)}>
									<EditIcon />
								</IconButton>
								<IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(item)}>
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</>
	)
}