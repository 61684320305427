import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    
  });

function TabPanel (props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();


    return (
        <Box
       
            // overflow={"hidden"}
            // maxHeight={"1234px"}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}

            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}  >
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default TabPanel;