import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment-timezone';

export default function TimezoneSelect({ value, onSelect, country = {
    code: "US",
    label: "United States",
    phone: "1"
}
}) {
    let timezones = [];
    if (country) {
        timezones = moment.tz.zonesForCountry(country.code, true);
    }

    let defaultTimezone = {
        name: "America/Los_Angeles",
        offset: 420
    }
    return (
        <Autocomplete
            options={timezones}
            value={value}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(event, newVal) => {
                onSelect(newVal)
            }}
            renderInput={(params) => <TextField {...params} label="Timezone" />}
        />
    );
}