import React, { createContext, useReducer } from 'react';
import { MapReducer } from '../reducers/MapReducer';

export const MapContext = createContext(null);
const init = initialArg => {
    return {
        trafficMode: initialArg.trafficMode,
        satelliteMode: initialArg.satelliteMode,
        clusterTasks: initialArg.clusterTasks,
        infoWindowPosition: initialArg.infoWindowPosition,
        showInfoWindow: initialArg.showInfoWindow,
    }
}

const MapContextProvider = (props) => {
    const [_mapContext, _mapDispatch] = useReducer(MapReducer, {
        trafficMode: false,
        satelliteMode: false,
        clusterTasks: [],
        infoWindowPosition: null,
        showInfoWindow: false,
    }, init);

    return (
        <MapContext.Provider value={{ _mapContext, _mapDispatch }}>
            {props.children}
        </MapContext.Provider>
    );
};

export default MapContextProvider;
