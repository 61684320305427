import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

import DashboardContainer from './DashboardContainer';
import GlobalContextProvider from '../contexts/GlobalContext';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, public_mode = false, title = '', meta, isAuthPage = false, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`Operation Track | ${title}`}</title>
      {meta}
    </Helmet>
    {
      isAuthPage ? <Box ref={ref} {...other}>
        {children}
      </Box> :
        <GlobalContextProvider>
          <DashboardContainer children={children} public_mode={public_mode} />
        </GlobalContextProvider>
    }

  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
