import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';

export const options = [
    {
        value: 'anywhere',
        label: 'Anywhere',
    },
    {
        value: 'teams_hub',
        label: 'Teams hub',
    },
    {
        value: 'drivers_location',
        label: 'Drivers location',
    },
];

export default function StartFromInput() {
    const { _route_optimize_dispatch, start_from } = useRouteOptimizeContext();

    const handleSelect = (event) => {
        _route_optimize_dispatch({
            type: "SET_STARTING_FROM",
            payload: event.target.value
        })
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="outlined-select-start-from"
                    select
                    label="Start from"
                    defaultValue="drivers_location"
                    helperText="Please select the starting point"
                    onChange={handleSelect}
                    value={start_from}
                >
                    {
                        options.map((option) => (
                            <MenuItem key={option.value} value={option.value} onSelect={handleSelect}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </div>
        </Box>
    );
}
