import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Formik, Form, Field } from "formik";
import { Box } from '@material-ui/core';

import { CircularProgress, FormLabel, Grid } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useAuthContext from '../../../hooks/useAuthContext';
import { updateUserPassword, updateNewUserSetting } from '../../../api/user';
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../../common/ModalClose';
import UserSettingValidation from '../../../utils/form/validations/UserSettingValidation';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { deepCopy } from '../../../utils/utils';

export default function UserSettingsForm() {
    const { current_user: user = {}, _globalDispatch } = useGlobalContext();
    const { accessibility_settings = {}, _id } = user;
    // console.log("user", user)
    const [open, setOpen] = useState(false);

    function handleClose() {
        setOpen(false);
    }

    const CustomFileInput = ({ field, form }) => {
        const handleFileChange = (event) => {
            const file = event.currentTarget.files[0];
            form.setFieldValue(field.name, file);
        };

        return (
            <input
                type="file"
                name={field.name}
                onChange={handleFileChange}
            />
        );
    };

    function handleClickOpen() {
        setOpen(true);
    }

    return (
        <div>
            <Formik
                initialValues={{
                    full_name: user.full_name || `${user.first_name} ${user.last_name}` || "",
                    email: user.email || "",
                    phone: user.phone || "",
                    // larger_marker: accessibility_settings.larger_marker || false,
                    // keyboard_shortcut: accessibility_settings.keyboard_shortcut || false,
                    // cluster_pin: accessibility_settings.cluster_pin || false,

                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const payload = {
                        accessibility_settings: {
                            larger_marker: values.larger_marker,
                            keyboard_shortcut: values.keyboard_shortcut,
                            cluster_pin: values.cluster_pin
                        },
                        full_name: values.full_name,
                        email: values.email,
                        phone: values.phone,
                        file: values.file,
                        _id: _id
                    }

                    console.log("payload userSettingsform", payload)

                    const _res = await updateNewUserSetting(payload);
                    if (_res.success) {

                        // const _updated_user = deepCopy(user);
                        // _updated_user.organization_settings = _res.data.organization_settings;

                        // _updated_user.full_name = payload.full_name
                        // _updated_user.email = payload.email
                        // _updated_user.phone = payload.phone
                        // _updated_user.accessibility_settings = payload.accessibility_settings

                        _globalDispatch({
                            type: "SET_CURRENT_USER",
                            payload: _res.data
                        });


                        toast.success("Settings updated successfully.")
                    }

                    setSubmitting(false);
                }}
            >
                {
                    props => {
                        const {
                            values,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        } = props;

                        return (
                            <Form>
                                {/* Full Name */}
                                <Field name="file" component={CustomFileInput} />
                                <TextField
                                    label="Name"
                                    name="full_name"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.full_name}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.full_name && props.touched.full_name}
                                />

                                {/* Email */}
                                <TextField
                                    label="Email"
                                    name="email"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.email && props.touched.email}
                                />

                                {/* Contact Phone */}
                                <TextField
                                    label="Phone"
                                    name="phone"
                                    type="phone"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.phone}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.phone && props.touched.phone}
                                />

                                <Box mt={2}>
                                    <Button variant='outlined' onClick={handleClickOpen}>
                                        Change Password
                                    </Button>
                                </Box>

                                <Box mt={2}>
                                    {/* <FormGroup>
                                        <FormLabel component="legend">Accessibility options</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.larger_marker}
                                                onChange={(event, val) => {
                                                    setFieldValue("larger_marker", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>Use larger task markers</Box>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.keyboard_shortcut}
                                                onChange={(event, val) => {
                                                    setFieldValue("keyboard_shortcut", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>Keyboard shortcuts</Box>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.cluster_pin}
                                                onChange={(event, val) => {
                                                    setFieldValue("cluster_pin", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>Cluster map pins on dashboard</Box>}
                                        />
                                    </FormGroup> */}
                                </Box>

                                {
                                    isSubmitting && <Box style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%"
                                    }}>
                                        <CircularProgress />
                                    </Box>
                                }

                                <Box textAlign='center'>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Box>

                            </Form>
                        );
                    }}
            </Formik>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            current_password: "",
                            password: "",
                            confirm_password: ""
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const payload = {
                                old_password: values.current_password,
                                password: values.password,
                                _id: _id
                            }

                            const _res = await updateUserPassword(payload);
                            console.log("_res", _res)
                            if (_res.success) {
                                toast.success("Password updated successfully.")
                                handleClose();
                            } else {
                                toast.error(_res.msg)
                            }


                            console.log("values", values)


                            setSubmitting(false);

                        }}
                        validationSchema={UserSettingValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Current Password"
                                                    name="current_password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.current_password && props.touched.current_password}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.password && props.touched.password}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Confirm password"
                                                    name="confirm_password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.confirm_password && props.touched.confirm_password}
                                                />
                                            </Grid>
                                        </Grid>

                                        {
                                            isSubmitting && <Box style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%"
                                            }}>
                                                <CircularProgress />
                                            </Box>
                                        }

                                        <Box textAlign='center'>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                            >
                                                Update
                                            </Button>
                                        </Box>

                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div >
    );
}
