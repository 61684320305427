export const AuthReducer = (prevState, action) => {

    switch (action.type) {
        case 'SIGN_IN':
            return {
                ...prevState,
                isSignOut: false,
                user: action.payload,
            };
        case 'AUTH_USER':
            return {
                ...prevState,
                isSignOut: false,
                user: action.payload,
            };
        case 'SIGN_OUT':
            return {
                ...prevState,
                isSignOut: true,
                user: null,
            };
   
        default:
            return prevState;
    }
};
