import * as yup from "yup";
require("yup-phone");

const DispatcherValidation = yup.object().shape({
    first_name: yup
        .string()
        .min(3, "Too Short !")
        .max(30, "Too Long !")
        .required("Required !"),
    last_name: yup
        .string()
        .min(3, "Too Short !")
        .max(30, "Too Long !")
        .required("Required !"),

    email: yup.string().email("Enter a Vaid Email").required("Email is Required"),
    phone: yup
        .string()
        // .phone()
        .required("Required !"),
});

export default DispatcherValidation;
