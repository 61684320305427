import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove';

import { Formik, Form } from 'formik'
import { CircularProgress, Grid, TextField, IconButton } from '@mui/material'
import { toast } from 'react-toastify'
import ModalClose from '../../common/ModalClose'
import { confirmAlert } from 'react-confirm-alert'; // Import
import useGlobalContext from '../../../hooks/useGlobalContext'
import { deleteCommunication, updateCommunication } from '../../../api/communication'
import CommunicationEntity from '../../../entities/CommunicationEntity'
import DeleteIcon from '@mui/icons-material/Delete';



import { Box } from "@mui/material";
import { useState } from "react";

import styled from '@emotion/styled';
import { GlobalContext } from '../../../contexts/GlobalContext';

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'


import TextareaAutosize from '@mui/base/TextareaAutosize';
import { insertStringAtIndex } from '../../../utils/utils';
import {
	ADDRESS_LONG_LABEL,
	ADDRESS_LONG_VALUE,
	ADDRESS_SHORT_LABEL,
	ADDRESS_SHORT_VALUE,
	DRIVER_FIRST_NAME_LABEL,
	DRIVER_FIRST_NAME_VALUE,
	DRIVER_LAST_NAME_LABEL,
	DRIVER_LAST_NAME_VALUE,
	DRIVER_NAME_LABEL,
	DRIVER_NAME_VALUE,
	DRIVER_PHONE_NUMBER_LABEL,
	DRIVER_PHONE_NUMBER_VALUE,
	ETA_LABEL,
	ETA_VALUE,
	LICENSE_PLATE_LABEL,
	LICENSE_PLATE_VALUE,
	ORGANIZATION_NAME_LABEL,
	ORGANIZATION_NAME_VALUE,
	PHOTO_URL_LABEL,
	PHOTO_URL_VALUE,
	RECIPIENT_FIRST_NAME_LABEL,
	RECIPIENT_FIRST_NAME_VALUE,
	RECIPIENT_LAST_NAME_LABEL,
	RECIPIENT_LAST_NAME_VALUE,
	RECIPIENT_NAME_LABEL,
	RECIPIENT_NAME_VALUE,
	SIGNATURE_URL_LABEL,
	SIGNATURE_URL_VALUE,
	TASK_ID_LABEL,
	TASK_ID_VALUE,
	TRACKING_LINK_LABEL,
	TRACKING_LINK_VALUE,
	VEHICLE_DESCRIPTION_LABEL,
	VEHICLE_DESCRIPTION_VALUE,
	VEHICLE_TYPE_LABEL,
	VEHICLE_TYPE_VALUE
} from '../../../constants/messageTags';

import TriggerWhenSelect from './TriggerWhenSelect'
import TriggerForSelect from './TriggerForSelect'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CommunicationType from './CommunicationType'
import TaskType from './TaskType'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'none',
        },
        '&:hover fieldset': {
            borderColor: 'none',
        },
        '&.Mui-focused fieldset': {
            borderWidth: 0.1
        },
    },
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "90%" }}>
    <CssTextField
        id="search-bar"
        className="text"
        onInput={(e) => {
            setSearchQuery(e.target.value);
        }}
        fullWidth
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"

    />
</Box>
);

export default function CommunicationsList() {
    const [open, setOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const { _globalData, _globalDispatch } = React.useContext(GlobalContext);

    const { communications, chosenCommunication: chosenItem  } = _globalData;
    const { chosenCommunication } = useGlobalContext();


    // console.log("communications", communications)
    const data = communications ? communications.getList() : [];

    function handleClose() {
		setOpen(false)
	}

	function handleClickOpen(item) {
        _globalDispatch({
            type: "SET_CHOSEN_COMMUNICATION",
            payload: item
        })
		setOpen(true)
	}

	const handleDeleteConfirmation = async () => {
		confirmAlert({
			customUI: ({ onClose }) => {
			  return (
				<div className="custom-ui__overlay">
				  <div className="custom-ui">
					<div className="custom-ui__header">
					  <h1>Are you sure?</h1>
					</div>
					<div className="custom-ui__body">
					  <p>Do you really want to delete? This process cannot be undone.</p>
					</div>
					<div className="custom-ui__footer">
					  <button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
					  <button
						className="custom-ui__button custom-ui__button--confirm"
						onClick={() => {
						  handleDelete();
						  onClose();
						}}
					  >
						Yes, Delete it!
					  </button>
					</div>
				  </div>
				</div>
			  );
			}
		  });
	};

	const handleDelete = async () => {
		// console.log("chosenCommunication", chosenCommunication)
		const _res = await deleteCommunication(chosenCommunication._id);
		if (_res.success) {

			communications.remove(chosenCommunication)

			_globalDispatch({
				type: "RESET_CHOSEN_COMMUNICATION"
			});
			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			toast("Communication removed succesfully.")
		}
	}


    // console.log("communications Data", data)

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) => d.name.toLowerCase().includes(query.toLowerCase()));
        }
    };

    const dataFiltered = filterData(searchQuery, data);

    return (<>
        {/* <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} /> */}
        {chosenCommunication?<Dialog open={open} onClose={handleClose} maxWidth='sm'>
				<ModalClose handleClose={handleClose} />
				<DialogTitle>{`Edit: ${chosenCommunication._id}`}</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							triggered_when: chosenCommunication.triggered_when || "started",
							triggered_for: chosenCommunication.triggered_for || "both",
							communication_type: chosenCommunication.communication_type || "sms",
							task_type: chosenCommunication.task_type || "general",
							notification_title: chosenCommunication.notification_title || "",
							on_way_notification_title: chosenCommunication.on_way_notification_title || "",
							insertedTag: "",
							cursor_position: 0,
							message: chosenCommunication.message || ""
						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							const { _id } = chosenCommunication;
							const updatePayload = values;
							updatePayload._id = _id;

							// console.log("updatePayload", updatePayload)

							setSubmitting(true)
							const _res = await updateCommunication(_id, updatePayload)

							if (_res.success) {
								toast('Communication details updated successfully.');
								setOpen(false);

								communications.update(updatePayload);

								_globalDispatch({
									type: "SET_CHOSEN_COMMUNICATION",
									payload: new CommunicationEntity(updatePayload)
								})

								_globalDispatch({
									type: "INCREASE_REFRESH_COUNTER"
								});
							}

							setSubmitting(false)
						}}
					// validationSchema={DriverValidation}
					>
						{props => {
							const { values, isSubmitting, handleSubmit, setFieldValue } = props

							return (
								<Form>
									<Box maxWidth={"100%"}>
										<Grid container spacing={2}>
											{/* Triggered When */}
											<Grid item xs={12}>
												<TriggerWhenSelect
													chosenVal={values.triggered_when}
													callBack={(newVal) => {
														setFieldValue("triggered_when", newVal)
													}}
												/>
											</Grid>

											{/* Triggered For */}
											<Grid item xs={12}>
												<TriggerForSelect
													chosenVal={values.triggered_for}
													callBack={(newVal) => {
														setFieldValue("triggered_for", newVal)
													}}
												/>
											</Grid>

											{/* Triggered For */}
											<Grid item xs={12}>
												<CommunicationType
													chosenVal={values.communication_type}
													callBack={(newVal) => {
														setFieldValue("communication_type", newVal)
													}}
												/>
											</Grid>

											{/* Task Type  */}
											{
												values.communication_type === "push_notification" && values.triggered_when === "started" &&
												<Grid item xs={12}>
													<TaskType
														chosenVal={values.task_type}
														callBack={(newVal) => {
															setFieldValue("task_type", newVal)
														}}
													/>
												</Grid>
											}

											{
												values.communication_type === "push_notification" &&
												<Grid item xs={12}>
													<FormControl sx={{ minWidth: "100%" }}>
														<TextField
															label="Notification title"
															name="notification_title"
															fullWidth
															variant="outlined"
															margin="normal"
															value={values.notification_title}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															error={props.errors.notification_title && props.touched.notification_title}
														/>
													</FormControl>
												</Grid>
											}

											{/* {
												values.communication_type === "push_notification" && values.triggered_when === "started" &&
												<Grid item xs={12}>
													<FormControl sx={{ minWidth: "100%" }}>
														<TextField
															label="On way notification title"
															name="on_way_notification_title"
															fullWidth
															variant="outlined"
															margin="normal"
															value={values.on_way_notification_title}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															error={props.errors.on_way_notification_title && props.touched.on_way_notification_title}
														/>
													</FormControl>
												</Grid>
											} */}


											{/* Insert Tag */}
											<Grid item xs={12}>
												<div>
													<FormControl sx={{ minWidth: "100%" }}>
														<InputLabel htmlFor="grouped-insert-tag">Insert Tag</InputLabel>
														<Select native
															value={values.insertedTag}
															label="Insert Tag"
															onChange={(newVal) => {
																// insertStringAtIndex
																setFieldValue("message", insertStringAtIndex(values.message, values.cursor_position, newVal.target.value))
																setFieldValue("insertedTag", "")
															}}>
															<option aria-label="None" value="" />
															<optgroup label="Recipient">
																<option value={RECIPIENT_FIRST_NAME_VALUE}>{RECIPIENT_FIRST_NAME_LABEL}</option>
																<option value={RECIPIENT_LAST_NAME_VALUE}>{RECIPIENT_LAST_NAME_LABEL}</option>
																<option value={RECIPIENT_NAME_VALUE}>{RECIPIENT_NAME_LABEL}</option>
															</optgroup>
															<optgroup label="Driver">
																<option value={DRIVER_FIRST_NAME_VALUE}>{DRIVER_FIRST_NAME_LABEL}</option>
																<option value={DRIVER_LAST_NAME_VALUE}>{DRIVER_LAST_NAME_LABEL}</option>
																<option value={DRIVER_NAME_VALUE}>{DRIVER_NAME_LABEL}</option>
																<option value={ETA_VALUE}>{ETA_LABEL}</option>
																{/* <option value={VEHICLE_DESCRIPTION_VALUE}>{VEHICLE_DESCRIPTION_LABEL}</option> */}
																<option value={VEHICLE_TYPE_VALUE}>{VEHICLE_TYPE_LABEL}</option>
																<option value={LICENSE_PLATE_VALUE}>{LICENSE_PLATE_LABEL}</option>
																<option value={DRIVER_PHONE_NUMBER_VALUE}>{DRIVER_PHONE_NUMBER_LABEL}</option>
															</optgroup>

															<optgroup label="Task">
																<option value={TASK_ID_VALUE}>{TASK_ID_LABEL}</option>
																<option value={ADDRESS_SHORT_VALUE}>{ADDRESS_SHORT_LABEL}</option>
																<option value={ADDRESS_LONG_VALUE}>{ADDRESS_LONG_LABEL}</option>
																<option value={TRACKING_LINK_VALUE}>{TRACKING_LINK_LABEL}</option>
																<option value={PHOTO_URL_VALUE}>{PHOTO_URL_LABEL}</option>
																{/* <option value={SIGNATURE_URL_VALUE}>{SIGNATURE_URL_LABEL}</option> */}
															</optgroup>

															<optgroup label="Organization">
																<option value={ORGANIZATION_NAME_VALUE}>{ORGANIZATION_NAME_LABEL}</option>
															</optgroup>
														</Select>
													</FormControl>
												</div>
											</Grid>

											{/* Message */}
											<Grid item xs={12}>
												<InputLabel htmlFor="grouped-insert-tag">Message</InputLabel>
												{/* <StyledTextarea
													aria-label="Message"
													value={values.message}
													minRows={3}
													placeholder="Message"
													style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}
													onChange={seletor => {
														console.log("seletor", seletor.target.selectionStart)
														setFieldValue("message", seletor.target.value)
														setFieldValue("cursor_position", seletor.target.selectionStart)
													}}
													onClick={selector => {
														console.log("On Focus", selector.target.selectionStart)
														setFieldValue("cursor_position", selector.target.selectionStart)
													}}
												/> */}

												<textarea
													placeholder='Message'
													name='message'
													value={values.message}
													onChange={selector => {
														setFieldValue("message", selector.target.value)
														setFieldValue("cursor_position", selector.target.selectionStart)
													}}
													onClick={selector => {
														console.log("On Focus", selector.target.selectionStart)
														setFieldValue("cursor_position", selector.target.selectionStart)
													}}
													style={{
														minWidth: "100%",
														minHeight: "256px",
														fontFamily: 'IBM Plex Sans, sans-serif',
														fontSize: '0.875rem',
														fontWeight: 400,
														lineHeight: 1.5,
														padding: '12px',
														borderRadius: '12px 12px 0 12px',
														color: "grey[900]",
														background: '#fff',
														border: "1px solid grey[200]",
														boxShadow: "0px 2px 2px grey[50]"
													}}
												/>
											</Grid>
										</Grid>


										{
											isSubmitting && <Box style={{
												position: "absolute",
												top: "50%",
												left: "50%"
											}}>
												<CircularProgress />
											</Box>
										}

										<Box textAlign='center'>
											<Button
												variant="contained"
												type="submit"
												color="primary"
												onClick={handleSubmit}
												disabled={isSubmitting}
											>
												Update
											</Button>
										</Box>

									</Box>
								</Form>
							)
						}}
					</Formik>
				</DialogContent>
			</Dialog>:''}
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell align="left"> Triggered When</TableCell>
            <TableCell align="left">For</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFiltered && dataFiltered.map((item,i) => (
            <TableRow
              key={item._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               {i+1}
              </TableCell>
             
              <TableCell align="left">{item.triggered_when}</TableCell>
              <TableCell align="left">{item.triggered_for }</TableCell>
              <TableCell align="left">{item.communication_type }</TableCell>
              <TableCell align="left"> 
              <IconButton edge="end" aria-label="edit" onClick={() => handleClickOpen(item)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(item)}>
              <DeleteIcon />
              </IconButton>
         </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        {/* <List sx={{ maxHeight: 300, bgcolor: 'background.paper', overflow: 'scroll' }}>
            {
                dataFiltered.map(item => <ListItem key={item.message}>
                    <ListItemText
                        primary={item.getLabel()}
                        secondary={item.getMessageExcerpt()}
                        onClick={() => {
                            _globalDispatch({
                                type: "SET_CHOSEN_COMMUNICATION",
                                payload: item
                            })
                        }}
                        style={{
                            cursor: "pointer",
                            backgroundColor: chosenItem !== null && chosenItem._id === item._id ? "rgb(145 158 171 / 80%)" : ""
                        }}
                    />
                </ListItem>)
            }
        </List> */}
    </>
    )
}