import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function VehicleSelect({ chosenVal, callBack }) {
    const [val, setVal] = React.useState(chosenVal || 'car');

    const handleChange = (event) => {
        const newVal = event.target.value;
        setVal(newVal);
        callBack(newVal)
    };

    return (
        <Box sx={{ minWidth: 120 }} mt={2}>
            <FormControl fullWidth>
                <InputLabel>Transportation Type</InputLabel>
                <Select
                    value={val}
                    label="Transportation type"
                    onChange={handleChange}
                >
                    <MenuItem value="car">Car</MenuItem>
                    <MenuItem value="bike">Bike</MenuItem>
                    <MenuItem value="bicycle">Bicycle</MenuItem>
                    <MenuItem value="walking">Walking</MenuItem>
                    <MenuItem value="transit">Public Transit</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}