import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './PaymentMethods.css';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import PaymentMethodForm from '../../Stripe/PaymentMethodForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // For default card icon

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_test_51PQD5L05bXUcXrFS4zLXj58JV6D3CL2KkCav1enG5nLVAdPxT5aXDYNDYiUqNsiKz5lu3mF6omHspapmg1144wS900g6TQJUAT');

const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedMethodId, setSelectedMethodId] = useState(null);
    const { _globalData } = React.useContext(GlobalContext);

    const { current_user: user = {} } = _globalData;

    const fetchPaymentMethods = async () => {
        try {
            const userId = user._id;

            const response = await fetch('/api/superadmin/listPaymentMethods', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ _id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch payment methods');
            }

            const data = await response.json();
            setPaymentMethods(data.paymentMethods.data);
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        }
    };

    useEffect(() => {
        fetchPaymentMethods();
    }, []);

    const handleSetDefault = async (paymentMethodId) => {
        try {
            const userId = user._id;
            const response = await fetch('/api/stripe/setDefaultPaymentMethod', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: userId,
                    paymentMethodId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to set default payment method');
            }

            fetchPaymentMethods();
        } catch (error) {
            console.error('Error setting default payment method:', error);
        }
    };

    const handleDelete = async () => {
        if (selectedMethodId) {
            try {
                const response = await fetch('/api/stripe/removePaymentCard', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ paymentMethodId: selectedMethodId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to delete payment method');
                }

                setPaymentMethods(paymentMethods.filter(method => method.id !== selectedMethodId));
            } catch (error) {
                console.error('Error removing payment method:', error);
            } finally {
                setDeleteDialogOpen(false);
            }
        }
    };

    const confirmDelete = (methodId) => {
        setSelectedMethodId(methodId);
        setDeleteDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDeleteDialogOpen(false);
        setSelectedMethodId(null);
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                    marginTop: '10px',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Attached Cards
                </Typography>
            </Box>

            <div className="payment-method">
                <Elements stripe={stripePromise}>
                    <PaymentMethodForm onPaymentMethodCreated={fetchPaymentMethods} />
                </Elements>

                <ul>
                    {paymentMethods.map((method) => (
                        <li key={method.id}>
                            <div
                                className="payment-method"
                                style={{
                                    border: method.default ? '2px solid green' : '1px solid gray',
                                    backgroundColor: method.default ? '#f0f9f0' : 'transparent',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginBottom: '10px',
                                    position: 'relative', // For positioning the default badge/icon
                                }}
                            >
                                <strong style={{ textTransform: 'capitalize' }}>{method.card.brand}</strong>

                                <p>
                                    <Typography variant="body1">
                                        Ending in XXXX XXXX XXXX {method.card.last4} - Expires {method.card.exp_month}/{method.card.exp_year}
                                    </Typography>
                                </p>

                                {/* Highlight default card */}
                                {method.default && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'green',
                                        }}
                                    >
                                        <CheckCircleIcon style={{ marginRight: '5px' }} />
                                        <Typography variant="body2" style={{ fontWeight: 'bold', color: 'green' }}>
                                            Default
                                        </Typography>
                                    </div>
                                )}

                                {/* Show Set as Default button only if not already the default */}
                                {!method.default && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSetDefault(method.id)}
                                        style={{ marginRight: '10px' }} // Added margin for space
                                    >
                                        Set as Default
                                    </Button>
                                )}

                                {/* Red Remove button */}
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: 'red', color: 'white' }} // Use sx for correct color
                                    onClick={() => confirmDelete(method.id)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Dialog for confirming deletion */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this payment method?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PaymentMethods;
