import { Box, Button } from '@mui/material'
import React from 'react'
import useMapContext from '../../hooks/useMapContext';

export default function SatelliteOption() {
    const { satelliteMode, _mapDispatch } = useMapContext();
    return (
        <Box paddingY={1}>
            <Button variant='contained'
                onClick={() => {
                    _mapDispatch({
                        type: "TOGGLE_SATELLITE_MODE"
                    })
                }}
                sx={{
                    color: "white",
                    backgroundColor: satelliteMode ? "blue" : "grey",
                    borderRadius: 0
                }}>
                Satellite
            </Button>
        </Box>
    )
}
