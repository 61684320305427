import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import useExportContext from '../../hooks/useExportContext'
import { CSVLink } from "react-csv";
import moment from 'moment';
import { getTaskStatusFromNumber } from '../../utils/task_status';
import { getExportTasks, getTasks } from '../../api/task';
import useFetchData from '../../hooks/useFetchData';
import TaskEntity from '../../entities/TaskEntity';
import { MOMENT_DATETIME_OUTPUT_DB, MOMENT_OUTPUT_DISPLAY } from '../../constants/moment-date-formats';

export default function ExportButton() {
    const { _exportData, chosenDriver, taskStatus, dateStart, dateEnd } = useExportContext();
    // const [data, setData] = useState([]);
    const getExportFileName = () => {
        let date_part = `${dateStart}.${dateEnd}`;
        if (dateStart === dateEnd) {
            date_part = dateStart;
        }
        return `${date_part}.${chosenDriver}.${getTaskStatusFromNumber(taskStatus)}.csv`
    }

    let filter = {
        task_status: taskStatus,
    };

    if (chosenDriver !== "all") {
        filter["driver._id"] = chosenDriver
    }

    if (dateStart === dateEnd) {
        filter.delivery_date = dateStart
    } else {
        filter.delivery_date = { $gte: dateStart, $lte: dateEnd }
    }

    if (filter.task_status === "ALL") {
        delete filter.task_status;
    }

    // console.log("filter", filter)

    const { data } = useFetchData(getExportTasks, filter, [_exportData]);

    const getExportData = () => {

        let head = [
            // "OrderId",
            "status",
            "CreaterOrganization",
            "ExecuterOrganization",
            "workerName",
            "deliveryDate",
            "Destination Address",
            "Recipient Name",
            "Recipient Phone",
            "completeBeforeTime",
            "completeAfterTime",
            // "creationTime",
            "Start Time",
            "Completion Time",
            "didSucceed",
            "completionNotes",
            "startLonLat",
            "completionLonLat",
            "destinationLonLat",
            "Distance(MI)",
            "Distance(KM)",
            "Recipient Note",
            "signatureUrl",
            "Photo URL",
            "taskType",
            "failureReason",
            "trackingViewed",
            "failureNotes",
            "completionNotes"
        ];
        let export_data = [head];

        for (let task of data) {
            const tEntity = new TaskEntity(task);
            export_data.push([
                // tEntity.getOrderId(),
                tEntity.getStatusLabel(),
                "", //CreaterOrganization
                "", //ExecuterOrganization
                tEntity.getDriverName(),
                moment(tEntity.getDeliveryDate()).format(MOMENT_OUTPUT_DISPLAY),
                tEntity.getDestinationAddress(),
                tEntity.getRecipientName(),
                tEntity.getRecipientPhone(),
                tEntity.getCompleteBefore(),
                tEntity.getCompleteAfter(),
                // tEntity.getCreatedTime(),
                tEntity.getStartTime(),
                tEntity.getCompletedTime(),
                tEntity.isCompleted() ? "YES" : "NO",
                "", //completionNotes
                tEntity.getOriginAddressLatLng(), // StartlatLng
                tEntity.getCompleteAddressLatLng(), //completionLatlng
                tEntity.getDestinationAddressLatLng(), // Destination latlng
                tEntity.getDistance(),
                tEntity.getDistanceKM(),
                tEntity.getRecipientNote(),
                "", // Signature URL
                tEntity.getFileURL(),
              
                tEntity.getTaskType(), // taskType
                tEntity.getFailureReason(), // Failure reason
                "", // Tracking viewed
                tEntity.getFailureNote(), // Failure notes
                tEntity.getCompletionNote() // Completion notes
            ])
        }

        return export_data;
    }

    return (
        <Box textAlign={"center"} >
            <CSVLink
                filename={getExportFileName()}

                data={getExportData()}>
                <Button variant='contained'>
                    Export
                </Button>
            </CSVLink>

        </Box >
    )
}
