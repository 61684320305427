import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FilterVerticalTabs from './dashboard/filters/FilterVerticalTabs';

import { filterModalStyle } from "../theme/modalBox"
import ModalClose from './common/ModalClose';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useGlobalContext from '../hooks/useGlobalContext';
import { refreshDriversTasks } from '../api/frontend';
import TasksListEntity from '../entities/TasksListEntity';
import DriversListEntity from '../entities/DriversListEntity';
import moment from 'moment-timezone';
import { MOMENT_INPUT, MOMENT_OUTPUT_DISPLAY, MOMENT_TIME_OUTPUT_DB, getTimezone } from '../constants/moment-date-formats';
import { deepCopy } from '../utils/utils';

export default function FiltersButton() {
	const { filterModalOpen, tasks: global_tasks, _globalDispatch, chosenDate, chosenTimeFrom, chosenTimeTo, selectedStatus } = useGlobalContext()

	const handleOpen = () => _globalDispatch({ type: "OPEN_FILTER_MODAL" });
	const handleClose = () => _globalDispatch({ type: "CLOSE_FILTER_MODAL" });
	const timezone = getTimezone();

	// console.log("chosenTimeFrom", chosenTimeFrom)

	const handleFilter = async () => {
		await _globalDispatch({
			type: "APPLY_FILTER"
		});

		const _selectedStatus = [...new Set(selectedStatus)];

		// console.log("_selectedStatus", _selectedStatus)

		const _res = await refreshDriversTasks(chosenDate, _selectedStatus);

		if (_res.success) {

			localStorage.setItem("@chosen_date", chosenDate);
			localStorage.setItem("@selected_status", JSON.stringify(_selectedStatus))

			const { tasks, drivers } = _res;

			// console.log("_res", _res)
			// console.log("chosenTimeFrom", chosenTimeFrom)
			// console.log("chosenTimeTo", chosenTimeTo)
			const chosenTimeFromFormatted = chosenTimeFrom.format(MOMENT_TIME_OUTPUT_DB);
			const chosenTimeToFormatted = chosenTimeTo.format(MOMENT_TIME_OUTPUT_DB);

			// console.log("chosenTimeFromFormatted", chosenTimeFromFormatted)
			// console.log("chosenTimeToFormatted", chosenTimeToFormatted)

			const isFromSame = chosenTimeFrom.isSame(chosenTimeFrom.startOf('day'), "minute");
			const isToSame = chosenTimeTo.isSame(chosenTimeTo.endOf('day'), "minute");

			const shouldApplyTimeFilter = !isFromSame && !isToSame;

			// console.log("chosenTimeFrom.startOf('day')", chosenTimeFrom.startOf('day').format())
			// console.log("isToSame", isToSame)
			// console.log("moment(chosenTimeFrom).tz(timezone).startOf('day')", moment(chosenTimeFrom).tz(timezone).startOf('day'))
			// console.log("moment(chosenTimeTo).tz(timezone).endOf('day')", moment(chosenTimeTo).tz(timezone).endOf('day'))

			let filteredTasks = deepCopy(tasks);

			// console.log("shouldApplyTimeFilter", shouldApplyTimeFilter)
			// console.log("filteredTasks Before", filteredTasks)
			if (shouldApplyTimeFilter) {
				const fromDateTime = moment(`${chosenDate} ${chosenTimeFromFormatted}`, MOMENT_INPUT).tz(timezone);
				const toDateTime = moment(`${chosenDate} ${chosenTimeToFormatted}`, MOMENT_INPUT).tz(timezone);

				filteredTasks = (tasks || []).filter(t => {
					if (t.complete_after && t.complete_before) {
						if (
							moment(t.complete_after, MOMENT_INPUT).tz(timezone).isSameOrAfter(fromDateTime)
							&& moment(t.complete_before, MOMENT_INPUT).tz(timezone).isSameOrBefore(toDateTime)
						) {
							return true;
						}
					} else if (t.complete_after && !t.complete_before) {
						if (
							moment(t.complete_after, MOMENT_INPUT).tz(timezone).isSameOrAfter(fromDateTime)
						) {
							return true;
						}
					} else if (!t.complete_after && t.complete_before) {
						if (
							moment(t.complete_before, MOMENT_INPUT).tz(timezone).isSameOrBefore(toDateTime)
						) {
							return true;
						}
					}

					return false;

				})
			}

			console.log("filteredTasks After", filteredTasks)
			global_tasks.reset(filteredTasks)
			_globalDispatch({
				type: "REFRESH_DRIVERS_TASKS",
				tasks: new TasksListEntity(filteredTasks),
				drivers: new DriversListEntity(drivers || [])
			});

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});
		}
	}

	// console.log("chosenDate", chosenDate)

	return (<div>
		<Button
			onClick={handleOpen}
			sx={{
				color: 'black', display: 'block',

			}}
		>
			<FilterAltIcon />
			<span style={{ position: "absolute", width: "128px" }}>{moment(chosenDate).format(MOMENT_OUTPUT_DISPLAY)}</span>
		</Button>

		<Modal
			open={filterModalOpen}
			onClose={handleClose}
		>
			<Box sx={filterModalStyle}>
				<ModalClose handleClose={handleClose} />
				<FilterVerticalTabs />
				<Box textAlign={"center"}>
					<Button
						variant='contained'
						disabled={selectedStatus.length < 1}
						onClick={handleFilter}
					>
						Apply
					</Button>
				</Box>
			</Box>
		</Modal>
	</div>
	);
}
