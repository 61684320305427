import { isObjEmpty } from "../utils/utils";
import InvoiceEntity from "./InvoiceEntity";

class InvoiceListEntity {
    constructor(invoices) {
        this.invoices = invoices.map(d => new InvoiceEntity(d))
    }

    getInvoices() {
        return this.invoices;
    }

    getTotalInvoice() {
        return this.invoices.length;
    }


   

   

   
}

export default InvoiceListEntity;