import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MOMENT_ONLY_TIME_OUTPUT_24, MOMENT_OUTPUT_DISPLAY_TIME_24, timezone } from '../../constants/moment-date-formats';
import { Grid } from '@mui/material';
import useGlobalContext from '../../hooks/useGlobalContext';
import { deepCopy } from '../../utils/utils';
import { getFormattedDateWithTime } from '../../utils/date';
import moment from 'moment-timezone';

export default function DriverStartEndTime({ day = "Monday" }) {
    const { chosenDriver, chosenDate, _globalDispatch } = useGlobalContext();
    const defaultStartTime = moment.tz(`${chosenDate} 10:00`, timezone).format();
    const defaultEndTime = moment.tz(`${chosenDate} 17:00`, timezone).format();

    // console.log("chosenDriver.timewindow", chosenDriver.timewindow)

    // const [timewindow, setTimewindow] = React.useState({
    //     monday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     tuesday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     wednesday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     thursday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     friday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     saturday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },
    //     sunday: {
    //         start_time: defaultStartTime,
    //         end_time: defaultEndTime
    //     },

    // });

    const [timewindow, setTimewindow] = React.useState({
        monday: {
            start_time: null,
            end_time: null
        },
        tuesday: {
            start_time: null,
            end_time: null
        },
        wednesday: {
            start_time: null,
            end_time: null
        },
        thursday: {
            start_time: null,
            end_time: null
        },
        friday: {
            start_time: null,
            end_time: null
        },
        saturday: {
            start_time: null,
            end_time: null
        },
        sunday: {
            start_time: null,
            end_time: null
        },

    });

    React.useEffect(() => {
        if (chosenDriver.timewindow) {
            setTimewindow(chosenDriver.timewindow)
        }
    }, [chosenDriver])

    // console.log("timewindow", timewindow)

    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
            <TimePicker
                label={`${day} start time`}
                value={timewindow[day.toLowerCase()]['start_time']}
                onChange={(newVal) => {
                    const _timewindow = deepCopy(timewindow);
                    // setShouldUpdate(true);
                    _timewindow[day.toLowerCase()]['start_time'] = newVal;
                    // if (!newVal) {
                    //     _timewindow[day.toLowerCase()]['start_time'] = null;
                    // }

                    // console.log("Start newVal", newVal)
                    // if (newVal) {
                    //     console.log("Start newVal.tz(timezone)", newVal.format(MOMENT_ONLY_TIME_OUTPUT_24))
                    //     // _timewindow[day.toLowerCase()]['start_time'] = newVal.format(MOMENT_ONLY_TIME_OUTPUT_24)
                    // }

                    setTimewindow(_timewindow)

                    const _chosen_driver = deepCopy(chosenDriver)
                    _chosen_driver.timewindow = _timewindow
                    _globalDispatch({
                        type: 'SET_CHOSEN_DRIVER',
                        payload: _chosen_driver
                    })
                }}
                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            />
        </Grid>

        <Grid item xs={6}>
            <TimePicker
                label={`${day} end time`}
                value={timewindow[day.toLowerCase()]['end_time']}
                onChange={(newVal) => {
                    const _timewindow = deepCopy(timewindow);
                    _timewindow[day.toLowerCase()]['end_time'] = newVal;
                    // if (!newVal) {
                    //     _timewindow[day.toLowerCase()]['end_time'] = null
                    // }
                    // console.log("End newVal", newVal)

                    // if (newVal) {
                    //     console.log("End newVal.tz(timezone)", newVal.format(MOMENT_ONLY_TIME_OUTPUT_24))
                    //     _timewindow[day.toLowerCase()]['end_time'] = newVal.format(MOMENT_ONLY_TIME_OUTPUT_24)
                    //     // console.log("_timewindow", _timewindow)

                    // }
                    setTimewindow(_timewindow)
                    const _chosen_driver = deepCopy(chosenDriver)
                    _chosen_driver.timewindow = _timewindow
                    _globalDispatch({
                        type: 'SET_CHOSEN_DRIVER',
                        payload: _chosen_driver
                    })
                }}

                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            />
        </Grid>
    </LocalizationProvider>
    );
}
