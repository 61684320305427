import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, Grid, ListItemText, ListItemIcon, Dialog, Slide, LinearProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/List';
import Sidebar from './Sidebar';
import useGlobalContext from '../hooks/useGlobalContext';
import Box from '@mui/material/Box';
import CreateTaskModal from '../components/modals/CreateTaskModal';
import CreateTaskButton from '../components/sidebarbuttons/CreateTaskButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuthHelper } from '../utils/auth';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import { onboardingstyle, style } from "../theme/modalBox"
import newlogo from '../../src/images/newoptracklogo.png'


import MapIcon from '@mui/icons-material/Map';
import TableViewIcon from '@mui/icons-material/TableView';
import TasksList from '../components/dashboard/TasksList';
import { DARK_COLOR, WHITE_COLOR } from '../constants/colors';
import FiltersButton from '../components/FiltersButton';
import ImportButton from '../components/sidebarbuttons/ImportButton';
import ExportButtons from '../components/sidebarbuttons/ExportButtons';
import SettingsButton from '../components/sidebarbuttons/SettingsButton';
import VerticalTabs from '../components/settings/VerticalTabs';
import VerticalTabsOnBoarding from '../components/settings/VerticalTabsOnboarding';
import ModalClose from '../components/common/ModalClose';
import CloseIcon from '@mui/icons-material/Close';
import BillingList from '../components/settings/billings/BillingList';
import { Avatar } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@mui/material';
import CardList from '../components/settings/cards/CardList';
import Joyride from 'react-joyride';
import $ from 'jquery';


const selectedStyle = {
	textDecoration: "underline",
	textUnderlineOffset: 8,
	cursor: "pointer"
}
const drawerWidth = 240;

const nonSelectedStyle = {
	color: "#a49b9b",
	cursor: "pointer"
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});



const Topbar = () => {
	const { tasks, admins, drivers, dispatchers, hubs, _globalData } = useGlobalContext();
	const { communications, teams } = _globalData;

	const { current_user: user = {}, _globalDispatch } = useGlobalContext();
	const { organization_settings = {}, _id, driver_app_settings = {} } = user
	const calculateDriverAppSettingPercentage = () => {

		// Check if `driver_app_settings` has data
		return Object.keys(driver_app_settings).length > 0 ? 100 : 0;
	};

	const calculateCompletionPercentageOfOrganization = (organizationSettings) => {
		const fields = [
			organizationSettings.organization_name,
			organizationSettings.sms_reply_email || organizationSettings.driver_support_email,
			organizationSettings.organization_email,
			organizationSettings.driver_support_email,
			organizationSettings.country,
			organizationSettings.timezone,
			organizationSettings.hq_address,
			organizationSettings.hq_address_lat_lng,
		];

		// Count non-empty values
		const completedFields = fields.filter((field) => {
			if (typeof field === "object") {
				return field && Object.keys(field).length > 0; // Check non-empty object
			}
			return Boolean(field); // Check non-empty string or other values
		}).length;

		// Calculate percentage
		const totalFields = fields.length;
		return Math.round((completedFields / totalFields) * 100);
	};
	const calculateCompletionPercentageOfUser = (usersetting) => {

		const fields = [
			usersetting.email,
			usersetting.full_name || usersetting.first_name,
			usersetting.phone,
		];

		// Count non-empty values
		const completedFields = fields.filter((field) => {
			if (typeof field === "object") {
				return field && Object.keys(field).length > 0; // Check non-empty object
			}
			return Boolean(field); // Check non-empty string or other values
		}).length;

		// Calculate percentage
		const totalFields = fields.length;
		return Math.round((completedFields / totalFields) * 100);
	};
	const onboardingSteps = [
		{
			percentage: calculateCompletionPercentageOfOrganization(organization_settings),
		},
		{
			percentage: calculateCompletionPercentageOfUser(user),
		},
		{
			percentage: admins?.getTotalAdmins?.() > 0 ? 100 : 0,
		},
		{
			percentage: dispatchers?.getTotalDispatchers?.() > 0 ? 100 : 0,
		},
		{
			percentage: hubs?.getTotalHubs?.() > 0 ? 100 : 0,
		},
		{
			percentage: drivers?.getTotalDrivers?.() > 0 ? 100 : 0,
		},
		{
			percentage: teams?.getTotalTeams?.() > 0 ? 100 : 0,
		},
		{
			percentage: calculateDriverAppSettingPercentage(),
		},
		{
			percentage: communications?.getTotalList?.() > 0 ? 100 : 0,
		},
	];

	const calculateTotalOnboardingPercentage = (steps) => {
		const totalSteps = steps.length;
		const completedPercentage = steps.reduce((total, step) => total + step.percentage, 0);
		return Math.round(completedPercentage / totalSteps);
	};
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const [selectedHub, setSelectedHub] = React.useState('');
	const [isDrawerOpenTask, setIsDrawerOpenTask] = React.useState(true);
	const { dashboardView, getHubs, isSuperadmin } = useGlobalContext();

	const { current_user } = _globalData;
	const [progress, setProgress] = useState(50);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const { handleLogout } = useAuthHelper();
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleDrawerToggle = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};
	const handleDrawerToggleTask = () => {
		setIsDrawerOpenTask(!isDrawerOpenTask);
	};

	const [openSetting, setOpenSetting] = React.useState(false);
	const handleOpenSetting = (tabindex) => {
		setOpenSetting(true)

		if (tabindex) {
			setTimeout(() => {
				$("#vertical-tab-" + tabindex).click();
			}, 300);
		}



	};
	const handleCloseSetting = () => setOpenSetting(false);
	const [openOnBoarding, setOpenOnBoarding] = React.useState(false);
	const handleOpenOnBoarding = (newIndex) => {
		setOpenOnBoarding(true)

	};
	const handleCloseOnBoarding = () => setOpenOnBoarding(false);
	const [openCard, setOpenCard] = useState(false);

	const handleClickOpenBilling = () => {
		_globalDispatch({
			type: "OPEN_BILLING_MODAL",
		})
	};

	const handleCloseCard = () => {
		setOpenCard(false);
	};

	const [run, setRun] = useState(true); // Controls whether Joyride runs

	const steps = [
		{
			target: ".menu-avatar-icon", // Matches the className of the MenuIcon
			content: "Click on the menu icon to open your account options.",
		},
		// Add more steps as needed
	];
	const [tabIndex, setTabIndex] = React.useState(0); // Track the active tab








	return (
		<>
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					{/*  */}
					<Box display="flex" flexGrow={1} alignItems="center">
						<IconButton edge="start" color="inherit" onClick={handleDrawerToggleTask}>
							<TaskIcon />
						</IconButton>
						<img src={newlogo} alt="Logo"
							style={{ height: '2em' }} />

					</Box>

					{/* Hubs selection */}
					{
						getHubs() && getHubs().length > 1 && <Box display="flex" flexGrow={1} alignItems="center">
							<FormControl fullWidth>
								<InputLabel id="hub-select-label" sx={{
									color: "#FFF",
									fontSize: 14,
									top: '-6px'
								}}>Choose Hub</InputLabel>
								<Select
									labelId="hub-select-label"
									label="Hub"
									value={selectedHub} // Control the value via state
									onChange={(event) => {
										setSelectedHub(event.target.value); // Update the state
										console.log("Selected Hub:", event.target.value);
										const val = event.target.value;
										if (val) {
											_globalDispatch({
												type: "SET_CUSTOM_CENTER",
												payload: {
													title: val.hub_name,
													location: val.full_address_latlng,
													isHub: true,
													hideMarker: true
												}
											})
										}
									}}

									sx={{
										color: 'white', // Select text color
										'.MuiOutlinedInput-notchedOutline': {
											borderColor: 'white', // Border color for default state
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: 'white', // Border color on hover
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'white', // Border color when focused
										},
										'.MuiSvgIcon-root': {
											color: 'white', // Dropdown arrow color
										},
										height: '40px', // Set custom height for the select input
										'.MuiOutlinedInput-input': {
											padding: '10px 14px', // Adjust input padding for better alignment
										},
										'.MuiInputLabel-root': {
											lineHeight: '1.4375em', // Ensures label stays vertically aligned
										},
									}}
								>
									{
										hubs.getHubs().map((h) => (
											<MenuItem key={h._id} value={h}>
												{h.hub_name}
											</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Box>
					}

					{/*  */}
					<Box display="flex" flexGrow={1} justifyContent="center">
						<CreateTaskButton />
						<ImportButton />
						<ExportButtons />

					</Box>


					<Box display="flex" flexGrow={1} justifyContent="flex-end"   >


						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{/* Dashboard */}
							<Box sx={{ flexDirection: 'row-reverse', display: "flex" }}>
								<Typography component='a' marginX={1}>
									<Joyride
										steps={steps}
										run={run}
										continuous
										scrollToFirstStep
										showSkipButton
										styles={{
											options: {
												zIndex: 1000,
											},
										}}
										callback={(data) => {
											if (data.status === "finished") {
												setRun(false); // Stops the tutorial once completed
											}
										}}
									/>

									<MenuIcon sx={{ color: '#fff' }} id="demo-positioned-button"
										className="menu-avatar-icon"
										aria-controls={open ? 'account-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									/>
									{/* </IconButton> */}
									{/* </Button> */}

									<Menu


										sx={{ mt: 2 }}
										anchorEl={anchorEl}
										id="account-menu"
										open={open}
										onClose={handleCloseMenu}
										onClick={handleCloseMenu}
										PaperProps={{
											elevation: 0,
											sx: {
												overflow: 'visible',
												filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
												mt: 1.5,
												'& .MuiAvatar-root': {
													width: 32,
													height: 32,
													ml: -0.5,
													mr: 1,
												},

												'&::before': {
													content: '""',
													display: 'block',
													position: 'absolute',
													top: 0,
													right: 14,
													width: 10,
													height: 10,
													bgcolor: 'background.paper',
													transform: 'translateY(-50%) rotate(45deg)',
													zIndex: 0,
												},
											},
										}}

										transformOrigin={{ horizontal: 'right', vertical: 'top' }}
										anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									>
										{
											current_user && <MenuItem  >

												<ListItemIcon >
													<Avatar src={current_user.user_image} alt={current_user.full_name} />
												</ListItemIcon>
												<ListItemText
													primary={current_user.full_name}
													secondary={
														<Typography variant="body2" color="textSecondary">
															{current_user.email}
														</Typography>
													}
												/>
											</MenuItem>
										}


										<MenuItem
											onClick={handleOpenOnBoarding}
											sx={{
												border: "2px solid transparent",
												borderRadius: "8px", // optional, for rounded corners
												borderColor: "primary.main",
												cursor: "pointer", // Ensure it looks clickable
											}}
										>
											<Box width="100%">
												<ListItemText primary="Onboarding" />
												<Box mt={1}>
													<LinearProgress variant="determinate" value={calculateTotalOnboardingPercentage(onboardingSteps)} />
													<Box mt={0.5} textAlign="center">
														<span>{`${calculateTotalOnboardingPercentage(onboardingSteps)}% Completed`}</span>
													</Box>
												</Box>
											</Box>
										</MenuItem>
										<MenuItem onClick={() => handleOpenSetting(0)}>
											<ListItemText primary="Settings" /></MenuItem>
										{
											isSuperadmin() && <MenuItem onClick={handleClickOpenBilling}>Billing</MenuItem>
										}



										<MenuItem onClick={handleLogout}>Logout</MenuItem>
									</Menu>
								</Typography>
								<Typography component='a' marginX={1} onClick={() => {
									_globalDispatch({
										type: "SET_DASHBOARD_VIEW",
										payload: "map"
									})
								}}
									sx={dashboardView === "map" ? selectedStyle : nonSelectedStyle}
								>
									<MapIcon />
								</Typography>
								<Typography component='a' marginX={1} onClick={() => {
									_globalDispatch({
										type: "SET_DASHBOARD_VIEW",
										payload: "table"
									})
								}}
									sx={dashboardView === "table" ? selectedStyle : nonSelectedStyle}
								>
									<TableViewIcon />
								</Typography>

							</Box>


						</Typography>


					</Box>

					<Dialog
						fullScreen
						open={openCard}
						onClose={handleCloseCard}
						TransitionComponent={Transition}
					>
						<AppBar sx={{ position: 'relative' }}>
							<Toolbar>
								<IconButton
									edge="start"
									color="inherit"
									onClick={handleCloseCard}
									aria-label="close"
								>
									<CloseIcon />
								</IconButton>
								<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
									Payment Cards
								</Typography>
							</Toolbar>
						</AppBar>
						<div style={{ padding: 20 }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									border: '1px solid #888 ',
									padding: '16px',
									borderRadius: '8px',
									marginBottom: '10px'
								}}
							>
								<Typography variant="h4" gutterBottom>
									Attached Cards
								</Typography>
							</Box>
							<Typography variant="body1">
								<CardList />
							</Typography>
						</div>
					</Dialog>

					<Modal
						open={openSetting}
						onClose={handleCloseSetting}
					// sx={{overflow: "scroll"}}
					>
						<Box sx={style}>
							<ModalClose handleClose={handleCloseSetting} />
							<VerticalTabs />

						</Box>
					</Modal>
					<Modal
						open={openOnBoarding}
						onClose={handleCloseOnBoarding}
						sx={{ overflow: "scroll" }}
					>
						<Box sx={onboardingstyle}>
							<ModalClose handleClose={handleCloseOnBoarding} />
							<VerticalTabsOnBoarding openSettingFromOnboarding={handleOpenSetting} />
						</Box>
					</Modal>

				</Toolbar>
			</AppBar>
			<Drawer anchor="left" open={isDrawerOpenTask}
				hideBackdrop={true}
				disableEnforceFocus
			>
				<Grid bgcolor={WHITE_COLOR} sx={{ height: '90vh', width: '40vh', marginTop: '60px', overflow: 'scroll' }}>
					<TasksList />
				</Grid >
				<FiltersButton />
			</Drawer >
			<Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerToggle} disableEnforceFocus  >
				<Sidebar />
			</Drawer>
		</>
	);
};

export default Topbar;