import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 * 
 * @param {*} driver_id 
 * @param {*} current_location 
 * @param {*} destinations 
 * @param {*} task_ids 
 * @param {*} order_ids 
 * @param {*} task_types 
 * @returns 
 */
export const getDistanceMatrix = async (driver_id, current_location, destinations, task_ids, order_ids, task_types, notify = true) => {
    return postRequest('/api/maps/distance-matrix', {
        driver_id,
        current_location,
        destinations,
        task_ids,
        order_ids,
        task_types,
        notify
    });
}

export const routeOptimize = async (origin, destination, waypoints, tasks) => {
    return postRequest('/api/maps/route-optimize', {
        origin,
        destination,
        waypoints,
        tasks
    });
}


export const _optimizeTours = async (data) => {
    return postRequest('/api/route-optimization/optimize-tours', data);
}