export const mapCenter = {
    lat: 37.8293112,
    lng: -122.2926535
}

export const mapInitOptions = {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"]
}

export const markerIcon = (label = "", task_type = "unassigned", task = {}, ref = null, _selected_tasks = []) => {
    let type = task_type.toLowerCase();

    const isSelected = _selected_tasks.find(_t => _t._id == task._id);
    if (isSelected) {
        type = `selected-${task_type.toLowerCase()}`
    }

    console.log("type from digthis", type)


    let markerPath = undefined;

    switch (type) {
        case "assigned":
            markerPath = require(`../icons/assigned-icon.svg`).default
            break;
        case "exception":
            markerPath = require(`../icons/exception-icon.svg`).default
            break;
        case "intransit":
            markerPath = require(`../icons/transit-icon.svg`).default
            break;
        case "completed":
            markerPath = require(`../icons/completed-icon.svg`).default
            break;
        case "failed":
            markerPath = require(`../icons/failed-icon.svg`).default
            break;
        case "selected-unassigned":
            markerPath = require(`../icons/selected/unassigned.svg`).default;
            break;
        case "selected-assigned":
            markerPath = require(`../icons/selected/assigned.svg`).default;
            break;
        case "selected-intransit":
            markerPath = require(`../icons/selected/intransit.svg`).default;
            break;
        case "selected-exception":
            markerPath = require(`../icons/selected/exception.svg`).default;
            break;
        case "selected-completed":
            markerPath = require(`../icons/selected/completed.svg`).default;
            break;
        case "selected-failed":
            markerPath = require(`../icons/selected/failed.svg`).default;
            break;
        case "unassigned":
        default:
            markerPath = require(`../icons/unassigned-icon.svg`).default
            break;
    }

    return {
        url: markerPath,
        fillColor: "",
        fillOpacity: 1,
        strokeColor: '',
        strokeWeight: 1,
        scale: 1,
        label: label,
        task,
        ref
    }
}

export const driverMarkerIcon = {
    url: require("../icons/current-location-svgrepo-com.svg").default,
    fillColor: '',
    fillOpacity: 1,
    strokeColor: 'transparent',
    strokeWeight: 1,
    scale: 1.7
}


export const homeMarkerIcon = {
    url: require("../icons/home-svgrepo-com.svg").default,
    fillColor: '',
    fillOpacity: 1,
    strokeColor: 'transparent',
    strokeWeight: 1,
    scale: 1
}