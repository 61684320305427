import React, { useEffect, useState } from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField, Box, FormLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment-timezone"
import { getTimezone, MOMENT_OUTPUT_DB, MOMENT_OUTPUT_DISPLAY_TIME } from '../../../constants/moment-date-formats';
import useGlobalContext from '../../../hooks/useGlobalContext';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CustomTimePicker from '../../common/CustomTimePicker';
import { deepCopy } from '../../../utils/utils';
import { addHoursMinutesAmPm } from '../../../utils/date';

export default function DateSelect() {
    const { chosenDate, chosenTimeFrom, _globalDispatch } = useGlobalContext();
    const [complete_before_details, setCompleteBeforeDetails] = useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });

    const [complete_after_details, setCompleteAfterDetails] = useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });

    useEffect(() => {

    }, [chosenTimeFrom])

    const timezone = getTimezone();

    useEffect(() => {
        // _globalDispatch({
        //     type: "SET_CHOSEN_TIME_FROM",
        //     payload: newVal.tz(timezone),
        // });



    }, [complete_after_details, complete_before_details])

    // console.log("digthis complete_after_details", complete_after_details)
    // console.log("digthis complete_before_details", complete_before_details)

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box marginY={2}>
            <DesktopDatePicker
                label="Delivery Date"
                inputFormat="MM/DD/YYYY"
                timezone={timezone}
                value={chosenDate}
                onChange={async (newVal) => {
                    const d = moment(newVal.$d).format(MOMENT_OUTPUT_DB);
                    // console.log("chosen date", d)

                    _globalDispatch({
                        type: "SET_CHOSEN_DATE",
                        payload: d
                    });

                }}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}

                />
                }
            />
        </Box>

        <Box marginY={2}>
            {/* <TimePicker
                label="From"
                onChange={(newVal) => {
                    if (newVal) {
                        _globalDispatch({
                            type: "SET_CHOSEN_TIME_FROM",
                            // payload: moment(newVal.$d).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME),
                            payload: newVal.tz(timezone),
                        });
                    }

                }}
                value={chosenTimeFrom}
                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            /> */}


            <FormLabel>Complete After</FormLabel>
            <Box mb={1}></Box>
            <CustomTimePicker
                initialVal={complete_after_details}
                onChange={(val) => {

                    const prev = deepCopy(complete_after_details)
                    if (val.type === "hour") {
                        prev.hour = val.hour
                    } else if (val.type === "minute") {
                        prev.minute = val.minute
                    } else if (val.type === "ampm") {
                        prev.ampm = val.ampm;
                    }

                    setCompleteAfterDetails(prev);

                    const _complete_after = addHoursMinutesAmPm(chosenDate, prev.hour, prev.minute, prev.ampm);
                    console.log("_complete_after", _complete_after)
                    _globalDispatch({
                        type: "SET_CHOSEN_TIME_FROM",
                        // payload: moment(newVal.$d).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME),
                        payload: _complete_after.tz(timezone),
                    });

                }}
            />
        </Box>

        <Box marginY={2}>
            {/* <TimePicker
                label="To"
                value={chosenTimeTo}
                onChange={(newVal) => {
                    if (newVal) {
                        _globalDispatch({
                            type: "SET_CHOSEN_TIME_TO",
                            // payload: moment(newVal.$d).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME)
                            payload: newVal.tz(timezone)
                        });
                    }
                }}
                ampm={true}
                renderInput={(params) => <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,  // Removes the clock icon
                    }}
                />}
            /> */}
            <FormLabel>Complete Before</FormLabel>
            <Box mb={1}></Box>
            <CustomTimePicker
                initialVal={complete_before_details}
                onChange={(val) => {
                    const prev = deepCopy(complete_before_details)
                    if (val.type === "hour") {
                        prev.hour = val.hour
                    } else if (val.type === "minute") {
                        prev.minute = val.minute
                    } else if (val.type === "ampm") {
                        prev.ampm = val.ampm;
                    }

                    setCompleteBeforeDetails(prev);

                    // _complete_after = addHoursMinutesAmPm(payload.delivery_date, complete_after_details.hour, complete_after_details.minute, complete_after_details.ampm);

                    const _complete_before = addHoursMinutesAmPm(chosenDate, prev.hour, prev.minute, prev.ampm);
                    console.log("_complete_before", _complete_before)
                    _globalDispatch({
                        type: "SET_CHOSEN_TIME_TO",
                        // payload: moment(newVal.$d).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME),
                        payload: _complete_before.tz(timezone),
                    });
                }}
            />
        </Box>

    </LocalizationProvider>
}
