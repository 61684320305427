import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';
import {
	Button,
	Box,
	Grid
} from '@mui/material';

import { _optimizeTours } from '../../api/maps';
import { deepCopy } from '../../utils/utils';

import Loader from '../Loader';

import DriverStartEndTime from '../route-optimize/DriverStartEndTime';
import { updateTimewindow } from '../../api/driver';
import { toast } from 'react-toastify';

export default function TimewindowModal() {
	const [loadingState, setLoadingState] = React.useState(0)
	const {
		_globalDispatch,
		timewindow_modal_open,
		chosenDriver,
		drivers
	} = useGlobalContext();

	/**
	 * 
	 */
	const handleClose = () => {
		_globalDispatch({
			type: "CLOSE_TIMEWINDOW_MODAL"
		})
	};

	const saveTimewindow = async () => {
		// updateDriver
		setLoadingState(1)
		const _res = await updateTimewindow(chosenDriver._id, { timewindow: chosenDriver.timewindow });

		if (_res.success) {
			toast.success("Time window updated successfully.");
			setLoadingState(2);
			const _chosen_driver = deepCopy(chosenDriver);

			drivers.updateSelectedDriver(_chosen_driver);
			_globalDispatch({
				type: "CLOSE_TIMEWINDOW_MODAL"
			});
		} else {
			toast.error(_res.msg)
		}

	};

	// console.log("chosenDriver.timewindow", chosenDriver)

	return (
		<Dialog
			open={timewindow_modal_open}
			onClose={handleClose}
			scroll="paper"
		>
			{
				loadingState === 1 && <Box sx={{
					position: 'absolute',
					top: "50%",
					left: "44%"
				}}>
					<Loader />
				</Box>
			}


			<ModalClose handleClose={handleClose} />
			<DialogTitle id="scroll-dialog-title">Driver schedule</DialogTitle>
			<DialogContent dividers={true}>
				<Box>
					{/* <Box sx={{
						position: "absolute",
						left: "47%",
						top: "50%"
					}}>
						<Loader />
					</Box> */}
					<Grid container spacing={2}>
						<DriverStartEndTime day='Monday' />
						<DriverStartEndTime day='Tuesday' />
						<DriverStartEndTime day='Wednesday' />
						<DriverStartEndTime day='Thursday' />
						<DriverStartEndTime day='Friday' />
						<DriverStartEndTime day='Saturday' />
						<DriverStartEndTime day='Sunday' />

						<Grid item xs={12}>
							<Box textAlign={"center"}>
								<Button
									variant='contained'
									size='small'
									onClick={saveTimewindow}
									sx={{ "marginLeft": "12px" }}
								>
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>

			</DialogContent>
		</Dialog>
	);
}