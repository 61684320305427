import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getHubs = async () => {
    return postRequest('/api/hubs/all', {});
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getHubDetails = async (id) => {
    return getRequest(`/api/hubs/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteHubs = async (_ids) => {
    return deleteRequest('/api/hubs/delete', { _ids });
}

export const deleteHub = async (id) => {
    return deleteRequest(`/api/hubs/delete/${id}`);
}

/**
 *
 * @param {*} Hub
 */
export const addHub = async (data) => {
    return postRequest('/api/hubs/add', data);
}

/**
 *
 * @param id
 * @param Hub
 * @returns {Promise<*>}
 */
export const updateHub = async (id, data) => {
    return putRequest(`/api/hubs/update/${id}`, data);
}
