import React, { createContext, useEffect, useReducer } from 'react';
import { ASSIGNED_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS, INTRANSIT_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../constants/task_status';
import { GlobalReducer } from '../reducers/GlobalReducer';
import { getToday } from '../utils/date';
import { getTimezone } from '../constants/moment-date-formats';
import moment from 'moment-timezone';

import dayjs from 'dayjs';
import _timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(_timezone);

export const GlobalContext = createContext(null);
const init = initialArg => {
    return {
        globalRefreshCounter: initialArg.globalRefreshCounter,
        dashboardView: initialArg.dashboardView,
        drivers: initialArg.drivers,
        admins: initialArg.admins,
        communicatons: initialArg.communicatons,
        plans: initialArg.plans,
        invoices: initialArg.invoices,
        chosenAdmin: initialArg.chosenAdmin,
        chosenCommunication: initialArg.chosenCommunication,
        tasks: initialArg.tasks,
        ongoingTasks: initialArg.ongoingTasks,
        chosenTask: initialArg.chosenTask,
        refreshCount: initialArg.refreshCount,
        taskOptionsModalOpen: initialArg.taskOptionsModalOpen,
        taskViewModalOpen: initialArg.taskViewModalOpen,
        billingModalOpen: initialArg.billingModalOpen,
        filterModalOpen: initialArg.filterModalOpen,
        editTasksModalOpen: initialArg.editTasksModalOpen,
        multiAssignTasksModalOpen: initialArg.multiAssignTasksModalOpen,
        route_optimize_modal_open: initialArg.route_optimize_modal_open,
        timewindow_modal_open: initialArg.timewindow_modal_open,
        tasksOptionModalOpen: initialArg.tasksOptionModalOpen,
        displayPolyline: initialArg.displayPolyline,
        polylineTasks: initialArg.polylineTasks,
        chosenDate: initialArg.chosenDate,
        chosenTimeFrom: initialArg.chosenTimeFrom,
        chosenTimeTo: initialArg.chosenTimeTo,
        multiSelectTasks: initialArg.multiSelectTasks,
        refreshing: initialArg.refreshing,
        trafficMode: initialArg.trafficMode,
        clusterView: initialArg.clusterView,
        selectedStatus: initialArg.selectedStatus,
        direction: initialArg.direction,
        infoWindow: initialArg.infoWindow,
        openedAccordions: initialArg.openedAccordions,
        lineRefs: initialArg.lineRefs,
        dragging: initialArg.dragging,
        current_user: initialArg.current_user,
        custom_center: initialArg.custom_center,
        taskOptionModalOffset: initialArg.taskOptionModalOffset,
    }

}

const GlobalContextProvider = (props) => {
    const [_globalData, _globalDispatch] = useReducer(GlobalReducer, {
        globalRefreshCounter: 0,
        dashboardView: 'map',
        invoices: null,
        hubs: null,
        teams: null,
        dispatchers: null,
        drivers: null,
        admins: null,
        communicatons: null,
        plans: null,
        choseTeam: null,
        chosenDispatcher: null,
        chosenDriver: null,
        chosenHub: null,
        chosenAdmin: null,
        chosenCommunication: null,
        tasks: null,
        ongoingTasks: null,
        chosenTask: null,
        refreshCount: 0,
        taskOptionsModalOpen: false,
        taskOptionsModalMode: 'single',
        taskViewModalOpen: false,
        billingModalOpen: false,
        filterModalOpen: false,
        tasksOptionModalOpen: false,
        editTasksModalOpen: false,
        multiAssignTasksModalOpen: false,
        route_optimize_modal_open: false,
        timewindow_modal_open: false,
        displayPolyline: false,
        polylineTasks: null,
        chosenDate: null,
        chosenTimeFrom: null,
        chosenTimeTo: null,
        multiSelectTasks: [],
        refreshing: false,
        trafficMode: false,
        clusterView: false,
        selectedStatus: [UNASSIGNED_TASK_STATUS, ASSIGNED_TASK_STATUS, INTRANSIT_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS],
        direction: false,
        infoWindow: null,
        openedAccordions: [],
        lineRefs: null,
        dragging: false,
        current_user: null,
        custom_center: null,
        draggable: true,
        taskOptionModalOffset: null
    }, init);

    const today = getToday();

    const timezone = getTimezone();

    useEffect(() => {
        const _chosen_date = localStorage.getItem("@chosen_date");
        const _selected_status = localStorage.getItem("@selected_status");
        _globalDispatch({
            type: "SET_CHOSEN_DATE",
            payload: _chosen_date ? _chosen_date : today
        });

        _globalDispatch({
            type: "SET_SELECTED_STATUS",
            payload: _selected_status ? JSON.parse(_selected_status) : [UNASSIGNED_TASK_STATUS, ASSIGNED_TASK_STATUS, INTRANSIT_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS]
        });

        const start_day = dayjs().tz(timezone).startOf('day');
        const end_day = dayjs().tz(timezone).endOf('day');

        _globalDispatch({
            type: "SET_INITIAL_TIME",
            chosenTimeFrom: start_day,
            chosenTimeTo: end_day,
        });

    }, [])

    return (
        <GlobalContext.Provider value={{ _globalData, _globalDispatch }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;
