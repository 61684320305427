import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getTasks = async (filter = {}) => {
    return postRequest('/api/tasks/all', { filter });
}

export const getExportTasks = async (filter = {}) => {
    return postRequest('/api/tasks/export-data', { filter });
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getTaskDetails = async (id) => {
    return getRequest(`/api/tasks/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteTask = async (_ids) => {
    return deleteRequest('/api/tasks/delete', { _ids });
}

/**
 *
 * @param {*} data
 */
export const addTask = async (data) => {
    return postRequest('/api/tasks/add', data);
}

/**
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export const updateTask = async (id, data) => {
    return putRequest(`/api/tasks/update/${id}`, data);
}

export const deleteAndAdd = async (data) => {
    return postRequest(`/api/tasks/delete-and-add`, data);
}

export const multiAssign = async (data) => {
    return postRequest(`/api/tasks/multi-assign`, data);
}

export const multiUnAssign = async (_ids) => {
    return postRequest(`/api/tasks/multi-unassign`, { _ids });
}

export const multiDelete = async (_ids) => {
    return postRequest(`/api/tasks/multi-delete`, { _ids });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const multiAassignPushNotification = async (data) => {
    return postRequest(`/api/tasks/multi-assign-push-notification`, data);
}

export const importData = async (data) => {
    return postRequest(`/api/tasks/import`, { data });
}

export const insertManyData = async (data) => {
    return postRequest(`/api/tasks/insert-many`, { data });
}
