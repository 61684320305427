import { deleteRequest, getRequest, postRequest, putRequest, newpostRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getDrivers = async () => {
    return postRequest('/api/drivers/all', {});
}

export const refreshDrivers = async (delivery_date) => {
    return postRequest('/api/drivers/refresh', { delivery_date });
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getDriverDetails = async (id) => {
    return getRequest(`/api/drivers/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteDrivers = async (_ids) => {
    return deleteRequest('/api/drivers/delete', { _ids });
}

export const deleteDriver = async (id) => {
    return deleteRequest(`/api/drivers/delete/${id}`);
}

/**
 *
 * @param {*} Driver
 */
export const addDriver = async (data) => {
    return newpostRequest('/api/drivers/newadd', data);
}

/**
 *
 * @param id
 * @param Driver
 * @returns {Promise<*>}
 */
export const updateDriver = async (id, data) => {
    return putRequest(`/api/drivers/update/${id}`, data);
}

export const resetDriverPassword = async (data) => {
    return postRequest(`/api/drivers/reset-password`, data);
}

export const updateTimewindow = async (id, data) => {
    return postRequest(`/api/drivers/update/${id}/timewindow`, data);
}
