import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@mui/material';

function CustomTimePicker({ initialVal = {}, onChange }) {
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [ampm, setAmpm] = useState("AM");


    useEffect(() => {
        setHour(initialVal.hour)
        setMinute(initialVal.minute)
        setAmpm(initialVal.ampm)
    }, [initialVal])

    const handleHourChange = (event) => {
        const value = event.target.value;
        if (value === '' || (Number(value) >= 1 && Number(value) <= 12)) {
            setHour(value);

            onChange({ hour: value, type: "hour" })
        }
    };

    const handleMinuteChange = (event) => {
        const value = event.target.value;
        if (value === '' || (Number(value) >= 0 && Number(value) <= 59)) {
            setMinute(value);
            onChange({ minute: value, type: "minute" })
        }
    };

    const handleAmPmChange = (event) => {
        setAmpm(event.target.value);
        onChange({ ampm: event.target.value, type: "ampm" })
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {/* Hour Input */}
            <Grid item>
                <TextField
                    label="Hour"
                    value={hour}
                    onChange={handleHourChange}
                    variant="outlined"
                    type="number"
                    size="small"
                    inputProps={{
                        maxLength: 2,
                        style: { padding: '8px 0', textAlign: 'center' } // Vertical and horizontal alignment
                    }}
                    sx={{ width: 60 }}
                />
            </Grid>

            {/* Minute Input */}
            <Grid item>
                <TextField
                    label="Minute"
                    value={minute}
                    onChange={handleMinuteChange}
                    variant="outlined"
                    type="number"
                    size="small"
                    inputProps={{
                        maxLength: 2,
                        style: { padding: '8px 0', textAlign: 'center' } // Vertical and horizontal alignment
                    }}
                    sx={{ width: 60 }}
                />
            </Grid>

            {/* AM/PM Input */}
            <Grid item>
                <TextField
                    label="AM/PM"
                    value={ampm}
                    onChange={handleAmPmChange}
                    variant="outlined"
                    select
                    size="small"
                    SelectProps={{
                        native: true,
                    }}
                    sx={{ width: 80 }}
                >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </TextField>
            </Grid>
        </Grid>
    );
}

export default CustomTimePicker;