export const CommunicationsReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_MESSAGE":
            return {
                ...prevState,
                message: action.payload
            }
        default:
            return prevState;
    }
};
