class HubEntity {
    constructor(details = {}) {
        this._id = details._id
        this.hub_name = details.hub_name
        this.full_address = details.full_address
        this.full_address_latlng = details.full_address_latlng
        this.teams = details.teams
        this.owner = details.owner
    }

    getId() {
        return this._id;
    }

    getHubName() {
        return this.hub_name;
    }

    getFullAddress() {
        return this.full_address;
    }

    getPayload() {
        return {
            _id: this._id,
            full_name: this.full_name,
            hub_name: this.hub_name,
            full_address: this.full_address,
            full_address_latlng: this.full_address_latlng,
            teams: this.teams,
            owner: this.owner
        }

    }

}

export default HubEntity;