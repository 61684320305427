import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import { getDashboardData, refreshDriversTasks } from '../api/frontend';
import DriversListEntity from '../entities/DriversListEntity';
import TasksListEntity from '../entities/TasksListEntity';
import { CircularProgress, Stack } from '@mui/material';
import useGlobalContext from '../hooks/useGlobalContext';
import { getToday } from '../utils/date';
import OnGoingTasksEntity from '../entities/OnGoingTasksEntity';
import CommunicationsListEntity from '../entities/CommunicationsListEntity';

import { useAuthHelper } from '../utils/auth';
import AdminsListEntity from '../entities/AdminsListEntity';
import { refreshToken } from '../api/auth';
import HubsListEntity from '../entities/HubsListEntity';
import TeamsListEntity from '../entities/TeamListEntity';
import DispatchersListEntity from '../entities/DispatchersListEntity';
import Topbar from '../pages/TopBarNav';
import PlansListEntity from '../entities/PlansListEntity';
import { ASSIGNED_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS, INTRANSIT_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../constants/task_status';
import socketIOClient from 'socket.io-client';
import { _setTimezoneLocalStorage, deepCopy, shouldRefresh } from '../utils/utils';
import InvoiceListEntity from '../entities/InvoiceListEntity';
import { toast } from 'react-toastify';

const socket = socketIOClient({
    reconnection: true,              // Enable reconnection
    reconnectionDelay: 1000,          // Start reconnection after 1 second
    reconnectionDelayMax: 5000,       // Maximum reconnection delay
    timeout: 20000,                   // Set connection timeout
    // reconnection: true,         // Enable auto-reconnect
    // reconnectionAttempts: 10,    // Attempt reconnections 10 times
    // reconnectionDelay: 1000,     // Delay between reconnection attempts
    // transports: ['websocket'],
});

export default function DashboardContainer({ children, public_mode = false }) {
    const [loadingState, setLoadingState] = useState(0);
    const { _globalDispatch, drivers: allDrivers, tasks: allTasks, chosenDate, refreshing, selectedStatus, current_user, getOwner } = useGlobalContext();

    const { handleLogout } = useAuthHelper();

    useEffect(() => {
        const owner = getOwner();
        // console.log("owner from DashboardContainer.js", owner);

        if (owner) {
            // console.log("Hello from L35", owner);

            // Join the room with the owner's ID
            socket.emit("join", owner._id);

            // Listen for 'refresh_data' events
            const handleRefreshData = async (message) => {
                console.log("refresh_data", message);
                const { type } = message;
                if (type === "location_update") {
                    const { driver_id, current_location, online_status, last_active } = message;
                    // console.log("allDrivers", allDrivers)
                    if (allDrivers) {
                        const _drivers = allDrivers.getDrivers()
                        // console.log("_drivers", _drivers)
                        const _updatedDrivers = deepCopy(_drivers);
                        _updatedDrivers.map(_d => {
                            if (_d._id == driver_id) {
                                _d.current_location = current_location;
                                _d.online_status = online_status;
                                _d.last_active = last_active;
                            }

                            return _d
                        });

                        // console.log("_updatedDrivers", new DriversListEntity(_updatedDrivers))

                        _globalDispatch({
                            type: "SET_DRIVERS",
                            payload: new DriversListEntity(_updatedDrivers)
                        });
                    }

                } else if (type === "task_status") {
                    const { task_id, task_status } = message;
                    if (allTasks) {
                        const _tasks = allTasks.getTasks();
                        const _updatedTasks = deepCopy(_tasks);
                        _updatedTasks.map(_d => {
                            if (_d._id == task_id) {
                                _d.task_status = task_status;
                            }

                            return _d
                        });

                        // console.log("_updatedTasks", _updatedTasks)

                        _globalDispatch({
                            type: "SET_TASKS",
                            payload: new TasksListEntity(_updatedTasks)
                        });
                    }
                }
                // const _shouldRefresh = shouldRefresh();
                // console.log("_shouldRefresh", _shouldRefresh);

                // if (_shouldRefresh) {
                //     await loadDriverData();
                // }
            };

            // console.log("Hello digthis")
            // Attach the event listener for 'refresh_data'
            socket.on('refresh_data', handleRefreshData);

            // Cleanup the event listener on unmount to avoid memory leaks
            return () => {
                socket.off('refresh_data', handleRefreshData); // Clean up the event listener
            };
        }
    }, [current_user]); // Only run when current_user changes


    useEffect(() => {
        const interval = setInterval(() => {
            const owner = getOwner();
            if (owner && owner._id) {
                // console.log("Owner from interval", owner)
                socket.emit("join", owner._id);
            }

        }, 120000);  // Every 300 seconds

        return () => {
            // socket.disconnect()
            clearInterval(interval)
        };
    }, [current_user]);

    // Load all data
    const loadData = async () => {
        if (public_mode) {
            return false;
        }

        let delivery_date = getToday();
        if (chosenDate) {
            delivery_date = chosenDate
        }

        const storedDate = localStorage.getItem("@chosen_date");
        let selected_status = localStorage.getItem("@selected_status");
        // console.log("selectedStatus", selectedStatus)
        if (selected_status) {
            selected_status = JSON.parse(selected_status);
        } else {
            selected_status = [UNASSIGNED_TASK_STATUS, ASSIGNED_TASK_STATUS, INTRANSIT_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS]
        }

        if (storedDate) {
            delivery_date = storedDate
        }

        const _res = await getDashboardData(delivery_date, selected_status);

        if (_res.success) {
            const { data = {} } = _res;

            // const _tasks = new TasksListEntity(data.tasks || []);
            const hubsEntity = new HubsListEntity(data.hubs || []);
            const teamsEntity = new TeamsListEntity(data.teams || []);
            const dispatcherEntity = new DispatchersListEntity(data.dispatchers || []);
            const tasksEntity = new TasksListEntity(data.tasks || [], data.alltasks || []);
            const driversEntity = new DriversListEntity(data.drivers || []);
            const adminsEntity = new AdminsListEntity(data.admins || []);
            const communicationsEntity = new CommunicationsListEntity(data.communications || []);
            const plansEntity = new PlansListEntity(data.plans || []);
            const invoiceEntity = new InvoiceListEntity(data.invoices || []);
            const ongoingTasksEntity = new OnGoingTasksEntity(data.ongoingTasks || []);

            _globalDispatch({
                type: "INIT",
                hubs: hubsEntity,
                teams: teamsEntity,
                dispatchers: dispatcherEntity,
                tasks: tasksEntity,
                drivers: driversEntity,
                admins: adminsEntity,
                communications: communicationsEntity,
                plans: plansEntity,
                invoices: invoiceEntity,
                ongoingTasks: ongoingTasksEntity
            })
        } else if (_res.success === false && _res.msg === "unauthorized") {
            // If response is unauthorized, force logout
            toast.error(_res.msg)
            handleLogout()
        }
    }

    const handleRefreshToken = async () => {
        const _res = await refreshToken();
        if (_res) {
            if (_res.success) {
                if (_res.user) {
                    _setTimezoneLocalStorage(_res.user)

                    _globalDispatch({
                        type: "SET_CURRENT_USER",
                        payload: _res.user
                    });

                    window.history.replaceState({}, '')
                    setLoadingState(2)
                    _globalDispatch({
                        type: "INCREASE_REFRESH_COUNTER"
                    })
                }
            } else {
                toast.error(_res.msg)
                handleLogout()
            }
        }

    }

    useEffect(() => {
        (async () => {
            setLoadingState(1);
            await loadData();
            await handleRefreshToken();

        })()
    }, []);

    if (loadingState < 2) {
        return <Stack
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <CircularProgress style={{
                position: 'absolute',
                top: '40%'
            }} />
        </Stack>;
    }

    return (
        <Box onKeyDown={(e) => {
            // console.log("onKeyDown DashboardContainer")
            if (e.key === "Escape") {
                _globalDispatch({
                    type: "CLEAR_SELECTED_TASKS"
                })

                _globalDispatch({
                    type: "SET_POLYLINE_TASKS",
                    payload: null
                })

                _globalDispatch({
                    type: "SET_DIRECTION",
                    payload: false
                })

                _globalDispatch({
                    type: "SET_INFOWINDOW",
                    payload: null
                })

                _globalDispatch({
                    type: "SET_DRAGGABLE_BOUNDS",
                    payload: true
                })
            }

            _globalDispatch({
                type: "SET_DRAGGABLE_BOUNDS",
                payload: !e.shiftKey
            })

        }}
            onKeyUp={(e) => {
                _globalDispatch({
                    type: "SET_DRAGGABLE_BOUNDS",
                    payload: true
                })
            }}>
            {
                !public_mode && <Topbar />
            }

            {
                refreshing &&
                <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '40%',
                            zIndex: 9999

                        }}
                    />
                </Stack>
            }
            <Box>
                {children}
            </Box>
        </Box>
    )
}
