import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Box } from "@mui/material";
import {  ListItemSecondaryAction, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/BorderColor';
import { useState } from "react";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import { GlobalContext } from '../../../contexts/GlobalContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { deepCopy } from '../../../utils/utils'
import { deleteUsers, updateUser } from '../../../api/user'
import AdminEditValidation from '../../../utils/form/validations/AdminEditValidation'

import { Formik, Form } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

import AdminValidation from '../../../utils/form/validations/AdminValidation'



import { CircularProgress, Grid } from '@mui/material'
import { toast } from 'react-toastify'
import ModalClose from '../../common/ModalClose'
import { confirmAlert } from 'react-confirm-alert'; // Import

import AdminEntity from '../../../entities/AdminEntity'


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'none',
        },
        '&:hover fieldset': {
            borderColor: 'none',
        },
        '&.Mui-focused fieldset': {
            borderWidth: 0.1
        },
    },
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "100%" }}>
    <CssTextField
        id="search-bar"
        className="text"
        onInput={(e) => {
            setSearchQuery(e.target.value);
        }}
        fullWidth
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"

    />
</Box>
);

export default function AdminsList() {
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false)
    const { _globalData, _globalDispatch } = React.useContext(GlobalContext);

    const { admins, chosenAdmin: chosenItem,chosenAdmin,tasks } = _globalData;
    console.log("admins", admins)
    const data = admins.getAdmins();
    console.log("data", data)

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) =>`${d.first_name} ${d.last_name}`.toLowerCase().includes(query.toLowerCase()));
        }
    };

    const dataFiltered = filterData(searchQuery, data);

    function handleClose() {
		setOpen(false)
	}

	function handleClickOpen(item) {
        _globalDispatch({
            type: "SET_CHOSEN_ADMIN",
            payload: item
        })
		setOpen(true)
	}

	const handleDeleteConfirmation = async (item) => {
      
		confirmAlert({
			customUI: ({ onClose }) => {
			  return (
				<div className="custom-ui__overlay">
				  <div className="custom-ui">
					<div className="custom-ui__header">
					  <h1>Are you sure?</h1>
					</div>
					<div className="custom-ui__body">
					  <p>Do you really want to delete? This process cannot be undone.</p>
					</div>
					<div className="custom-ui__footer">
					  <button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
					  <button
						className="custom-ui__button custom-ui__button--confirm"
						onClick={() => {
						  handleDelete(item);
						  onClose();
						}}
					  >
						Yes, Delete it!
					  </button>
					</div>
				  </div>
				</div>
			  );
			}
		  });
	};

	const handleDelete = async (item) => {
		console.log("chosenAdmin", item)
		const _res = await deleteUsers(item._id);
		if (_res.success) {
			// update tasks list
			if (_res.tasks) {
				for (let t of _res.tasks) {
					tasks.update(t)
				}
			}

			admins.removeAdminFromList(item)

			_globalDispatch({
				type: "RESET_CHOSEN_ADMIN"
			});

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			toast("Admin deleted succesfully.")
		}
	}

    return (<>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        
        {chosenAdmin?<Dialog open={open} onClose={handleClose} maxWidth='sm'>
				<ModalClose handleClose={handleClose} />
				<DialogTitle>{`Edit Admin: ${chosenAdmin.full_name}`}</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							userId: chosenAdmin._id || '',
							first_name: chosenAdmin.first_name || '',
							last_name: chosenAdmin.last_name || '',
							phone: chosenAdmin.phone || '',
							email: chosenAdmin.email || '',
							password: "",
							confirm_password: ""

						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							const { _id } = chosenAdmin;
							const adminPayload = deepCopy(values);
							adminPayload._id = _id;

							setSubmitting(true)
							const _res = await updateUser(_id, adminPayload)

							if (_res.success) {
								toast('Admins details updated successfully.');
								// setOpen(false);

								admins.updateSelectedAdmin(adminPayload);

								_globalDispatch({
									type: "SET_CHOSEN_ADMIN",
									payload: new AdminEntity(adminPayload)
								})

								_globalDispatch({
									type: "INCREASE_REFRESH_COUNTER"
								});
							}

							setSubmitting(false)
						}}
						validationSchema={AdminEditValidation}
					>
						{props => {
							const { values, isSubmitting, handleSubmit, setFieldValue } = props

							return (
								<Form>
									<Grid container spacing={2}>
										{/* Name */}
										<Grid item xs={6}>
											<TextField
												label="First Name"
												name="first_name"
												fullWidth
												variant="outlined"
												margin="normal"
												value={values.first_name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.first_name && props.touched.full_name}
											/>
										</Grid>

										{/* Phone */}
										<Grid item xs={6}>
											<TextField
												label="Last Name"
												name="last_name"
												fullWidth
												variant="outlined"
												margin="normal"
												value={values.last_name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.last_name && props.touched.last_name}
											/>
										</Grid>

										{/* Email */}
										<Grid item xs={12}>
											<TextField
												label='Email'
												name='email'
												type='email'
												fullWidth
												variant='outlined'
												margin='normal'
												value={values.email}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.email && props.touched.email}
											/>
										</Grid>

										{/* Phone */}
										<Grid item xs={12}>
											<TextField
												label="Phone"
												name="phone"
												fullWidth
												variant="outlined"
												margin="normal"
												value={values.phone}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.phone && props.touched.phone}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												label="Password"
												name="password"
												type="password"
												fullWidth
												variant="outlined"
												margin="normal"
												value={values.password}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.password && props.touched.password}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												label="Confirm password"
												name="confirm_password"
												type="password"
												fullWidth
												variant="outlined"
												margin="normal"
												value={values.confirm_password}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.confirm_password && props.touched.confirm_password}
											/>
										</Grid>

										{isSubmitting && (
											<Box
												style={{
													position: 'absolute',
													top: '50%',
													left: '50%'
												}}
											>
												<CircularProgress />
											</Box>
										)}
										<Grid item xs={12}>
											<Box textAlign='center'>
												<Button
													variant='contained'
													type='submit'
													color='primary'
													onClick={handleSubmit}
													disabled={isSubmitting}
												>
													Update
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</DialogContent>
			</Dialog>:''}
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">S.No</TableCell>
            <TableCell align="left"> Name</TableCell>
            <TableCell align="left">Mobile Number</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFiltered && dataFiltered.map((item,i) => (
            <TableRow
              key={item._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
               {i+1}
              </TableCell>
              <TableCell align="left"> 
             
       {item.full_name}
      </TableCell>
              <TableCell align="left">{item.phone}</TableCell>
              <TableCell align="left">{item.email }</TableCell>
              <TableCell align="left"> 
			  {item.role !== 'superadmin' && (
  <>
    <IconButton edge="end" aria-label="edit" onClick={() => handleClickOpen(item)}>
      <EditIcon />
    </IconButton>
    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(item)}>
      <DeleteIcon />
    </IconButton>
  </>
)}
         </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    )
}