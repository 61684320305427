import DriversList from "../drivers/DriversList";
import AddFormDialog from "../communications/AddFormDialog";
import EditFormDialog from "../communications/EditFormDialog";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import CommunicationsList from "../communications/CommunicationsList";
import CommunicationsContextProvider from "../../../contexts/CommunicationsContext";
export default function PanelCommunications() {
    const { _globalData } = useContext(GlobalContext);

    return <CommunicationsContextProvider>
         <AddFormDialog />
        <CommunicationsList />
        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {
                _globalData.chosenCommunication !== null &&
                <EditFormDialog />
            }

           
        </div> */}
    </CommunicationsContextProvider>
}