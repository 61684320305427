import DriversList from "../drivers/DriversList";
import AddFormDialog from "../drivers/AddFormDialog";
import useGlobalContext from "../../../hooks/useGlobalContext";

export default function PanelDrivers() {
    const { _globalData } = useGlobalContext();
    return <>
     <AddFormDialog />
        <DriversList />
    </>
}