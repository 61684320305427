import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { importModalBoxStyle } from "../../theme/modalBox"
import ModalClose from '../common/ModalClose';

import { toast } from 'react-toastify'
import { CSVLink } from "react-csv";
import { SAMPLE_IMPORT_DATA } from '../import/sample_data';
import { importData, insertManyData } from '../../api/task';
import { chunkArrayInGroups, deepCopy, flattenArray } from '../../utils/utils';
import useGlobalContext from '../../hooks/useGlobalContext';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CsvDropzone from './CsvDropzone';
import TaskEntity from '../../entities/TaskEntity';
import { confirmAlert } from 'react-confirm-alert';
import Loader from "../Loader"
import { generateFullAddress, parseFullAddress } from '../../utils/addressUtil';

export default function ImportButton() {

	const tableStyle = {
		width: '100%',
		borderCollapse: 'collapse',
	};

	const cellStyle = {
		border: '1px solid black',
		padding: '8px',
		textAlign: 'left',
	};

	const headerStyle = {
		...cellStyle,
		backgroundColor: '#f2f2f2',
	};

	const {
		_globalDispatch,
		refreshData,
		chosenDate,
		tasks
	} = useGlobalContext();

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState(false);

	const allowed_columns = ["address_1", "address_2", "city", "complete_after", "complete_before", "country", "full_address", "delivery_date", "destination_note", "recipient_name", "recipient_note", "recipient_phone", "state", "task_type", "zip", "recipient_email", "tags"]

	const handleOpen = () => {
		setOpen(true)
	};
	const handleClose = () => {
		setData(false)
		setOpen(false)
	};

	const handleUpload = async () => {
		if (!data) {
			toast.error("Empty data!!!!")
			return false;
		}
		if (data.length < 1) {
			toast.error("Empty data!!!!")
			return false;
		}

		let limit = 50;
		setLoading(true);

		// console.log("data", data)

		const data_chunk = chunkArrayInGroups(data, limit);
		let isAllGood = true;
		let _results = [];
		let _error_data = [];

		// console.log("data_chunk", data_chunk);
		// return false;

		for (let dc of data_chunk) {
			console.log("dc before", dc)
			dc.map(a => {
				if (a.full_address) {
					const address_components = parseFullAddress(a.full_address);
					a.address_1 = address_components.address_1
					a.address_2 = address_components.address_2
					a.city = address_components.city
					a.zip = address_components.zip
					a.country = address_components.country
				}
			})

			const _res = await importData(dc);
			if (_res.success === false) {
				_error_data.push(_res.error_data)
			}

			isAllGood = isAllGood && _res.success;

			_results.push(_res.data)
		}

		// console.log("dat from state", data)

		if (!isAllGood) {

			const __flatten_results = flattenArray(_results);
			setData(__flatten_results)

			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="custom-ui__overlay">
							<div className="custom-ui">
								<div className="custom-ui__header">
									<h2>Error found !</h2>
									<h3>Are you sure want to proceed anyway?</h3>
								</div>
								<div className="custom-ui__body">
									<p>We found some errors in some of the tasks your imported. These tasks are highlighted in the toolbar on the left. Please correct the error to move forward.</p>
								</div>
								<div className="custom-ui__footer">
									<button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
									<button
										className="custom-ui__button custom-ui__button--confirm proceed"
										onClick={async () => {
											await handleImport(_results);
											onClose();
										}}
									>
										Yes, Proceed!!
									</button>
								</div>
							</div>
						</div>
					);
				}
			});

			setLoading(false)
			return false;
		}

		handleImport(_results)
	}

	/**
	 * 
	 * @param {*} _data 
	 */
	const handleDataSetup = async (_data) => {
		const __data = _data.map(_d => {
			_d.has_error = false
			if (_d.full_address) {
				const address_components = parseFullAddress(_d.full_address);
				_d.address_1 = address_components.address_1
				_d.address_2 = address_components.address_2
				_d.city = address_components.city
				_d.state = address_components.state
				_d.zip = address_components.zip
				_d.country = address_components.country
			}
			return _d
		})
		setData(__data)
	}

	/**
	 * 
	 * @param {*} results 
	 */
	const handleImport = async (results) => {
		const _to_import_tasks = flattenArray(results);
		const _res = await insertManyData(_to_import_tasks);
		if (_res.success) {
			let _to_update_tasks = []
			const _results = _res.data;
			for (let r of _results) {
				const { delivery_date } = r;
				if (chosenDate === delivery_date) {
					_to_update_tasks.push(r);
				}
			}

			for (let t of _to_update_tasks) {
				tasks.add(new TaskEntity(t))
			}

			setLoading(false)
			toast.success("The imports completed.");
			setData([])
			setOpen(false);

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});
		}
	}

	return (<>
		<div>
			<ListItem disablePadding >
				<ListItemButton onClick={handleOpen} >
					<ListItemText primary="Import Task" />
				</ListItemButton>
			</ListItem>
		</div>


		<Modal
			open={open}
			onClose={handleClose}
		>

			<Box sx={importModalBoxStyle}>
				{
					loading && <Box sx={{
						position: "absolute",
						top: "50%",
						left: "50%"
					}}>
						<Loader />
					</Box>
				}


				<Typography textAlign={'center'} variant="h6" component="h2" >
					Import Tasks
				</Typography>
				<ModalClose handleClose={handleClose} />
				<Box paddingX={2} paddingY={2} textAlign={"center"} >

					<CSVLink
						filename={'import_sample.csv'}
						data={SAMPLE_IMPORT_DATA}
					>
						<Button variant='text' textAlign={"right"}>
							<DownloadIcon />Download sample
						</Button>
					</CSVLink>

					<CsvDropzone onFileLoaded={handleDataSetup} />
					<div style={{ height: '250px', overflow: 'scroll' }}>
						{data.length > 0 && (
							<table style={tableStyle}>
								<thead>
									<tr>
										{Object.keys(data[0]).map((key) => {
											return allowed_columns.includes(key) && <th key={key} style={headerStyle}>{key}</th>
										})}
									</tr>
								</thead>
								<tbody>
									{
										data.map((row, index) => {
											console.log("row", row)
											return (
												<tr key={index} style={{ border: '1px solid red' }}>
													{
														Object.entries(row).map((value, i) => {
															return allowed_columns.includes(value[0]) && <td key={i} style={{ ...cellStyle, border: row.has_error ? "1px solid red" : "1px solid black" }}>
																{
																	value[0] === "full_address" && !value[1] ? generateFullAddress(row.address_1, row.address_2, row.city, row.state, row.zip, row.country) : value[1]
																}
															</td>
														})
													}
												</tr>
											)
										})
									}

									{/* {
										Object.entries(data).map(([key, value]) => (
											<tr key={key}>
												<td style={{ border: '1px solid black', padding: '8px' }}>{key}</td>
												<td style={{ border: '1px solid black', padding: '8px' }}>
													{value !== null ? value.toString() : "N/A"}
												</td>
											</tr>
										))
									} */}
								</tbody>
							</table>
						)}
					</div>
				</Box>

				<Box textAlign={"center"}>
					<Button
						variant='contained'
						sx={{ textAlign: "center" }}
						onClick={handleUpload}
					>
						Import
					</Button>
				</Box>
				{
					loading && <Box sx={{ position: 'absolute', top: "70%", left: "25%" }}>
						{/* <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center', justifyContent: 'center' }}>
							<Typography sx={{ marginRight: 2 }}>{`Success: ${successCount}`}</Typography>
							<Loader />
							<Typography sx={{ marginLeft: 2 }}>{`Failed: ${failureCount}`}</Typography>
						</Box> */}
					</Box>
				}

			</Box>
		</Modal>
	</>
	);
}
