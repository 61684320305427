import React, { useCallback, useEffect, useState } from 'react'
import {
    Accordion,
    Box,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';
import useGlobalContext from '../../hooks/useGlobalContext';
import AccordionDetails from '../customized/CustomAccordionDetails';
import AccordionSummary from '../customized/CustomAccordionSummary';
import { arrayContainsArray, arraysEqual, deepCopy, flat_unique } from '../../utils/utils';
import moment from 'moment-timezone';
import { MOMENT_ONLY_TIME_OUTPUT_24 } from '../../constants/moment-date-formats';

const IndeterminateCheckbox = ({ team, handleAccordionChange }) => {
    const { opened_team_accordions, chosen_drivers, _route_optimize_dispatch } = useRouteOptimizeContext();
    const { chosenDate, drivers } = useGlobalContext()
    // console.log("chosen_drivers", chosen_drivers)
    const [checked, setChecked] = React.useState([]);

    useEffect(() => {
        setChecked(chosen_drivers)
    }, [chosen_drivers])

    const handleChangeParent = (event, team) => {
        const preDrivers = deepCopy(chosen_drivers);
        const driver_ids = team.driver_ids && team.driver_ids.map(d => d._id);

        let result = [];
        if (!event.target.checked) {
            setChecked([])

            result = preDrivers.filter(element => !driver_ids.includes(element));

            _route_optimize_dispatch({
                type: "SET_CHOSEN_DRIVERS",
                payload: [... new Set(result)]
            })

            return false;
        }

        result = [...preDrivers, ...driver_ids];

        setChecked([... new Set(driver_ids)]);

        _route_optimize_dispatch({
            type: "SET_CHOSEN_DRIVERS",
            payload: [... new Set(result)]
        })
    };

    const handleChangeChild = (event, driver) => {
        if (!driver) {
            return false;
        }

        const preDrivers = deepCopy(chosen_drivers);
        let result = [];

        setChecked(prev => {
            if (event.target.checked) {
                return [...prev, driver._id]
            }

            return prev.filter(o => o !== driver._id)

        });

        if (event.target.checked) {
            result = [...preDrivers, driver._id]
        } else {
            result = preDrivers.filter(o => o !== driver._id)
        }

        _route_optimize_dispatch({
            type: "SET_CHOSEN_DRIVERS",
            payload: [... new Set(result)]
        })
    };

    const isParentChecked = (team) => {
        if (!checked) {
            return false
        }
        if (checked.length < 1) {
            return false
        }

        if (!team.driver_ids) {
            return false;
        }

        if (team.driver_ids.length < 1) {
            return false
        }

        const _drivers_ids = team.driver_ids.map(d => d._id);

        return arrayContainsArray(checked, _drivers_ids);
    }

    const isParentIntermediate = (team) => {
        if (!checked) {
            return false
        }
        if (checked.length < 1) {
            return false
        }

        if (!team.driver_ids) {
            return false;
        }

        if (team.driver_ids.length < 1) {
            return false
        }
        const _drivers_ids = team.driver_ids.map(d => d._id);

        const checked_driver_ids = _drivers_ids.filter(id => checked.includes(id))

        return checked_driver_ids.length > 0 && _drivers_ids.length > checked_driver_ids.length
    }

    const isChildChecked = (driver) => {
        return checked.includes(driver._id)
    }

    const _day = moment(chosenDate).format("dddd").toLowerCase();

    return <Accordion
        key={team._id}
        disableGutters={true}
        expanded={opened_team_accordions.includes(team._id)}
        onChange={handleAccordionChange(team._id)}
    >
        <AccordionSummary>
            <Box sx={{ marginLeft: 2 }}>
                <FormControlLabel
                    label={team.team_name}
                    control={
                        <Checkbox
                            checked={isParentChecked(team)}
                            indeterminate={isParentIntermediate(team)}
                            onChange={(event) => handleChangeParent(event, team)}
                        />
                    }
                />
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                {
                    team.driver_ids && team.driver_ids.map(_driver => {
                        const _full_driver = drivers.getDriverById(_driver._id)

                        if (!_full_driver) {
                            return null
                        }

                        return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormControlLabel
                                label={_driver.name}
                                control={<Checkbox checked={isChildChecked(_driver)} onChange={(event) => {
                                    handleChangeChild(event, _driver)
                                }} />}
                            />
                            {
                                _full_driver.cost_per_hour && <Typography sx={{
                                    fontSize: 12
                                }}>{`( $${_full_driver.cost_per_hour} per hour )`}</Typography>
                            }

                            {
                                _full_driver.timewindow && _full_driver['timewindow'][_day] && <Typography sx={{
                                    fontSize: 12
                                }}>
                                    {`( ${moment(_full_driver['timewindow'][_day].start_time).format(MOMENT_ONLY_TIME_OUTPUT_24)} - ${moment(_full_driver['timewindow'][_day].end_time).format(MOMENT_ONLY_TIME_OUTPUT_24)} )`}
                                </Typography>
                            }


                        </Box>
                    })
                }

            </Box>
        </AccordionDetails>

    </Accordion >
}

export default function TeamDriversSelect() {
    const { _route_optimize_dispatch, opened_team_accordions, chosen_drivers } = useRouteOptimizeContext();
    const {
        teams,
        drivers,
        chosenDate
    } = useGlobalContext();

    const [isAll, setIsAll] = useState(false)

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        if (typeof event.target.className !== "object") {
            return false
        }
        const expandedAccordions = deepCopy([...opened_team_accordions])

        if (!expandedAccordions.includes(panel)) {          //checking weather array contain the id
            expandedAccordions.push(panel);               //adding to array because value doesnt exists
        } else {
            expandedAccordions.splice(expandedAccordions.indexOf(panel), 1);  //deleting
        }

        _route_optimize_dispatch({
            type: "SET_OPENED_TEAM_ACCORDIONS",
            payload: expandedAccordions
        })

    }

    const handleSelectAll = event => {
        setIsAll(event.target.checked)
        if (!event.target.checked) {
            _route_optimize_dispatch({
                type: "SET_CHOSEN_DRIVERS",
                payload: []
            })

            return false;
        }

        const _all_drivers_ids = drivers.getDrivers().map(d => d._id);

        _route_optimize_dispatch({
            type: "SET_CHOSEN_DRIVERS",
            payload: [... new Set(_all_drivers_ids)]
        })
    }

    const _day = moment(chosenDate).format("dddd").toLowerCase();

    const nonTeamDrivers = () => {
        // first get all drivers listed on team
        const team_drivers_ids = teams.getTeams().map(t => t.driver_ids.map(d => d._id));

        return drivers.getDrivers().filter(d => !flat_unique(team_drivers_ids).includes(d._id))
    }

    return <Box>
        <Box sx={{ marginLeft: 2 }}>
            <FormControlLabel
                label={`${isAll ? "Unselect" : "Select"} All`}
                control={<Checkbox onChange={(event) => {
                    handleSelectAll(event)
                }} />}
            />
        </Box>
        {
            teams.getTotalTeams() > 0 && teams.getTeams().map(team => team && team.driver_ids && team.driver_ids.length > 0 && <IndeterminateCheckbox team={team} handleAccordionChange={handleAccordionChange} />
            )
        }

        {
            nonTeamDrivers().map(_full_driver => {
                return <Box ml={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormControlLabel
                        label={_full_driver.name}
                        control={<Checkbox
                            checked={chosen_drivers.includes(_full_driver._id)}
                            onChange={(event) => {
                                // handleChangeChild(event, _full_driver._id)
                                let _chosen_drivers = deepCopy(chosen_drivers);
                                if (event.target.checked) {
                                    _chosen_drivers.push(_full_driver._id)
                                } else {
                                    _chosen_drivers = _chosen_drivers.filter(d => d != _full_driver._id)
                                }

                                _route_optimize_dispatch({
                                    type: "SET_CHOSEN_DRIVERS",
                                    payload: _chosen_drivers
                                })
                            }}
                        />}
                    />
                    {
                        _full_driver.cost_per_hour && <Typography sx={{
                            fontSize: 12
                        }}>{`( $${_full_driver.cost_per_hour} per hour )`}</Typography>
                    }

                    {
                        _full_driver.timewindow && _full_driver['timewindow'][_day] && <Typography sx={{
                            fontSize: 12
                        }}>
                            {`( ${moment(_full_driver['timewindow'][_day].start_time).format(MOMENT_ONLY_TIME_OUTPUT_24)} - ${moment(_full_driver['timewindow'][_day].end_time).format(MOMENT_ONLY_TIME_OUTPUT_24)} )`}
                        </Typography>
                    }
                </Box>
            })
        }
    </Box>
}
