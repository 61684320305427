class InvoiceEntity {
    constructor(details = {}) {
        this._id = details._id
        this.remarks = details.remarks
        this.price = details.price
        this.period_start = details.period_start
        this.period_end = details.period_end
        this.created_at = details.createdAt
        this.plan = details.plan
       
    }

    getId() {
        return this._id;
    }
    getRemarks() {
        return this.remarks;
    }

   
    getPayload() {
        return {
            _id: this._id,
            remarks: this.remarks,
            price: this.price,
            period_start: this.period_start,
            period_end: this.period_end,
            created_ar: this.created_at,
            plan: this.plan,
        }

    }
}

export default InvoiceEntity;