import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import TuneIcon from '@mui/icons-material/Tune';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import GroupsIcon from '@mui/icons-material/Groups';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DriverIcon from '../../images/healthicons_truck-driver.png';
import { Typography } from '@mui/material';
import useGlobalContext from '../../hooks/useGlobalContext';



const OnboardingPage = ({ openSettingFromOnboarding }) =>  {
  const { tasks, admins, drivers, dispatchers, hubs, _globalData } = useGlobalContext();
  const { communications,teams  } = _globalData;
  console.log('communications',communications);
  console.log('hubs',hubs);
  console.log('teams',teams.length>0?100:0);
  console.log('hubs',hubs.length>0?100:0);

   const { current_user: user = {}, _globalDispatch } = useGlobalContext();
      const { organization_settings = {}, _id,driver_app_settings = {} } = user
      const calculateDriverAppSettingPercentage = () => {
      
        // Check if `driver_app_settings` has data
        return Object.keys(driver_app_settings).length > 0 ? 100 : 0;
      };

      const calculateCompletionPercentageOfOrganization = (organizationSettings) => {
        const fields = [
          organizationSettings.organization_name,
          organizationSettings.sms_reply_email || organizationSettings.driver_support_email,
          organizationSettings.organization_email,
          organizationSettings.driver_support_email,
          organizationSettings.country,
          organizationSettings.timezone,
          organizationSettings.hq_address,
          organizationSettings.hq_address_lat_lng,
        ];
      
        // Count non-empty values
        const completedFields = fields.filter((field) => {
          if (typeof field === "object") {
            return field && Object.keys(field).length > 0; // Check non-empty object
          }
          return Boolean(field); // Check non-empty string or other values
        }).length;
      
        // Calculate percentage
        const totalFields = fields.length;
        return Math.round((completedFields / totalFields) * 100);
      };
      const calculateCompletionPercentageOfUser = (usersetting) => {
       
        const fields = [
          usersetting.email,
          usersetting.full_name || usersetting.first_name,
          usersetting.phone,
        ];
      
        // Count non-empty values
        const completedFields = fields.filter((field) => {
          if (typeof field === "object") {
            return field && Object.keys(field).length > 0; // Check non-empty object
          }
          return Boolean(field); // Check non-empty string or other values
        }).length;
      
        // Calculate percentage
        const totalFields = fields.length;
        return Math.round((completedFields / totalFields) * 100);
      };
  const onboardingSteps = [
    {
      label: "Organization",
      icon: <BusinessIcon />,
      percentage: calculateCompletionPercentageOfOrganization(organization_settings),
      link: "/organization", // Example link
    },
    {
      label: "User Settings",
      icon: <TuneIcon />,
      percentage: calculateCompletionPercentageOfUser(user),
      link: "/user-settings",
    },
    {
      label: "Admins",
      icon: <ManageAccountsIcon />,
      percentage: admins?.getTotalAdmins?.() > 0 ? 100 : 0,
      link: "/admins",
    },
    {
      label: "Dispatchers",
      icon: <LocalShippingIcon />,
      percentage: dispatchers?.getTotalDispatchers?.() > 0 ? 100 : 0,
      link: "/dispatchers",
    },
    {
      label: "Hubs",
      icon: <DeviceHubIcon />,
      percentage: hubs?.getTotalHubs?.() > 0 ? 100 : 0,
      link: "/hubs",
    },
    {
      label: "Drivers",
      icon: <img src={DriverIcon} alt="Driver" style={{ width: 24 }} />,
      percentage: drivers?.getTotalDrivers?.() > 0 ? 100 : 0,
      link: "/drivers",
    },
    {
      label: "Teams",
      icon: <GroupsIcon />,
      percentage: teams?.getTotalTeams?.()>0?100:0,
      link: "/teams",
    },
    {
      label: "Driver App",
      icon: <AppSettingsAltIcon />,
      percentage: calculateDriverAppSettingPercentage(),
      link: "/driver-app",
    },
    {
      label: "Communications",
      icon: <ConnectWithoutContactIcon />,
      percentage: communications?.getTotalList?.() > 0 ? 100 : 0,
      link: "/communications",
    },
  ];
   
  
    const handleClick = (tabindex) => {
        openSettingFromOnboarding(tabindex); 
      };
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        p: 3,
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
      }}
    >
      
                  <Typography variant="h4" gutterBottom>
                   Onboarding Steps
                    </Typography>
      {onboardingSteps.map((step, index) => (
        <Link
          key={index}
          onClick={() => handleClick(index)}
          underline="none"
          sx={{
            display: 'block',
            width: '100%',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            p={2}
            border={1}
            borderColor="grey.300"
            borderRadius={2}
            sx={{ '&:hover': { borderColor: 'primary.main', cursor: 'pointer' } }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              {step.icon}
              <Box fontWeight="bold">{step.label}</Box>
            </Box>
            <LinearProgress
              variant="determinate"
              value={step.percentage}
              sx={{
                width: '100%',
                mt: 1,
              }}
            />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {step.percentage}%
        </Typography>
      </Box>
          </Box>
        </Link>
      ))}
    </Box>
  );
}
export default OnboardingPage;
