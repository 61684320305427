import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import useGlobalContext from '../../hooks/useGlobalContext';
import { CircularProgress, makeStyles } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DirectionsIcon from '@mui/icons-material/Directions';
import { addTask, deleteTask, multiAssign, multiDelete, multiUnAssign, updateTask } from "../../api/task"
import { toast } from 'react-toastify';
import TaskEntity from '../../entities/TaskEntity';
import { UNASSIGNED_TASK_STATUS } from '../../constants/task_status';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Box, Modal, Slide } from '@mui/material';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        left: '35vh',
        top: 50
    }
});

function TaskOptionsDailog() {
    const classes = useStyles();
    const { taskOptionsModalOpen, chosenTask, _globalDispatch, tasks, clearModal, multiSelectTasks, taskOptionsModalMode, current_user, taskOptionModalOffset } = useGlobalContext();
    const [apiCallState, setApiCallState] = React.useState(0);
    console.log("taskOptionsModalOpen", taskOptionsModalOpen)

    let singleSelectedAction = [
        {
            icon: <EditIcon fontSize='small' />,
            type: "edit",
            label: `Edit task`
        },
        {
            icon: <FileCopyIcon fontSize='10' />,
            type: "clone",
            label: `Clone task`
        },
        {
            icon: <AddIcon fontSize='10' />,
            type: "assign",
            label: `Assign task`
        },
        {
            icon: <RemoveIcon fontSize='10' />,
            type: "unassign",
            label: `Unassign task`
        },
        {
            icon: <DeleteIcon fontSize='10' />,
            type: "delete",
            label: `Delete task`
        },
    ];
    let multiSelectedAction = [
        {
            icon: <AddIcon fontSize='10' />,
            type: "assign",
            label: `Assign ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
        },
        {
            icon: <RemoveIcon fontSize='10' />,
            type: "unassign",
            label: `Unassign ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
        },
        {
            icon: <DeleteIcon fontSize='10' />,
            type: "delete",
            label: `Delete ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
        },
    ];

    // console.log('user',current_user);

    if (current_user && current_user.plan_details && current_user.plan_details.basic_route_optimization) {
        multiSelectedAction = [
            {
                icon: <DirectionsIcon fontSize='10' />,
                type: "optimize",
                label: `Optimize ${multiSelectTasks.length} tasks`
            },
            {
                icon: <AddIcon fontSize='10' />,
                type: "assign",
                label: `Assign ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
            },
            {
                icon: <RemoveIcon fontSize='10' />,
                type: "unassign",
                label: `Unassign ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
            },
            {
                icon: <DeleteIcon fontSize='10' />,
                type: "delete",
                label: `Delete ${taskOptionsModalMode === "multi" ? multiSelectTasks.length : ''} tasks`
            },
        ];

    }


    let actions = multiSelectTasks && multiSelectTasks.length > 1 ? multiSelectedAction : singleSelectedAction

    const handleClose = () => {
        _globalDispatch({
            type: "CLOSE_TASK_OPTIONS_FORM"
        })
    };

    const getTaskIds = (d = null) => {
        // console.log("chosen task", chosenTask)
        let _ids = [];
        if (taskOptionsModalMode === "single") {
            _ids.push(chosenTask.getId())
        } else {
            for (let t of multiSelectTasks) {
                _ids.push(t.getId())
            }
        }

        return _ids;
    }

    const handleAssign = async () => {
        _globalDispatch({
            type: "OPEN_MULTI_TASK_ASSIGN_FORM"
        })
    }

    // handle delete
    const handleDelete = async () => {
        setApiCallState(1);

        // Get list of task IDs
        const _ids = getTaskIds();

        const _res = await multiDelete(_ids);

        if (_res.success) {
            // toast(_res.msg)
            _globalDispatch({
                type: "CLOSE_TASK_OPTIONS_FORM"
            });

            // Update the tasks list state
            if (taskOptionsModalMode === "single") {
                tasks.remove(chosenTask)
            } else {
                for (let task of multiSelectTasks) {
                    tasks.remove(task)
                }

                _globalDispatch({
                    type: "CLEAR_SELECTED_TASKS"
                })
            }

            _globalDispatch({
                type: "INCREASE_REFRESH_COUNTER"
            })
        }

        setApiCallState(2);
    }

    const handleUnAssign = async function () {
        const unAssignedTasks = tasks.getUnAssignedTasks()

        let defaultSortNum = 99;
        if (unAssignedTasks.length > 0) {
            const maxSortNumObj = unAssignedTasks.reduce(function (prev, current) {
                return (prev.sort_number > current.sort_number) ? prev : current
            }) //returns object

            if (maxSortNumObj) {
                defaultSortNum = maxSortNumObj.sort_number
            }
        }

        // return false;
        let tmpPayload = undefined;

        if (multiSelectTasks.length > 0) {
            for (let task of multiSelectTasks) {
                defaultSortNum += defaultSortNum;
                tmpPayload = {
                    ...task,
                    driver: null,
                    task_status: UNASSIGNED_TASK_STATUS,
                    sort_number: defaultSortNum
                }
                tasks.update(tmpPayload)
                updateTask(task._id, tmpPayload);
            }
        } else {
            tmpPayload = {
                ...chosenTask,
                driver: null,
                task_status: UNASSIGNED_TASK_STATUS,
                sort_number: defaultSortNum + 1
            }

            tasks.update(tmpPayload)

            updateTask(chosenTask._id, tmpPayload);
        }

        clearModal();
    }

    // Handle clone
    const cloneTask = async function () {
        const payload = chosenTask.getClonePayload();

        console.log("payload", payload)

        const _res = await addTask(payload);
        if (_res.success) {
            tasks.add(_res.data)
        }
        console.log("_res", _res);

        clearModal();
    }

    const handleListItemClick = async (value) => {
        const type = value.toLowerCase();
        switch (type) {
            case 'assign': {
                await handleAssign();
                break;
            }
            case 'clone': {
                await cloneTask();
                break;
            }
            case 'edit': {

                _globalDispatch({
                    type: "OPEN_EDIT_FORM"
                });
                break;
            }
            case 'optimize': {
                // console.log("multiSelectTasks from tasksOptionDialog", multiSelectTasks)
                _globalDispatch({
                    type: "OPEN_ROUTE_OPTIMIZE_FORM"
                });
                break;
            }
            case 'unassign': {
                await handleUnAssign();
                break;
            }
            case 'delete': {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-ui__overlay">
                                <div className="custom-ui">
                                    <div className="custom-ui__header">
                                        <h1>Are you sure?</h1>
                                    </div>
                                    <div className="custom-ui__body">
                                        <p>Do you really want to delete? This process cannot be undone.</p>
                                    </div>
                                    <div className="custom-ui__footer">
                                        <button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
                                        <button
                                            className="custom-ui__button custom-ui__button--confirm"
                                            onClick={() => {
                                                handleDelete();
                                                onClose();
                                            }}
                                        >
                                            Yes, Delete it!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                });
            }
        }

    };

    console.log("taskOptionModalOffset", taskOptionModalOffset)
    console.log("taskOptionModalOffset ? Math.min(Math.abs(taskOptionModalOffset.left + 800), 800) : 800", taskOptionModalOffset ? Math.min(Math.abs(taskOptionModalOffset.left + 800), 800) : 800)

    return (<Modal
        onClose={handleClose}
        open={taskOptionsModalOpen}
    >
        <Box sx={{
            backgroundColor: "white",
            width: 200,
            position: "absolute",
            left: taskOptionModalOffset ? Math.min(Math.abs(taskOptionModalOffset.left + 800), 800) : 300,
            top: taskOptionModalOffset ? Math.min(Math.abs(taskOptionModalOffset.top), 300) : 300,

        }}>
            {
                apiCallState === 1 && <CircularProgress size={20} style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%"
                }} />
            }

            <List sx={{ pt: 0 }}>
                {
                    actions.map((action) => (
                        <ListItem disableGutters key={action.label}>
                            <ListItemButton onClick={() => handleListItemClick(action.type)} key={action.label}>
                                <ListItemAvatar sx={{ backgroundColor: "transparent" }}>
                                    <Avatar sx={{ width: 20, height: 20 }}>
                                        {action.icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText secondary={action.label} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    </Modal>
    );
}

export default TaskOptionsDailog;