export const DISABLED_LABEL = "Disabled"
export const DISABLED_VALUE = 0

export const ENABLED_LABEL = "Enabled"
export const ENABLED_VALUE = 1

export const ENABLED_WITH_WARNING_LABEL = "Enabled, with warning"
export const ENABLED_WITH_WARNING_VALUE = 2

export const REQUIRED_LABEL = "Required"
export const REQUIRED_VALUE = 3


