import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { DARK_COLOR,WHITE_COLOR } from '../../constants/colors';

const CustomAccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({

    backgroundColor: WHITE_COLOR,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        color: "black",
        fontSize: 12
    },

}));

export default CustomAccordionSummary;