class CommunicationEntity {
    constructor(c) {
        this._id = c._id
        this.triggered_when = c.triggered_when
        this.triggered_for = c.triggered_for
        this.message = c.message
        this.communication_type = c.communication_type
        this.task_type = c.task_type
        this.notification_title = c.notification_title
        this.on_way_notification_title = c.on_way_notification_title
    }

    getId() {
        return this._id;
    }

    getCommunicationType() {
        if (this.communication_type === "push_notification") {
            return 'notification'
        }

        return this.communication_type || 'sms';
    }

    getLabel() {
        let part1 = `Task ${this.triggered_when} ( ${this.triggered_for} )  ( ${this.getCommunicationType() || 'sms'} )`
        let part2 = `( ${this.task_type || 'general'} )`;
        if (this.communication_type === "push_notification") {
            return `${part1} ${part2}`
        }

        return part1;
    }

    getMessageExcerpt() {
        if (this.message.length < 100) {
            return this.message;
        }

        return `${this.message.substring(0, 100)} ...`
    }

}

export default CommunicationEntity;