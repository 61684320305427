class TeamEntity {
    constructor(details = {}) {
        this._id = details._id
        this.team_name = details.team_name
        this.drivers = details.drivers
        this.dispatchers = details.dispatchers
        this.driver_ids = details.driver_ids
        this.dispatcher_ids = details.dispatcher_ids
        this.hub_id = details.hub_id
        this.hub = details.hub
        this.owner = details.owner
    }

    getId() {
        return this._id;
    }

    getTeamName() {
        return this.team_name;
    }

    getPayload() {
        return {
            _id: this._id,
            team_name: this.team_name,
            drivers: this.drivers,
            driver_ids: this.driver_ids,
            dispatcher_ids: this.dispatcher_ids,
            hub_id: this.hub_id,
            hub: this.hub,
            owner: this.owner,
        }

    }

}

export default TeamEntity;