import {postRequest} from './client';

/**
 *
 * Login API call
 *
 * @param {*} email
 * @param {*} password
 */
export const loginRequest = (email, password) => {
    return postRequest('/api/auth/login', {email, password});
};

/**
 * Logout API call
 */
export const logoutRequest = () => {
    return postRequest('/api/auth/logout', {});
};

/**
 *
 */
export const validateToken = async () => {
    return postRequest('/api/auth/validate-token');
}

export const refreshToken = async () => {
    return postRequest('/api/auth/refresh-token');
}
