import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

import { Formik, Form, Field } from "formik";
import { Box } from '@material-ui/core';
import DriverValidation from '../../../utils/form/validations/DriverValidation';
import VehicleSelect from './VehicleSelect';
import { addDriver } from '../../../api/driver';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ModalClose from '../../common/ModalClose';

import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import { refreshToken } from '../../../api/auth';
import useGlobalContext from '../../../hooks/useGlobalContext';

import VehicleCapacityType from './VehicleCapacityTypeSelect';

export default function AddFormDialog() {
    const [open, setOpen] = useState(false);
    const { isLoaded } = useJsApiLoader(mapInitOptions);
    const { _globalData, _globalDispatch, drivers, getOwner } = useGlobalContext();
    const { current_user: user = {} } = _globalData;

    const [autocomplete, setAutoComplete] = useState(undefined);

    const onLoad = useCallback((auto) => {
        setAutoComplete(auto)
    }, []);


    const handleClose = (event, reason) => {
        // Prevent closing the dialog if the reason is "backdropClick"
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const CustomFileInput = ({ field, form }) => {
        const handleFileChange = (event) => {
            const file = event.currentTarget.files[0];
            form.setFieldValue(field.name, file);
        };

        return (
            <input
                type="file"
                name={field.name}
                onChange={handleFileChange}
            />
        );
    };

    async function handleClickOpen() {
        const _res = await refreshToken();

        if (_res.success) {
            const { user = {} } = _res;

            const { plan_details = {} } = user;

            const { no_of_drivers } = plan_details;

            if (no_of_drivers > drivers.getTotalDrivers()) {
                setOpen(true);
            }
            else {
                _globalDispatch({
                    type: "OPEN_BILLING_MODAL",
                })
                console.log("Add option to upgrade plan")
            }

            _globalDispatch({
                type: "SET_CURRENT_USER",
                payload: user
            })
        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h6">
                    Drivers ({drivers.getTotalDrivers()}/{user.plan_details.no_of_drivers ?? 0})
                </Typography>
                <Button variant="contained" onClick={handleClickOpen} sx={{ float: 'right' }}>
                    <AddIcon />
                    Add New Driver
                </Button>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>New Driver</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            name: "",
                            phone: "",
                            address: "",
                            address_latlng: "",
                            email: "",
                            vehicle_type: "car",
                            vehicle_model: "",
                            vehicle_capacity_type: "",
                            vehicle_capacity: "",
                            vehicle_license_plate: "",
                            vehicle_color: "",
                            cost_per_hour: "",
                            cost_per_km: "",
                            cost_per_mile: "",
                            password: "",
                            confirm_password: "",
                            file: null
                        }}

                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            const payload = values;

                            const _res = await addDriver(payload);

                            if (_res.success) {
                                resetForm();
                                toast("Driver added successfully");

                                const { drivers } = _globalData;

                                drivers.addDriverToList(_res.data)

                                setOpen(false);
                                _globalDispatch({
                                    type: "INCREASE_REFRESH_COUNTER"
                                });
                            } else {
                                toast.error(_res.msg)
                            }

                            setSubmitting(false);

                        }}
                        validationSchema={DriverValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        {/* Name */}
                                        <Field name="file" component={CustomFileInput} />
                                        <TextField
                                            label="Name"
                                            name="name"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.name}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.name && props.touched.name}
                                        />

                                        {/* Phone */}
                                        <TextField
                                            label="Phone"
                                            name="phone"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.phone}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.phone && props.touched.phone}
                                        />

                                        {
                                            isLoaded && <AddressAutoComplete
                                                onLoad={onLoad}
                                                onPlaceChanged={() => {
                                                    console.log('event', 'sbcd')
                                                    const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                                    setFieldValue("address", autocomplete.getPlace().formatted_address)

                                                    setFieldValue("address_latlng", addressPayload.geometry_location);

                                                    addUpdateAddress(addressPayload);
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Address"
                                                    name="address"
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.address}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.address && props.touched.address}

                                                />
                                            </AddressAutoComplete>
                                        }

                                        {/* Email */}
                                        <TextField
                                            label="Email"
                                            name="email"
                                            type="email"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.email}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.email && props.touched.email}

                                        />

                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <VehicleSelect
                                                    chosenVal={values.vehicle_type}
                                                    callBack={(newVal) => {
                                                        setFieldValue("vehicle_type", newVal)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* Vehicle Model */}
                                                <TextField
                                                    label="Vehicle Model"
                                                    name="vehicle_model"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.vehicle_model}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.vehicle_model && props.touched.vehicle_model}

                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <VehicleCapacityType
                                                    chosenVal={values.vehicle_capacity_type}
                                                    callBack={(newVal) => {
                                                        setFieldValue("vehicle_capacity_type", newVal)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Vehicle Capacity"
                                                    name="vehicle_capacity"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.vehicle_capacity}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.vehicle_capacity && props.touched.vehicle_capacity}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Vehicle License Plate"
                                                    name="vehicle_license_plate"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.vehicle_license_plate}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.vehicle_license_plate && props.touched.vehicle_license_plate}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Vehicle Color"
                                                    name="vehicle_color"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.vehicle_color}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.vehicle_color && props.touched.vehicle_color}
                                                />
                                            </Grid>

                                        </Grid>

                                        {/* Cost */}
                                        <Grid container spacing={2}>

                                            <Grid item xs={4}>

                                                <TextField
                                                    label="Cost per hour"
                                                    name="cost_per_hour"
                                                    type="number"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.cost_per_hour}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.cost_per_hour && props.touched.cost_per_hour}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Cost per kilometer"
                                                    name="cost_per_km"
                                                    type="number"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.cost_per_km}
                                                    // onChange={props.handleChange}
                                                    onChange={val => {
                                                        setFieldValue("cost_per_km", val.currentTarget.value)
                                                        setFieldValue("cost_per_mile", (val.currentTarget.value * 1.60934).toFixed(2))
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.cost_per_km && props.touched.cost_per_km}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    label='Cost per mile'
                                                    name='cost_per_mile'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    margin='normal'
                                                    value={values.cost_per_mile}
                                                    // onChange={props.handleChange}
                                                    onChange={val => {
                                                        // setFieldValue("cost_per_mile", val)
                                                        // setFieldValue("cost_per_km", val * 1.60934)
                                                        setFieldValue("cost_per_mile", val.currentTarget.value)
                                                        setFieldValue("cost_per_km", (val.currentTarget.value / 1.60934).toFixed(2))
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={
                                                        props.errors.cost_per_mile && props.touched.cost_per_mile
                                                    }
                                                />
                                            </Grid>

                                        </Grid>

                                        {
                                            isSubmitting && <Box style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%"
                                            }}>
                                                <CircularProgress />
                                            </Box>
                                        }

                                        <Box textAlign='center'>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                            >
                                                Create
                                            </Button>
                                        </Box>

                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}
