import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField } from '../../../components/hook-form';


import Alert from '@mui/material/Alert';
import { asyncForgotPassword } from '../../../api/user';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';

export default function ForgotPasswordForm() {
	const [apiError, setAPIError] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required')
	});

	const defaultValues = {
		email: ''
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (values) => {
		try {
			setAPIError('');
			setLoading(true);
			const res = await asyncForgotPassword(
				{
					to: values.email
				}
			);

			console.log("_Res", res)
			if (res.success === true) {
				console.log("navigating to dashboard")
				toast.success("The password reset link sent to your email. Please check and proceed.");
				// handleLogin(res.user);

				// navigate('/dashboard/app', { replace: true, state: { history: 'login' } });
			}
			else {
				setAPIError(res.msg)
			}

			setLoading(false)
		} catch (err) {
			setAPIError('Something went wrong. Please try again.');
			console.log("Err", err);
		}

	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			{
				loading && <Loader />
			}

			<Stack spacing={3}>
				<RHFTextField name="email" label="Email address" defaultValue="Prefilled Data" InputLabelProps={{
					shrink: true,
				}} />

			</Stack>

			{
				apiError && <Alert severity="error">{apiError}</Alert>
			}

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 20
				}}
			>
				<LoadingButton fullwidth size="large" type="submit" variant="contained" loading={isSubmitting}>
					Reset
				</LoadingButton>
			</div>
		</FormProvider>
	);
}
