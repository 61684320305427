import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Formik, Form } from "formik";
import { Box } from '@material-ui/core';

import { CircularProgress, FormLabel, Grid } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { updateUserDriverAppSetting, updateUserPassword } from '../../../api/user';
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../../common/ModalClose';
import UserSettingValidation from '../../../utils/form/validations/UserSettingValidation';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper'

import TaskCompletionOption from './TaskCompletionOptions';
import { deepCopy } from '../../../utils/utils';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { DISABLED_VALUE, ENABLED_VALUE } from '../../../constants/task_completion_options';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function DriverAppForm() {
    const { current_user: user = {}, _globalDispatch } = useGlobalContext();
    const { _id, driver_app_settings = {} } = user;

    const [open, setOpen] = useState(false);
    const [task_completion_setting, setTaskCompletionSetting] = useState({
        notes_pickup: driver_app_settings.notes_pickup || ENABLED_VALUE,
        notes_dropoff: driver_app_settings.notes_dropoff || ENABLED_VALUE,
        signature_pickup: driver_app_settings.signature_pickup || DISABLED_VALUE,
        signature_dropoff: driver_app_settings.signature_dropoff || DISABLED_VALUE,
        photo_pickup: driver_app_settings.photo_pickup || ENABLED_VALUE,
        photo_dropoff: driver_app_settings.photo_dropoff || ENABLED_VALUE
    });


    function handleClose() {
        setOpen(false);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    const handleSettingChange = (key, val) => {
        // console.log("key, val", key, val)
        const preVal = deepCopy(task_completion_setting)
        preVal[key] = val
        setTaskCompletionSetting(preVal)

    }

    // console.log("task_completion_setting", task_completion_setting)

    return (
        <div>
            <Formik
                initialValues={{
                    allow_start_task_out_of_order: driver_app_settings.allow_start_task_out_of_order || false,
                    warn_starting_task_out_of_order: driver_app_settings.warn_starting_task_out_of_order || false,
                    warn_starting_task_before_complete_after_time: driver_app_settings.warn_starting_task_before_complete_after_time || false,
                    allow_handed_to_customer: driver_app_settings.allow_handed_to_customer || false,
                    allow_photos_upload: driver_app_settings.allow_photos_upload || true,
                    allow_initiate_communication: driver_app_settings.allow_initiate_communication || true,
                    enable_checklist_before_task_start: driver_app_settings.enable_checklist_before_task_start || false,
                    notes_pickup: driver_app_settings.notes_pickup || false,
                    notes_dropoff: driver_app_settings.notes_dropoff || false,
                    signature_pickup: driver_app_settings.signature_pickup || false,
                    signature_dropoff: driver_app_settings.signature_dropoff || false,
                    photo_pickup: driver_app_settings.photo_pickup || false,
                    photo_dropoff: driver_app_settings.photo_dropoff || false,
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const {
                        allow_start_task_out_of_order,
                        warn_starting_task_out_of_order,
                        warn_starting_task_before_complete_after_time,
                        allow_handed_to_customer,
                        allow_photos_upload,
                        allow_initiate_communication,
                        enable_checklist_before_task_start,

                    } = values;

                    const {
                        notes_pickup,
                        notes_dropoff,
                        signature_pickup,
                        signature_dropoff,
                        photo_pickup,
                        photo_dropoff
                    }
                        = task_completion_setting;

                    const payload = {
                        driver_app_settings: {
                            allow_start_task_out_of_order,
                            warn_starting_task_out_of_order,
                            warn_starting_task_before_complete_after_time,
                            allow_handed_to_customer,
                            allow_photos_upload,
                            allow_initiate_communication,
                            enable_checklist_before_task_start,
                            notes_pickup,
                            notes_dropoff,
                            signature_pickup,
                            signature_dropoff,
                            photo_pickup,
                            photo_dropoff
                        },
                        _id: _id
                    }

                    console.log("payload", payload)

                    const _res = await updateUserDriverAppSetting(payload);
                    if (_res.success) {
                        const _updated_user = deepCopy(user);
                        _updated_user.driver_app_settings = payload.driver_app_settings;

                        _globalDispatch({
                            type: "SET_CURRENT_USER",
                            payload: _updated_user
                        });

                        toast.success("Settings updated successfully.")
                    }

                    setSubmitting(false);
                }}
            >
                {
                    props => {
                        const {
                            values,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        } = props;

                        return (
                            <Form>
                                <Box mt={2}>
                                    <FormGroup>
                                        <FormLabel component="legend">Driver App Settings</FormLabel>
                                        {/* 1 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.allow_start_task_out_of_order}
                                                onChange={(event, val) => {
                                                    setFieldValue("allow_start_task_out_of_order", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Allow drivers to start tasks out of order.
                                            </Box>}
                                        />

                                        {/* 2 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.warn_starting_task_out_of_order}
                                                onChange={(event, val) => {
                                                    setFieldValue("warn_starting_task_out_of_order", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Warn driver when starting tasks out of order
                                            </Box>}
                                        />

                                        {/* 3 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.warn_starting_task_before_complete_after_time}
                                                onChange={(event, val) => {
                                                    setFieldValue("warn_starting_task_before_complete_after_time", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Warn driver when starting task before the complete after time
                                            </Box>}
                                        />

                                        {/* 4 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.allow_handed_to_customer}
                                                onChange={(event, val) => {
                                                    setFieldValue("allow_handed_to_customer", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Allow handed to customer option on task completion
                                            </Box>}
                                        />

                                        {/* 4 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.allow_photos_upload}
                                                onChange={(event, val) => {
                                                    setFieldValue("allow_photos_upload", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Allow photos uploaded from camera roll
                                            </Box>}
                                        />

                                        {/* 5 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.allow_initiate_communication}
                                                onChange={(event, val) => {
                                                    setFieldValue("allow_initiate_communication", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Allow drivers to initiate communicate with recipients
                                            </Box>}
                                        />

                                        {/* 6 */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={values.enable_checklist_before_task_start}
                                                onChange={(event, val) => {
                                                    setFieldValue("enable_checklist_before_task_start", val)
                                                }} />}
                                            label={<Box component={'div'} fontSize={12}>
                                                Allow driver to do checklist before start
                                            </Box>}
                                        />
                                    </FormGroup>

                                    <Box sx={{ flexGrow: 1 }} mb={2}>
                                        <Grid container spacing={2}>
                                            {/* Heading */}
                                            <Grid item xs={4}>
                                                <Item>On Completion</Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Item>Pickup Tasks</Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Item>Dropoff Tasks</Item>
                                            </Grid>

                                            {/* Notes */}
                                            <Grid item xs={4}>
                                                <Item>Notes</Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.notes_pickup} setting_key={"notes_pickup"} handleSettingChange={handleSettingChange} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.notes_dropoff} setting_key={"notes_dropoff"} handleSettingChange={handleSettingChange} />
                                            </Grid>

                                            {/* Signature */}
                                            <Grid item xs={4}>
                                                <Item>Signature</Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.signature_pickup} setting_key={"signature_pickup"} handleSettingChange={handleSettingChange} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.signature_dropoff} setting_key={"signature_dropoff"} handleSettingChange={handleSettingChange} />
                                            </Grid>

                                            {/* Photo */}
                                            <Grid item xs={4}>
                                                <Item>Photo</Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.photo_pickup} setting_key={"photo_pickup"} handleSettingChange={handleSettingChange} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaskCompletionOption val={task_completion_setting.photo_dropoff} setting_key={"photo_dropoff"} handleSettingChange={handleSettingChange} />
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Box>

                                {
                                    isSubmitting && <Box style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%"
                                    }}>
                                        <CircularProgress />
                                    </Box>
                                }

                                <Box textAlign='center'>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Box>

                            </Form>
                        );
                    }}
            </Formik>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            current_password: "",
                            password: "",
                            confirm_password: ""
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const payload = {
                                old_password: values.current_password,
                                password: values.password,
                                _id: _id
                            }

                            const _res = await updateUserPassword(payload);
                            console.log("_res", _res)
                            if (_res.success) {
                                toast.success("Password updated successfully.")
                                handleClose();
                            } else {
                                toast.error(_res.msg)
                            }


                            console.log("values", values)


                            setSubmitting(false);

                        }}
                        validationSchema={UserSettingValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Current Password"
                                                    name="current_password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.current_password && props.touched.current_password}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.password && props.touched.password}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Confirm password"
                                                    name="confirm_password"
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.confirm_password && props.touched.confirm_password}
                                                />
                                            </Grid>
                                        </Grid>

                                        {
                                            isSubmitting && <Box style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%"
                                            }}>
                                                <CircularProgress />
                                            </Box>
                                        }

                                        <Box textAlign='center'>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                            >
                                                Update
                                            </Button>
                                        </Box>

                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div >
    );
}
