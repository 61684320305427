import React from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { Box, Typography } from '@mui/material';

const CsvDropzone = ({ onFileLoaded }) => {
	const onDrop = (acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			Papa.parse(file, {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				transformHeader: header =>
					header
						.toLowerCase()
						.replace(/\W/g, '_'),
				complete: (results) => {
					console.log('Parsed CSV data:', results.data);
					onFileLoaded(results.data);
				},
				error: (error) => {
					console.error('Error parsing CSV:', error);
				},
			});
		});
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: '.csv',
	});

	return (
		<Box
			{...getRootProps()}
			sx={{
				border: '2px dashed #ccc',
				padding: 3,
				textAlign: 'center',
				cursor: 'pointer',
				backgroundColor: isDragActive ? '#f0f0f0' : '#ffffff',
			}}
		>
			<input {...getInputProps()} />
			<Typography variant="h6">
				{isDragActive ? 'Drop the files here ...' : 'Drag & drop a CSV file here, or click to select one'}
			</Typography>
		</Box>
	);
};

export default CsvDropzone;
