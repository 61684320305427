import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateForm from '../settings/tasks/CreateForm';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';

export default function EditTaskModal() {
	const { editTasksModalOpen, _globalDispatch } = useGlobalContext();

	const handleClose = () => {
		_globalDispatch({
			type: "CLOSE_EDIT_FORM"
		})
	};

	return (
		<Dialog
			open={editTasksModalOpen}
			onClose={handleClose}
			scroll="paper"
		>
			<ModalClose handleClose={handleClose} />
			<DialogTitle id="scroll-dialog-title">Create/Edit Task</DialogTitle>
			<DialogContent dividers={true}>
				<CreateForm />
			</DialogContent>
		</Dialog>
	);
}