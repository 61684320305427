import { isObjEmpty } from "../utils/utils";
import DriverEntity from "./DriverEntity";

class DriversListEntity {
    constructor(drivers) {
        this.drivers = drivers.map(d => new DriverEntity(d))
    }

    getDrivers() {
        return this.drivers;
    }

    getTotalDrivers() {
        return this.drivers.length;
    }

    updateSelectedDriver(driver) {
        this.drivers = this.drivers.map(d => {
            const dEntity = new DriverEntity(driver)
            if (d.getId() === dEntity.getId()) {
                d = dEntity
            }

            return d;
        })
    }

    addDriverToList(driver) {
        console.log("driver", driver)
        this.drivers = [...this.drivers, new DriverEntity(driver)];
        console.log("this.drivers", this.drivers)
    }

    removeDriverFromList(driver) {
        this.drivers = this.drivers.filter(d => d.getId() !== driver._id)
    }

    getDriverByName(name) {
        return this.drivers.find(d => d.getName() === name)
    }

    getDriverById(id) {
        return this.drivers.find(d => d.getId() === id)
    }

    getOnlineDrivers() {
        return this.drivers.filter(d => d.isOnline())
    }
}

export default DriversListEntity;