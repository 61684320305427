function modalWidth() {
	return {
	  '@media (min-width:600px)': {
		width: "100%",
	  },
	  '@media (min-width:900px)': {
		width: "100%",
	  },
	  '@media (min-width:1200px)': {
		width: "70%",
	  },
	};
  }
function importmodalWidth() {
	return {
	  '@media (min-width:600px)': {
		width: "100%",
	  },
	  '@media (min-width:900px)': {
		width: "1200px",
	  },
	  '@media (min-width:1200px)': {
		width: "1200px",
	  },
	};
  }


export const style = {
	
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width:'100%',
	...modalWidth(),
	height: "90%",
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	borderRadius:"10px"
};
export const onboardingstyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	...modalWidth(),
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	borderRadius: '10px',
	maxHeight: '90vh', // Ensures it doesn't overflow the viewport
	overflow: 'auto', // Adds scroll behavior if content exceeds max height
  };

export const importModalBoxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	...importmodalWidth(),
	height: "500px",
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	borderRadius:"10px"
};

export const filterModalStyle = {
	position: 'absolute',
	top: '8%',
	left: '2%',
	width: "400px",
	height: "400px",
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	borderRadius:"10px"
};



