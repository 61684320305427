import React,{useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CreateForm from '../settings/tasks/CreateForm';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';
import { refreshToken } from '../../api/auth';
import LayersIcon from '@mui/icons-material/Layers';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText  } from '@mui/material';


import { AppBar, Toolbar, Typography, IconButton,Slide} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BillingList from '../settings/billings/BillingList';



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });




export default function CreateTaskButton() {

	const { editTasksModalOpen, _globalDispatch, tasks } = useGlobalContext();

	const handleClose = async () => {
		_globalDispatch({
			type: "CLOSE_EDIT_FORM"
		})
	};

	const handleOpen = async () => {
		const _res = await refreshToken();

		if (_res.success) {
			const { user = {} } = _res;

			const { plan_details = {} } = user;

			const { day_limit = 0, no_of_tasks = 0 } = plan_details;
		
			if (tasks && no_of_tasks > tasks.getAllTasks()) {
				_globalDispatch({
					type: "OPEN_EDIT_FORM"
				})
			}
			else {
				_globalDispatch({
                    type: "OPEN_BILLING_MODAL",
                })
                console.log("Add option to upgrade plan")
			}
			_globalDispatch({
				type: "SET_CURRENT_USER",
				payload: user
			})
		}
	}

	
  
	


	return (
		<div>
			<ListItem disablePadding  >
				<ListItemButton onClick={handleOpen}>
					<ListItemIcon>
						{/* <AddIcon /> */}
					</ListItemIcon>
					<ListItemText primary="Add Task" />
				</ListItemButton>
			</ListItem>

			<Dialog
				open={editTasksModalOpen}
				onClose={handleClose}
				scroll="paper"
			>
				<ModalClose handleClose={handleClose} />
				<DialogTitle id="scroll-dialog-title">Create Task</DialogTitle>
				
				<DialogContent dividers={true} >
					<CreateForm />
				</DialogContent>
			</Dialog>
			
		</div>
	);
}