import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	InfoWindow,
	MarkerClusterer,
	Polyline,
	TrafficLayer,
	Rectangle,
	OverlayView
} from '@react-google-maps/api';
import { homeMarkerIcon, mapCenter, mapInitOptions, markerIcon } from '../../constants/map';

import useGlobalContext from '../../hooks/useGlobalContext';
import useMapContext from '../../hooks/useMapContext';
import { Box } from '@mui/system';
import { COMPLETED_TASK_STATUS } from '../../constants/task_status';
import { __locations_data, _drivers_data, _locations_data, _route_1 } from '../../utils/_data';
import { deepCopy, isObjEmpty } from '../../utils/utils';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import BackHandIcon from '@mui/icons-material/BackHand';
import { Grid, Paper, Typography, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const containerStyle = {
	width: "100%",
	height: "100vh"
};

function GoogleMapContainer() {
	const mapRef = useRef(null);
	const markerRefs = useRef([]);
	const { isLoaded } = useJsApiLoader(mapInitOptions);
	const {
		tasks,
		drivers,
		polylineTasks,
		_globalDispatch,
		ongoingTasks,
		multiSelectTasks,
		direction,
		globalRefreshCounter,
		openedAccordions,
		lineRefs,
		current_user,
		hubs,
		custom_center,
		draggable,
		isDriverOnline
	} = useGlobalContext();

	const { trafficMode, _mapDispatch, satelliteMode } = useMapContext()
	// const [draggable, setDragabble] = useState(true);
	const [mouseDown, setMouseDown] = useState(false);
	const [markers, setMarkers] = useState(null);
	const [bounds, setBounds] = useState(null);
	const [offsets, setOffsets] = useState([]);
	const [projection, setProjection] = useState(null);
	const rectangleRef = useRef(null);
	const startLatLngRef = useRef(null); // Store starting point for rectangle
	let online_drivers = [];

	if (drivers) {
		online_drivers = drivers.getOnlineDrivers();
	}

	const getPolylinePaths = (pt) => {
		if (!polylineTasks) {
			return []
		}
		let _return = [];


		const NotallCompleted = pt.filter(p => !p.isCompleted());

		// console.log("pt", pt)
		for (let t of pt) {
			_return.push(t.getLatLng())
		}

		// return _return;

		// prepend
		// console.log("driversLocations", driversLocations)
		const matchingDriver = driversLocations.find(d => pt.length > 0 && (pt[0].driver._id == d.driver_id))

		// console.log("matchingDriver", matchingDriver)

		if (!matchingDriver) {
			return _return;
		}

		if (NotallCompleted < 1) {
			return _return;
		}

		return [
			matchingDriver.current_location,
			..._return
		]
	}

	useEffect(() => {
		let driversTasks = [];
		if (tasks) {
			driversTasks = tasks.getAssignedNonFailedDrivers(drivers.getDrivers());
		}

		let _updatedPolylines = [];
		if (polylineTasks !== null && polylineTasks.length > 0) {
			Object.entries(polylineTasks).map(([key, value]) => {
				const updatePolyline = driversTasks.find(dt => dt.driver.name === value.driver);

				// console.log("updatePolyline", updatePolyline)

				if (updatePolyline && updatePolyline.tasks.length > 0) {
					const NotallCompleted = updatePolyline.tasks
						.filter((t, index) => !t.isCompleted())

					const __filteredPolylines = updatePolyline.tasks
						.filter((t, index) => !t.isCompleted() || NotallCompleted < 1)
						.map((t, index) => {
							t.id = index + 1;
							t.ref = index + 1
							return t;
						});

					_updatedPolylines.push({
						driver: value.driver,
						data: __filteredPolylines
					})
				}
			});

			_globalDispatch({
				type: "SET_POLYLINE_TASKS",
				payload: _updatedPolylines
			})
		}

		setMarkers(tasks ? tasks.getMarkers() : []);
	}, [globalRefreshCounter]);

	const getOptionsP = (path) => {
		return {
			draggable: false,
			strokeColor: 'red',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: 'red',
			fillOpacity: 0.35,
			clickable: true,
			editable: false,
			visible: true,
			radius: 30000,
			path: path,
			zIndex: 99999999,
			icons: [
				{
					icon: {
						path: "M15.997 13.374l-7.081 7.081L7 18.54l8.997-8.998 9.003 9-1.916 1.916z",
						fillOpacity: 1,
						strokeWeight: 0.5,
						rotation: 0,
						scale: 0.8,
						anchor: {
							x: 16,
							y: 0
						}

					},
					repeat: '128px',

				},
			],
		}
	}

	const onLoadP = polyline => {
	};

	const onLoad = () => {
	};

	// get HQ lat lng
	const center = useMemo(() => {
		if (custom_center && custom_center.location) {
			return custom_center.location
		}

		if (!current_user) {
			return mapCenter;
		}

		if (!current_user.organization_settings) {
			return mapCenter;
		}

		if (!current_user.organization_settings.hq_address_lat_lng) {
			return mapCenter;
		}

		if (isObjEmpty(current_user.organization_settings.hq_address_lat_lng)) {
			return mapCenter;
		}

		return current_user.organization_settings.hq_address_lat_lng;

	}, [custom_center]);


	const options = useMemo(
		() => ({
			mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
			disableDefaultUI: true,
			streetViewControl: false,
			fullscreenControl: false,
			zoomControl: true,
			draggable: draggable,
			strictBounds: true,
			zoomControl: false
		}),
		[draggable]
	);

	const onLoadMap = useCallback((map) => {
		mapRef.current = map;
		if (satelliteMode) {
			map.setMapTypeId('hybrid'); // Explicitly set the map type on load
		}

		// console.log("mapref digthis", map.getZoom())

		// const projection = map.getProjection();
		// console.log("projection digthis", projection)
		// if (projection) {
		// 	const newOffsets = markers.map((markerData, index) => {
		// 		const markerRef = markerRefs.current[index];
		// 		const latLng = markerRef.getPosition();
		// 		const pixelPosition = projection.fromLatLngToPoint(latLng);
		// 		const scale = Math.pow(2, map.getZoom());

		// 		// Convert map coordinates to pixel offsets
		// 		return {
		// 			id: markerData._id,
		// 			left: pixelPosition.x * scale - map.getDiv().offsetLeft,
		// 			top: pixelPosition.y * scale - map.getDiv().offsetTop,
		// 		};
		// 	});

		// 	setOffsets(newOffsets);
		// }

	}, []);

	useEffect(() => {
		if (mapRef.current) {
			if (satelliteMode) {
				mapRef.current.setMapTypeId('hybrid');
			} else {
				mapRef.current.setMapTypeId('roadmap'); // Reset to default map type when satelliteMode is false
			}
		}
	}, [satelliteMode]);

	const onUnmount = useCallback(() => {
		mapRef.current = null;
	}, []);


	let driversLocations = [];

	if (ongoingTasks) {
		driversLocations = ongoingTasks.getDriversLocations(online_drivers);
	}

	const openClustertasks = cluster => {
		//Extract data
		let cluserData = [];
		let _tasks = [];
		let _toOpenedAccordions = [];
		let _taskRefs = [];
		for (let m of cluster.getMarkers()) {

			_tasks.push(m.icon.task);
			_toOpenedAccordions.push(m.icon.task.getDriverName() || "unassigned")
			_taskRefs.push(m.icon.ref)

			let splited = m.icon.label.split(",");

			let toPush = {};
			if (splited.length > 0) {
				toPush.color = splited[0].trim()
			}
			if (splited.length > 1) {
				toPush.address = splited[1].trim()
			}
			if (splited.length > 2) {
				toPush.recipient = splited[2].trim()
			}
			if (splited.length > 3) {
				toPush.driver = splited[3].trim()
			}

			if (splited.length > 4) {
				toPush._id = splited[4].trim()
			}

			cluserData.push(toPush)
		}

		const _uniuq_toOpenedAccordions = [...new Set(_toOpenedAccordions)];

		//https://stackoverflow.com/questions/1885557/simplest-code-for-array-intersection-in-javascript
		// const intersect = openedAccordions.filter(value => _uniuq_toOpenedAccordions.includes(value));

		_globalDispatch({
			type: "SET_OPENED_ACCORDIONS",
			payload: [...openedAccordions, ..._uniuq_toOpenedAccordions]
		});

		console.log("yo digthis")

		window.setTimeout(() => {
			if (lineRefs.current[Math.max(..._taskRefs)].current) {
				lineRefs.current[Math.max(..._taskRefs)].current.scrollIntoView({
					behavior: 'instant',
					block: 'center',
				})
			}

		}, 600)
	}

	// Handle MouseMove to dynamically update the rectangle bounds
	const handleMouseMove = (e) => {
		if (!draggable && mouseDown) {
			const currentLatLng = e.latLng;

			setBounds((prevBounds) => {
				// If it's the first movement, initialize bounds from the start point
				if (!startLatLngRef.current) {
					startLatLngRef.current = currentLatLng;
				}

				// Create new bounds with the start point and current point
				const newBounds = new window.google.maps.LatLngBounds();
				newBounds.extend(startLatLngRef.current);  // Start point
				newBounds.extend(currentLatLng);           // Current mouse position
				return newBounds;
			});
		}
	};

	// Handle MouseDown to start the rectangle drawing
	const handleMouseDown = () => {
		if (!draggable) {
			setMouseDown(true);
			setBounds(null); // Reset bounds to start drawing a new rectangle
			startLatLngRef.current = null; // Clear starting point
		}
	};

	// Handle MouseUp to finalize the rectangle and select markers
	const handleMouseUp = () => {
		setMouseDown(false);
		if (!bounds) return;

		// Collect selected markers inside the bounds
		const selectedMarkers = markers.filter(marker => bounds.contains(marker.center)).map(marker => marker.task);

		_globalDispatch({
			type: "SET_MAP_SELECTED_TASKS",
			payload: selectedMarkers,
		});

		setBounds(null)
	};


	const calculateMarkerOffsets = (markers, projection) => {
		const newOffsets = markers.map((marker) => {
			console.log("projection", projection)
			const latLng = new window.google.maps.LatLng(marker.center.lat, marker.center.lng);
			const pixelPosition = projection ? projection.fromLatLngToDivPixel(latLng) : null;

			if (!pixelPosition) {
				console.warn(`Projection failed for marker ${marker.id}`);
				return null;
			}

			return {
				_id: marker._id,
				title: marker.title,
				left: pixelPosition.x,
				top: pixelPosition.y,
			};
		}).filter(Boolean); // Remove null values
		setOffsets(newOffsets);
	};

	const handleMapDragEnd = () => {
		const projection = mapRef.current.getProjection();
		if (projection) {
			calculateMarkerOffsets(markers, projection);
		}
	};

	if (!isLoaded) return null;

	console.log("markerOffsets digthis", offsets)

	return <Box
		tabIndex={0}
		onKeyDown={(e) => {
			if (e.key === "Escape") {
				_globalDispatch({
					type: "CLEAR_SELECTED_TASKS"
				})

				_globalDispatch({
					type: "SET_POLYLINE_TASKS",
					payload: null
				})

				_globalDispatch({
					type: "SET_DIRECTION",
					payload: false
				})

				_globalDispatch({
					type: "SET_INFOWINDOW",
					payload: null
				})

				setBounds(null)
				// setDragabble(true)
			}

			// setDragabble(!e.shiftKey)
		}}
		onKeyUp={(e) => {
			setBounds(null)
		}}

	>
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			defaultZoom={11}
			zoom={11}
			options={options}
			onLoad={onLoadMap}
			onKeyUp={() => {
				console.log("on Key down")
			}}
			onUnmount={onUnmount}
			onMouseMove={handleMouseMove}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onClick={() => {
				_globalDispatch({
					type: "SET_DIRECTION",
					payload: false
				})
			}}
		// onDragEnd={handleMapDragEnd}
		>

			{/* Traffic layer */}
			{
				trafficMode && <TrafficLayer />
			}

			{/* Shift select */}
			{/* Rectangle for shift select */}
			{bounds && (
				<Rectangle
					ref={rectangleRef}
					bounds={bounds.toJSON()}
					options={{
						strokeWeight: 0.8,
					}}
				/>
			)}

			{/* Drivers Locations */}
			{
				(
					driversLocations || [])
					.map(driverLocation => {
						return isDriverOnline(driverLocation.driver_id) && <Marker
							key={driverLocation.driver_id}
							position={driverLocation.current_location}
							icon={{
								// path: window.google.maps.SymbolPath.CIRCLE,
								// strokeColor: "#367CE7",
								// scale: 4
								path: window.google.maps.SymbolPath.CIRCLE,
								scale: 8, // Size of the circle
								fillColor: "#367CE7", // Fill color of the circle
								fillOpacity: 1,
								strokeColor: "#FFF", // Border color
								strokeWeight: 2,
							}}
							zIndex={9999}
							// onDblClick={() => {
							// 	if (driverLocation.destination_position) {
							// 		_globalDispatch({
							// 			type: "SET_DIRECTION",
							// 			payload: driverLocation
							// 		})
							// 	}
							// }}
							onClick={() => {
								console.log("On clicking driver", driverLocation)
								let pre_opened_accordions = deepCopy(openedAccordions);
								let to_open_accordions = [];
								if (driverLocation.teams && driverLocation.teams.length > 0) {
									driverLocation.teams.map(t => {
										to_open_accordions.push(t)
									});
								}

								to_open_accordions.push(driverLocation.driver_id);

								_globalDispatch({
									type: "SET_OPENED_ACCORDIONS",
									payload: [...new Set([...pre_opened_accordions, ...to_open_accordions])]
								})
							}}
							title={driverLocation.driver_name}
						/>
					})
			}


			{/* Marker cluster */}

			<MarkerClusterer
				minimumClusterSize={2}
				averageCenter={true}
				gridSize={20}
				zoomOnClick={false}
				onClick={(cluster) => {
					console.log("cluster click", cluster)
					//Extract data
					let cluserData = [];
					// Check if any clusters are completed
					const completedOnClusters = cluster.getMarkers().filter(m => {
						const { icon = {} } = m
						const { task = {} } = icon;
						const { task_status } = task;

						if (task_status === COMPLETED_TASK_STATUS) {
							return m;
						}
					});

					if (completedOnClusters.length > 0 || true) {
						for (let m of cluster.getMarkers()) {
							let splited = m.icon.label.split(",");

							let toPush = {};
							if (splited.length > 0) {
								toPush.color = splited[0].trim()
							}
							if (splited.length > 1) {
								toPush.address = splited[1].trim()
							}
							if (splited.length > 2) {
								toPush.recipient = splited[2].trim()
							}
							if (splited.length > 3) {
								toPush.driver = splited[3].trim()
							}

							if (splited.length > 4) {
								toPush._id = splited[4].trim()
							}

							cluserData.push(toPush)
						}

						_mapDispatch({
							type: "SET_CLUSTER_TASKS",
							payload: cluserData
						})
					}

					const _cluster_tasks = cluster.getMarkers().map(m => {
						const { icon = {} } = m
						const { task = {} } = icon;

						return task;
					});

					if (_cluster_tasks && _cluster_tasks.length > 0) {
						_cluster_tasks.map(_c => {
							console.log("_c", _c)
							_globalDispatch({
								type: "SET_SELECTED_TASKS",
								payload: _c
							})
						})
					}

					openClustertasks(cluster);

				}}


				options={{
					imageSizes: [36, 36],
					imagePath: 'https://localfoodzca.s3.us-west-2.amazonaws.com/uploads/icons/m',
					// imagePath: 'https://operationtrack.s3.eu-north-1.amazonaws.com/icons/m'
				}}

				// options={{
				// 	styles: [
				// 		{
				// 			url: "https://operationtrack.s3.eu-north-1.amazonaws.com/icons/m1.png", // Path to custom blue cluster icon
				// 			width: 40,
				// 			height: 40,
				// 			// textColor: "#FFFFFF", // Optional, color of the text inside the cluster
				// 			textSize: 12, // Optional, size of the text
				// 		},
				// 		{
				// 			url: "'https://localfoodzca.s3.us-west-2.amazonaws.com/uploads/icons/m.png", // Path to custom red cluster icon
				// 			width: 50,
				// 			height: 50,
				// 			// textColor: "#FFFFFF",
				// 			textSize: 14,
				// 		},
				// 		// You can add more styles for different cluster sizes
				// 	],
				// }}
				onRightClick={(e) => {
					console.log("onright click on lcuster")
				}}
				onLoad={(clusterer) => {
					const overlayView = new window.google.maps.OverlayView();
					overlayView.onAdd = () => {
						const projection = overlayView.getProjection();
						if (projection) {
							calculateMarkerOffsets(markers, projection);
						} else {
							console.error("Projection is not available");
						}
					};
					overlayView.draw = () => { }; // Required but not used
					overlayView.setMap(mapRef.current);
				}}

			>
				{(clusterer) =>
					(markers || []).map(m => <Marker
						key={m._id}
						position={m.center}
						title={m.title}
						customInfo={m.title}
						// label={m.label}

						onClick={(e) => {
							e.domEvent.preventDefault();
							e.domEvent.stopPropagation();
							// handle single click

							// Command click
							if (e.domEvent.metaKey) {
								_globalDispatch({
									type: "SET_SELECTED_TASKS",
									payload: m.task
								})
							} else {
								_globalDispatch({
									type: "SET_AS_SELECTED_TASKS",
									payload: [m.task]
								});
							}

							_globalDispatch({
								type: "SET_INFOWINDOW",
								payload: m.task
							});

							// set polyline tasks if it is assigned tasks
							// const {driver= null}
							const { task = {} } = m
							const { driver } = task;

							// Check if current task is already on the polyline formart or not
							if (driver._id) {
								let checkAlready = false;
								if (polylineTasks) {
									// console.log("polylineTasks", polylineTasks)
									checkAlready = polylineTasks.find(p => p.driver === driver.name)
								}

								// console.log("multiSelectTasks", multiSelectTasks)
								// console.log("checkAlreadyBefore", checkAlready)
								// if (multiSelectTasks) {
								// 	console.log("multiSelectTasks", multiSelectTasks)
								// 	checkAlready = multiSelectTasks.find(p => p._id === task._id)
								// }

								// console.log("checkAlready", checkAlready)

								if (checkAlready) {
									// _globalDispatch({
									// 	type: "OPEN_TASK_VIEW_FORM",
									// 	chosenTask: task
									// });

									return false;
								}
							}

							if (driver._id && !task.isCompleted()) {
								const __polylineTasks = tasks.getTasksByDriver(driver._id).filter(t => !t.isCompleted());

								_globalDispatch({
									type: "SET_POLYLINE_TASKS",
									payload: [
										...polylineTasks || [],
										{
											driver: driver.name,
											data: __polylineTasks
										}
									]
								})
							} else if (driver._id && task.isCompleted()) {
								const allTasksComplated = tasks.getTasksByDriver(driver._id).filter(t => !t.isCompleted());
								if (allTasksComplated.length < 1) {
									_globalDispatch({
										type: "SET_POLYLINE_TASKS",
										payload: [
											...polylineTasks || [],
											{
												driver: driver.name,
												NotallCompleted: true,
												data: tasks.getTasksByDriver(driver._id)
											}
										]
									})
								}
							}

							//https://stackoverflow.com/questions/53279563/scroll-to-specific-expansion-panel-in-material-accordion-after-component-loaded
							if (lineRefs) {
								if (!lineRefs.current[m.ref].current) {
									return false;
								}
							}

							const accordionPanelName = task.getDriverName();
							if (!openedAccordions.includes(accordionPanelName)) {
								_globalDispatch({
									type: "SET_OPENED_ACCORDIONS",
									payload: [...openedAccordions, accordionPanelName]
								})

								if (lineRefs) {
									window.setTimeout(() => {
										lineRefs.current[m.ref].current.scrollIntoView({
											behavior: 'instant',
											block: 'center',
										})
									}, 600)
								}
							} else {
								if (lineRefs) {
									lineRefs.current[m.ref].current.scrollIntoView({
										behavior: 'instant',
										block: 'center',
									})
								}
							}
						}}
						onDblClick={(e) => {
							_globalDispatch({
								type: "OPEN_TASK_VIEW_FORM",
								chosenTask: m.task
							})
						}}
						icon={markerIcon(
							m.label,
							m.taskStatus,
							m.task,
							m.ref,
							multiSelectTasks
						)}
						clusterer={clusterer}
						onRightClick={() => {
							const offset = offsets.find(o => o._id === m._id);
							console.log("offset", offset)
							_globalDispatch({
								type: "OPEN_TASK_OPTIONS_FORM",
								chosenTask: m.task,
								taskOptionsModalMode: multiSelectTasks.length > 1 ? "multi" : "single",
								taskOptionModalOffset: offset
							});
						}}
						onLoad={(marker) => {
							markerRefs.current[m._id] = marker;
						}}

					/>

					)
				}
			</MarkerClusterer>



			{/* Center marker // LocalFoodz Cali */}
			{
				hubs.getHubs().map(hub => {
					// console.log("hub from ", hub)
					return <Marker
						key={hub.hub_name}
						position={hub.full_address_latlng}
						title={hub.hub_name}
						icon={homeMarkerIcon}
						zIndex={99999999999}
					/>
				})
			}

			{
				custom_center && !custom_center.isHub && !custom_center.hideMarker && custom_center.location && <Marker
					position={custom_center.location}
					title={custom_center.title}
					zIndex={99999999999}
				/>
			}

			{
				polylineTasks && Object.entries(polylineTasks).map(([key, pt]) => {
					return (pt.data || []).map(p => <InfoWindow
						key={p._id}
						onLoad={onLoad}
						position={p.destination_address_latlng}
						onCloseClick={() => {
							_globalDispatch({
								type: "SET_INFOWINDOW",
								payload: null
							})
						}}
						options={{ pixelOffset: new window.google.maps.Size(8, -8) }}
					>
						<span>{p.id}</span>
					</InfoWindow>)

				})
			}

			{
				polylineTasks && Object.entries(polylineTasks).map(([key, pt]) => {

					const path = getPolylinePaths(pt.data || []);

					console.log("getPolylinePaths", path)

					return <Polyline
						key={key}
						onLoad={onLoadP}
						// onLoad={()=>{return false;}}
						path={path}
						options={getOptionsP(path)}
						onDrag={() => {
						}}
						onClick={() => {
						}}

					/>
				})
			}
		</GoogleMap>
		{/* 
		<Box sx={{
			position: "absolute",
			bottom: "14%",
			right: 10,
			backgroundColor: 'white'
		}}
			onClick={() => {
				_globalDispatch({
					type: "SET_DRAGGABLE_BOUNDS",
					payload: false
				})
			}}
		>
			<CropFreeOutlinedIcon sx={{
				width: 40,
				height: 40,
				color: !draggable ? "green" : ""
			}} />
		</Box>

		<Box sx={{
			position: "absolute",
			bottom: "20%",
			right: 10,
			backgroundColor: 'white'
		}}
			onClick={() => {
				_globalDispatch({
					type: "SET_DRAGGABLE_BOUNDS",
					payload: false
				})
			}}
		>
			<BackHandIcon sx={{
				width: 40,
				height: 40,
				color: !draggable ? "green" : ""
			}} />



		</Box> */}

		<Box sx={{
			position: "absolute",
			bottom: "10%",
			right: 20,
			backgroundColor: 'white'
		}} >

			<Grid
				container
				direction="column"
				spacing={0}
				alignItems="center"
				sx={{
					// padding: "4px"
				}}
			>
				<Grid sx={{ textAlign: "center" }} item >
					<Tooltip title="Rectangular selection" arrow>
						<Box sx={{
							width: "36px",
							height: "36px",
							// textAlign: "center",
							// alignItems: "center"
						}}

							onClick={() => {
								_globalDispatch({
									type: "SET_DRAGGABLE_BOUNDS",
									payload: false
								})
							}}
						>
							<CropFreeOutlinedIcon sx={{
								color: !draggable ? "green" : ""
							}} />
						</Box>
					</Tooltip>
				</Grid>
				<Grid sx={{ alignSelf: "center" }} item>
					<Tooltip title="Grab" arrow borderBottom={"1px solid grey"}>
						<Box
							sx={{
								width: "36px",
								height: "36px",
								textAlign: "center"
							}}
							onClick={() => {
								_globalDispatch({
									type: "SET_DRAGGABLE_BOUNDS",
									payload: true
								})
							}}
						>
							<BackHandIcon sx={{
								// width: "36px",
								// height: "36px",
								color: draggable ? "green" : ""
							}} />
						</Box>
					</Tooltip>
				</Grid>
				<Grid item>
					<Tooltip title="Zoom in" arrow borderBottom={"1px solid grey"}>
						<Box sx={{
							width: "36px",
							height: "36px",
							textAlign: "center"
						}}
							onClick={() => {
								if (mapRef.current) {
									const currentZoom = mapRef.current.getZoom();
									mapRef.current.setZoom(currentZoom + 1);
								}
							}}
						>
							<AddIcon sx={{
								// width: 36,
								// height: 36,

							}} />
						</Box>
					</Tooltip>
				</Grid>
				<Grid item>
					<Tooltip title="Zoom out" arrow>
						<Box sx={{
							width: "36px",
							height: "36px",
							textAlign: "center"
						}}
							onClick={() => {
								if (mapRef.current) {
									const currentZoom = mapRef.current.getZoom();
									mapRef.current.setZoom(currentZoom - 1);
								}
							}}
						>
							<RemoveIcon sx={{
								// width: "36!important",
								// height: "!important",

							}} />
						</Box>
					</Tooltip>
				</Grid>
			</Grid>
		</Box>
	</Box>
}

export default GoogleMapContainer;