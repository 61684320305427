import * as React from 'react';

import { Box, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import useGlobalContext from '../../../hooks/useGlobalContext';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function Tracker() {
  const { tasks, admins, drivers, dispatchers, hubs, _globalData } = useGlobalContext();

  const { current_user: user = {} } = _globalData;

  return (<>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #888 ',
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '10px',
        marginTop: '10px',
      }}
    >

      <Typography variant="h4" gutterBottom>
        Usage
      </Typography>
    </Box>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Plan :</TableCell>
            <TableCell align="left"> {user.plan_details.title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">Task :</TableCell>
            <TableCell align="left">{tasks && tasks.getAllTasks()}/{user.plan_details.no_of_tasks ?? 0}</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell align="center">Driver :</TableCell>

            <TableCell align="left">{drivers && drivers.getTotalDrivers()}/{user.plan_details.no_of_drivers ?? 0}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Admin :</TableCell>

            <TableCell align="left">{admins && admins.getTotalAdmins()}/{user.plan_details.no_of_admins ?? 0}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Dispatch :</TableCell>

            <TableCell align="left">{dispatchers && dispatchers.getTotalDispatchers()}/{user.plan_details.no_of_dispatchers ?? 0}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Hubs :</TableCell>

            <TableCell align="left">{hubs && hubs.getTotalHubs()}/{user.plan_details.no_of_hubs ?? 0}</TableCell>
          </TableRow>



        </TableBody>
      </Table>
    </TableContainer>

  </>
  )
}