export const SAMPLE_IMPORT_DATA = [
    [
        'Recipient phone', 'Recipient name', 'Recipient Note', 'Recipient email', 'Task type', 'Address 1', 'Address 2', "City", "State", "Zip", "Country", "Full address", 'Destination note', 'Delivery Date', 'Complete after', 'Complete before', 'Tags'
    ],
    [
        '+15555555555', 'John Doe', 'Please ring the bell', 'example@gmail.com', 'dropoff', '14A Midhill Dr', '', 'Mill valley', 'CA', '94941', 'US', '', '', '2024-02-14', '10:00 AM', '04:00 PM', "tag1, tag2"
    ],
    [
        '+15555555555', 'John Doe', 'Please ring the bell', 'example@gmail.com', 'dropoff', '14A Midhill Dr', '', 'Mill valley', 'CA', '94941', 'US', '', '', '2024-02-14', '10:00 AM', '04:00 PM', "tag1, tag2, tag3"
    ],
]