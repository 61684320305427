export const getTaskStatusFromNumber = (num) => {
    switch (num) {
        case 1:
            return "Assigned"
        case 1.5:
            return "Exception"
        case 2:
            return "Intransit"
        case 3:
            return "Completed"
        case -1:
            return "Failed"
        case "ALL":
            return "All_Tasks"
        case 0:
        default:
            return "Unassigned"
    }
}