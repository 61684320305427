import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';

export default function ServiceTimeInput() {

    const { _route_optimize_dispatch, service_time_per_task } = useRouteOptimizeContext();

    const handleChange = (event) => {
        _route_optimize_dispatch({
            type: "SET_SERVICE_TIME_PER_TASK",
            payload: event.target.value
        })
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id=""
                    label=""
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={service_time_per_task}
                    InputProps={{ inputProps: { min: 5, max: 10 } }}
                    helperText="Service time per task (minutes)"
                    onChange={handleChange}
                />
            </div>
        </Box>
    );
}
