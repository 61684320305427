import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateForm from '../settings/tasks/CreateForm';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';

import { DialogActions } from '@material-ui/core';
import { Button } from '@mui/material';
import TaskView from '../settings/tasks/TaskView';

export default function ViewTaskModal() {
	const { chosenTask, taskViewModalOpen, _globalDispatch } = useGlobalContext();

	const handleClose = () => {
		_globalDispatch({
			type: "CLOSE_TASK_VIEW_FORM"
		})
	};

	const openEditForm = () => {
		// console.log("chosenTask", chosenTask);
		_globalDispatch({
			type: "OPEN_EDIT_FORM"
		})
	}

	return (taskViewModalOpen && chosenTask && <Dialog
		open={taskViewModalOpen}
		onClose={handleClose}
		scroll="paper"
		fullWidth={true}
	>
		<ModalClose handleClose={handleClose} />
		<DialogTitle variant='h6'>{`Task ${chosenTask.getId()}`}</DialogTitle>
		<DialogContent dividers={true}>
			<TaskView />
		</DialogContent>
		<DialogActions>
			<Button variant='outlined' onClick={openEditForm}>Edit</Button>
			<Button variant='contained' onClick={handleClose}>Done</Button>
		</DialogActions>
	</Dialog>
	);
}