import { useContext } from "react";
import { RouteOptimizeContext } from "../contexts/RouteOptimizeContext";
import { getChosenDate } from "../utils/date";

export default function useRouteOptimizeContext() {
    const { _route_optimize_context, _route_optimize_dispatch } = useContext(RouteOptimizeContext)
    const {
        chosen_date = getChosenDate(),
        opened_team_accordions = [],
        chosen_drivers = [],
        start_from = null,
        max_tasks_per_route = 10,
        service_time_per_task = 5,
        end_at = null,
        optimization_mode = null,
        global_start_time = null,
        global_end_time = null,
    } = _route_optimize_context;

    return {
        _route_optimize_context,
        _route_optimize_dispatch,
        chosen_date,
        opened_team_accordions,
        chosen_drivers,
        start_from,
        max_tasks_per_route,
        service_time_per_task,
        end_at,
        optimization_mode,
        global_end_time,
        global_start_time
    }
}