import { deleteRequest, getRequest, newpostRequest, postRequest, putRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getUsers = async () => {
    return getRequest('/api/users/all', {});
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getUserDetails = async (id) => {
    return getRequest(`/api/users/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteUsers = async (_ids) => {
    return deleteRequest('/api/users/delete', { _ids });
}

/**
 *
 * @param {*} user
 */
export const registerUser = async (user) => {
    return postRequest('/api/users/register', user);
}

/**
 *
 * @param id
 * @param user
 * @returns {Promise<*>}
 */
export const updateUser = async (id, user) => {
    return putRequest(`/api/users/update/${id}`, user);
}

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export const updateUserOrganizationSetting = async (payload) => {
    return postRequest(`/api/users/update-organization-settings`, payload);
}

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export const updateUserSetting = async (payload) => {
    return postRequest(`/api/users/update-user-settings`, payload);
}
export const updateNewUserSetting = async (payload) => {
    return newpostRequest(`/api/users/update-new-user-settings`, payload);
}

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export const updateUserDriverAppSetting = async (payload) => {
    return postRequest(`/api/users/update-driver-app-settings`, payload);
}

export const updateUserPassword = async (payload) => {
    return postRequest(`/api/users/update-password`, payload);
}

export const asyncForgotPassword = async (data) => {
    return postRequest(`/api/users/forgot-password`, data);
}

export const resetUserPassword = async (data) => {
    return postRequest(`/api/users/reset-password`, data);
}
