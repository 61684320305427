import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import DriversListEntity from "../entities/DriversListEntity";
import OnGoingTasksEntity from "../entities/OnGoingTasksEntity";
import TasksListEntity from "../entities/TasksListEntity";
import CommunicationsListEntity from "../entities/CommunicationsListEntity";
import PlansListEntity from "../entities/PlansListEntity";
import AdminsListEntity from "../entities/AdminsListEntity";
import { extractUser } from "../utils/data_extract";
import HubsListEntity from "../entities/HubsListEntity";
import TeamsListEntity from "../entities/TeamListEntity";
import DispatchersListEntity from "../entities/DispatchersListEntity";
import InvoiceListEntity from "../entities/InvoiceListEntity";

export default function useGlobalContext() {
    const { _globalData, _globalDispatch } = useContext(GlobalContext);
    const {
        globalRefreshCounter = 0,
        dashboardView = 'map',
        hubs = new HubsListEntity([]),
        teams = new TeamsListEntity([]),
        dispatchers = new DispatchersListEntity([]),
        drivers = new DriversListEntity([]),
        admins = new AdminsListEntity([]),
        communications = new CommunicationsListEntity([]),
        plans = new PlansListEntity([]),
        invoices = new InvoiceListEntity([]),
        tasks = new TasksListEntity([]),
        ongoingTasks = new OnGoingTasksEntity([]),
        chosenHub,
        chosenTeam,
        chosenDispatcher,
        chosenTask,
        chosenDriver,
        chosenCommunication,
        chosenAdmin,
        taskOptionsModalOpen = false,
        taskOptionsModalMode = "single",
        taskViewModalOpen = false,
        billingModalOpen = false,
        filterModalOpen = false,
        editTasksModalOpen = false,
        multiAssignTasksModalOpen = false,
        route_optimize_modal_open = false,
        timewindow_modal_open = false,
        tasksOptionModalOpen = false,
        displayPolyline = false,
        polylineTasks = null,
        chosenDate,
        chosenTimeFrom,
        chosenTimeTo,
        multiSelectTasks = [],
        refreshing = false,
        trafficMode = false,
        selectedStatus,
        direction = false,
        infoWindow,
        openedAccordions = [],
        lineRefs = null,
        dragging = false,
        current_user = {},
        custom_center = false,
        draggable = true,
        taskOptionModalOffset = null
    } = _globalData;

    // const _hubs = hubs.getHubs();

    const clearModal = () => {
        _globalDispatch({
            type: "CLEAR_SELECTED_TASKS"
        })

        _globalDispatch({
            type: "CLOSE_MULTI_TASK_ASSIGN_FORM"
        })

        _globalDispatch({
            type: "CLOSE_TASK_OPTIONS_FORM"
        });

        _globalDispatch({
            type: "INCREASE_REFRESH_COUNTER"
        });
    }

    const openAccordion = (panel) => {
        const expandedAccordions = [...openedAccordions]

        if (!expandedAccordions.includes(panel)) { //checking weather array contain the id
            expandedAccordions.push(panel);        //adding to array because value doesnt exists
        }

        _globalDispatch({
            type: "SET_OPENED_ACCORDIONS",
            payload: expandedAccordions
        })
    }

    const refreshData = () => {

        _globalDispatch({
            type: "INCREASE_REFRESH_COUNTER"
        });
    }

    const isDriverOnline = (driver_id) => {
        const match = drivers.getDrivers().find(d => d._id == driver_id);

        return match.isOnline()
    }

    const getPlanDetails = () => {
        const { plan_details } = current_user;

        return plan_details;
    }

    const canAddDrivers = () => {
        const { plan_details } = current_user;
        const { no_of_drivers } = plan_details;

        return no_of_drivers > drivers.getTotalDrivers()
    }

    const getOwner = () => {
        // console.log("current_user", current_user)
        if (!current_user) {
            return null
        }
        const _current_user = extractUser(current_user);

        if (_current_user.role === "subscriber" || _current_user.role === "superadmin") {
            return _current_user;
        }

        return _current_user.owner
    }

    /**
     * 
     * @returns 
     */
    const isDispatcher = () => {
        // console.log("current_user", current_user)
        const _current_user = extractUser(current_user);
        const { role } = _current_user
        // Need to check for admins and dispatcher
        return role === "dispatcher";
    }

    const isSuperadmin = () => {
        // console.log("current_user", current_user)
        const _current_user = extractUser(current_user);
        const { role } = _current_user
        // Need to check for admins and dispatcher
        return role === "superadmin";
    }

    const getHubs = () => {
        return hubs.getHubs();
    }

    return {
        _globalData,
        _globalDispatch,
        globalRefreshCounter,
        dashboardView,
        hubs,
        teams,
        dispatchers,
        drivers,
        admins,
        communications,
        plans,
        invoices,
        chosenCommunication,
        tasks,
        ongoingTasks,
        chosenHub,
        chosenDriver,
        chosenAdmin,
        chosenTask,
        chosenDispatcher,
        chosenTeam,
        taskOptionsModalOpen,
        taskOptionsModalMode,
        taskViewModalOpen,
        billingModalOpen,
        filterModalOpen,
        editTasksModalOpen,
        multiAssignTasksModalOpen,
        route_optimize_modal_open,
        timewindow_modal_open,
        tasksOptionModalOpen,
        displayPolyline,
        polylineTasks,
        chosenDate,
        chosenTimeFrom,
        chosenTimeTo,
        multiSelectTasks,
        refreshing,
        trafficMode,
        selectedStatus,
        direction,
        infoWindow,
        openedAccordions,
        lineRefs,
        dragging,
        current_user,
        custom_center,
        draggable,
        taskOptionModalOffset,
        clearModal,
        openAccordion,
        refreshData,
        isDriverOnline,
        getPlanDetails,
        canAddDrivers,
        getOwner,
        isDispatcher,
        isSuperadmin,
        getHubs
    }
}