export const RouteOptimizeReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_CHOSEN_DATE":
            return {
                ...prevState,
                chosen_date: action.payload
            }

        case "SET_CHOSEN_DRIVERS":
            return {
                ...prevState,
                chosen_drivers: action.payload
            }
        case "SET_OPENED_TEAM_ACCORDIONS":
            return {
                ...prevState,
                opened_team_accordions: action.payload
            }

        case "SET_STARTING_FROM":
            return {
                ...prevState,
                start_from: action.payload
            }
        case "SET_ENDING_AT":
            return {
                ...prevState,
                end_at: action.payload
            }
        case "SET_OPTIMIZATION_MODE":
            return {
                ...prevState,
                optimization_mode: action.payload
            }
        case "SET_MAX_TASKS_PER_ROUTE":
            return {
                ...prevState,
                max_tasks_per_route: action.payload
            }
        case "SET_SERVICE_TIME_PER_TASK":
            return {
                ...prevState,
                service_time_per_task: action.payload
            }
        case "SET_WAYPOINTS":
            return {
                ...prevState,
                waypoints: action.payload
            }
        case "SET_TASKS":
            return {
                ...prevState,
                tasks: action.payload
            }

        case "SET_GLOBAL_START_TIME":
            return {
                ...prevState,
                global_start_time: action.payload
            }

        case "SET_GLOBAL_END_TIME":
            return {
                ...prevState,
                global_end_time: action.payload
            }
        default:
            return prevState;
    }
};
