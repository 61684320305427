import moment from "moment-timezone";
import { MOMENT_OUTPUT_SS } from "../constants/moment-date-formats";
import { getNow } from "./date";

export const removeTrailingSlash = (str) => {
    return str.replace(/\/+$/, '');
}

export const isObjEmpty = (obj) => {
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length === 0;
}

export const pluck = (arr, key) => arr.map(i => i[key]);

export const insertStringAtIndex = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
}

export const chunkArrayInGroups = (arr, size) => {
    let result = [];
    let pos = 0;
    while (pos < arr.length) {
        result.push(arr.slice(pos, pos + size));
        pos += size;
    }
    return result;
}

export const deepCopy = obj => {
    return JSON.parse(JSON.stringify(obj))
}

export const shouldRefresh = () => {
    const now = getNow();
    const last_location_update_time = localStorage.getItem("@refresh_update_time");
    let shouldUpdate = false;
    console.log("last_location_update_time", last_location_update_time)
    console.log("now", now)

    if (last_location_update_time) {
        const diff = moment().diff(moment(last_location_update_time, MOMENT_OUTPUT_SS), 'seconds');
        console.log("diff", diff)
        if (diff >= 3) {
            shouldUpdate = true;
            localStorage.setItem("@refresh_update_time", now)
        }
    } else {
        shouldUpdate = true;
        localStorage.setItem("@refresh_update_time", now)
    }

    console.log("shouldUpdate", shouldUpdate)
    return shouldUpdate;
}

export const _setTimezoneLocalStorage = (user = {}) => {
    // set time zone
    const { organization_settings = {} } = user;
    const { timezone = {} } = organization_settings;
    if (timezone && timezone.name) {
        console.log("Setting timezone on localstorage")
        localStorage.setItem("@timezone", timezone.name)
    }

}

export const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    let sortedArr1 = arr1.slice().sort();
    let sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}

export const hasCommonElements = (arr1, arr2) => {
    let set1 = new Set(arr1);

    for (let element of arr2) {
        if (set1.has(element)) {
            return true;
        }
    }

    return false;
}

export const arrayContainsArray = (superset, subset) => {
    return subset.every(value => superset.includes(value));
}

export const flattenArray = (arr) => {
    return arr.reduce((acc, val) =>
        Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val), []);
}

export const flat_unique = (multiArray) => {
    return [...new Set(multiArray.flat())]
}



