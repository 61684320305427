import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateForm from '../settings/tasks/CreateForm';
import ModalClose from '../common/ModalClose';
import useGlobalContext from '../../hooks/useGlobalContext';

import { Button, Grid, Stack, Typography } from '@mui/material';
import useMapContext from '../../hooks/useMapContext';
import RoomIcon from '@mui/icons-material/Room';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ViewClusterTasksModal() {
	const { clusterTasks, _mapDispatch } = useMapContext();
	console.log("clusterTasks", clusterTasks)
	const { _globalDispatch, tasks } = useGlobalContext()

	const handleClose = () => {
		// _globalDispatch({
		// 	type: "CLOSE_TASK_VIEW_FORM"
		// })
		_mapDispatch({
			type: "SET_CLUSTER_TASKS",
			payload: []
		})
	};

	const openEditForm = () => {
		// console.log("chosenTask", chosenTask);
		// _globalDispatch({
		// 	type: "OPEN_EDIT_FORM"
		// })
	}

	return (clusterTasks.length > 0 && <Dialog
		open={clusterTasks.length > 0}
		onClose={handleClose}
		scroll="paper"
		fullWidth={true}
	>
		<ModalClose handleClose={handleClose} />
		<DialogTitle variant='h6' sx={{ padding: "6px 24px" }}>{`${clusterTasks.length} Tasks`}</DialogTitle>
		<DialogContent dividers={true} sx={{ padding: 0 }}>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Destination</TableCell>
							<TableCell>Recipient</TableCell>
							<TableCell>Driver</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>

						{Object.entries(clusterTasks).map(([key, row]) => (
							<TableRow
								key={key}
								sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
								onClick={() => {
									const _chosen_task = tasks.getTaskById(row._id);
									if (_chosen_task.length > 0) {
										_globalDispatch({
											type: "OPEN_TASK_VIEW_FORM",
											chosenTask: _chosen_task[0]
										})
									}
								}}
							>
								<TableCell component="th" scope="row">
									<Stack direction="row" spacing={2} alignItems="center">
										<RoomIcon fontSize='13px' sx={{ color: row.color }} />
										<Typography fontSize={{ fontSize: "10px" }}>{row.address}</Typography>
									</Stack>
								</TableCell>
								<TableCell sx={{ fontSize: "10px" }}>{row.recipient}</TableCell>
								<TableCell sx={{ fontSize: "10px" }}>{row.driver || "Unassigned"}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</DialogContent>
	</Dialog >
	);
}