import HubsList from "../hubs/HubsList";
import AddFormDialog from "../hubs/AddFormDialog";
import useGlobalContext from "../../../hooks/useGlobalContext";

export default function PanelHubs() {
    const { chosenHub } = useGlobalContext();
    return <>
      <AddFormDialog />
        <HubsList />
       
    </>
}