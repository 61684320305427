import React, { createContext, useReducer } from 'react';
import { RouteOptimizeReducer } from '../reducers/RouteOptimizeReducer';
import { getChosenDate } from '../utils/date';

export const RouteOptimizeContext = createContext(null);
const init = initialArg => {
    return {
        chosen_date: initialArg.chosen_date,
        chosen_drivers: initialArg.chosen_drivers,
        opened_team_accordions: initialArg.opened_team_accordions,
        service_time_per_task: initialArg.service_time_per_task,
        max_tasks_per_route: initialArg.max_tasks_per_route,
        start_from: initialArg.start_from,
        end_at: initialArg.end_at,
        optimization_mode: initialArg.optimization_mode,
        waypoints: initialArg.waypoints,
        tasks: initialArg.tasks,
        global_start_time: initialArg.global_start_time,
        global_end_time: initialArg.global_end_time,
    }
}

const RouteOptimizeContextProvider = (props) => {
    const [_route_optimize_context, _route_optimize_dispatch] = useReducer(RouteOptimizeReducer, {
        chosen_date: getChosenDate(),
        chosen_drivers: [],
        opened_team_accordions: [],
        max_tasks_per_route: 10,
        service_time_per_task: 5,
        start_from: 'teams_hub',
        end_at: 'anywhere',
        optimization_mode: 'maximum_distribution_quickest_time',
        waypoints: [],
        tasks: [],
        global_start_time: null,
        global_end_time: null,
    }, init);

    return (
        <RouteOptimizeContext.Provider value={{ _route_optimize_context, _route_optimize_dispatch }}>
            {props.children}
        </RouteOptimizeContext.Provider>
    );
};

export default RouteOptimizeContextProvider;
