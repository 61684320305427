export const GlobalReducer = (prevState, action) => {
    if (action.type === "CLOSE_TASK_OPTIONS_FORM") {
        console.log("CLOSE_TASK_OPTIONS_FORM", action)
    }

    if (action.type === "OPEN_TASK_OPTIONS_FORM") {
        console.log("OPEN_TASK_OPTIONS_FORM", action)
    }
    switch (action.type) {
        case "INIT":
            return {
                ...prevState,
                hubs: action.hubs,
                teams: action.teams,
                dispatchers: action.dispatchers,
                tasks: action.tasks,
                drivers: action.drivers,
                admins: action.admins,
                communications: action.communications,
                plans: action.plans,
                invoices: action.invoices,
                ongoingTasks: action.ongoingTasks,
                refreshing: false
            }

        case "RESET_AFTER_FILTER":
            return {
                ...prevState,
                tasks: action.tasks,
                drivers: action.drivers,
                refreshing: false
            }

        case "REFRESH_DRIVERS_TASKS":
            return {
                ...prevState,
                tasks: action.tasks,
                drivers: action.drivers,
                refreshing: false
            }
        case "REFRESH_TEAM_DRIVERS_TASKS":
            return {
                ...prevState,
                tasks: action.tasks,
                drivers: action.drivers,
                teams: action.teams,
                globalRefreshCounter: prevState.globalRefreshCounter + 1,
                refreshing: false
            }

        case "INCREASE_REFRESH_COUNTER":
            return {
                ...prevState,
                globalRefreshCounter: prevState.globalRefreshCounter + 1,
            }

        case "SET_DASHBOARD_VIEW":
            return {
                ...prevState,
                dashboardView: action.payload,
            }

        case 'SET_HUBS':
            return {
                ...prevState,
                hubs: action.payload,
            };
        case 'SET_TASKS':
            return {
                ...prevState,
                tasks: action.payload,
                globalRefreshCounter: prevState.globalRefreshCounter + 1,
            };
        case 'SET_DRIVERS':
            return {
                ...prevState,
                drivers: action.payload,
                globalRefreshCounter: prevState.globalRefreshCounter + 1,
            };

        case 'SET_TEAMS':
            return {
                ...prevState,
                teams: action.payload,
            };

        case 'SET_DISPATCHERS':
            return {
                ...prevState,
                dispatchers: action.payload,
            };

        case 'SET_CHOSEN_TASK':
            return {
                ...prevState,
                chosenTask: action.payload,
            };

        case 'SET_CHOSEN_TEAM':
            return {
                ...prevState,
                chosenTeam: action.payload,
            };

        case 'SET_CHOSEN_DISPATCHER':
            return {
                ...prevState,
                chosenDispatcher: action.payload,
            };

        case 'SET_COMMUNICATIONS':
            return {
                ...prevState,
                communications: action.payload,
            };
        case 'SET_PLANS':
            return {
                ...prevState,
                plans: action.payload,
            };
        case 'SET_INVOICES':
            return {
                ...prevState,
                invoices: action.payload,
            };
        case 'SET_CHOSEN_DRIVER':
            return {
                ...prevState,
                chosenDriver: action.payload,
            };
        case 'SET_CHOSEN_ADMIN':
            return {
                ...prevState,
                chosenAdmin: action.payload,
            };
        case 'SET_CHOSEN_HUB':
            return {
                ...prevState,
                chosenHub: action.payload,
            };
        case 'SET_CHOSEN_COMMUNICATION':
            return {
                ...prevState,
                chosenCommunication: action.payload,
            };
        case 'RESET_CHOSEN_DRIVER':
            return {
                ...prevState,
                chosenDriver: null
            };
        case 'RESET_CHOSEN_ADMIN':
            return {
                ...prevState,
                chosenAdmin: null
            };
        case 'RESET_CHOSEN_TEAM':
            return {
                ...prevState,
                chosenTeam: null
            };
        case 'RESET_CHOSEN_HUB':
            return {
                ...prevState,
                chosenHub: null
            };
        case 'RESET_CHOSEN_DISPATCHER':
            return {
                ...prevState,
                chosenDispatcher: null
            };

        case 'RESET_CHOSEN_COMMUNICATION':
            return {
                ...prevState,
                chosenCommunication: null
            };

        case 'OPEN_EDIT_FORM':
            return {
                ...prevState,
                editTasksModalOpen: true,
                taskOptionsModalOpen: false
            };

        case 'CLOSE_EDIT_FORM':
            return {
                ...prevState,
                chosenTask: null,
                editTasksModalOpen: false
            };

        case 'OPEN_TASK_OPTIONS_FORM':
            return {
                ...prevState,
                chosenTask: action.chosenTask,
                taskOptionsModalOpen: true,
                taskOptionsModalMode: action.taskOptionsModalMode,
                taskOptionModalOffset: action.taskOptionModalOffset
            };

        case 'OPEN_TASK_VIEW_FORM':
            return {
                ...prevState,
                chosenTask: action.chosenTask,
                taskViewModalOpen: true,
            };

        case 'CLOSE_TASK_VIEW_FORM':
            return {
                ...prevState,
                chosenTask: null,
                taskViewModalOpen: false
            };
        case 'CLOSE_BILLING_MODAL':
            return {
                ...prevState,
                billingModalOpen: false
            };
        case 'OPEN_BILLING_MODAL':
            return {
                ...prevState,
                billingModalOpen: true
            };

        case 'CLOSE_TASK_OPTIONS_FORM':
            return {
                ...prevState,
                chosenTask: null,
                taskOptionsModalOpen: false,
                taskOptionModalOffset: null
            };

        case 'OPEN_MULTI_TASK_ASSIGN_FORM':
            return {
                ...prevState,
                multiAssignTasksModalOpen: true
            };
        case 'OPEN_ROUTE_OPTIMIZE_FORM':
            return {
                ...prevState,
                route_optimize_modal_open: true
            };

        case 'CLOSE_MULTI_TASK_ASSIGN_FORM':
            return {
                ...prevState,
                multiAssignTasksModalOpen: false
            };
        case 'CLOSE_ROUTE_OPTIMIZE_FORM':
            return {
                ...prevState,
                route_optimize_modal_open: false
            };

        case 'OPEN_FILTER_MODAL':
            return {
                ...prevState,
                filterModalOpen: true
            };

        case 'CLOSE_FILTER_MODAL':
            return {
                ...prevState,
                filterModalOpen: false
            };

        case 'OPEN_TASKS_OPTIONS_MODAL':
            return {
                ...prevState,
                tasksOptionModalOpen: true
            };

        case 'CLOSE_TASKS_OPTIONS_MODAL':
            return {
                ...prevState,
                tasksOptionModalOpen: false
            };

        case 'OPEN_TIMEWINDOW_MODAL':
            return {
                ...prevState,
                timewindow_modal_open: true
            };

        case 'CLOSE_TIMEWINDOW_MODAL':
            return {
                ...prevState,
                timewindow_modal_open: false
            };
        case 'SET_REFRESH_COUNT':
            return {
                ...prevState,
                refreshCount: prevState.refreshCount + 1
            };

        case 'SET_POLYLINE_DISPLAY':
            return {
                ...prevState,
                displayPolyline: action.payload
            };
        case 'SET_POLYLINE_TASKS':
            return {
                ...prevState,
                polylineTasks: action.payload
            };

        case 'SET_DRAGGABLE_BOUNDS':
            return {
                ...prevState,
                draggable: action.payload
            };

        case 'SET_CHOSEN_DATE':
            return {
                ...prevState,
                chosenDate: action.payload,

            };

        case 'SET_INITIAL_TIME':
            return {
                ...prevState,
                chosenTimeFrom: action.chosenTimeFrom,
                chosenTimeTo: action.chosenTimeTo,
            };

        case 'SET_CHOSEN_TIME_FROM':
            return {
                ...prevState,
                chosenTimeFrom: action.payload
            };

        case 'SET_CHOSEN_TIME_TO':
            return {
                ...prevState,
                chosenTimeTo: action.payload
            };

        case 'SET_SELECTED_TASKS':
            const preSelectedTasks = prevState.multiSelectTasks;
            // console.log("preSelectedTasks", preSelectedTasks)
            let updatedList = preSelectedTasks;
            const isAlreadyExisted = preSelectedTasks.filter(s => s._id === action.payload._id)

            if (isAlreadyExisted.length > 0) {
                // console.log("action.paylooad", action.payload)
                // updatedList = preSelectedTasks.filter(p => p._id !== action.payload._id)
            } else {
                updatedList.push(action.payload)
            }

            return {
                ...prevState,
                multiSelectTasks: updatedList
            };

        case 'SET_AS_SELECTED_TASKS':
            return {
                ...prevState,
                multiSelectTasks: action.payload
            };

        case 'SET_INFOWINDOW':
            return {
                ...prevState,
                infoWindow: action.payload
            };

        case 'SET_MAP_SELECTED_TASKS':
            return {
                ...prevState,
                multiSelectTasks: action.payload
            };

        case "CLEAR_SELECTED_TASKS":
            return {
                ...prevState,
                multiSelectTasks: []
            }
        case "SET_REFESHING":
            return {
                ...prevState,
                refreshing: true
            }
        case "RESET_REFESHING":
            return {
                ...prevState,
                refreshing: false
            }

        case "TOGGLE_TRAFFIC_MODE":
            return {
                ...prevState,
                trafficMode: !prevState.trafficMode
            }
        case "TOGGLE_CLUSTER_VIEW":
            return {
                ...prevState,
                clusterView: !prevState.clusterView
            }

        case 'SET_SELECTED_STATUS':
            // const preSelectedStatus = prevState.selectedStatus;
            // let updatedStatus = preSelectedStatus;
            // const isAlreadyExistedStatus = preSelectedStatus.filter(s => s === action.payload)
            // if (isAlreadyExistedStatus.length > 0) {
            //     updatedStatus = preSelectedStatus.filter(p => p !== action.payload)
            // } else {
            //     updatedStatus.push(action.payload)
            // }

            return {
                ...prevState,
                selectedStatus: action.payload
            };

        case "APPLY_FILTER":
            return {
                ...prevState,
                refreshing: true,
                filterModalOpen: false
            }

        case "SET_DIRECTION":
            return {
                ...prevState,
                direction: action.payload
            }

        case "SET_OPENED_ACCORDIONS":
            return {
                ...prevState,
                openedAccordions: action.payload
            }
        case "SET_LINEREFS":
            return {
                ...prevState,
                lineRefs: action.payload
            }

        case "SET_DRAGGING":
            return {
                ...prevState,
                dragging: action.payload
            }

        case "SET_CURRENT_USER":
            return {
                ...prevState,
                current_user: action.payload
            }

        case "SET_CUSTOM_CENTER":
            return {
                ...prevState,
                custom_center: action.payload
            }

        default:
            return prevState;
    }
};
