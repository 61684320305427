export const RECIPIENT_NAME_LABEL = "Recipient Name"
export const RECIPIENT_NAME_VALUE = "{RecipientName}"

export const RECIPIENT_FIRST_NAME_LABEL = "Recipient First Name"
export const RECIPIENT_FIRST_NAME_VALUE = "{RecipientFirstName}"

export const RECIPIENT_LAST_NAME_LABEL = "Recipient Last Name"
export const RECIPIENT_LAST_NAME_VALUE = "{RecipientLastName}"

// Driver
export const DRIVER_NAME_LABEL = "Driver Name"
export const DRIVER_NAME_VALUE = "{DriverName}"

export const DRIVER_FIRST_NAME_LABEL = "Driver First Name"
export const DRIVER_FIRST_NAME_VALUE = "{DriverFirstName}"

export const DRIVER_LAST_NAME_LABEL = "Driver Last Name"
export const DRIVER_LAST_NAME_VALUE = "{DriverLastName}"

export const ETA_LABEL = "ETA"
export const ETA_VALUE = "{ETA}"

export const VEHICLE_DESCRIPTION_LABEL = "Vehicle Description"
export const VEHICLE_DESCRIPTION_VALUE = "{VehicleDescription}"

export const VEHICLE_TYPE_LABEL = "Vehicle Type"
export const VEHICLE_TYPE_VALUE = "{VehicleType}"

export const LICENSE_PLATE_LABEL = "License Plate"
export const LICENSE_PLATE_VALUE = "{LicensePlate}"

export const DRIVER_PHONE_NUMBER_LABEL = "Driver Phone Number"
export const DRIVER_PHONE_NUMBER_VALUE = "{DriverPhoneNumber}"

// Task
export const TASK_ID_LABEL = "Task ID"
export const TASK_ID_VALUE = "{TaskID}"

export const ADDRESS_SHORT_LABEL = "Address (Short)"
export const ADDRESS_SHORT_VALUE = "{AddressShort}"

export const ADDRESS_LONG_LABEL = "Address (Long)"
export const ADDRESS_LONG_VALUE = "{AddressLong}"

export const TRACKING_LINK_LABEL = "Tracking Link"
export const TRACKING_LINK_VALUE = "{TrackingLink}"

export const SIGNATURE_URL_LABEL = "Signature URL"
export const SIGNATURE_URL_VALUE = "{SignatureURL}"

export const ORGANIZATION_NAME_LABEL = "Organization Name"
export const ORGANIZATION_NAME_VALUE = "{OrganizationName}"

export const PHOTO_URL_LABEL = "Photo URL"
export const PHOTO_URL_VALUE = "{PhotoURL}"