import moment from 'moment-timezone';

export const MOMENT_INPUT = ['YYYY-MM-DD', 'MM/DD/YYYY', 'YYYY-MM-DD hh:mm A']
export const MOMENT_OUTPUT_DISPLAY = 'MM/DD/YYYY';
export const MOMENT_OUTPUT_DISPLAY_TIME = 'MM/DD/YYYY hh:mm A';
export const MOMENT_OUTPUT_DISPLAY_TIME_24 = 'MM/DD/YYYY HH:mm';
export const MOMENT_OUTPUT_DB = 'YYYY-MM-DD';
export const MOMENT_DATETIME_OUTPUT_DB = 'YYYY-MM-DD hh:mm A';
export const MOMENT_TIME_OUTPUT_DB = 'hh:mm A';
export const MOMENT_ONLY_TIME_OUTPUT_24 = 'HH:mm';
export const MOMENT_OUTPUT_SS = 'YYYY-MM-DD hh:mm:ss A';
const ct = require('countries-and-timezones');

export const getTimezone = () => {
    const localstorage_timezone = localStorage.getItem("@timezone");
    if (localstorage_timezone) {
        return localstorage_timezone;
    }

    return moment.tz.guess();
}

export const timezone = getTimezone();

export const getLocalTimeZone = () => {

    const timeZone = moment.tz.guess();
    const offset = moment.tz(timeZone).format('Z');

    return {
        name: timeZone,
        offset: offset
    };


}

export const getCountryByTimezone = (timezone) => {
    console.log("timezone", timezone)
    return ct.getCountryForTimezone(timezone.name)
}

