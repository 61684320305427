class PlanEntity {
    constructor(details = {}) {
        this._id = details._id
        this.title = details.title
        this.description = details.description
        this.monthly_plan_price = details.monthly_plan_price
        this.yearly_plan_price = details.yearly_plan_price
        this.year_month = details.year_month
        this.plan_price = details.plan_price
       
    }

    getId() {
        return this._id;
    }

    getTitle() {
        return this.title;
    }
    getDescription() {
        return this.description;
    }

    getMonthlyPlanPrice() {
        return this.monthly_plan_price;
    }
    getPlanPrice() {
        return this.plan_price;
    }
    getYearMonth() {
        return this.year_month;
    }
    getYearlyPlanPrice() {
        return this.yearly_plan_price;
    }

    getPayload() {
        return {
            _id: this._id,
            title: this.title,
            monthly_plan_price: this.monthly_plan_price,
            yearly_plan_price: this.yearly_plan_price,
            plan_price: this.plan_price,
            year_month: this.year_month
        }

    }
}

export default PlanEntity;