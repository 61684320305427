class AdminEntity {
    constructor(details = {}) {
        this._id = details._id
        this.first_name = details.first_name
        this.last_name = details.last_name
        this.full_name = `${details.first_name} ${details.last_name}`
        this.email = details.email
        this.owner = details.owner
        this.phone = details.phone
        this.role = details.role
        this.organization_settings = details.organization_settings
        this.accessibility_settings = details.accessibility_settings
        this.driver_app_settings = details.driver_app_settings
        this.plan_details = details.plan_details
    }

    getId() {
        return this._id;
    }

    getName() {
        return this.full_name;
    }

    getPhone() {
        return this.phone;
    }

    getPayload() {
        return {
            _id: this._id,
            full_name: this.full_name,
            phone: this.phone,
            email: this.email,
            owner: this.owner,
            first_name: this.first_name,
            last_name: this.last_name,
            role: this.role,
            organization_settings: this.organization_settings,
            accessibility_settings: this.accessibility_settings,
            driver_app_settings: this.accessibility_settings,
            plan_details: this.accessibility_settings,
        }

    }

}

export default AdminEntity;