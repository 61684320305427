import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import VerticalTabs from '../settings/VerticalTabs';

import { style } from "../../theme/modalBox"
import ModalClose from '../common/ModalClose';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';



export default function SettingsButton() {
	
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true)
	};
	const handleClose = () => setOpen(false);

	return (
			<div>
				<ListItem disablePadding>
				<ListItemButton onClick={handleOpen}>
					<ListItemIcon>
					<SettingsIcon />
					</ListItemIcon>
					<ListItemText primary="Settings" />
				</ListItemButton>
			</ListItem>

				<Modal
					open={open}
					onClose={handleClose}
					
				>
					<Box sx={style}>
						<ModalClose handleClose={handleClose} />
						<VerticalTabs   />
					</Box>
				</Modal>
			</div>
	);
}
