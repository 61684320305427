import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import GetAppIcon from '@mui/icons-material/GetApp';

import { style } from "../../theme/modalBox"
import TaskSelect from '../export/TaskSelect';
import DateRange from '../export/DateRange';
import ExportContextProvider from '../../contexts/ExportContext';
import ExportButton from '../export/ExportButton';
import DriverSelect from '../export/DriverSelect';
import ModalClose from '../common/ModalClose';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


export default function ExportButtons() {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true)
	};
	const handleClose = () => setOpen(false);

	return (
		<ExportContextProvider>
			<div>
			<ListItem disablePadding >
				<ListItemButton onClick={handleOpen}>
					{/* <ListItemIcon>
					<GetAppIcon />
					</ListItemIcon> */}
					<ListItemText primary="Export Task" />
				</ListItemButton>
			</ListItem>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Export Tasks
					</Typography>
					<ModalClose handleClose={handleClose} />
					<Box paddingX={2}>
						<TaskSelect />
						<DateRange />
						<DriverSelect />
						<ExportButton />
					</Box>
				</Box>
			</Modal>
		</ExportContextProvider>
	);
}
