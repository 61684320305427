import { useCallback, useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    CircularProgress,
    FormLabel,
    FormGroup,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Box,
    MenuItem
} from '@mui/material';


import { Formik, Form } from "formik";


import { Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import useGlobalContext from '../../../hooks/useGlobalContext';

import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { routeOptimize } from '../../../api/maps';
import { toast } from 'react-toastify';

const OptimizeRouteForm = ({ data, handleClose }) => {
    console.log("data from OptimizeRouteForm", data)
    const { _globalData, _globalDispatch, teams, hubs } = useGlobalContext();
    const { tasks } = _globalData;
    const [autocomplete, setAutoComplete] = useState("");
    const [startingHub, setStartingHub] = useState("");
    const [endingHub, setEndingHub] = useState("");

    const onLoad = useCallback((auto) => {
        setAutoComplete(auto)
    }, []);

    const getDriverHubLocation = (_driver) => {
        const _driver_team = teams.getTeams().filter(t => {
            const _drivers_ids = t.driver_ids.map(_d => _d._id);
            return _drivers_ids.includes(_driver._id)
        });

        if (!_driver_team) {
            return null
        }

        if (_driver_team.length < 1) {
            return null
        }

        const { hub } = _driver_team[0];

        if (!hub) {
            return null;
        }

        return {
            latitude: hub.full_address_latlng.lat,
            longitude: hub.full_address_latlng.lng
        }
    }

    const HUB_ADDRESS = getDriverHubLocation(data.driver);

    let initValues = {
        origin_type: "hub_address",
        destination_type: "last_task_address",
        origin_task_address: null,
        destination_task_address: null,
        origin_address: '',
        origin_address_latlng: null,
        destination_address: '',
        destination_address_latlng: null
    }

    const getHubsOptions = () => {
        if (!hubs) {
            return null
        }

        if (hubs.length < 1) {
            return null
        }

        return hubs.getHubs();
    }

    useEffect(() => {
        const _hubs = getHubsOptions();
        if (_hubs && _hubs.length > 0) {
            setStartingHub(_hubs[0].full_address_latlng)
            setEndingHub(_hubs[0].full_address_latlng)
        }
    }, [])

    return (
        <Formik
            initialValues={initValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                let origin = null;
                let destination = null;
                let waypoints = [];

                const { origin_type, destination_type, origin_address_latlng, destination_address_latlng } = values;
                const { driver = {}, tasks: driverTasks } = data;

                if (driverTasks.length > 0) {
                    waypoints = driverTasks.map(t => t.destination_address_latlng)
                }

                // Origin calculation
                if (origin_type === "hub_address") {
                    // origin = HUB_ADDRESS;
                    if (!startingHub) {
                        toast.error("Staring point is empty.");

                        return false;
                    }
                    origin = startingHub;
                } else if (origin_type === "driver_address") {
                    origin = driver.address_latlng || HUB_ADDRESS;
                } else if (origin_type === "custom_address") {
                    origin = origin_address_latlng || HUB_ADDRESS;
                }

                // Destination calculation
                if (destination_type === "last_task_address") {
                    if (driverTasks.length > 0) {
                        destination = driverTasks[driverTasks.length - 1].destination_address_latlng;
                    }

                } else if (destination_type === "hub_address") {
                    // destination = HUB_ADDRESS;
                    if (!endingHub) {
                        toast.error("Ending point is empty.");

                        return false;
                    }
                    destination = endingHub;
                }
                else if (destination_type === "driver_address") {
                    destination = driver.address_latlng || HUB_ADDRESS;
                } else if (destination_type === "custom_address") {
                    destination = destination_address_latlng;
                }

                // console.log(origin, destination, waypoints, driverTasks);
                // return false

                const _res = await routeOptimize(origin, destination, waypoints, driverTasks);

                if (_res.success) {
                    const { optimized_tasks = [] } = _res;

                    if (optimized_tasks.length > 0) {
                        const promise1 = optimized_tasks.map(async ot => tasks.update(ot));
                        Promise.all(promise1).then(result => {
                            _globalDispatch({
                                type: "INCREASE_REFRESH_COUNTER"
                            });

                            handleClose();
                        })
                    }
                }

                setSubmitting(false);
            }}
        >
            {
                props => {
                    const {
                        values,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue,

                    } = props;

                    return (
                        <Form>

                            {/* Origin Details */}
                            <FormGroup sx={{ my: 2 }}>
                                <Grid container spacing={1} sx={{}}>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel id="task-type">Choose starting point</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="task-type"
                                                name="row-radio-buttons-group"
                                                defaultValue="dropoff"
                                                value={values.origin_type}
                                                onChange={(event) => {
                                                    setFieldValue("origin_type", event.currentTarget.value)
                                                }}
                                            >
                                                <FormControlLabel value="hub_address" name='origin_type' control={<Radio />} label="Hub address" />
                                                <FormControlLabel value="driver_address" name='origin_type' control={<Radio />} label="Driver address" />
                                                <FormControlLabel value="custom_address" name='origin_type' control={<Radio />} label="Custom address" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {
                                        values.origin_type === "custom_address" &&
                                        <Grid container spacing={1} sx={{}}>
                                            <Grid item xs={12}>
                                                <AddressAutoComplete
                                                    onLoad={onLoad}
                                                    onPlaceChanged={() => {
                                                        // console.log("autocomplete", autocomplete)
                                                        const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                                        setFieldValue("origin_address", autocomplete.getPlace().formatted_address)

                                                        setFieldValue("origin_address_latlng", addressPayload.geometry_location)
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Origin address"
                                                        name="origin_address"
                                                        variant="outlined"
                                                        margin="normal"
                                                        value={values.origin_address}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.origin_address && props.touched.origin_address}
                                                    />
                                                </AddressAutoComplete>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        values.origin_type === "hub_address" && getHubsOptions() &&
                                        <Grid container spacing={1} sx={{}}>
                                            <Grid item xs={12}>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <TextField
                                                            select
                                                            label="Select hub"
                                                            // defaultValue="drivers_location"
                                                            // helperText="Please select hub"
                                                            onChange={(e) => setStartingHub(e.target.value)}
                                                            value={startingHub}
                                                        >
                                                            {
                                                                getHubsOptions().map((option) => (
                                                                    <MenuItem key={option._id} value={option.full_address_latlng}>
                                                                        {option.hub_name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextField>
                                                    </div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </FormGroup>

                            {/* Destination Details */}
                            <FormGroup sx={{ my: 2 }}>
                                <Grid container spacing={1} sx={{}}>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel id="task-type">Choose ending point</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="task-type"
                                                name="row-radio-buttons-group"
                                                defaultValue="dropoff"
                                                value={values.destination_type}
                                                onChange={(event) => {
                                                    setFieldValue("destination_type", event.currentTarget.value)
                                                }}
                                            >
                                                <FormControlLabel value="hub_address" name='destination_type' control={<Radio />} label="Hub address" />
                                                <FormControlLabel value="driver_address" name='destination_type' control={<Radio />} label="Driver address" />
                                                <FormControlLabel value="custom_address" name='destination_type' control={<Radio />} label="Custom address" />
                                                <FormControlLabel value="last_task_address" name='destination_type' control={<Radio />} label="Last task address" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {
                                        values.destination_type === "custom_address" &&
                                        <Grid container spacing={1} sx={{}}>
                                            <Grid item xs={12}>
                                                <AddressAutoComplete
                                                    onLoad={onLoad}
                                                    onPlaceChanged={() => {
                                                        // console.log("autocomplete", autocomplete)
                                                        const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                                        setFieldValue("destination_address", autocomplete.getPlace().formatted_address)

                                                        setFieldValue("destination_address_latlng", addressPayload.geometry_location)
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Destination address"
                                                        name="destination_address"
                                                        variant="outlined"
                                                        margin="normal"
                                                        value={values.destination_address}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.destination_address && props.touched.destination_address}

                                                    />
                                                </AddressAutoComplete>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        values.destination_type === "hub_address" && getHubsOptions() &&
                                        <Grid container spacing={1} sx={{}}>
                                            <Grid item xs={12}>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <TextField
                                                            select
                                                            label="Select hub"
                                                            onChange={(e) => setEndingHub(e.target.value)}
                                                            value={endingHub}
                                                        >
                                                            {
                                                                getHubsOptions().map((option) => (
                                                                    <MenuItem key={option._id} value={option.full_address_latlng}>
                                                                        {option.hub_name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextField>
                                                    </div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </FormGroup>

                            {
                                isSubmitting && <Box style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%"
                                }}>
                                    <CircularProgress />
                                </Box>
                            }

                            <Box textAlign='center'>
                                <Button

                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Sort
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
        </Formik>
    )
}

export default OptimizeRouteForm;
