import { memo, useCallback, useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    CircularProgress,
    FormLabel,
    FormGroup,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Autocomplete,
    Box,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';


import { Formik, Form } from "formik";
import TaskValidation from '../../../utils/form/validations/TaskValidation';
import { toast } from 'react-toastify';

import { addTask, updateTask } from '../../../api/task';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import moment from "moment-timezone"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { MOMENT_DATETIME_OUTPUT_DB, MOMENT_INPUT, MOMENT_OUTPUT_DB, MOMENT_TIME_OUTPUT_DB, timezone } from '../../../constants/moment-date-formats';
import useGlobalContext from '../../../hooks/useGlobalContext';
import TaskEntity from '../../../entities/TaskEntity';
import { ASSIGNED_TASK_STATUS, UNASSIGNED_TASK_STATUS, INTRANSIT_TASK_STATUS, COMPLETED_TASK_STATUS, FAILED_TASK_STATUS, EXCEPTION_TASK_STATUS } from '../../../constants/task_status';

import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import CustomTimePicker from '../../common/CustomTimePicker';
import { addHoursMinutesAmPm, getHourMinuteAmPm } from '../../../utils/date';

const CreateForm = () => {
    const { _globalData, _globalDispatch, chosenTask = {}, hubs, chosenDate } = useGlobalContext();
    const { drivers, tasks } = _globalData;
    const driverOptions = drivers.getDrivers();
    const { isLoaded } = useJsApiLoader(mapInitOptions);

    const [autocomplete, setAutoComplete] = useState(undefined);
    const [task_status, setTaskStatus] = useState(chosenTask ? chosenTask.task_status : UNASSIGNED_TASK_STATUS);
    const [task_hub, setTaskHub] = useState(chosenTask && chosenTask.hub ? chosenTask.hub._id : (hubs && hubs.length > 0 ? hubs.getHubs()[0]._id : ""));
    const [close, setClose] = useState(false);
    const [tags, setTags] = useState([]);
    const [complete_before_details, setCompleteBeforeDetails] = useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });

    const [complete_after_details, setCompleteAfterDetails] = useState({
        hour: 0,
        minute: 0,
        ampm: "AM"
    });

    const onLoad = useCallback((auto) => {
        setAutoComplete(auto)
    }, []);

    const handleChange = (event) => {
        setTaskStatus(event.target.value);
    };

    const handleHubChange = (event) => {
        setTaskHub(event.target.value);
    };

    let initValues = {
        recipient_phone: "",
        recipient_name: "",
        recipient_email: "",
        recipient_note: "",
        task_type: "dropoff",
        task_details: "",
        destination_address: "",
        destination_address_latlng: "",
        destination_address_2: "",
        destination_note: "",
        driver: null,
        delivery_date: moment().tz(timezone),
        complete_after: null,
        complete_before: null,
        hub: null
    }

    if (chosenTask instanceof TaskEntity) {
        initValues = {
            recipient_phone: chosenTask.getRecipientPhone(),
            recipient_name: chosenTask.getRecipientName(),
            recipient_email: chosenTask.getRecipientEmail(),
            recipient_note: chosenTask.getRecipientNote(),
            task_type: chosenTask.getTaskType() || "pickup",
            task_details: chosenTask.getTaskDetails(),
            destination_address: chosenTask.getDestinationAddress(),
            destination_address_latlng: chosenTask.getLatLng(),
            destination_address_2: chosenTask.getDestinationAddress2(),
            destination_note: chosenTask.getDestinationNote(),
            driver: chosenTask.getDriver(),
            delivery_date: chosenTask.getDeliveryDate(),
            complete_after: chosenTask.getCompleteAfter(),
            complete_before: chosenTask.getCompleteBefore(),
            hub: chosenTask.getHub(),
        }
    }

    useEffect(() => {
        if ((hubs.getHubs().length === 1) || (hubs.getHubs().length > 1 && (!chosenTask || !chosenTask.hub))) {
            setTaskHub(hubs.getHubs()[0]._id)
        }

        if (chosenTask && chosenTask.tags) {
            setTags(chosenTask.tags)
        }

        if (chosenTask && chosenTask instanceof TaskEntity) {
            if (chosenTask.getCompleteAfter()) {
                console.log("chosenTask.getCompleteAfter()", chosenTask.getCompleteAfter())
                const _complete_after_ = getHourMinuteAmPm(chosenTask.getCompleteAfter());

                setCompleteAfterDetails(_complete_after_)
            }

            if (chosenTask.getCompleteBefore()) {
                const _complete_before_ = getHourMinuteAmPm(chosenTask.getCompleteBefore());

                setCompleteBeforeDetails(_complete_before_)
            }
        }
    }, []);

    // console.log("complete_after_details", complete_after_details)

    return (
        <Formik
            initialValues={initValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                const payload = { ...values };

                // change the date format
                const { delivery_date } = payload;
                // console.log("complete_after", complete_after.format())
                // console.log("complete_before", complete_before.format())

                let d = delivery_date;
                if (delivery_date && typeof delivery_date === "object") {
                    d = moment(delivery_date.$d).tz(timezone).format(MOMENT_OUTPUT_DB);
                }



                payload.delivery_date = d;

                payload.task_status = task_status;
                // to make sure to add unassigned tasks to driver to last
                if (payload.driver && chosenTask && chosenTask.isUnAssigned()) {
                    const chosenDriversTasks = tasks.getTasksByDriver(payload.driver._id);
                    if (chosenDriversTasks.length > 0) {
                        const lastTasks = chosenDriversTasks[chosenDriversTasks.length - 1]
                        payload.sort_number = lastTasks.sort_number + 0.001;
                    }
                }

                let _res = undefined;


                payload.hub = hubs.getHubs().find(h => h._id == task_hub)
                payload.tags = tags;

                let _complete_after = null, _complete_before = null;
                if (complete_after_details.hour || complete_after_details.minute) {
                    _complete_after = addHoursMinutesAmPm(payload.delivery_date, complete_after_details.hour, complete_after_details.minute, complete_after_details.ampm);
                    console.log("_complete_after", _complete_after.format(MOMENT_DATETIME_OUTPUT_DB))
                    payload.complete_after = _complete_after.format(MOMENT_DATETIME_OUTPUT_DB);
                }

                if (complete_before_details.hour || complete_before_details.minute) {
                    _complete_before = addHoursMinutesAmPm(payload.delivery_date, complete_before_details.hour, complete_before_details.minute, complete_before_details.ampm);
                    console.log("_complete_before", _complete_before.format(MOMENT_DATETIME_OUTPUT_DB))
                    payload.complete_before = _complete_before.format(MOMENT_DATETIME_OUTPUT_DB)
                }

                if (_complete_after && _complete_before) {
                    if (_complete_after.isSameOrAfter(_complete_before)) {
                        toast.error("Invalid time frame set up.");

                        return false;
                    }

                }

                if (chosenTask) {
                    _res = await updateTask(chosenTask.getId(), payload);

                    // If current task_status = 0, Unassigned, set order status to inProgress
                    if (chosenTask.task_status === UNASSIGNED_TASK_STATUS
                        && payload.driver !== null) {
                    }
                } else {
                    _res = await addTask(payload);
                }

                if (_res.success) {
                    if (chosenDate === payload.delivery_date) {
                        if (!chosenTask) {
                            tasks.add(_res.data);
                            resetForm();
                        } else {
                            tasks.update(_res.data);
                        }
                    }

                    // console.log("new TaskEntity(_res.data)", new TaskEntity(_res.data))
                    _globalDispatch({
                        type: "SET_CHOSEN_TASK",
                        payload: new TaskEntity(_res.data)
                    })

                    if (close) {
                        _globalDispatch({
                            type: "CLOSE_EDIT_FORM"
                        })
                        _globalDispatch({
                            type: "CLOSE_TASK_VIEW_FORM"
                        })

                        setClose(false);
                    }

                    _globalDispatch({
                        type: "INCREASE_REFRESH_COUNTER"
                    })

                    // toast("Task saved successfully.");
                }

                setSubmitting(false);
            }}
            validationSchema={TaskValidation}
        >
            {
                props => {
                    const {
                        values,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue,

                    } = props;

                    return (
                        <Form >
                            {/* Recipient Details */}
                            <FormGroup >
                                {
                                    chosenTask && chosenTask.order_id &&
                                    <FormLabel>Order ID: {chosenTask.order_id}</FormLabel>
                                }
                                <FormLabel>Recipient details</FormLabel>

                                <Grid container spacing={1} sx={{}}>
                                    <Grid item xs={6} sx={{}}>
                                        {/* Phone */}
                                        <TextField
                                            sx={{}}
                                            label="Recipient Phone"
                                            name="recipient_phone"
                                            type="tel"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.recipient_phone}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.recipient_phone && props.touched.recipient_phone}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* Name */}
                                        <TextField
                                            label="Recipient Name"
                                            name="recipient_name"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.recipient_name}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.recipient_name && props.touched.recipient_name}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* Name */}
                                        <TextField
                                            label="Recipient Email"
                                            name="recipient_email"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={values.recipient_email}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.recipient_email && props.touched.recipient_email}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Recipient Note"
                                            name="recipient_note"
                                            fullWidth
                                            multiline={true}
                                            variant="outlined"
                                            margin="normal"
                                            value={values.recipient_note}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.recipient_note && props.touched.recipient_note}
                                        />
                                    </Grid>

                                </Grid>
                            </FormGroup>

                            {/* Task Details */}
                            <FormGroup sx={{ my: 2 }}>
                                <Grid container spacing={1} sx={{}}>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <FormLabel id="task-type">Task details</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="task-type"
                                                name="row-radio-buttons-group"
                                                defaultValue="dropoff"
                                                value={values.task_type}
                                                onChange={(event) => {
                                                    setFieldValue("task_type", event.currentTarget.value)
                                                }}
                                            >
                                                <FormControlLabel value="dropoff" name='task_type' control={<Radio />} label="Dropoff" />
                                                <FormControlLabel value="pickup" name='task_type' control={<Radio />} label="Pickup" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Task Status</InputLabel>
                                            <Select
                                                value={task_status}
                                                label="Task Status"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={UNASSIGNED_TASK_STATUS}>Unassigned</MenuItem>
                                                <MenuItem value={ASSIGNED_TASK_STATUS}>Assigned</MenuItem>
                                                <MenuItem value={INTRANSIT_TASK_STATUS}>In Transit</MenuItem>
                                                <MenuItem value={EXCEPTION_TASK_STATUS}>Exception</MenuItem>
                                                <MenuItem value={COMPLETED_TASK_STATUS}>Completed</MenuItem>
                                                <MenuItem value={FAILED_TASK_STATUS}>Failed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} marginTop={1}>
                                        <FormControl fullWidth>
                                            <InputLabel>Hub</InputLabel>
                                            <Select
                                                value={task_hub}
                                                label="Hub"
                                                onChange={handleHubChange}
                                            >
                                                {
                                                    hubs.getHubs().map(h => <MenuItem key={h._id} value={h._id}>{h.hub_name}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} marginTop={1}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                options={[]} // No predefined options, allowing custom entries
                                                value={tags}
                                                onChange={(event, newValue) => {
                                                    setTags(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Add tags ( Press enter )" />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            label="Task Details"
                                            name="task_details"
                                            fullWidth
                                            multiline={true}
                                            variant="outlined"
                                            margin="normal"
                                            value={values.task_details}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.errors.task_details && props.touched.task_details}
                                        />
                                    </Grid> */}
                                </Grid>
                            </FormGroup>

                            {/* Destination Details */}
                            {
                                isLoaded && <FormGroup sx={{ my: 2 }}>
                                    <FormLabel>Destination Details</FormLabel>
                                    <Grid container spacing={1} sx={{}}>
                                        <Grid item xs={12}>
                                            <AddressAutoComplete
                                                onLoad={onLoad}
                                                onPlaceChanged={() => {
                                                    // console.log("autocomplete", autocomplete)
                                                    const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                                    setFieldValue("destination_address", autocomplete.getPlace().formatted_address)

                                                    setFieldValue("destination_address_latlng", addressPayload.geometry_location)
                                                    addUpdateAddress(addressPayload)
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Address"
                                                    name="destination_address"
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.destination_address}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.destination_address && props.touched.destination_address}

                                                />
                                            </AddressAutoComplete>

                                            <TextField
                                                label="Apartment, Building, Suite"
                                                name="destination_address_2"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                value={values.destination_address_2}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.destination_address_2 && props.touched.destination_address_2}
                                            />

                                            {/* <TextField
                                                label="Destination Notes"
                                                name="destination_note"
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                value={values.destination_note}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.destination_note && props.touched.destination_note}
                                            /> */}
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            }

                            <FormGroup sx={{ my: 2 }}>
                                <FormLabel>Assigned Details</FormLabel>
                                <Grid container spacing={1} sx={{}}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
                                            <Autocomplete
                                                sx={{ margin: 0 }}
                                                id="driver"
                                                name="driver"
                                                options={driverOptions}
                                                getOptionLabel={option => option.name || ""}
                                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                                onChange={(e, value) => {
                                                    setFieldValue(
                                                        "driver",
                                                        value !== null ? value : null
                                                    );
                                                }}
                                                value={values?.driver}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option._id}>
                                                            {option.name}
                                                        </li>
                                                    );
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        margin="normal"
                                                        label="Driver"
                                                        fullWidth
                                                        name="driver"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <FormControl fullWidth>
                                                <DesktopDatePicker
                                                    label="Delivery Date"
                                                    inputFormat="MM/DD/YYYY"
                                                    value={values?.delivery_date}
                                                    onChange={(newVal) => {
                                                        setFieldValue("delivery_date", newVal)
                                                    }}

                                                    renderInput={(params) => <TextField
                                                        fullWidth
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, readOnly: true }}

                                                    />}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormLabel>Complete After</FormLabel>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <FormControl fullWidth>
                                                <TimePicker
                                                    label="Complete After"
                                                    onChange={(newVal) => {
                                                        setFieldValue("complete_after", newVal)
                                                    }}
                                                    defaultValue={null}
                                                    value={values?.complete_after}
                                                    ampm={true}
                                                    renderInput={(params) => <TextField
                                                        fullWidth
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: null,  // Removes the clock icon
                                                        }}
                                                    />}
                                                />
                                            </FormControl>
                                        </LocalizationProvider> */}
                                        <Box mb={1}></Box>
                                        <CustomTimePicker
                                            initialVal={complete_after_details}
                                            onChange={(val) => {
                                                if (val.type === "hour") {
                                                    setCompleteAfterDetails(prev => {
                                                        prev.hour = val.hour

                                                        return prev;
                                                    })
                                                } else if (val.type === "minute") {
                                                    // m_complete_after.add(val.hour, "hours")
                                                    setCompleteAfterDetails(prev => {
                                                        prev.minute = val.minute

                                                        return prev;
                                                    })
                                                } else if (val.type === "ampm") {
                                                    // m_complete_after.add(val.hour, "hours")
                                                    setCompleteAfterDetails(prev => {
                                                        prev.ampm = val.ampm;

                                                        return prev
                                                    })
                                                }

                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <FormControl fullWidth>
                                                <TimePicker
                                                    label="Complete Before"
                                                    value={values?.complete_before}
                                                    onChange={(newVal) => {
                                                        setFieldValue("complete_before", newVal)
                                                    }}
                                                    disablePast={true}
                                                    ampm={true}
                                                    renderInput={(params) => <TextField
                                                        fullWidth
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: null,
                                                        }}
                                                    />}
                                                />
                                            </FormControl>
                                        </LocalizationProvider> */}
                                        <FormLabel>Complete Before</FormLabel>
                                        <Box mb={1}></Box>
                                        <CustomTimePicker
                                            initialVal={complete_before_details}
                                            onChange={(val) => {
                                                if (val.type === "hour") {
                                                    setCompleteBeforeDetails(prev => {
                                                        prev.hour = val.hour

                                                        return prev;
                                                    })
                                                } else if (val.type === "minute") {
                                                    setCompleteBeforeDetails(prev => {
                                                        prev.minute = val.minute

                                                        return prev;
                                                    })
                                                } else if (val.type === "ampm") {
                                                    setCompleteBeforeDetails(prev => {
                                                        prev.ampm = val.ampm;

                                                        return prev
                                                    })
                                                }

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            {
                                isSubmitting && <Box style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%"
                                }}>
                                    <CircularProgress />
                                </Box>
                            }

                            <Box textAlign='center'>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onClick={() => {
                                        // handleSubmit()
                                        setClose(true)
                                    }}
                                    disabled={isSubmitting}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Save and close
                                </Button>
                            </Box>

                        </Form>
                    );
                }}
        </Formik>
    )
}

export default memo(CreateForm);
