import React, { useState, useEffect, useRef, createRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import Accordion from '../customized/CustomAccordion';
import AccordionDetails from '../customized/CustomAccordionDetails';
import AccordionSummary from '../customized/CustomAccordionSummary';
import AddressList from './AddressList';
import { ASSIGNED_FILL_COLOR, DEFAULT_FILL_COLOR } from '../../constants/colors';
import useGlobalContext from '../../hooks/useGlobalContext';
import { multiAassignPushNotification, updateTask } from '../../api/task';
import { ASSIGNED_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../../constants/task_status';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { pluck } from '../../utils/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { getDistanceMatrix } from '../../api/maps';
import BasicTasksOptimizeModal from '../modals/BasicTasksOptimizeModal';
import CircleIcon from '@mui/icons-material/Circle';
import MessageIcon from '@mui/icons-material/Message';
import BulkMessageModal from '../modals/BulkMessageModal';
import './tasklist.css'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 0,
    margin: 0
});

const TasksList = () => {
    const {
        _globalData,
        tasks,
        drivers,
        _globalDispatch,
        globalRefreshCounter,
        openedAccordions,
        refreshData,
        teams,
        current_user
    } = useGlobalContext();
    const [unassginedTasks, setUnAssignedTasks] = useState([]);
    const [driversTasks, setDriversTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [basicOptimizeModalOpen, setBasicOptimizeModalOpen] = useState(false);
    const [bulkMessageModalOpen, setBulkMessageModalOpen] = useState(false);
    const [driverData, setDriverData] = useState({});

    console.log("openedAccordions", openedAccordions)

    const lineRefs = useRef([]);

    useEffect(() => {
        const tmpUnassigned = tasks ? tasks.getUnAssignedTasks() : [];
        const allTasks = tasks ? tasks.getTasks() : [];

        lineRefs.current = allTasks.map((_, i) => lineRefs.current[i] ?? createRef());
        const _driversTasks = tasks ? tasks.getAssignedTasksListByDrivers(drivers ? drivers.getDrivers() : []) : []
        setUnAssignedTasks(tmpUnassigned);
        setDriversTasks(_driversTasks);

        _globalDispatch({
            type: "SET_LINEREFS",
            payload: lineRefs
        })
    }, [globalRefreshCounter]);

    const handleAccordionChange = (panel) => (event, newExpanded) => {

        const expandedAccordions = [...openedAccordions]

        if (!expandedAccordions.includes(panel)) {          //checking weather array contain the id
            expandedAccordions.push(panel);               //adding to array because value doesnt exists
        } else {
            expandedAccordions.splice(expandedAccordions.indexOf(panel), 1);  //deleting
        }

        _globalDispatch({
            type: "SET_OPENED_ACCORDIONS",
            payload: expandedAccordions
        })

    }

    const getDriverTasks = driver_id => {
        return driversTasks.filter(dt => {
            if (dt.driver) {
                if (dt.driver._id === driver_id) {
                    return dt
                }
            }

            return null;
        })
    }

    const getNonTeamDriverTasks = () => {
        let team_driver_ids = []
        teams.getTeams().filter(t => {
            let _driver_ids = [];
            if (t.driver_ids) {
                _driver_ids = t.driver_ids.map(d => d._id)
            }

            team_driver_ids.push(..._driver_ids);
        });

        return driversTasks.filter(dt => {
            if (dt.driver) {
                return !team_driver_ids.includes(dt.driver._id)
            }

            return null;
        })
    }

    const updateOrderingDb = async (original = [], currentPosition, newPosition) => {
        const filteredData = original;
        const currentData = filteredData[currentPosition]
        const newData = filteredData[newPosition]

        const currentDataPayload = currentData.getPayload();
        let limit = 0.001;
        // Upward
        if (currentPosition > newPosition) {
            // decrease sort_number by 0.001 from newData
            if (newPosition > 0) {
                const preNewData = filteredData[newPosition - 1];
                const diff = newData.sort_number - preNewData.sort_number;
                if (diff <= limit) {
                    currentDataPayload.sort_number = newData.sort_number - (diff / 2);
                } else {
                    currentDataPayload.sort_number = newData.sort_number - limit
                }

            } else {
                currentDataPayload.sort_number = newData.sort_number - limit
            }

        } else {
            // decrease sort_number by 0.001 from newData
            if (newPosition < filteredData.length - 1) {
                const nextNewData = filteredData[newPosition + 1];
                const diff = nextNewData.sort_number - newData.sort_number;
                if (diff <= limit) {
                    currentDataPayload.sort_number = newData.sort_number + (diff / 2)
                } else {
                    currentDataPayload.sort_number = newData.sort_number + limit
                }
            } else {
                currentDataPayload.sort_number = newData.sort_number + limit
            }
        }

        updateTask(currentData.getId(), currentDataPayload)
            .then(result => {
                _globalDispatch({
                    type: "SET_DRAGGING",
                    payload: false
                });
            });

        tasks.update(currentDataPayload)

        for (let t of tasks.getTasks()) {
            tasks.update(t)
        }

        refreshData();
    }


    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    const getList = id => {
        if (id === "unassigned") {
            return unassginedTasks;
        }
        const dtasks = driversTasks.find(dt => dt.driver.getId() === id)

        return dtasks.tasks;
    }

    // Handle drag and drop
    const onDragEnd = result => {
        // return false;

        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        // Set Flag on drag end
        _globalDispatch({
            type: "SET_DRAGGING",
            payload: true
        });

        const sourceLists = getList(source.droppableId);
        const destinationLists = getList(destination.droppableId);

        // This is for reordering of tasks under same driver
        if (source.droppableId === destination.droppableId) {

            const items = reorder(
                sourceLists,
                source.index,
                destination.index
            );

            if (source.droppableId === "unassigned") {
                setUnAssignedTasks(items);
            }

            updateOrderingDb(sourceLists, source.index, destination.index)

        } else {
            const result = move(
                getList(source.droppableId),
                destinationLists,
                source,
                destination
            );

            // openAccordion(destination.droppableId)

            // update state of tasks
            let oldDriversTasks = Array.from(driversTasks);
            oldDriversTasks = oldDriversTasks.map(ot => {
                if (result[ot.driver.getName()]) {
                    ot.tasks = result[ot.driver.getName()]
                }

                return ot;
            });


            setDriversTasks(oldDriversTasks)

            // update tasks in DB
            const currentTask = sourceLists[source.index]
            let sort_number = 100;
            const destinationTask = destinationLists[destination.index]
            const preDestinationTask = destinationLists[destination.index - 1]

            if (destinationTask) {
                if (preDestinationTask) {
                    sort_number = destinationTask.sort_number - ((destinationTask.sort_number - preDestinationTask.sort_number) / 10);
                } else {
                    sort_number = destinationTask.sort_number - 1;
                }

            } else {
                if (destinationLists.length > 0) {
                    let maxSortNumObj = destinationLists.reduce(function (prev, current) {
                        return (prev.sort_number > current.sort_number) ? prev : current
                    });

                    if (maxSortNumObj) {
                        sort_number = maxSortNumObj.sort_number + 0.01
                    }
                }
            }


            let taskPaload = {
                ...currentTask,
                sort_number,
                driver: destination.droppableId === "unassigned" ? null : drivers.getDriverById(destination.droppableId).getPayload()
            }
            if (source.droppableId === "unassigned" && destination.droppableId !== "unassigned") {
                taskPaload.task_status = ASSIGNED_TASK_STATUS
            } else if (destination.droppableId === "unassigned" && source.droppableId !== "unassigned") {
                taskPaload.task_status = UNASSIGNED_TASK_STATUS
            }


            tasks.update(taskPaload)
            refreshData()

            // find the destination driver
            updateTask(currentTask._id, taskPaload).then(result => {
                _globalDispatch({
                    type: "SET_DRAGGING",
                    payload: false
                })
            })
        }
    };

    /**
     * 
     * @param {*} dt 
     */
    const handlePushNotificationToDriver = async (dt) => {
        setLoading(true);
        await multiAassignPushNotification({
            task_ids: pluck(dt.tasks, "_id"),
            tasks: dt.tasks,
            driver: dt.driver
        });

        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    const handleETACalculation = async (dt) => {
        const { driver = {}, tasks = [] } = dt;

        if (!driver || tasks.length < 1) {
            return false;
        }

        setLoading(true);

        const destinations = tasks.map(a => a.destination_address_latlng);
        const task_ids = tasks.map(a => a._id);
        const order_ids = tasks.map(a => a.order_id);
        const task_types = tasks.map(a => a.task_type);

        const _res = await getDistanceMatrix(
            driver._id,
            driver.current_location,
            destinations,
            task_ids,
            order_ids,
            task_types,
            false
        );

        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }

    if (!_globalData.tasks) {
        return null;
    }

    return (<div className="task-list-icons">
        <DragDropContext onDragEnd={onDragEnd}>

            <Droppable droppableId="unassigned">
                {
                    (provided, snapshot) => {
                        if (snapshot.isDraggingOver) {
                            // openAccordion("unassigned")
                        }

                        return (
                            <div
                                ref={provided.innerRef}
                                style={
                                    getListStyle(snapshot.isDraggingOver)
                                }
                            >
                                <Accordion
                                    disableGutters={true}
                                    expanded={openedAccordions.includes("unassigned")}
                                    onChange={handleAccordionChange('unassigned')}
                                >
                                    <AccordionSummary>
                                        <Typography fontSize={10} marginLeft={1}>
                                            {`Unassigned (${unassginedTasks.length} tasks)`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AddressList
                                            data={unassginedTasks}
                                            bgcolor={DEFAULT_FILL_COLOR}
                                            unassigned={true}
                                            lineRefs={lineRefs}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                {provided.placeholder}
                            </div>
                        )
                    }
                }
            </Droppable>

            {
                teams.getTotalTeams() > 0 && teams.getTeams().map(team => {
                    return <Accordion
                        key={team._id}
                        disableGutters={true}
                        expanded={openedAccordions.includes(team._id)}
                        onChange={handleAccordionChange(team._id)}
                    >
                        <AccordionSummary>
                            <Typography fontSize={10} marginLeft={1}>
                                {team.team_name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                team.driver_ids && team.driver_ids.map(_driver => {
                                    const _driverTasks = getDriverTasks(_driver._id);

                                    return Object.entries(_driverTasks).map(([key, dt]) => {
                                        let subHeading = "No tasks";
                                        if (dt.tasks.length > 0) {
                                            const completed = dt.tasks.filter(t => t.isCompleted())
                                            subHeading = `${completed.length} of ${dt.tasks.length}`
                                        }

                                        return <Droppable key={key} droppableId={`${dt.driver.getId()}`}>
                                            {
                                                (provided, snapshot) => {
                                                    if (snapshot.isDraggingOver) {
                                                        // openAccordion(`${dt.driver.getId()}`)
                                                    }

                                                    return (<div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                    >
                                                        <Accordion
                                                            disableGutters={true}
                                                            expanded={openedAccordions.includes(dt.driver.getId())}
                                                            onChange={handleAccordionChange(dt.driver.getId())}
                                                            onClick={() => {
                                                                if (dt && dt.driver && dt.driver.current_location && dt.driver.online_status) {
                                                                    _globalDispatch({
                                                                        type: "SET_CUSTOM_CENTER",
                                                                        payload: {
                                                                            location: dt.driver.current_location,
                                                                            title: dt.driver.getName(),
                                                                            isHub: false,
                                                                            hideMarker: true,
                                                                        }
                                                                    })
                                                                }

                                                            }}
                                                        >
                                                            <AccordionSummary>
                                                                <Stack direction={"row"}>
                                                                    <CircleIcon className="circle-online" sx={{ marginLeft: "2px", marginTop: "2px", fontSize: '0.9rem' }} htmlColor={dt.driver.getOnlineStatusColor()} />
                                                                    <Typography fontSize={10} marginLeft={1} variant='p'>{`${dt.driver.getName()} ( ${subHeading} )`}</Typography>
                                                                </Stack>

                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                {
                                                                    dt.tasks.length > 0 &&
                                                                    <Stack direction={"row"} sx={{ marginLeft: '28px' }}>

                                                                        {
                                                                            current_user.plan_details.basic_eta_notification && !tasks.hasOngoingCompletedTaskForDriver(dt.driver._id) &&
                                                                            <Box onClick={() => handlePushNotificationToDriver(dt)}>
                                                                                <NotificationAddIcon sx={{ marginLeft: "2px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                                            </Box>
                                                                        }

                                                                        {
                                                                            current_user.plan_details.basic_eta_notification ? <Box
                                                                                onClick={async () => {
                                                                                    handleETACalculation(dt);
                                                                                }}>
                                                                                <DriveEtaIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />

                                                                            </Box> : null
                                                                        }

                                                                        {
                                                                            current_user.plan_details.quick_route_organize ? <Box
                                                                                onClick={async () => {
                                                                                    setBasicOptimizeModalOpen(true)
                                                                                    setDriverData(dt);
                                                                                }}>
                                                                                <ManageHistoryIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                                            </Box> : null
                                                                        }
                                                                        {
                                                                            current_user.plan_details.bulk_sms ? <Box
                                                                                onClick={async () => {
                                                                                    setBulkMessageModalOpen(true)
                                                                                    setDriverData(dt);
                                                                                }}>

                                                                                <MessageIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                                            </Box> : null
                                                                        }


                                                                        {
                                                                            loading &&
                                                                            <CircularProgress size={16} sx={{ ml: 4 }} />
                                                                        }

                                                                    </Stack>
                                                                }

                                                                <AddressList
                                                                    data={dt.tasks}
                                                                    driver={dt.driver.getName()}
                                                                    lineRefs={lineRefs}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {provided.placeholder}
                                                    </div>
                                                    )
                                                }
                                            }
                                        </Droppable>
                                    })
                                })
                            }
                        </AccordionDetails>
                    </Accordion>
                })
            }

            {
                Object.entries(getNonTeamDriverTasks()).map(([key, dt]) => {
                    let subHeading = "No tasks";
                    if (dt.tasks.length > 0) {
                        const completed = dt.tasks.filter(t => t.isCompleted())
                        subHeading = `${completed.length} of ${dt.tasks.length}`
                    }

                    return <Droppable key={key} droppableId={`${dt.driver.getId()}`}>
                        {
                            (provided, snapshot) => {
                                if (snapshot.isDraggingOver) {
                                    // openAccordion(`${dt.driver.getId()}`)
                                }

                                return (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        <Accordion
                                            disableGutters={true}
                                            expanded={openedAccordions.includes(dt.driver.getId())}
                                            onChange={handleAccordionChange(dt.driver.getId())}
                                            onClick={() => {
                                                if (dt && dt.driver && dt.driver.current_location && dt.driver.online_status) {
                                                    _globalDispatch({
                                                        type: "SET_CUSTOM_CENTER",
                                                        payload: {
                                                            location: dt.driver.current_location,
                                                            title: dt.driver.getName(),
                                                            isHub: false,
                                                            hideMarker: true
                                                        }
                                                    })
                                                }
                                            }}
                                        >
                                            <AccordionSummary>
                                                <Stack direction={"row"}>
                                                    <CircleIcon className="circle-online" sx={{ marginLeft: "2px", marginTop: "2px", fontSize: '0.9rem' }} htmlColor={dt.driver.getOnlineStatusColor()} />
                                                    <Typography fontSize={10} marginLeft={1} variant='p'>{`${dt.driver.getName()} ( ${subHeading} )`}</Typography>
                                                </Stack>

                                            </AccordionSummary>

                                            <AccordionDetails>
                                                {
                                                    dt.tasks.length > 0 &&
                                                    <Stack direction={"row"} sx={{ marginLeft: '28px' }}>

                                                        {
                                                            !tasks.hasOngoingCompletedTaskForDriver(dt.driver._id) &&
                                                            <Box onClick={() => handlePushNotificationToDriver(dt)}>
                                                                <NotificationAddIcon sx={{ marginLeft: "2px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                            </Box>
                                                        }

                                                        {
                                                            current_user.plan_details.basic_eta_notification && <Box
                                                                onClick={async () => {
                                                                    handleETACalculation(dt);
                                                                }}>
                                                                <DriveEtaIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />

                                                            </Box>
                                                        }

                                                        {
                                                            current_user.plan_details.quick_route_organize ? <Box
                                                                onClick={async () => {
                                                                    setBasicOptimizeModalOpen(true)
                                                                    setDriverData(dt);
                                                                }}>
                                                                <ManageHistoryIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                            </Box> : null
                                                        }

                                                        {
                                                            current_user.plan_details.bulk_sms ? <Box
                                                                onClick={async () => {
                                                                    setBulkMessageModalOpen(true)
                                                                    setDriverData(dt);
                                                                }}>
                                                                <MessageIcon sx={{ marginLeft: "6px", marginTop: "2px", fontSize: "16px", cursor: "pointer" }} htmlColor={ASSIGNED_FILL_COLOR} />
                                                            </Box> : null
                                                        }


                                                        {
                                                            loading &&
                                                            <CircularProgress size={16} sx={{ ml: 4 }} />
                                                        }

                                                    </Stack>
                                                }

                                                <AddressList
                                                    data={dt.tasks}
                                                    driver={dt.driver.getName()}
                                                    lineRefs={lineRefs}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        }
                    </Droppable>
                })
            }
        </DragDropContext>

        {/* Basic route optimization modal */}
        <BasicTasksOptimizeModal
            opened={basicOptimizeModalOpen}
            handleClose={() => {
                setBasicOptimizeModalOpen(false)
            }}
            data={driverData}
        />
        {/* Bulk message modal */}
        <BulkMessageModal
            opened={bulkMessageModalOpen}
            handleClose={() => {
                setBulkMessageModalOpen(false)
            }}
            data={driverData}
        />
    </div>
    );
}

export default TasksList;
