import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../common/ModalClose';

import useGlobalContext from '../../hooks/useGlobalContext';
import {
	Button,
	Box,
	Grid
} from '@mui/material';

import MaxTasksInput from '../route-optimize/MaxTasksInput';
import ServiceTimeInput from '../route-optimize/ServiceTimeInput';
import StartFromInput from '../route-optimize/StartFromInput';
import EndAtInput from '../route-optimize/EndAtInput';
import OptimizationModeInput from '../route-optimize/OptimizationModeInput';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';
import { _optimizeTours, _batchOptimizeTours } from '../../api/maps';
import { deepCopy } from '../../utils/utils';
import moment from 'moment-timezone';
import { MOMENT_ONLY_TIME_OUTPUT_24, MOMENT_OUTPUT_DISPLAY_TIME, MOMENT_OUTPUT_DISPLAY_TIME_24, timezone } from '../../constants/moment-date-formats';
import TasksListEntity from '../../entities/TasksListEntity';
import Loader from '../Loader';
import GlobalStartEndTime from '../route-optimize/GlobalStartEndTime';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import TeamDriversSelect from '../route-optimize/TeamDriversSelect';

export default function RouteOptimizationModal() {
	const [loadingState, setLoadingState] = React.useState(0)
	const {
		route_optimize_modal_open,
		_globalDispatch,
		hubs,
		teams,
		drivers,
		multiSelectTasks,
		chosenDate,
		tasks
	} = useGlobalContext();

	const _day = moment(chosenDate).format("dddd").toLowerCase();

	const {
		chosen_drivers,
		_route_optimize_context,
		optimization_mode,
		start_from,
		end_at,
		global_start_time,
		global_end_time
	} = useRouteOptimizeContext();

	/**
	 * 
	 */
	const handleClose = () => {
		_globalDispatch({
			type: "CLOSE_ROUTE_OPTIMIZE_FORM"
		})
	};

	/**
	 * 
	 * @param {*} _driver 
	 * @returns 
	 */
	const getDriverHubLocation = (_driver) => {
		const _driver_team = teams.getTeams().filter(t => {
			const _drivers_ids = t.driver_ids.map(_d => _d._id);
			return _drivers_ids.includes(_driver._id)
		});

		if (!_driver_team) {
			return null
		}

		if (_driver_team.length < 1) {
			return null
		}

		const { hub } = _driver_team[0];

		if (!hub) {
			return null;
		}

		return {
			latitude: hub.full_address_latlng.lat,
			longitude: hub.full_address_latlng.lng
		}
	}

	const handleProceedConfirmation = async () => {

		if (!chosen_drivers || chosen_drivers.length < 1) {
			toast.error("You must select at least one driver.");
			return false;
		}

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-ui__overlay">
						<div className="custom-ui">
							<div className="custom-ui__header">
								<h1>Are you sure want to proceed?</h1>
							</div>
							<div className="custom-ui__body">
								<p>Please make sure to add timewindow and rates for drivers for more accurate optimization.</p>
							</div>
							<div className="custom-ui__footer">
								<button className="custom-ui__button custom-ui__button--cancel" onClick={onClose}>No</button>
								<button
									className="custom-ui__button custom-ui__button--confirm proceed"
									onClick={() => {
										handleOptimize();
										onClose();
									}}
								>
									Yes, Proceed!
								</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	/**
	 * 
	 */
	const handleOptimize = async () => {
		const payload = {};
		setLoadingState(1)

		const _chosen_drivers = drivers.getDrivers().filter(d => chosen_drivers.includes(d._id))
		payload._chosen_drivers = _chosen_drivers;
		payload.tasks = deepCopy(multiSelectTasks);

		if (start_from === "teams_hub" && hubs.getHubs().length < 1) {
			toast.error("Please add hub first.");
			setLoadingState(2)
			return false
		}

		if (end_at === "teams_hub" && hubs.getHubs().length < 1) {
			toast.error("Please add hub first.");
			setLoadingState(2)
			return false
		}

		// shipments
		payload.shipments = payload.tasks.map(t => {
			let { destination_address_latlng = {}, hub = null, destination_address } = t;


			if (!hub || !hub.full_address_latlng) {
				hub = hubs.getHubs()[0]
			}

			const _deliveries = [
				{
					arrivalLocation: {
						latitude: destination_address_latlng.lat,
						longitude: destination_address_latlng.lng
					},
					duration: `${_route_optimize_context.service_time_per_task * 60}s`, // in seconds,
					label: destination_address
				}
			]

			if (t.complete_before && !t.complete_after) {
				const end_time = moment(t.complete_before).format(MOMENT_ONLY_TIME_OUTPUT_24)

				_deliveries[0]["timeWindows"] = [
					{
						"endTime": moment.tz(`${chosenDate} ${end_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
					}
				]
			} else if (t.complete_after && !t.complete_before) {
				const start_time = moment(t.complete_after).format(MOMENT_ONLY_TIME_OUTPUT_24);
				_deliveries[0]["timeWindows"] = [
					{
						"startTime": moment.tz(`${chosenDate} ${start_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
					}
				]
			} else if (t.complete_after && t.complete_before) {
				const start_time = moment(t.complete_after).format(MOMENT_ONLY_TIME_OUTPUT_24);
				const end_time = moment(t.complete_before).format(MOMENT_ONLY_TIME_OUTPUT_24);
				_deliveries[0]["timeWindows"] = [
					{
						"startTime": moment.tz(`${chosenDate} ${start_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
						"endTime": moment.tz(`${chosenDate} ${end_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
					}
				]
			}

			let _pick_ups = hub ? [
				{
					arrivalLocation: {
						latitude: hub.full_address_latlng.lat,
						longitude: hub.full_address_latlng.lng
					},
					// duration: `${_route_optimize_context.service_time_per_task * 60}s` // in seconds,
					label: hub.hub_name,
				},
			] : null;

			const _return = {
				deliveries: _deliveries,
				pickups: _pick_ups,
				_id: t._id
			}

			return _return;
		});

		// vehicles
		let validDriverAddress = true;
		payload.vehicles = _chosen_drivers.map(_d => {
			let { address_latlng, current_location } = _d;

			if (!address_latlng) {
				address_latlng = current_location;
			}

			if (address_latlng) {
				validDriverAddress = validDriverAddress && true;
			} else {
				validDriverAddress = validDriverAddress && false;
			}

			let start_location = null
			let end_location = null
			if (start_from === "drivers_location") {
				if (address_latlng) {
					address_latlng = {
						latitude: address_latlng.lat,
						longitude: address_latlng.lng,
					}

					start_location = address_latlng;
				} else {
					start_location = getDriverHubLocation(_d); // if not, teams hub location is default
				}

			} else if (start_from === "teams_hub") {
				start_location = getDriverHubLocation(_d);
			}

			if (end_at === "drivers_location") {
				if (address_latlng) {
					address_latlng = {
						latitude: address_latlng.lat,
						longitude: address_latlng.lng,
					}

					end_location = address_latlng;
				}
			} else if (end_at === "teams_hub") {
				end_location = getDriverHubLocation(_d);
			}

			const _return = {
				"label": _d.email,
				"endLocation": end_location,
				"startLocation": start_location,
				"costPerHour": parseFloat(_d.cost_per_hour),
				"costPerKilometer": parseFloat(_d.cost_per_km),
			}

			if (_d.timewindow) {
				if (_d.timewindow[_day].start_time) {
					const start_time = moment(_d.timewindow[_day].start_time).format(MOMENT_ONLY_TIME_OUTPUT_24);

					_return["startTimeWindows"] = [
						{
							"startTime": moment.tz(`${chosenDate} ${start_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
							// "endTime": moment.tz(`${chosenDate} ${start_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
						}
					]
				}

				if (_d.timewindow[_day].end_time) {
					const end_time = moment(_d.timewindow[_day].end_time).format(MOMENT_ONLY_TIME_OUTPUT_24);

					_return["endTimeWindows"] = [
						{
							// "startTime": moment.tz(`${chosenDate} ${end_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
							"endTime": moment.tz(`${chosenDate} ${end_time}`, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),

						}
					]
				}
			}

			return _return;
		});

		// console.log("validDriverAddress", validDriverAddress)

		// setLoadingState(2)
		// return false;

		if (!validDriverAddress) {
			if (start_from === "drivers_location" || end_at === "drivers_location") {
				toast.error("Please make sure to add drivers location correctly.");
				setLoadingState(2)
				return false;
			}
		}

		// global start time
		let _global_start_time = moment.tz(`${chosenDate} 00:01`, timezone);
		if (global_start_time) {
			_global_start_time = moment.tz(`${chosenDate} ${global_start_time.format(MOMENT_ONLY_TIME_OUTPUT_24)}`, timezone);
		}
		const global_start_utc_time = _global_start_time.utc();
		payload.globalStartTime = global_start_utc_time.format('YYYY-MM-DDTHH:mm:ss[Z]')

		// Global end time
		let _global_end_time = moment.tz(`${chosenDate} 23:59`, timezone);
		if (global_end_time) {
			_global_end_time = moment.tz(`${chosenDate} ${global_end_time.format(MOMENT_ONLY_TIME_OUTPUT_24)}`, timezone);
		}
		const global_end_utc_time = _global_end_time.utc();

		payload.globalEndTime = global_end_utc_time.format('YYYY-MM-DDTHH:mm:ss[Z]')

		payload.timezone = timezone
		payload.optimization_mode = optimization_mode

		if (payload._chosen_drivers && payload._chosen_drivers.length === 1 && payload.optimization_mode === "load_balance_most_efficient") {
			payload.optimization_mode = "maximum_distribution_quickest_time"
		}

		// console.log("payload", payload);
		// setLoadingState(2)
		// return false
		
		const _res = await _optimizeTours(payload);
		if (_res.success) {
			setLoadingState(2)
			for (let _t of _res.all_tasks) {
				tasks.update(_t)
			}

			_globalDispatch({
				type: "REFRESH_TASKS",
				tasks: new TasksListEntity((_res.all_tasks || []))
			});

			_globalDispatch({
				type: "CLOSE_TASK_OPTIONS_FORM"
			})

			_globalDispatch({
				type: "INCREASE_REFRESH_COUNTER"
			});

			handleClose();
			toast.success("Success")
		} else {
			setLoadingState(2)
			toast.error(_res.err.message || "something went wrong.")
		}
	}

	return (
		<Dialog
			open={route_optimize_modal_open}
			onClose={handleClose}
			scroll="paper"
		>
			{
				loadingState === 1 && <Box sx={{
					position: 'absolute',
					top: "50%",
					left: "44%"
				}}>
					<Loader />
				</Box>
			}


			<ModalClose handleClose={handleClose} />
			<DialogTitle id="scroll-dialog-title">Route Optimize</DialogTitle>
			<DialogContent dividers={true}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<OptimizationModeInput />
						</Grid>

						<Grid item xs={12}>
							<TeamDriversSelect />
						</Grid>

						{/* Global start time */}
						<GlobalStartEndTime />

						<Grid item xs={6}>
							<MaxTasksInput />
						</Grid>
						<Grid item xs={6}>
							<ServiceTimeInput />
						</Grid>

						<Grid item xs={6}>
							<StartFromInput />
						</Grid>

						<Grid item xs={6}>
							<EndAtInput />
						</Grid>

						<Grid item xs={12}>
							<Box textAlign={"center"}>
								<Button
									variant='contained'
									size='small'
									onClick={handleProceedConfirmation}
									sx={{ "marginLeft": "12px" }}
								>
									Optimize
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>

			</DialogContent>
		</Dialog>
	);
}