import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';

export default function MaxTasksInput() {
    const { _route_optimize_dispatch, max_tasks_per_route } = useRouteOptimizeContext();
    const handleChange = (event) => {
        _route_optimize_dispatch({
            type: "SET_MAX_TASKS_PER_ROUTE",
            payload: event.target.value
        })
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id=""
                    label=""
                    type="number"
                    value={max_tasks_per_route}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{ inputProps: { min: 5, max: 10 } }}
                    helperText="Maximum number of tasks per Route"
                    onChange={handleChange}
                />
            </div>
        </Box>
    );
}
