import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate, Navigate, useNavigation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import Alert from '@mui/material/Alert';
import { resetUserPassword } from '../../../api/user';
import { toast } from 'react-toastify';
// ----------------------------------------------------------------------

export default function ResetPasswordForm({ user_id, token }) {
	const [apiError, setAPIError] = useState('');

	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		password: Yup.string().required('Password is required'),
	});

	const defaultValues = {
		password: ''
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (values) => {
		try {
			setAPIError('');
			const res = await resetUserPassword(
				{
					user_id,
					token,
					password: values.password
				}
			);

			if (res.success === true) {
				toast.success("The password reset it successfully. Now redirecting to login page.")
				setTimeout(() => {
					navigate('/login', { replace: true, state: { history: 'forgot-password' } });
				}, 300)

			}
			else {
				setAPIError(res.msg)
			}
		} catch (err) {
			setAPIError('Something went wrong. Please try again.');
			console.log("Err", err);
		}

	};

	const goToLogin = async () => {
		console.log("You clicked forgot password")
		navigate('/login', { replace: true, state: { history: 'login' } });
	}

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>

				<RHFTextField
					name="password"
					label="Password"
					type={showPassword ? 'text' : 'password'}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			{
				apiError && <Alert severity="error">{apiError}</Alert>
			}

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
				<Link
					style={{
						cursor: "pointer"
					}}
					onClick={goToLogin}
					variant="subtitle2" underline="hover" sx={{ ml: 2 }}>
					Go to login?
				</Link>
			</Stack>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<LoadingButton fullwidth size="large" type="submit" variant="contained" loading={isSubmitting}>
					Reset
				</LoadingButton>
			</div>
		</FormProvider>
	);
}
