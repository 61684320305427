import { Box } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export default ({handleClose}) => {
    return <Box style={{
        position: "absolute",
        right: 16,
        top: 16,
        cursor: "pointer"
    }}>
        <CloseIcon onClick={handleClose} />
    </Box>
}