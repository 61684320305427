import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getCommunications = async () => {
    return getRequest('/api/communications/all', {});
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getCommunicationDetails = async (id) => {
    return getRequest(`/api/communications/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteCommunication = async (_ids) => {
    return deleteRequest('/api/communications/delete-many', { _ids });
}

/**
 *
 * @param {*} Driver
 */
export const addCommunication = async (data) => {
    return postRequest('/api/communications/add', data);
}

/**
 *
 * @param id
 * @param Driver
 * @returns {Promise<*>}
 */
export const updateCommunication = async (id, data) => {
    return putRequest(`/api/communications/update/${id}`, data);
}
