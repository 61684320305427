import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Box, Button,IconButton, Typography } from "@mui/material";
import { useState } from "react";
import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { makeStyles } from '@mui/styles';
import { updateSubcription } from '../../../api/subscription';
import { AuthContext } from '../../../contexts/AuthContext';
import { deepCopy } from '../../../utils/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jsPDF from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';



const useStyles = makeStyles((theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
    listItem: {
        border: '1px solid black',
        marginRight: '8px',
        '&:hover': {
            backgroundColor: '#4F46E5', // Use MUI's hover color
            color: 'white',
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    backgroundColor: '#4F46E5', // Use MUI's hover color
                    color: 'white',
                },
            },
        },
        '&:last-child': {
            marginRight: 0,
        },
    },

}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'none',
        },
        '&:hover fieldset': {
            borderColor: 'none',
        },
        '&.Mui-focused fieldset': {
            borderWidth: 0.1
        },
    },
});

const SearchBar = ({ setSearchQuery }) => (<Box style={{ width: "90%" }}>
    <CssTextField
        id="search-bar"
        className="text"
        onInput={(e) => {
            setSearchQuery(e.target.value);
        }}
        fullWidth
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"

    />
</Box>
);



export default function InvoiceList() {
    const [searchQuery, setSearchQuery] = useState("");
    const { _globalData } = React.useContext(GlobalContext);

    const { invoices,current_user } = _globalData;


    const data = invoices ? invoices.getInvoices() : [];
    



console.log('data',data);
console.log('current_user',current_user);
    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) => d.title.toLowerCase().includes(query.toLowerCase()));
        }
    };

    const billData = {
        customerName: 'John Doe',
        date: '2024-08-20',
        items: [
          { name: 'Product 1', quantity: 2, price: 10.0 },
          { name: 'Product 2', quantity: 1, price: 15.5 },
          { name: 'Product 3', quantity: 5, price: 7.25 },
        ],
      };

    const dataFiltered = filterData(searchQuery, data);

    function generateStyledPDF(billData) {
      const doc = new jsPDF();
    
      // Colors and fonts
      doc.setFont('helvetica', 'normal');
      doc.setTextColor(50);
    
      // Add total amount with styling
      const totalAmount = billData.items.reduce((acc, item) => acc + item.price * item.quantity, 0);

      doc.setFontSize(24);
      doc.setTextColor(34, 139, 34); // Green color
      doc.text(`$${totalAmount.toFixed(2)}`, 20, 30); // Total amount
    
      // Add logo (dummy image)
      const dummyImage = 'https://operationtrack.com/static/media/latest-logo.6507254fa0c1366af14e.png'; // Replace with base64 image
      const imgWidth = 60; // Width of the image
      const imgHeight = 15; // Height of the image
      doc.addImage(dummyImage, 'PNG', 120, 15, imgWidth, imgHeight);
    
      // Add company details
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text('Local Foodz Cali Inc.', 20, 50);
      doc.text('Invoice 1NwD5M2qTRGLPWxrwAWfIv8N', 180, 50, { align: 'right' });
    
      doc.text('703 Market Street, Floor 20', 180, 60, { align: 'right' });
      doc.text('San Francisco, California 94103', 180, 70, { align: 'right' });
      doc.text('United States', 180, 80, { align: 'right' });
    
      // Add date
      doc.setFontSize(10);
      doc.text('September 30, 2023', 180, 90, { align: 'right' });
    
      // Add description section
      const tableStartY = 110;
      doc.setFontSize(12);
      doc.text('Description', 20, tableStartY);
      doc.text('Amount', 160, tableStartY, { align: 'right' });
    
      // Table rows
      let y = tableStartY + 10;
      billData.items.forEach((item) => {
        doc.setFontSize(10);
        doc.text(item.name, 20, y);
        doc.text(`$${item.price.toFixed(2)}`, 180, y, { align: 'right' });
        y += 10;
      });
    
      // Total due
      doc.setFontSize(12);
      doc.text('Total:', 20, y + 10);
      doc.text(`$${totalAmount.toFixed(2)}`, 180, y + 10, { align: 'right' });
    
      // Paid
      doc.setFontSize(14);
      doc.setTextColor(34, 139, 34); // Green color
      doc.text(`Paid: $${totalAmount.toFixed(2)}`, 20, y + 30);
    
      // Transaction details
      doc.setTextColor(0, 0, 0); // Black
      doc.setFontSize(10);
      doc.text(`Customer: ${billData.customerName}`, 20, y + 40);
      doc.text('Transaction ID: 3NwD5M2qTRGLPWxr0AwwuGyr', 20, y + 50);
    
      // Footer
      doc.setFontSize(8);
      doc.setTextColor(100);
      doc.text('Visit us at https://operationtrack.com', 20, 280);
    
      // Save the PDF
      doc.save('styled_invoice.pdf');
    }
    
    

    function generatePDF(billData) {
        const doc = new jsPDF();
      
        // Add a title
        doc.setFontSize(22);
        doc.text('Invoice', 20, 20);
      
        // Add customer details
        doc.setFontSize(14);
        doc.text(`Bill to: ${billData.customerName}`, 20, 40);
        doc.text(`Created Date: ${billData.date}`, 20, 50);
        doc.text(`Start Date: ${billData.start_date}`, 20, 60);
        doc.text(`End Date: ${billData.end_date}`, 20, 70);
      
        // Add a table header
        doc.text('Item', 20, 80);
        doc.text('Quantity', 100, 80);
        doc.text('Price', 140, 80);
        doc.text('Total', 180, 80);
      
        // Add table rows
        let y = 90;
        billData.items.forEach(item => {
          doc.text(item.name, 20, y);
          doc.text(item.quantity.toString(), 100, y);
          doc.text(`$${item.price.toFixed(2)}`, 140, y);
          doc.text(`$${(item.price * item.quantity).toFixed(2)}`, 180, y);
          y += 10;
        });
      
        // Add total amount
        const totalAmount = billData.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
        doc.setFontSize(16);
        doc.text(`Total: $${totalAmount.toFixed(2)}`, 20, y + 20);
      
        // Save the PDF
        doc.save('invoice.pdf');
      }
      function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      
    return (<>
    	<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									border: '1px solid #888 ',
									padding: '16px',
									borderRadius: '8px',
									marginBottom: '10px',
                  marginTop: '10px',
								}}
							>
							
								<Typography variant="h4" gutterBottom>
									Invoices
								</Typography>
							</Box>
     
                                            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell align="center">Remarks</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Subscription Start</TableCell>
            <TableCell align="center">Subscription End</TableCell>
            {/* <TableCell align="center">Created At</TableCell> */}
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFiltered && dataFiltered.map((subscriber,i) => (
            <TableRow
              key={subscriber._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               {i+1}
              </TableCell>
              <TableCell align="center">{subscriber.remarks}</TableCell>
              <TableCell align="center">${subscriber.price/100}</TableCell>
              <TableCell align="center">{new Date(subscriber.period_start).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</TableCell>
              <TableCell align="center">{new Date(subscriber.period_end).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</TableCell>
              {/* <TableCell align="center">{new Date(subscriber.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</TableCell> */}
              <TableCell align="center">  
              <IconButton edge="end" aria-label="edit" onClick={() => generateStyledPDF({
        customerName: current_user.first_name+' '+current_user.last_name,
        date: formatDate(new Date(subscriber.created_at)),
        start_date: formatDate(new Date(subscriber.period_start)),
        end_date: formatDate(new Date(subscriber.period_end)),
        items: [
          { name: subscriber.plan.title, quantity: 1, price: subscriber.price/100 },
        ],
      })}>
              <DownloadIcon />
            </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    )
}