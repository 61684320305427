export const MapReducer = (prevState, action) => {
    switch (action.type) {
        case "TOGGLE_TRAFFIC_MODE":
            return {
                ...prevState,
                trafficMode: !prevState.trafficMode
            }

        case "TOGGLE_SATELLITE_MODE":
            return {
                ...prevState,
                satelliteMode: !prevState.satelliteMode
            }
        case "SET_CLUSTER_TASKS":
            return {
                ...prevState,
                clusterTasks: action.payload
            }
        case "SET_INFOWINDOW_POSITION":
            return {
                ...prevState,
                infoWindowPosition: action.payload
            }

        case "SHOW_INFOWINDOW":
            return {
                ...prevState,
                showInfoWindow: true
            }
        case "HIDE_INFOWINDOW":
            return {
                ...prevState,
                showInfoWindow: false
            }
        default:
            return prevState;
    }
};
