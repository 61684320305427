import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';

const CustomAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export default CustomAccordion;