import React, { createContext, useReducer } from 'react';
import { AuthReducer } from '../reducers/AuthReducer';

export const AuthContext = createContext(null);
const init = initialArg => {
    return {
        user: initialArg.user,
        _user: initialArg._user
    }
}

const AuthContextProvider = (props) => {
    const [auth, authDispatch] = useReducer(AuthReducer, {
        user: null,
        _user: null,
    }, init);

    return (
        <AuthContext.Provider value={{ auth, authDispatch }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
