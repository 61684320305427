import React, { useState,useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  Slide,
  FormControlLabel, Switch

} from '@mui/material';

import useGlobalContext from '../../../hooks/useGlobalContext';
import { updateSubcription, endTrialAndStartSubscription } from '../../../api/subscription';
import { deepCopy } from '../../../utils/utils';
import './ulli.css';

import CloseIcon from '@mui/icons-material/Close';
import Tracker from '../tracking/Usage';
import PanelInvoice from '../panels/PanelInvoice';
import CardList from '../cards/CardList';
import AssessmentIcon from '@mui/icons-material/Assessment'; // Icon for "Usage"
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Icon for "Cards"
import ReceiptIcon from '@mui/icons-material/Receipt'; // Icon for "Invoices"
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // Icon for "Billing"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BillingList() {
  const [activeTab, setActiveTab] = useState(0);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { _globalData, _globalDispatch, billingModalOpen } = useGlobalContext();
  const { plans, chosenCommunication: chosenItem, current_user: user = {} } = _globalData;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // State for confirmation modal
  const [selectedPlan, setSelectedPlan] = useState(null); // Track selected plan for update
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingItemId, setLoadingItemId] = useState(null); // Track loading per item

 





  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseBilling = () => {
    _globalDispatch({
      type: "CLOSE_BILLING_MODAL"
    });
  };




  const verticalTabs = (
    // <Tabs
    //   orientation="vertical"
    //   variant="scrollable"
    //   value={activeTab}
    //   onChange={handleTabChange}
    //   sx={{
    //     borderRight: 1,
    //     borderColor: 'divider',
    //     width: '200px',
    //     alignItems: 'center',
    //   }}
    // >
    //  <Tab label="Billing" icon={<AccountBalanceIcon />} iconPosition="start"  sx={{ justifyContent: 'flex-start' }} />
    //   <Tab label="Usage" icon={<AssessmentIcon />} iconPosition="start"  sx={{ justifyContent: 'flex-start' }} />
    //   <Tab label="Invoices" icon={<ReceiptIcon />} iconPosition="start"  sx={{ justifyContent: 'flex-start' }} />
    //   <Tab label="Cards" icon={<CreditCardIcon />} iconPosition="start"  sx={{ justifyContent: 'flex-start' }} />
    // </Tabs>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={activeTab}
      onChange={handleTabChange}
      sx={{
        borderRight: 1,
        borderColor: 'divider',
        width: '200px',
        alignItems: 'stretch', // Ensures consistent alignment without stretching beyond the container width
      }}
    >
      <Tab
        label="Billing"
        icon={<AccountBalanceIcon />}
        iconPosition="start"
        sx={{
          justifyContent: 'flex-start', // Aligns icon and label to the start
          textAlign: 'left', // Ensures text aligns with the icon
        }}
      />
      <Tab
        label="Usage"
        icon={<AssessmentIcon />}
        iconPosition="start"
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
        }}
      />
      <Tab
        label="Invoices"
        icon={<ReceiptIcon />}
        iconPosition="start"
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
        }}
      />
      <Tab
        label="Cards"
        icon={<CreditCardIcon />}
        iconPosition="start"
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
        }}
      />
    </Tabs>

  );

  const handleUpdateSubscription = (plan) => {
    setSelectedPlan(plan); // Set the selected plan
    setIsConfirmModalOpen(true); // Open the confirmation modal
  };

  const confirmUpdateSubscription = async () => {
    setLoadingItemId(selectedPlan._id); // Start loader for specific item
    try {
      const response = await updateSubcription({
        plan_id: selectedPlan._id,
        plan_interval: selectedPlan.getYearMonth(),
      });


      if (response?.error) {
        // Handle error (if needed)
      } else {
        const updatedUser = deepCopy(user);
        updatedUser.plan_details = response.user.plan_details;
        updatedUser.current_plan_details = response.user.plan_details;

        // Update user details in global context
        _globalDispatch({
          type: 'SET_CURRENT_USER',
          payload: updatedUser,
        });
        setIsConfirmModalOpen(false); // Close the confirmation modal

        // await reloadPlans(); // Refresh the billing list after updating the subscription
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingItemId(null); // Stop loader after update
    }
  };

  const handleEndTrial = async () => {
    try {
      console.log('user', user);
      const response = await endTrialAndStartSubscription({
        _id: user._id,
      });

      if (response?.error) {
        // Handle error (if needed)
      } else {
        const updatedUser = deepCopy(user);
        updatedUser.plan_details = response.user.plan_details;
        updatedUser.trialDaysRemaining = 0;
        updatedUser.current_plan_details = response.user.plan_details;

        // Update user details in global context
        _globalDispatch({
          type: 'SET_CURRENT_USER',
          payload: updatedUser,
        });
        setIsConfirmOpen(false);

        // await reloadPlans(); // Refresh the billing list after updating the subscription
      }


    } catch (error) {
      console.log(error);
    } finally {
      setLoadingItemId(null); // Stop loader after update
    }
  }

  const handleConfirmCloseTrial = () => {
    setIsConfirmOpen(false); // Close dialog without proceeding
  };

  const renderTabContent = () => {

    switch (activeTab) {
      case 0:
        return (
          <Box sx={{ flex: 1, overflow: 'auto',minWidth:'800px' }}>
            {/* <Typography variant="h4">Billing Content</Typography> */}
            {activeTab === 0 && (
              <div>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Billings {user.trialDaysRemaining > 0 && (
                      <span style={{ color: 'grey', fontSize: '16px', }}>({user.trialDaysRemaining} days remaining for trial)<button
                        style={{
                          backgroundColor: '#4F46E5', // Attractive primary color
                          color: 'white',
                          padding: '10px 20px',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          border: 'none',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
                          transition: 'background-color 0.3s, transform 0.2s', // Smooth transition
                        }}
                        onClick={() => setIsConfirmOpen(true)}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#3F3BE5'} // Darker shade on hover
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#4F46E5'} // Original color on mouse leave
                        onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.95)'} // Shrink on click
                        onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'} // Return to normal
                      >
                        End trial and start subscription now
                      </button>
                        <Dialog
                          open={isConfirmOpen}
                          onClose={handleConfirmCloseTrial}
                          aria-labelledby="confirm-dialog-title"
                          aria-describedby="confirm-dialog-description"
                        >
                          <DialogTitle id="confirm-dialog-title">Confirm Subscription</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="confirm-dialog-description">
                              Are you sure you want to end the trial period and start the subscription? This action cannot be undone.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleConfirmCloseTrial} color="secondary">Cancel</Button>
                            <Button onClick={handleEndTrial} color="primary" autoFocus>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </span>
                    )}
                  </Typography>
                </Box>
                {/* <List sx={{  bgcolor: 'background.paper', overflow: 'scroll', display: 'flex', flexDirection: 'row', padding: 0  }}>
                                {dataFiltered.map((item) => (
                                    <ListItem
                                        key={item._id}
                                        style={{
                                            border: '1px solid black',
                                            marginRight: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: user?.current_plan_details?._id === item._id ? '#4F46E5' : '',
                                            color: user?.plan_details?._id === item._id ? 'white' : '',
                                        }}
                                        className={classes.listItem}
                                    >
                                        <ListItemText
                                            primary={<p style={{ textAlign: 'center', fontSize: '20px', fontWeight: '800', textTransform: 'capitalize' }}>{item.getTitle()}</p>}
                                            secondary={<p className="feature-description" dangerouslySetInnerHTML={{ __html: item.getDescription() }}></p>}
                                        />
                                        {user?.current_plan_details?._id === item._id ? (
                                            <Button style={{ backgroundColor: 'white', color: '#655EE9' }}>Current Plan</Button>
                                           
                                        ) : (
                                            <Button
                                            style={{ backgroundColor: 'black', color: 'white', marginBottom: '10px' }}
                                            onClick={() => updateSubscription(item._id, item.getYearMonth())}
                                            disabled={loadingItemId === item._id} // Disable button if loading
                                        >
                                            {loadingItemId === item._id ? 'Updating...' : `Update $${item.getPlanPrice()} / ${item.getYearMonth()}`}
                                        </Button>
                                        )}
                                    </ListItem>
                                ))}
                            </List> */}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                 


                 <FormControlLabel
  control={
    <Switch
      checked={selectedYearMonth !== 'month'}
      onChange={(event) => {
        console.log('yearMonths[0]',yearMonths[0]);
        console.log('selectedYearMonth',selectedYearMonth);
        const newValue = selectedYearMonth === 'month' ? 'year' : 'month';
        setSelectedYearMonth(newValue);
      }}
      color="primary"
    />
  }
  label={selectedYearMonth === 'month' ? 'Month' : `${selectedYearMonth}`}
  sx={{
    marginBottom: '24px',
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '600px', 
    display: 'flex',
    justifyContent: 'center',
  }}
/>



                  <List
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '16px',
                      padding: 0,
                      justifyContent: 'center',
                      width: '100%',
                      maxWidth: '1200px',
                    }}
                  >
                    {dataFiltered.map((item) => (
                      <ListItem
                        key={item._id}
                        sx={{
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          padding: '16px',
                          width: '45%', // Set width to 30% to fit 3 items per row
                          minWidth: '200px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          backgroundColor:
                            user?.current_plan_details?._id === item._id ? '#4F46E5' : '#fff',
                          color:
                            user?.current_plan_details?._id === item._id ? 'white' : 'black',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: '800', textTransform: 'capitalize' }}>
                              {item.getTitle()}
                            </p>
                          }
                          secondary={
                            <div
                              style={{ textAlign: 'center', fontSize: '2px', marginTop: '8px' }}
                              className="feature-description" dangerouslySetInnerHTML={{ __html: item.getDescription() }}
                            ></div>
                          }
                        />
                        {user?.current_plan_details?._id === item._id ? (
                          <Button
                            sx={{
                              backgroundColor: 'white',
                              color: '#4F46E5',
                              marginTop: '16px',
                              width: '100%',
                              fontWeight: 'bold',
                              '&:hover': {
                                backgroundColor: '#e5e5ff',
                              },
                            }}
                          >
                            Current Plan
                          </Button>
                        ) : (
                          user.current_plan_details.plan_price < item.getPlanPrice() ? <Button
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              marginTop: '16px',
                              width: '100%',
                              fontWeight: 'bold',
                              '&:hover': {
                                backgroundColor: '#333',
                              },
                            }}
                            onClick={() => handleUpdateSubscription(item)}
                            disabled={loadingItemId === item._id} // Disable button if loading
                          >
                            {loadingItemId === item._id ? 'Updating...' : `Update $${item.getPlanPrice()} / ${item.getYearMonth()}`}
                          </Button> : <Button
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              marginTop: '16px',
                              width: '100%',
                              fontWeight: 'bold',
                              '&:hover': {
                                backgroundColor: '#333',
                              },
                            }}
                          >
                            Please contact Administrator {item.year_month}
                          </Button>
                        )}
                      </ListItem>
                    ))}
                  </List>
                  <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
                    <DialogTitle>Confirm Subscription Update</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to update your subscription to "{selectedPlan?.getTitle()}"? This action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setIsConfirmModalOpen(false)} color="secondary">Cancel</Button>
                      <Button onClick={confirmUpdateSubscription} color="primary" autoFocus>
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </div>
            )}
          </Box>
        );
      case 1:
        return (
          <Box sx={{ flex: 1, overflow: 'auto',minWidth:'800px' }}>
            {/* <Typography variant="h4">Usage Content</Typography> */}
            <Tracker />
          </Box>
        );
      case 2:
        return (
          <Box sx={{ flex: 1, overflow: 'auto',minWidth:'800px' }}>
            {/* <Typography variant="h4">Invoices Content</Typography> */}
            <PanelInvoice />
          </Box>
        );
      case 3:
        return (
          <Box sx={{ flex: 1, overflow: 'auto',minWidth:'800px' }}>
            {/* <Typography variant="h4">Cards Content</Typography> */}
            <CardList />
          </Box>
        );
      default:
        return null;
    }
  };

  const filterData = (query, data) => {
    if (!query) return data;
    return data.filter((d) => d.title.toLowerCase().includes(query.toLowerCase()));
  };

  // const dataFiltered = filterData(searchQuery, plans ? plans.getPlans() : []);

  const [selectedYearMonth, setSelectedYearMonth] = useState('year');

  // Handle toggle change
  const handleToggleChange = (event, newValue) => {
    console.log("Before toggle:", selectedYearMonth);  // Check current state
    setSelectedYearMonth(newValue);
    console.log("After toggle:", newValue);  // Check new state
  };

  const allFilteredData = filterData(searchQuery, plans ? plans.getPlans() : []);

  // Apply year_month filter
  const dataFiltered = selectedYearMonth === 'year'
    ? allFilteredData.filter((item) => item.getYearMonth() === 'year')
    : allFilteredData.filter((item) => item.getYearMonth() === 'month');


  // Get unique year_month values for the toggle
  const yearMonths = Array.from(new Set(allFilteredData.map((item) => item.getYearMonth())));


  return (
    <Dialog onClose={handleCloseBilling}
      TransitionComponent={Transition}
      open={billingModalOpen} maxWidth="lg"    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Billing
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleCloseBilling} aria-label="close">
            <CloseIcon />
          </IconButton>

        </Toolbar>

      </AppBar>
      <Box sx={{ display: 'flex', height: '100%' }}>
        {verticalTabs}
        <Box sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          width: '100%', // Ensure the box spans full width
          height: '500px', // Set a fixed height to prevent size changes
          border: '1px solid #ddd', // Optional border for separation
          borderRadius: '8px', // Match the design
          overflow: 'auto', // Handle overflow content gracefully
          backgroundColor: '#f9f9f9',
        }}>
          {renderTabContent()}
        </Box>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={isConfirmOpen} onClose={handleConfirmCloseTrial}>
        <DialogTitle>Confirm Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to end the trial period and start the subscription? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCloseTrial} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEndTrial} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
