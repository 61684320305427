import { isObjEmpty } from "../utils/utils";
import PlanEntity from "./PlanEntity";

class PlansListEntity {
    constructor(plans) {
        this.plans = plans.map(d => new PlanEntity(d))
    }

    getPlans() {
        return this.plans;
    }

    getTotalPlans() {
        return this.plans.length;
    }


    getPlanByTitle(title) {
        return this.plans.find(d => d.getTitle() === title)
    }

    getPlanById(id) {
        return this.plans.find(d => d.getId() === id)
    }

   

   
}

export default PlansListEntity;