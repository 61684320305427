import * as yup from "yup";
require("yup-phone");

const UserSettingValidation = yup.object().shape({
    current_password: yup.string().required("Current Password field is required."),
    password: yup.string().required("Current Password field is required."),
    confirm_password: yup.string()
        .required()
        .oneOf([yup.ref("password"), null], "Must match new password.")
});

export default UserSettingValidation;
