import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModalClose from '../common/ModalClose';
import OptimizeRouteForm from '../settings/tasks/OptimizeRouteForm';

export default function BasicTasksOptimizeModal({ data = {}, opened = false, handleClose }) {
	return (
		<Dialog
			open={opened}
			scroll="paper"
			fullWidth
		>
			<ModalClose handleClose={() => handleClose()} />
			<DialogTitle id="scroll-dialog-title">Quick Sort Route</DialogTitle>
			<DialogContent dividers={true}>
				<OptimizeRouteForm data={data} handleClose={handleClose} />
			</DialogContent>
		</Dialog>
	);
}