export const ExportReducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_CHOSEN_STATUS':
            return {
                ...prevState,
                taskStatus: action.payload
            };

        case 'SET_DATE_START':
            return {
                ...prevState,
                dateStart: action.payload
            };
        case 'SET_DATE_END':
            return {
                ...prevState,
                dateEnd: action.payload
            }
        case 'SET_CHOSEN_DRIVER':
            return {
                ...prevState,
                chosenDriver: action.payload,
            };
        case 'SET_REFRESH_COUNT':
            return {
                ...prevState,
                refreshCount: prevState.refreshCount + 1
            };
        default:
            return prevState;
    }
};
