import { useCallback, useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    CircularProgress,
    FormLabel,
    FormGroup,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Box,
    InputLabel,
    Select
} from '@mui/material';




import { Formik, Form } from "formik";


import useGlobalContext from '../../../hooks/useGlobalContext';

import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { routeOptimize } from '../../../api/maps';
import { deepCopy, insertStringAtIndex } from '../../../utils/utils';
import {

    ADDRESS_LONG_LABEL,
    ADDRESS_LONG_VALUE,
    ADDRESS_SHORT_LABEL,
    ADDRESS_SHORT_VALUE,
    DRIVER_NAME_LABEL,
    DRIVER_NAME_VALUE,
    DRIVER_PHONE_NUMBER_LABEL,
    DRIVER_PHONE_NUMBER_VALUE,
    ETA_LABEL,
    ETA_VALUE,
    LICENSE_PLATE_LABEL,
    LICENSE_PLATE_VALUE,
    ORGANIZATION_NAME_LABEL,
    ORGANIZATION_NAME_VALUE,
    RECIPIENT_FIRST_NAME_LABEL,
    RECIPIENT_FIRST_NAME_VALUE,
    RECIPIENT_LAST_NAME_LABEL,
    RECIPIENT_LAST_NAME_VALUE,
    RECIPIENT_NAME_LABEL,
    RECIPIENT_NAME_VALUE,
    SIGNATURE_URL_LABEL,
    SIGNATURE_URL_VALUE,
    TASK_ID_LABEL,
    TASK_ID_VALUE,
    TRACKING_LINK_LABEL,
    TRACKING_LINK_VALUE,
    VEHICLE_DESCRIPTION_LABEL,
    VEHICLE_DESCRIPTION_VALUE,
    VEHICLE_TYPE_LABEL,
    VEHICLE_TYPE_VALUE
} from '../../../constants/messageTags';
import { sendBulkMessage } from '../../../api/sms';
import { toast } from 'react-toastify';



const BulkMessageForm = ({ data, handleClose }) => {
    const [requesting, setRequesting] = useState(false)
    let initValues = {
        insertedTag: "",
        cursor_position: 0,
        message: ""
    }

    console.log("requesting", requesting)

    return (
        <Formik
            initialValues={initValues}
            onSubmit={async (values, { resetForm }) => {
                setRequesting(true);

                try {
                    const { driver, tasks } = data;
                    const payload = deepCopy(values);
                    payload.driver = driver;
                    payload.recipients = tasks;

                    const _res = await sendBulkMessage(payload);
                    // Simulate form submission (e.g., API call)
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    if (_res.success) {
                        toast.success("Success")
                    } else {
                        toast.error("Something went wrong. Please try again later.")
                    }
                    handleClose();
                    console.log("_res", _res)
                } catch (error) {
                    // Handle error
                    console.error('Form submission failed', error);
                    toast.error("Something went wrong. Please try again later.")
                } finally {
                    // Re-enable the submit button
                    setRequesting(false);
                }
            }}
        >
            {
                props => {
                    const {
                        values,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue,

                    } = props;

                    return (
                        <Form>

                            {/* Destination Details */}
                            <FormGroup sx={{ my: 2 }}>
                                <Grid container spacing={1} sx={{}}>
                                    {/* Insert Tag */}
                                    <Grid item xs={12}>
                                        <div>
                                            <FormControl sx={{ minWidth: "100%" }}>
                                                <InputLabel htmlFor="grouped-insert-tag">Insert Tag</InputLabel>
                                                <Select native
                                                    value={values.insertedTag}
                                                    label="Insert Tag"
                                                    onChange={(newVal) => {
                                                        // insertStringAtIndex
                                                        setFieldValue("message", insertStringAtIndex(values.message, values.cursor_position, newVal.target.value))
                                                        setFieldValue("insertedTag", "")
                                                    }}>
                                                    <option aria-label="None" value="" />
                                                    <optgroup label="Recipient">
                                                        <option value={RECIPIENT_FIRST_NAME_VALUE}>{RECIPIENT_FIRST_NAME_LABEL}</option>
                                                        <option value={RECIPIENT_LAST_NAME_VALUE}>{RECIPIENT_LAST_NAME_LABEL}</option>
                                                        <option value={RECIPIENT_NAME_VALUE}>{RECIPIENT_NAME_LABEL}</option>
                                                    </optgroup>
                                                    <optgroup label="Driver">
                                                        <option value={DRIVER_NAME_VALUE}>{DRIVER_NAME_LABEL}</option>
                                                        <option value={ETA_VALUE}>{ETA_LABEL}</option>
                                                        {/* <option value={VEHICLE_DESCRIPTION_VALUE}>{VEHICLE_DESCRIPTION_LABEL}</option> */}
                                                        <option value={VEHICLE_TYPE_VALUE}>{VEHICLE_TYPE_LABEL}</option>
                                                        <option value={LICENSE_PLATE_VALUE}>{LICENSE_PLATE_LABEL}</option>
                                                        <option value={DRIVER_PHONE_NUMBER_VALUE}>{DRIVER_PHONE_NUMBER_LABEL}</option>
                                                    </optgroup>

                                                    <optgroup label="Task">
                                                        <option value={TASK_ID_VALUE}>{TASK_ID_LABEL}</option>
                                                        <option value={ADDRESS_SHORT_VALUE}>{ADDRESS_SHORT_LABEL}</option>
                                                        <option value={ADDRESS_LONG_VALUE}>{ADDRESS_LONG_LABEL}</option>
                                                        <option value={TRACKING_LINK_VALUE}>{TRACKING_LINK_LABEL}</option>
                                                        {/* <option value={SIGNATURE_URL_VALUE}>{SIGNATURE_URL_LABEL}</option> */}
                                                    </optgroup>

                                                    <optgroup label="Organization">
                                                        <option value={ORGANIZATION_NAME_VALUE}>{ORGANIZATION_NAME_LABEL}</option>
                                                    </optgroup>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    {/* Message */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="grouped-insert-tag">Message</InputLabel>
                                        <textarea
                                            placeholder='Message'
                                            name='message'
                                            value={values.message}
                                            onChange={selector => {
                                                setFieldValue("message", selector.target.value)
                                                setFieldValue("cursor_position", selector.target.selectionStart)
                                            }}
                                            onClick={selector => {
                                                console.log("On Focus", selector.target.selectionStart)
                                                setFieldValue("cursor_position", selector.target.selectionStart)
                                            }}
                                            style={{
                                                minWidth: "100%",
                                                minHeight: "256px",
                                                fontFamily: 'IBM Plex Sans, sans-serif',
                                                fontSize: '0.875rem',
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                padding: '12px',
                                                borderRadius: '12px 12px 0 12px',
                                                color: "grey[900]",
                                                background: '#fff',
                                                border: "1px solid grey[200]",
                                                boxShadow: "0px 2px 2px grey[50]"
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </FormGroup>

                            {
                                isSubmitting && <Box style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%"
                                }}>
                                    <CircularProgress />
                                </Box>
                            }

                            <Box textAlign='center'>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={requesting}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Send
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
        </Formik>
    )
}

export default BulkMessageForm;
