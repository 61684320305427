import React, { createContext, useReducer } from 'react';
import { InvoiceReducer } from '../reducers/InvoiceReducer';

export const InvoiceContext = createContext(null);
const init = initialArg => {
    return {
        message: initialArg.message
    }
}

const InvoiceContextProvider = (props) => {
    const [_invoice, _invoice_dispatch] = useReducer(InvoiceReducer, {
        message: '',
    }, init);

    return (
        <InvoiceContext.Provider value={{ _invoice, _invoice_dispatch }}>
            {props.children}
        </InvoiceContext.Provider>
    );
};

export default InvoiceContextProvider;
