import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { GlobalContext } from '../../contexts/GlobalContext';
import './PaymentMethodForm.css'; // Create this CSS file for custom styles

const PaymentMethodForm = ({ onPaymentMethodCreated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { _globalData } = React.useContext(GlobalContext);

  const { current_user: user = {} } = _globalData;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log('Payment Method created successfully:', paymentMethod);

      // Send the payment method to your backend
      const response = await fetch('/api/stripe/addPaymentCard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          _id: user._id
        }),
      });

      const data = await response.json();

      if (response.ok) {
        onPaymentMethodCreated(paymentMethod);
      } else {
        setErrorMessage(data.error || 'Failed to save payment method');
      }
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="card-element-wrapper">
        <CardElement options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              letterSpacing: '0.025em',
              fontFamily: 'Source Code Pro, monospace',
              '::placeholder': {
                color: '#aab7c4',
              },
              padding: '10px'
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }} />
      </div>
      <button type="submit" className="payment-button" disabled={!stripe || isLoading}>
        {isLoading ? 'Processing...' : 'Add Payment Method'}
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </form>
  );
};

export default PaymentMethodForm;
