import React, { createContext, useReducer } from 'react';
import { COMPLETED_TASK_STATUS } from '../constants/task_status';
import { ExportReducer } from '../reducers/ExportReducer';
import { getToday } from '../utils/date';

export const ExportContext = createContext(null);
const init = initialArg => {
    return {
        taskStatus: initialArg.taskStatus,
        dateStart: initialArg.dateStart,
        dateEnd: initialArg.dateEnd,
        chosenDriver: initialArg.chosenDriver,
    }
}

const ExportContextProvider = (props) => {
    const today = getToday()
    const [_exportData, _exportDispatch] = useReducer(ExportReducer, {
        taskStatus: COMPLETED_TASK_STATUS,
        dateStart: today,
        dateEnd: today,
        chosenDriver: "all"
    }, init);

    return (
        <ExportContext.Provider value={{ _exportData, _exportDispatch }}>
            {props.children}
        </ExportContext.Provider>
    );
};

export default ExportContextProvider;
