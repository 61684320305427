import React, { createContext, useReducer } from 'react';
import { CommunicationsReducer } from '../reducers/CommunicationsReducer';

export const CommunicationsContext = createContext(null);
const init = initialArg => {
    return {
        message: initialArg.message
    }
}

const CommunicationsContextProvider = (props) => {
    const [_communications, _communications_dispatch] = useReducer(CommunicationsReducer, {
        message: '',
    }, init);

    return (
        <CommunicationsContext.Provider value={{ _communications, _communications_dispatch }}>
            {props.children}
        </CommunicationsContext.Provider>
    );
};

export default CommunicationsContextProvider;
