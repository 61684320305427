import { useState } from 'react';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';

// material
import {
    Typography,
    Container,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    Box,
    Card

} from '@mui/material';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { useParams, useSearchParams } from 'react-router-dom';
import { resetUserPassword } from '../api/user';
import { resetDriverPassword } from '../api/driver';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// sections
import { useTheme } from '@mui/styles';

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


export default function PasswordReset() {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [apiError, setAPIError] = useState('');
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

    const { user_id, token } = useParams();
    const [searchParams] = useSearchParams();

    const type = searchParams.get('type');

    let PasswordResetSchema = Yup.object({
        password: Yup.string().required('Password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: PasswordResetSchema,
        onSubmit: async (values, { setErrors, resetForm }) => {
            try {
                const payload = {
                    user_id: user_id,
                    token: token,
                    password: values.password
                };
                let _res = null;
                if (type === "users") {
                    _res = await resetUserPassword(payload);
                } else if (type === "drivers") {
                    _res = await resetDriverPassword(payload);
                }

                if (_res && _res.success) {
                    setIsUpdateSuccess(true);
                    toast("Password updated successfully");
                    resetForm();
                }
                else {
                    setAPIError(_res.msg)
                }

            } catch (err) {
                console.log("Err", err)
                toast("Something went wrong. Please try again.");
                setAPIError("Something went wrong. Please try again.")
            }

        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    // return null;

    return (
        <Page title="Password Reset" isAuthPage={true}>
            <RootStyle>
                <Container style={{
                    width: "500px"
                }} >
                    <Card style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card style={{ marginTop: 70, borderTopLeftRadius: 50, borderTopRightRadius: 50, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                            <ContentStyle>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="h4" gutterBottom align="center">
                                        Operation Track System
                                    </Typography>

                                    <Typography sx={{ color: 'text.secondary', mb: 5 }} align="center">
                                        Enter your new passwords here
                                    </Typography>
                                    <FormikProvider value={formik}>
                                        {
                                            isUpdateSuccess && <Stack spacing={3}>
                                                <Alert severity="success">User updated successfully.</Alert>
                                                <Box sx={{ paddingX: 2 }} />
                                            </Stack>
                                        }
                                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                            <Stack spacing={3}>
                                                <TextField
                                                    fullWidth
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Password"
                                                    autoComplete='off'
                                                    autoCapitalize='off'
                                                    {...getFieldProps('password')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                                    {
                                                                        showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                />

                                                <TextField
                                                    fullWidth
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Confirm password"
                                                    autoComplete='off'
                                                    autoCapitalize='off'
                                                    {...getFieldProps('confirm_password')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                                    {
                                                                        showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
                                                                    }
                                                                    {/* <Icon icon={showPassword ? getIcon('eva:eye-fill') : getIcon('eva:eye-off-fill')} /> */}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                                    helperText={touched.confirm_password && errors.confirm_password}
                                                />

                                                <Stack spacing={2} style={{ width: 200, alignSelf: 'center' }}>
                                                    {
                                                        apiError && <Alert severity="error">{apiError}</Alert>
                                                    }
                                                    <LoadingButton
                                                        fullWidth={true}
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={isSubmitting}
                                                    >
                                                        {"Reset"}
                                                    </LoadingButton>
                                                </Stack>
                                            </Stack>
                                        </Form>
                                    </FormikProvider>
                                </Box>
                            </ContentStyle>
                        </Card>
                    </Card>


                </Container>
            </RootStyle>
        </Page>
    );
}
