import DispatchersList from "../dispatchers/DispatchersList";
import AddFormDialog from "../dispatchers/AddFormDialog";

import useGlobalContext from "../../../hooks/useGlobalContext";

export default function PanelDispatchers() {
    const { chosenDispatcher } = useGlobalContext();

    return <>
     <AddFormDialog />
        <DispatchersList />
      
    </>
}