import axios from 'axios';

const client = axios.create({
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": true,
    }
});
const clientPost = axios.create({
    headers: {
        // "Content-type": "application/json",
       "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": true,
    }
});


/**
 *
 * @param route
 * @param payload
 * @returns {Promise<T>}
 */
export const getRequest = async (route, payload = {} ) => {
    return client.get(route, payload)
        .then(res => {
            return res.data
        })
        .catch(err => { return err.response.data })
}

/**
 *
 * @param route
 * @param payload
 * @returns {Promise<T>}
 */
export const postRequest = async ( route, payload = {} ) => {
    return client.post(route, payload)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log("err", err)
            return err.response.data;
         })
}
export const newpostRequest = async ( route, payload = {} ) => {
    return clientPost.post(route, payload)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log("err", err)
            return err.response.data;
         })
}

/**
 *
 * @param route
 * @param payload
 * @returns {Promise<T>}
 */
export const putRequest = async (route, payload = {} ) => {
    return client.put(route, payload)
        .then(res => {
            return res.data
        })
        .catch(err => { return err.response.data })
}

/**
 *
 * @param route
 * @param payload
 * @returns {Promise<T>}
 */
export const deleteRequest = async (route, payload = {} ) => {
    return client.delete(route, {data:payload})
        .then(res => {
            return res.data
        })
        .catch(err => { return err.response.data })
}

export default client;
