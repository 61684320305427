import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import useRouteOptimizeContext from '../../hooks/useRouteOptimizeContext';

export const options = [
    {
        value: 'anywhere',
        label: 'Anywhere',
    },
    {
        value: 'teams_hub',
        label: 'Teams hub',
    },
    {
        value: 'drivers_location',
        label: 'Drivers location',
    },
];

export default function EndAtInput() {
    const { _route_optimize_dispatch, end_at } = useRouteOptimizeContext();

    const handleSelect = (event) => {
        _route_optimize_dispatch({
            type: "SET_ENDING_AT",
            payload: event.target.value
        })
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="outlined-select-end-at"
                    select
                    label="End at"
                    defaultValue="anywhere"
                    helperText="Please select the ending point"
                    onChange={handleSelect}
                    value={end_at}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </Box>
    );
}
