import moment from "moment-timezone"

import { ASSIGNED_FILL_COLOR, COMPLETED_FILL_COLOR, DEFAULT_FILL_COLOR, EXCEPTION_FILL_COLOR, FAILED_FILL_COLOR, TRANSIT_FILL_COLOR } from "../constants/colors"
import { MOMENT_INPUT, MOMENT_OUTPUT_DISPLAY_TIME, getTimezone } from "../constants/moment-date-formats"
import { ASSIGNED_TASK_STATUS, COMPLETED_TASK_STATUS, INTRANSIT_TASK_STATUS, UNASSIGNED_TASK_STATUS } from "../constants/task_status"
import { getTaskStatusFromNumber } from "../utils/task_status"
import DriverEntity from "./DriverEntity"
import { MOMENT_TIME_OUTPUT_DB } from "../constants/moment-date-formats"
const timezone = getTimezone();

class TaskEntity {
    constructor({
        _id,
        id, // for count
        ref,
        recipient_phone,
        recipient_name,
        recipient_email,
        recipient_note,
        task_type,
        task_details,
        destination_address,
        destination_city,
        destination_address_latlng,
        destination_address_2,
        destination_note,
        driver,
        task_status,
        delivery_date,
        complete_before,
        complete_after,
        sort_number,
        start_time,
        completed_time,
        distance,
        duration,
        file_url,
        handed_to_customer,
        delivery_day_match,
        failure_note,
        failure_reason,
        order_id = null,
        created_at,
        updated_at,
        origin_address_latlng,
        start_address_latlng,
        complete_address_latlng,
        overnight = false,
        distance_matrix = null,
        eta = null,
        completion_note = null,
        hub = null,
        tags = [],
        signature_img = ""
    }) {
        this._id = _id
        this.id = id
        this.ref = ref
        this.order_id = order_id
        this.recipient_phone = recipient_phone
        this.recipient_name = recipient_name
        this.recipient_email = recipient_email
        this.recipient_note = recipient_note
        this.task_type = task_type
        this.task_details = task_details
        this.destination_address = destination_address
        this.destination_city = destination_city
        this.destination_address_latlng = destination_address_latlng
        this.destination_address_2 = destination_address_2
        this.destination_note = destination_note
        this.driver = new DriverEntity(driver || {})
        this.task_status = task_status
        this.delivery_date = delivery_date
        this.complete_before = complete_before
        this.complete_after = complete_after
        this.sort_number = sort_number
        this.start_time = start_time
        this.completed_time = completed_time
        this.distance = distance
        this.duration = duration
        this.file_url = file_url
        this.handed_to_customer = handed_to_customer
        this.delivery_day_match = delivery_day_match
        this.created_at = created_at
        this.updated_at = updated_at
        this.failure_note = failure_note
        this.failure_reason = failure_reason

        this.origin_address_latlng = origin_address_latlng
        this.start_address_latlng = start_address_latlng
        this.complete_address_latlng = complete_address_latlng

        this.overnight = overnight
        this.distance_matrix = distance_matrix

        this.eta = eta
        this.completion_note = completion_note
        this.hub = hub
        this.tags = tags
        this.signature_img = signature_img
    }

    getId() {
        return this._id;
    }

    getOrderId() {
        return this.order_id;
    }

    getIndex() {
        return this.id;
    }

    getRecipientPhone() {
        return this.recipient_phone || ""
    }

    getRecipientName() {
        return this.recipient_name || ""
    }

    getRecipientEmail() {
        return this.recipient_email || ""
    }

    getRecipientNote() {
        return (this.recipient_note || "").replaceAll(",", ";").replaceAll('"', "'")
    }

    getCompletionNote() {
        return (this.completion_note || "").replaceAll(",", ";").replaceAll('"', "'")
    }

    getTaskType() {
        return this.task_type || "pickup"
    }

    getTaskDetails() {
        return (this.task_details || "").replaceAll(",", ";").replaceAll('"', "'")
    }

    getDestinationAddress() {
        if (!this.destination_address) {
            return null
        }

        let line1 = this.destination_address.split(',').shift();
        let line2 = this.destination_address.split(',').slice(1).join(',').trim();

        if (this.destination_address_2) {
            line1 = `${line1}, ${this.destination_address_2}`
        }

        return `${line1}, ${line2}`
    }

    getDestinationAddressLatLng() {
        if (!this.destination_address_latlng) {
            return '';
        }

        return `${this.destination_address_latlng.lat},${this.destination_address_latlng.lng}`
    }

    getDestinationAddress2() {
        return this.destination_address_2 || ""
    }

    getDestinationNote() {
        return (this.destination_note || "").replaceAll(",", ";").replaceAll('"', "'")
    }

    getDriver() {
        if (!this.driver) {
            return null
        }
        if (!this.driver.getId()) {
            return null
        }
        return this.driver
    }

    getDriverName() {
        const driver = this.getDriver();
        if (!driver) {
            return 'unassigned'
        }

        return driver.getName()
    }

    getDriverPhone() {
        const driver = this.getDriver();
        if (!driver) {
            return ''
        }

        return driver.getPhone()
    }

    getDeliveryDate() {
        return this.delivery_date
    }

    getDeliveryCompleteTime() {
        return moment(this.completed_time).format(MOMENT_OUTPUT_DISPLAY_TIME)
    }


    isValidAddress() {
        const validCheck = this.destination_address_latlng !== null
            && typeof this.destination_address_latlng === "object"
            && this.destination_address_latlng.lat
            && this.destination_address_latlng.lng;

        // if delivery date dont match completed date
        const deliveryCheck = moment(this.delivery_date).isSame(this.completed_time, 'day');
        if (!this.completed_time) {
            return validCheck;
        }

        return validCheck && deliveryCheck;
    }

    getLatLng() {
        return this.destination_address_latlng || {}
    }

    getAddress() {
        return this.destination_address || ""
    }

    getDestinationCity() {
        if (this.destination_city) {
            return this.destination_city
        }

        let splited = this.getAddress().split(",");
        if (splited.length < 2) {
            return ''
        }

        return splited[1].trim()

    }

    getAddressShort() {

        let splited = this.getAddress().split(",");
        if (splited.length < 1) {
            return ''
        }
        if (this.destination_address_2) {
            return `${splited[0].trim()}, ${this.destination_address_2}`;
        }
        return splited[0].trim();
    }

    getStatus() {
        return this.task_status;
    }

    getStatusLabel() {
        return getTaskStatusFromNumber(this.getStatus())
    }

    getMarkerColor() {
        let fillColor = null;
        switch (this.getStatus()) {
            case -1:
                fillColor = FAILED_FILL_COLOR;
                break;
            case 1:
                fillColor = ASSIGNED_FILL_COLOR;
                break;
            case 1.5:
                fillColor = EXCEPTION_FILL_COLOR;
                break;
            case 2:
                fillColor = TRANSIT_FILL_COLOR
                break;
            case 3:
                fillColor = COMPLETED_FILL_COLOR
                break;
            case 0:
            default:
                fillColor = DEFAULT_FILL_COLOR
                break;
        }

        return fillColor;

    }

    isUnAssigned() {
        return this.getStatus() === UNASSIGNED_TASK_STATUS && !this.driver.getId()
    }

    isAssignedOnly() {
        return this.getStatus() === ASSIGNED_TASK_STATUS;
    }

    isCompleted() {
        return this.getStatus() === COMPLETED_TASK_STATUS;
    }

    isAssignedOrTransit() {
        return this.task_status < COMPLETED_TASK_STATUS;
    }

    isAssignedOrTransitOrCompleted() {
        return this.task_status > UNASSIGNED_TASK_STATUS && this.task_status <= COMPLETED_TASK_STATUS;
    }

    // In Miles
    getDistance() {
        if (!this.distance) {
            return '';
        }

        return (parseFloat(this.distance) * 0.621371).toFixed(2);
    }

    // In KMs
    getDistanceKM() {
        if (!this.distance) {
            return '';
        }

        return (parseFloat(this.distance)).toFixed(2);
    }

    getDuration() {
        return this.duration;
    }

    getStartTime() {
        if (!this.start_time && !this.completed_time && !this.duration && !this.eta) {
            return '';
        }

        if (!this.start_time && this.completed_time && !this.duration && !this.eta) {
            return '';
        }

        if (!this.start_time && this.completed_time && this.duration) {
            return moment(this.completed_time).subtract(this.duration, "minutes").format(MOMENT_OUTPUT_DISPLAY_TIME)
        }

        if (!this.start_time && this.completed_time && this.eta) {
            return moment(this.completed_time).subtract(this.eta.value, "seconds").format(MOMENT_OUTPUT_DISPLAY_TIME)
        }

        return moment(this.start_time).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getCompletedTime() {
        if (!this.completed_time && !this.file_url) {
            return '';
        }

        if (!this.completed_time && this.file_url && this.duration) {
            return moment(this.completed_time).add(this.duration, "minutes").format(MOMENT_OUTPUT_DISPLAY_TIME)
        }

        return moment(this.completed_time).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getCompleteBefore() {
        if (!this.complete_before) {
            return '';
        }
        return moment(this.complete_before).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getCompleteAfter() {
        if (!this.complete_after) {
            return '';
        }
        return moment(this.complete_after).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getPayload() {
        return {
            _id: this._id,
            order_id: this.order_id,
            recipient_phone: this.recipient_phone,
            recipient_name: this.recipient_name,
            recipient_note: this.recipient_note,
            task_type: this.task_type,
            task_details: this.task_details,
            destination_address: this.destination_address,
            destination_address_latlng: this.destination_address_latlng,
            destination_address_2: this.destination_address_2,
            destination_note: this.destination_note,
            driver: this.driver,
            task_status: this.task_status,
            delivery_date: this.delivery_date,
        }

    }

    getClonePayload() {
        return {
            order_id: this.order_id,
            recipient_phone: this.recipient_phone,
            recipient_name: this.recipient_name,
            recipient_note: this.recipient_note,
            task_type: this.task_type,
            task_details: this.task_details,
            destination_address: this.destination_address,
            destination_address_latlng: this.destination_address_latlng,
            destination_address_2: this.destination_address_2,
            destination_note: this.destination_note,
            driver: this.driver,
            task_status: this.task_status,
            delivery_date: this.delivery_date,
            sort_number: this.sort_number + 0.05
        }

    }

    getFileURL() {
        if (this.handed_to_customer && !this.file_url) {
            return 'Handed to customer'
        }

        return this.file_url;
    }

    getFillColor() {
        switch (this.getStatus()) {
            case ASSIGNED_TASK_STATUS:
                return ASSIGNED_FILL_COLOR;
            case INTRANSIT_TASK_STATUS:
                return TRANSIT_FILL_COLOR;
            case COMPLETED_TASK_STATUS:
                return COMPLETED_FILL_COLOR;
            case UNASSIGNED_TASK_STATUS:
            default:
                return DEFAULT_FILL_COLOR
        }
    }

    getMarkerLabel() {
        return `${this.getFillColor()}, ${this.getAddressShort()}, ${this.getRecipientName()}, ${this.getDriverName()}, ${this.getId()}`
    }

    getCreatedTime() {
        return moment(this.created_at).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getUpdatedTime() {
        return moment(this.updated_at).tz(timezone).format(MOMENT_OUTPUT_DISPLAY_TIME);
    }

    getCompleteBefore() {

        return this.complete_before || null
    }

    getCompleteAfter() {
        return this.complete_after || null
    }

    getTimeWindowText() {

        if (this.complete_before && this.complete_after) {
            return ` ${moment(this.complete_before, MOMENT_INPUT).tz(timezone).calendar(null, {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: '[last] dddd',
                nextWeek: 'dddd',
                sameElse: 'L'
            })} | ${moment(this.complete_after, MOMENT_INPUT).tz(timezone).format(MOMENT_TIME_OUTPUT_DB)} - ${moment(this.complete_before, MOMENT_INPUT).tz(timezone).format(MOMENT_TIME_OUTPUT_DB)}`
        } else if (this.complete_after && !this.complete_before) {
            return `After ${moment(this.complete_after).calendar()}`
        } else if (!this.complete_after && this.complete_before) {
            return `Before ${moment(this.complete_before).calendar()}`
        }


        return null;
    }

    isTransit() {
        return this.task_status === INTRANSIT_TASK_STATUS
    }

    getFailureNote() {
        return this.failure_note || ''
    }

    getFailureReason() {
        return this.failure_reason || '';
    }

    getOriginAddressLatLng() {
        if (!this.origin_address_latlng) {
            return '';
        }

        return `${this.origin_address_latlng.lat},${this.origin_address_latlng.lng}`
    }

    getStartAddressLatLng() {
        if (!this.start_address_latlng) {
            return '';
        }

        return `${this.start_address_latlng.lat},${this.start_address_latlng.lng}`
    }

    getCompleteAddressLatLng() {
        if (!this.complete_address_latlng) {
            return '';
        }

        return `${this.complete_address_latlng.lat},${this.complete_address_latlng.lng}`
    }

    getEtaTime() {
        if (this.isCompleted()) {
            return '';
        }

        if (!this.distance_matrix) {
            return '';
        }

        return this.distance_matrix.eta_time;
    }

    getHub() {
        return this.hub
    }

}

export default TaskEntity;