import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Formik, Form } from "formik";
import { Box } from '@material-ui/core';

import { CircularProgress, FormLabel } from '@mui/material';

import CountrySelect from './CountrySelect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TimezoneSelect from './TimezoneSelect';
import { updateUserOrganizationSetting } from '../../../api/user';
import { toast } from 'react-toastify';
import { Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { _setTimezoneLocalStorage, deepCopy } from '../../../utils/utils';
import { getCountryByTimezone, getLocalTimeZone } from '../../../constants/moment-date-formats';
import { getCountryCallingCode } from "libphonenumber-js";

export default function OrganizationForm() {
    const [autocomplete, setAutoComplete] = useState(undefined);
    const { current_user: user = {}, _globalDispatch } = useGlobalContext();
    const { organization_settings = {}, _id } = user

    const onLoad = useCallback((auto) => {
        setAutoComplete(auto)
    }, []);

    const country = getCountryByTimezone(getLocalTimeZone());

    console.log("user from L36", organization_settings)
    console.log("organization_settings", organization_settings)

    return (
        <div>
            <Formik
                initialValues={{
                    organization_name: organization_settings.organization_name || "",
                    sms_reply_email: organization_settings.sms_reply_email || organization_settings.driver_support_email || "",
                    organization_email: organization_settings.organization_email || "",
                    driver_support_email: organization_settings.driver_support_email || "",
                    country: organization_settings.country || {
                        code: country.id,
                        label: country.name,
                        phone: getCountryCallingCode(country.id)
                    },
                    timezone: organization_settings.timezone || getLocalTimeZone(),
                    hq_address: organization_settings.hq_address || "",
                    hq_address_lat_lng: organization_settings.hq_address_lat_lng || {},
                    // team_assignment: organization_settings.team_assignment || false,
                    // show_localtimes: organization_settings.show_localtimes || false,
                    // restrict_auto_assignment: organization_settings.restrict_auto_assignment || false,
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const payload = {
                        organization_settings: { ...values },
                        _id: _id
                    }

                    // console.log("payload", payload)
                    const _res = await updateUserOrganizationSetting(payload);
                    if (_res.success) {
                        const _updated_user = deepCopy(user);
                        _updated_user.organization_settings = payload.organization_settings;

                        _setTimezoneLocalStorage(_updated_user)

                        _globalDispatch({
                            type: "SET_CURRENT_USER",
                            payload: _updated_user
                        });

                        toast.success("Settings updated successfully.")
                    }

                    setSubmitting(false);
                }}
            >
                {
                    props => {
                        const {
                            values,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        } = props;

                        return (
                            <Form>
                                {/* Organization Name */}
                                <TextField
                                    label="Organization Name"
                                    name="organization_name"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.organization_name}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.organization_name && props.touched.organization_name}
                                />

                                {/* Organization Email */}
                                <TextField
                                    label="Organization Email"
                                    name="organization_email"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.organization_email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.organization_email && props.touched.organization_email}
                                />

                                {/* Message reply email */}
                                <TextField
                                    label="SMS reply email"
                                    name="sms_reply_email"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.sms_reply_email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.sms_reply_email && props.touched.sms_reply_email}
                                />

                                {/* Driver Support Email */}
                                <TextField
                                    label="Driver Support Email"
                                    name="driver_support_email"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    value={values.driver_support_email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.driver_support_email && props.touched.driver_support_email}
                                />
                                <Box mt={1}>
                                    <CountrySelect
                                        onSelect={val => {
                                            console.log("selected country", val)
                                            setFieldValue("country", val)
                                            setFieldValue("timezone", null)
                                        }}
                                        value={values.country}
                                    />
                                </Box>

                                <Box mt={2}>
                                    <TimezoneSelect
                                        onSelect={val => {
                                            console.log("selected timezone", val)
                                            setFieldValue("timezone", val)
                                        }}
                                        country={values.country}
                                        value={values.timezone}
                                    />
                                </Box>

                                <AddressAutoComplete
                                    onLoad={onLoad}
                                    onPlaceChanged={async () => {
                                        // console.log("autocomplete", autocomplete)
                                        const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                        setFieldValue("hq_address", autocomplete.getPlace().formatted_address)

                                        setFieldValue("hq_address_lat_lng", addressPayload.geometry_location)
                                        await addUpdateAddress(addressPayload)
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        label="HQ Address"
                                        name="hq_address"
                                        variant="outlined"
                                        margin="normal"
                                        value={values.hq_address}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.errors.hq_address && props.touched.hq_address}

                                    />
                                </AddressAutoComplete>

                                {/* <Box mt={2}>
                                    <FormGroup>
                                        <FormLabel component="legend">Advance Features</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.team_assignment} onChange={(event, val) => {
                                                console.log("on check", event, val)
                                                setFieldValue("team_assignment", val)
                                            }} />}
                                            label={<Box component={'div'} fontSize={12}>Enable team assignment</Box>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={values.show_localtimes} onChange={(event, val) => {
                                                console.log("on check", event, val)
                                                setFieldValue("show_localtimes", val)
                                            }} />}
                                            label={<Box component={'div'} fontSize={12}>Show local times based on location</Box>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={values.restrict_auto_assignment} onChange={(event, val) => {
                                                console.log("on check", event, val)
                                                setFieldValue("restrict_auto_assignment", val)
                                            }} />}
                                            label={<Box component={'div'} fontSize={12}>Restrict dashboard auto assignment to assiged teams</Box>}
                                        />
                                    </FormGroup>
                                </Box> */}
                                {/* 
                                <Box mt={2}>
                                    <FormGroup>
                                        <FormLabel component="legend">Social Single Sign On</FormLabel>
                                        <Switch checked={values.social_login} onChange={(event, val) => {
                                            setFieldValue("social_login", val)
                                        }} />
                                    </FormGroup>
                                </Box>
                                 */}

                                {
                                    isSubmitting && <Box style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%"
                                    }}>
                                        <CircularProgress />
                                    </Box>
                                }

                                <Box textAlign='center' marginBottom={"10px"}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
            </Formik>
        </div >
    );
}
