import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COMPLETED_TASK_STATUS, ASSIGNED_TASK_STATUS, UNASSIGNED_TASK_STATUS } from '../../constants/task_status';
import useExportContext from '../../hooks/useExportContext';

export default function TaskSelect() {
    const { _exportDispatch, taskStatus } = useExportContext();
    const handleChange = (event) => {
        _exportDispatch({
            type: "SET_CHOSEN_STATUS",
            payload: event.target.value
        })
    };

    return (
        <Box sx={{ minWidth: 120 }} my={2}>
            <FormControl fullWidth>
                <InputLabel>Task Status</InputLabel>
                <Select
                    value={taskStatus}
                    label="Task Status"
                    onChange={handleChange}
                >
                    <MenuItem value={COMPLETED_TASK_STATUS}>Completed</MenuItem>
                    <MenuItem value={ASSIGNED_TASK_STATUS}>Assigned</MenuItem>
                    <MenuItem value={UNASSIGNED_TASK_STATUS}>Unassigned</MenuItem>
                    <MenuItem value={'ALL'}>All Tasks</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}