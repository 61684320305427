import { Box, Button } from '@mui/material'
import React from 'react'
import useMapContext from '../../hooks/useMapContext';

export default function TrafficLayerOption() {
    const { trafficMode, _mapDispatch } = useMapContext();
    return (
        <Box style={{
            // position: "absolute",
            // top: 80,
            // right: "110px",
            // zIndex: 1,

        }} paddingY={1}>
            <Button variant='contained'
                onClick={() => {
                    _mapDispatch({
                        type: "TOGGLE_TRAFFIC_MODE"
                    })
                }}
                sx={{
                    color: "white",
                    backgroundColor: trafficMode ? "blue" : "grey",
                    borderRadius: 0
                }}>
                Traffic
            </Button>
        </Box>
    )
}
