// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Scrollbar styles */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1!important;
  }

  /* Webkit browsers */
  *::-webkit-scrollbar {
    width: 8px!important;
  }

  *::-webkit-scrollbar-track {
    background: #f1f1f1!important;
    display:none;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #888!important;
    border-radius: 10px!important;
    border: 2px solid #f1f1f1!important;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #555!important;
  }
  
`;

export default GlobalStyles;
