import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

import { Formik, Form } from "formik";
import { Box } from '@material-ui/core';
// import HubValidation from '../../../utils/form/validations/HubValidation';

import { CircularProgress, Grid, Typography } from '@mui/material';
import ModalClose from '../../common/ModalClose';

import { addHub } from '../../../api/hub';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { deepCopy } from '../../../utils/utils';
import { refreshToken } from '../../../api/auth';
import { useJsApiLoader, Autocomplete as AddressAutoComplete } from '@react-google-maps/api';
import { mapInitOptions } from '../../../constants/map';
import { getAddressPayloadFromGoogleAddress } from '../../../utils/addressUtil';
import { addUpdateAddress } from '../../../api/address';
import { toast } from 'react-toastify';

export default function AddFormDialog() {
    const [open, setOpen] = useState(false);
    const { _globalDispatch, _globalData, hubs, teams } = useGlobalContext();
    const { current_user: user = {} } = _globalData;

    const { isLoaded } = useJsApiLoader(mapInitOptions);
    const [autocomplete, setAutoComplete] = useState(undefined);

    const onLoad = useCallback((auto) => {
        setAutoComplete(auto)
    }, []);

   
    const handleClose = (event, reason) => {
        // Prevent closing the dialog if the reason is "backdropClick"
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      };

    async function handleClickOpen() {
        const _res = await refreshToken();

        if (_res.success) {
            const { user = {} } = _res;

            const { plan_details = {} } = user;

            const { no_of_hubs = 0 } = plan_details;

            if (no_of_hubs > hubs.getTotalHubs()) {
                console.log("hub form 55")
                setOpen(true);
            }
            else {
                _globalDispatch({
                    type: "OPEN_BILLING_MODAL",
                })
                console.log("Add option to upgrade plan hub 60 line")
            }

            _globalDispatch({
                type: "SET_CURRENT_USER",
                payload: user
            })
        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h6">
                    Hubs ({hubs.getTotalHubs()}/{user.plan_details.no_of_hubs ?? 0})
                </Typography>
                <Button variant="contained" onClick={handleClickOpen} sx={{ float: 'right' }}>
                    <AddIcon />
                    Add New Hub
                </Button>
            </Box>
            {/* <Button variant="outlined" onClick={handleClickOpen} style={{ margin: 4 }}>
                <AddIcon />
            </Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>New hub</DialogTitle>
                {/* <DialogContentText>Edit hub's details below</DialogContentText> */}
                <DialogContent>
                    <Formik
                        initialValues={{
                            hub_name: "",
                            full_address: "",
                            full_address_latlng: "",
                            teams: []
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const payload = deepCopy(values);

                            const _res = await addHub(payload);

                            // console.log("_res", _res)
                            if (_res.success) {
                                resetForm();

                                hubs.addHubToList(_res.data)
                                _globalDispatch({
                                    type: "SET_CHOSEN_HUB",
                                    payload: _res.data
                                });

                                setOpen(false);
                                _globalDispatch({
                                    type: "INCREASE_REFRESH_COUNTER"
                                });

                                toast.success("Hub added successfully.");
                            } else {
                                console.log("Err", _res.msg)
                                toast.error(_res.msg)
                            }
                            setSubmitting(false);

                        }}
                    // validationSchema={HubValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        {/* Name */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Hub Name"
                                                    name="hub_name"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.hub_name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.hub_name && props.touched.hub_name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    isLoaded && <AddressAutoComplete
                                                        onLoad={onLoad}
                                                        onPlaceChanged={() => {

                                                            const addressPayload = getAddressPayloadFromGoogleAddress(autocomplete);

                                                            setFieldValue("full_address", autocomplete.getPlace().formatted_address)

                                                            setFieldValue("full_address_latlng", addressPayload.geometry_location);

                                                            addUpdateAddress(addressPayload);
                                                        }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Full Address"
                                                            name="full_address"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={values.full_address}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.full_address && props.touched.full_address}

                                                        />
                                                    </AddressAutoComplete>
                                                }
                                            </Grid>

                                            <Grid item xs={12}>

                                                {
                                                    isSubmitting && <Box style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%"
                                                    }}>
                                                        <CircularProgress />
                                                    </Box>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box textAlign='center'>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        Create
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}
