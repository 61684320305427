import Grid from '@mui/material/Grid';
import Page from '../components/Page';
import 'react-toastify/dist/ReactToastify.css';

import TrafficLayerOption from '../components/map/TrafficLayerOption';
import MapContextProvider from '../contexts/MapContext';

import GoogleMapDriversLocation from '../components/map/GoogleMapDriversLocation';

const DashboardWrap = () => {
	return (
		<Grid>
			<MapContextProvider>
				<GoogleMapDriversLocation />
				<TrafficLayerOption />
			</MapContextProvider>
		</Grid>
	)
}

export default function DriversPublicLocation() {
	return (
		<Page
			title="Dashboard"
			public_mode={true}
		>
			<DashboardWrap />
		</Page>
	);
}
