import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 *
 * @param {*} data
 */
export const updateSubcription = async (data) => {
    return postRequest('/api/stripe/updateSubscription', data);
}
export const endTrialAndStartSubscription = async (data) => {
    return postRequest('/api/stripe/endTrialAndStartSubscription', data);
}
