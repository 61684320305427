class OnGoingTasksEntity {
    constructor(ongoingTasks = []) {
        this.ongoingTasks = ongoingTasks
    }

    getDriversLocations(online_drivers = []) {
        console.log("online_drivers", online_drivers)
        let newOnlineDrivers = []
        for (let d of online_drivers) {
            newOnlineDrivers.push({
                _id: null,
                current_location: d.current_location,
                driver_id: d._id,
                driver_name: d.name,
                destination_position: null,
                teams: d.teams
            })
        }

        return [...newOnlineDrivers];
    }

}

export default OnGoingTasksEntity;