import { deleteRequest, getRequest, postRequest, putRequest } from './client';

/**
 *
 * @returns {Promise<*>}
 */
export const getTeams = async () => {
    return postRequest('/api/teams/all', {});
}

/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const getTeamDetails = async (id) => {
    return getRequest(`/api/teams/detail/${id}`, {});
}

/**
 *
 * @param _ids
 * @returns {Promise<*>}
 */
export const deleteTeams = async (_ids) => {
    return deleteRequest('/api/teams/delete', { _ids });
}

export const deleteTeam = async (id) => {
    return deleteRequest(`/api/teams/delete/${id}`);
}

/**
 *
 * @param {*} Team
 */
export const addTeam = async (data) => {
    return postRequest('/api/teams/add', data);
}

/**
 *
 * @param id
 * @param Team
 * @returns {Promise<*>}
 */
export const updateTeam = async (id, data) => {
    return putRequest(`/api/teams/update/${id}`, data);
}
