import { useContext } from "react"
import { AuthContext } from "../contexts/AuthContext";
import { logoutRequest } from '../api/auth';
import { useNavigate } from "react-router-dom";
import socketIOClient from 'socket.io-client';
const socket = socketIOClient({
    reconnection: true,              // Enable reconnection
    reconnectionDelay: 1000,          // Start reconnection after 1 second
    reconnectionDelayMax: 5000,       // Maximum reconnection delay
    timeout: 20000,                   // Set connection timeout
});

export const useAuthHelper = () => {

    const { auth, authDispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const isLoggedIn = () => {
        const _otd_conn = localStorage.getItem("_otd_conn");
        return _otd_conn === "1";
    }

    const handleLogin = (user) => {
        socket.emit("join", user._id)
        localStorage.setItem('_otd_conn', "1")

        authDispatch({
            type: 'SIGN_IN',
            payload: user
        });
    }

    const handleLogout = async () => {
        try {
            await logoutRequest();
            socket.disconnect();
            localStorage.removeItem("_otd_conn")
            authDispatch({
                type: 'SIGN_OUT',
            });

            navigate("/login", { replace: true, state: { history: "dashboard" } })

        } catch (err) {
            console.log("Err", err)
        }

    }

    const getAuth = () => {
        return auth;
    }

    return {
        auth,
        authDispatch,
        isLoggedIn,
        handleLogin,
        handleLogout,
        getAuth
    }
}



