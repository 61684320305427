import DispatcherEntity from "./DispatcherEntity";

class DispatchersListEntity {
    constructor(dispatchers) {
        this.dispatchers = dispatchers.map(d => new DispatcherEntity(d))
    }

    getDispatchers() {
        return this.dispatchers;
    }

    getTotalDispatchers() {
        return (this.dispatchers || []).length
    }

    updateSelectedDispatchers(dispatcher) {
        this.dispatchers = this.dispatchers.map(d => {
            const dEntity = new DispatcherEntity(dispatcher)
            if (d.getId() === dEntity.getId()) {
                d = dEntity
            }

            return d;
        })
    }

    addDispatcherToList(dispatcher) {
        // console.log("dispatcher", dispatcher)
        this.dispatchers = [...this.dispatchers, new DispatcherEntity(dispatcher)];
        // console.log("this.dispatchers", this.dispatchers)
    }

    removeDispatcherFromList(dispatcher) {
        this.dispatchers = this.dispatchers.filter(d => d.getId() !== dispatcher._id)
    }

    getDispatcherByName(name) {
        return this.dispatchers.find(d => d.getName() === name)
    }

    getDispatcherById(id) {
        return this.dispatchers.find(d => d.getId() === id)
    }
}

export default DispatchersListEntity;