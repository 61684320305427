import moment from "moment-timezone"
import { getTimezone } from "../constants/moment-date-formats"
import { isObjEmpty } from "../utils/utils"
const timezone = getTimezone();
class DriverEntity {
    constructor(details = {}) {
        this._id = details._id
        this.name = details.name
        this.driver_image = details.driver_image
        this.phone = details.phone
        this.address = details.address
        this.address_latlng = details.address_latlng
        this.email = details.email
        this.vehicle_type = details.vehicle_type
        this.vehicle_model = details.vehicle_model
        this.vehicle_capacity = details.vehicle_capacity
        this.vehicle_capacity_type = details.vehicle_capacity_type
        this.vehicle_license_plate = details.vehicle_license_plate
        this.vehicle_color = details.vehicle_color
        this.online_status = details.online_status
        this.current_location = details.current_location
        this.last_active = details.last_active
        this.cost_per_hour = details.cost_per_hour
        this.cost_per_km = details.cost_per_km
        this.timewindow = details.timewindow
        this.teams = details.teams
    }

    getId() {
        return this._id;
    }

    getName() {
        return this.name;
    }

    getPhone() {
        return this.phone;
    }
    getDriverImage() {
        return this.driver_image;
    }

    getPayload() {
        return {
            _id: this._id,
            name: this.name,
            driver_image: this.driver_image,
            phone: this.phone,
            email: this.email,
            vehicle_type: this.vehicle_type,
            vehicle_model: this.vehicle_model,
            vehicle_license_plate: this.vehicle_license_plate,
            vehicle_color: this.vehicle_color
        }

    }

    isOnline() {
        if (!this.last_active) {
            return false;
        }

        if (!this.current_location) {
            return false;
        }

        if (isObjEmpty(this.current_location)) {
            return false;
        }

        const now = moment().tz(timezone);
        const last_active = moment(this.last_active).tz(timezone);
        const diff = now.diff(last_active, 'minutes');
        // console.log("this.last_active", this.getName(), now.format(), last_active.format());

        // console.log(now.diff(last_active, 'minutes'))
        // console.log("diff on last_active", diff)
        return this.online_status === true && diff <= 120;
    }

    getOnlineStatusColor() {
        return this.isOnline() ? '#5fdba7' : '#808080'
    }

    getTimewindow() {
        return this.timewindow;
    }

}

export default DriverEntity;