import CommunicationEntity from "./CommunicationEntity";

class CommunicationsListEntity {
    constructor(communications = []) {
        this.communications = communications.map(t => new CommunicationEntity(t))
    }

    update(communication) {
        this.communications = this.communications.map(t => {
            const tEntity = new CommunicationEntity(communication)
            if (t.getId() === tEntity.getId()) {
                t = tEntity
            }

            return t;
        })

    }

    add(communication) {
        this.communications = [...this.communications, new CommunicationEntity(communication)];
    }

    remove(communication) {
        this.communications = this.communications.filter(t => t.getId() !== communication._id)
    }

    getList() {
        return this.communications || [];
    }
    getTotalList() {
        return (this.communications || []).length
    }
}

export default CommunicationsListEntity;