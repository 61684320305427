import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

import { Formik, Form } from "formik";
import { Box } from '@material-ui/core';
// import TeamValidation from '../../../utils/form/validations/TeamValidation';

import { Autocomplete, CircularProgress, Grid, FormControl, Typography } from '@mui/material';
import ModalClose from '../../common/ModalClose';

import { addTeam } from '../../../api/team';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { deepCopy, isObjEmpty } from '../../../utils/utils';
import { refreshToken } from '../../../api/auth';
import { toast } from 'react-toastify';

export default function AddFormDialog() {
    const [open, setOpen] = useState(false);
    const { _globalDispatch, _globalData, teams, drivers, dispatchers, hubs } = useGlobalContext();
    const { current_user: user = {} } = _globalData;


    console.log("dispatchers", dispatchers)
    const driversOption = drivers.getDrivers();
    const dispatchersOptions = dispatchers.getDispatchers();
    const hubsOption = hubs.getHubs();


    const handleClose = (event, reason) => {
        // Prevent closing the dialog if the reason is "backdropClick"
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      };

    async function handleClickOpen() {
        const _res = await refreshToken();

        if (_res.success) {
            const { user = {} } = _res;

            const { plan_details = {} } = user;

            const { no_of_teams = 0 } = plan_details;

            if (no_of_teams > teams.getTotalTeams()) {
                setOpen(true);
            }
            else {
                _globalDispatch({
                    type: "OPEN_BILLING_MODAL",
                })
                console.log("Add option to upgrade plan")
            }

            _globalDispatch({
                type: "SET_CURRENT_USER",
                payload: user
            })
        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h6">
                    Teams ({teams.getTotalTeams()}/{user.plan_details.no_of_teams ?? 0})
                </Typography>
                <Button variant="contained" onClick={handleClickOpen} sx={{ float: 'right' }}>
                    <AddIcon />
                    Add New Team

                </Button>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md">
                <ModalClose handleClose={handleClose} />
                <DialogTitle>New team</DialogTitle>
                {/* <DialogContentText>Edit team's details below</DialogContentText> */}
                <DialogContent sx={{ width: "100%" }} >
                    <Formik
                        initialValues={{
                            team_name: "",
                            drivers: [],
                            dispatchers: [],
                            hub: {}
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            const payload = deepCopy(values);
                            // if (!payload.dispatchers || payload.dispatchers.length < 1) {
                            //     toast.error("Please add dispatcher");
                            //     return false
                            // }

                            // if (!payload.hub || isObjEmpty(payload.hub)) {
                            //     toast.error("Please add hub");
                            //     return false
                            // }

                            const _res = await addTeam(payload);

                            // console.log("_res", _res)
                            if (_res.success) {
                                resetForm();

                                teams.addTeamToList(_res.data)
                                _globalDispatch({
                                    type: "SET_CHOSEN_TEAM",
                                    payload: _res.data
                                });

                                setOpen(false);
                                _globalDispatch({
                                    type: "INCREASE_REFRESH_COUNTER"
                                });
                                toast.success("Team added successfully.");
                            } else {
                                console.log("Err", _res.msg)
                                toast.error(_res.msg)
                            }
                            setSubmitting(false);

                        }}
                    // validationSchema={TeamValidation}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <Form>
                                        {/* Name */}
                                        <Grid container spacing={2} width={"100%"} sx={{ width: 787 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Team Name"
                                                    name="team_name"
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={values.team_name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.team_name && props.touched.team_name}
                                                />
                                            </Grid>

                                            <Grid item xs={12} width={"100%"}>
                                                <FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
                                                    <Autocomplete
                                                        sx={{ margin: 0 }}
                                                        id="teams"
                                                        name="Teams"
                                                        options={driversOption}
                                                        multiple={true}
                                                        getOptionLabel={option => option.name || ""}
                                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                                        onChange={(e, value) => {
                                                            setFieldValue(
                                                                "drivers",
                                                                value !== null ? value : null
                                                            );
                                                        }}

                                                        value={values?.teams}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={option._id}>
                                                                    {option.name}
                                                                </li>
                                                            );
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                margin="normal"
                                                                label="Drivers"
                                                                fullWidth
                                                                name="Drivers"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} width={"100%"}>
                                                <FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
                                                    <Autocomplete
                                                        sx={{ margin: 0 }}
                                                        id="dispatchers"
                                                        name="Dispatchers"
                                                        options={dispatchersOptions}
                                                        multiple={true}
                                                        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                                        onChange={(e, value) => {
                                                            setFieldValue(
                                                                "dispatchers",
                                                                value !== null ? value : null
                                                            );
                                                        }}

                                                        value={values?.dispatchers}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={option._id}>
                                                                    {`${option.first_name} ${option.last_name}`}
                                                                </li>
                                                            );
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                margin="normal"
                                                                label="Dispatchers"
                                                                fullWidth
                                                                name="Dispatchers"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} width={"100%"}>
                                                <FormControl fullWidth sx={{ margin: 0 }} className="mui-driver-slect-formcontrol">
                                                    <Autocomplete
                                                        sx={{ margin: 0 }}
                                                        id="hub"
                                                        name="Hub"
                                                        options={hubsOption}
                                                        multiple={false}
                                                        getOptionLabel={option => option.hub_name || ""}
                                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                                        onChange={(e, value) => {
                                                            setFieldValue(
                                                                "hub",
                                                                value !== null ? value : null
                                                            );
                                                        }}

                                                        value={values?.hub}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={option._id}>
                                                                    {`${option.hub_name}`}
                                                                </li>
                                                            );
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                margin="normal"
                                                                label="Hub"
                                                                fullWidth
                                                                name="hub"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>

                                                {
                                                    isSubmitting && <Box style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%"
                                                    }}>
                                                        <CircularProgress />
                                                    </Box>
                                                }
                                            </Grid>

                                            <Grid item xs={12}>

                                                <Box textAlign='center'>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        Create
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}
