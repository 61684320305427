import { useContext } from "react";
import { ExportContext } from "../contexts/ExportContext";

export default function useExportContext() {
    const { _exportData, _exportDispatch } = useContext(ExportContext)
    
    const {
        taskStatus = null,
        dateStart = null,
        dateEnd = null,
        chosenDriver = null
    } = _exportData;

    return {
        _exportData,
        _exportDispatch,
        taskStatus,
        dateStart,
        dateEnd,
        chosenDriver
    }
}