import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AddIcon from '@mui/icons-material/Add';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Formik, Form, Field } from "formik";
import { Box } from '@material-ui/core';
import { CircularProgress, Grid, Typography } from '@mui/material';
import ModalClose from '../../common/ModalClose';
import { GlobalContext } from '../../../contexts/GlobalContext';
import TriggerWhenSelect from './TriggerWhenSelect';
import TriggerForSelect from './TriggerForSelect';

import { deepCopy, insertStringAtIndex } from '../../../utils/utils';

import {
    TextField,
} from '@mui/material';

import {
    ADDRESS_LONG_LABEL,
    ADDRESS_LONG_VALUE,
    ADDRESS_SHORT_LABEL,
    ADDRESS_SHORT_VALUE,
    DRIVER_FIRST_NAME_LABEL,
    DRIVER_FIRST_NAME_VALUE,
    DRIVER_LAST_NAME_LABEL,
    DRIVER_LAST_NAME_VALUE,
    DRIVER_NAME_LABEL,
    DRIVER_NAME_VALUE,
    DRIVER_PHONE_NUMBER_LABEL,
    DRIVER_PHONE_NUMBER_VALUE,
    ETA_LABEL,
    ETA_VALUE,
    LICENSE_PLATE_LABEL,
    LICENSE_PLATE_VALUE,
    ORGANIZATION_NAME_LABEL,
    ORGANIZATION_NAME_VALUE,
    PHOTO_URL_LABEL,
    PHOTO_URL_VALUE,
    RECIPIENT_FIRST_NAME_LABEL,
    RECIPIENT_FIRST_NAME_VALUE,
    RECIPIENT_LAST_NAME_LABEL,
    RECIPIENT_LAST_NAME_VALUE,
    RECIPIENT_NAME_LABEL,
    RECIPIENT_NAME_VALUE,
    SIGNATURE_URL_LABEL,
    SIGNATURE_URL_VALUE,
    TASK_ID_LABEL,
    TASK_ID_VALUE,
    TRACKING_LINK_LABEL,
    TRACKING_LINK_VALUE,
    VEHICLE_DESCRIPTION_LABEL,
    VEHICLE_DESCRIPTION_VALUE,
    VEHICLE_TYPE_LABEL,
    VEHICLE_TYPE_VALUE
} from '../../../constants/messageTags';

import { addCommunication } from '../../../api/communication';
import useGlobalContext from '../../../hooks/useGlobalContext';

import { toast } from 'react-toastify';
import CommunicationType from './CommunicationType';
import TaskType from './TaskType';
import CommunicationsListEntity from '../../../entities/CommunicationsListEntity';

export default function AddFormDialog() {
    const [open, setOpen] = useState(false);
    const { _globalDispatch, communications } = useGlobalContext(GlobalContext);

    function handleClose() {
        setOpen(false);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    return (
        <div>
         <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #888 ',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h6">
                    Communications 
                </Typography>
                <Button variant="contained" onClick={handleClickOpen} sx={{ float: 'right' }}>
                    <AddIcon />
                    Add New Communication
                </Button>
            </Box>
            {/* <Button variant="outlined" onClick={handleClickOpen} style={{ margin: 4 }}>
                <AddIcon />
            </Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm">
                <ModalClose handleClose={handleClose} />
                <DialogContent>
                    <Formik
                        initialValues={{
                            triggered_when: "started",
                            triggered_for: "both",
                            communication_type: "sms",
                            task_type: "general",
                            notification_title: "",
                            on_way_notification_title: "",
                            insertedTag: "",
                            cursor_position: 0,
                            message: ""
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {

                            setSubmitting(true);

                            const _res = await addCommunication(values);
                            console.log("values", values)
                            console.log("_res", _res)
                            if (_res.success) {
                                resetForm();
                                const newCommunication = deepCopy(values);
                                newCommunication._id = _res.data._id
                                if (communications) {
                                    if (communications instanceof CommunicationsListEntity) {
                                        _globalDispatch({
                                            type: "SET_COMMUNICATIONS",
                                            payload: new CommunicationsListEntity([
                                                ...communications.getList(),
                                                newCommunication
                                            ])
                                        })
                                    } else {
                                        _globalDispatch({
                                            type: "SET_COMMUNICATIONS",
                                            payload: new CommunicationType([
                                                ...communications || [],
                                                newCommunication
                                            ])
                                        })
                                    }
                                } else {
                                    _globalDispatch({
                                        type: "SET_COMMUNICATIONS",
                                        payload: new CommunicationsListEntity([
                                            ...communications || [],
                                            newCommunication
                                        ])
                                    })
                                }

                                toast("Communication added successfully");

                                // communications.add(_res.data)

                                setOpen(false);

                                _globalDispatch({
                                    type: "INCREASE_REFRESH_COUNTER"
                                });

                            }
                            setSubmitting(false);

                        }}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    setFieldValue,

                                } = props;

                                return (
                                    <Form>
                                        <Grid container spacing={2}>
                                            {/* Triggered When */}
                                            <Grid item xs={12}>
                                                <TriggerWhenSelect
                                                    chosenVal={values.triggered_when}
                                                    callBack={(newVal) => {
                                                        setFieldValue("triggered_when", newVal)
                                                    }}
                                                />
                                            </Grid>

                                            {/* Triggered For */}
                                            <Grid item xs={12}>
                                                <TriggerForSelect
                                                    chosenVal={values.triggered_for}
                                                    callBack={(newVal) => {
                                                        setFieldValue("triggered_for", newVal)
                                                    }}
                                                />
                                            </Grid>

                                            {/* Type */}
                                            <Grid item xs={12}>
                                                <CommunicationType
                                                    chosenVal={values.communication_type}
                                                    callBack={(newVal) => {
                                                        setFieldValue("communication_type", newVal)
                                                    }}
                                                />
                                            </Grid>

                                            {/* Task Type  */}
                                            {
                                                values.communication_type === "push_notification" && values.triggered_when === "started" &&
                                                <Grid item xs={12}>
                                                    <TaskType
                                                        chosenVal={values.task_type}
                                                        callBack={(newVal) => {
                                                            setFieldValue("task_type", newVal)
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {/* Notification Title */}
                                            {
                                                values.communication_type === "push_notification" &&
                                                <Grid item xs={12}>
                                                    <FormControl sx={{ minWidth: "100%" }}>
                                                        <TextField
                                                            label="Notification Title"
                                                            name="notification_title"
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={values.notification_title}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.notification_title && props.touched.notification_title}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {/* Insert Tag */}
                                            <Grid item xs={12}>
                                                <div>
                                                    <FormControl sx={{ minWidth: "100%" }}>
                                                        <InputLabel htmlFor="grouped-insert-tag">Insert Tag</InputLabel>
                                                        <Select native
                                                            value={values.insertedTag}
                                                            label="Insert Tag"
                                                            onChange={(newVal) => {
                                                                // insertStringAtIndex
                                                                setFieldValue("message", insertStringAtIndex(values.message, values.cursor_position, newVal.target.value))
                                                                setFieldValue("insertedTag", "")
                                                            }}>
                                                            <option aria-label="None" value="" />
                                                            <optgroup label="Recipient">
                                                                <option value={RECIPIENT_FIRST_NAME_VALUE}>{RECIPIENT_FIRST_NAME_LABEL}</option>
                                                                <option value={RECIPIENT_LAST_NAME_VALUE}>{RECIPIENT_LAST_NAME_LABEL}</option>
                                                                <option value={RECIPIENT_NAME_VALUE}>{RECIPIENT_NAME_LABEL}</option>
                                                            </optgroup>
                                                            <optgroup label="Driver">
                                                                <option value={DRIVER_FIRST_NAME_VALUE}>{DRIVER_FIRST_NAME_LABEL}</option>
                                                                <option value={DRIVER_LAST_NAME_VALUE}>{DRIVER_LAST_NAME_LABEL}</option>
                                                                <option value={DRIVER_NAME_VALUE}>{DRIVER_NAME_LABEL}</option>
                                                                <option value={ETA_VALUE}>{ETA_LABEL}</option>
                                                                {/* <option value={VEHICLE_DESCRIPTION_VALUE}>{VEHICLE_DESCRIPTION_LABEL}</option> */}
                                                                <option value={VEHICLE_TYPE_VALUE}>{VEHICLE_TYPE_LABEL}</option>
                                                                <option value={LICENSE_PLATE_VALUE}>{LICENSE_PLATE_LABEL}</option>
                                                                <option value={DRIVER_PHONE_NUMBER_VALUE}>{DRIVER_PHONE_NUMBER_LABEL}</option>
                                                            </optgroup>

                                                            <optgroup label="Task">
                                                                <option value={TASK_ID_VALUE}>{TASK_ID_LABEL}</option>
                                                                <option value={ADDRESS_SHORT_VALUE}>{ADDRESS_SHORT_LABEL}</option>
                                                                <option value={ADDRESS_LONG_VALUE}>{ADDRESS_LONG_LABEL}</option>
                                                                <option value={TRACKING_LINK_VALUE}>{TRACKING_LINK_LABEL}</option>
                                                                <option value={PHOTO_URL_VALUE}>{PHOTO_URL_LABEL}</option>
                                                                {/* <option value={SIGNATURE_URL_VALUE}>{SIGNATURE_URL_LABEL}</option> */}
                                                            </optgroup>

                                                            <optgroup label="Organization">
                                                                <option value={ORGANIZATION_NAME_VALUE}>{ORGANIZATION_NAME_LABEL}</option>
                                                            </optgroup>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            {/* Message */}
                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="grouped-insert-tag">Message</InputLabel>
                                                <textarea
                                                    placeholder='Message'
                                                    name='message'
                                                    value={values.message}
                                                    onChange={selector => {
                                                        setFieldValue("message", selector.target.value)
                                                        setFieldValue("cursor_position", selector.target.selectionStart)
                                                    }}
                                                    onClick={selector => {
                                                        console.log("On Focus", selector.target.selectionStart)
                                                        setFieldValue("cursor_position", selector.target.selectionStart)
                                                    }}
                                                    style={{
                                                        minWidth: "100%",
                                                        minHeight: "256px",
                                                        fontFamily: 'IBM Plex Sans, sans-serif',
                                                        fontSize: '0.875rem',
                                                        fontWeight: 400,
                                                        lineHeight: 1.5,
                                                        padding: '12px',
                                                        borderRadius: '12px 12px 0 12px',
                                                        color: "grey[900]",
                                                        background: '#fff',
                                                        border: "1px solid grey[200]",
                                                        boxShadow: "0px 2px 2px grey[50]"
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {
                                            isSubmitting && <Box style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%"
                                            }}>
                                                <CircularProgress />
                                            </Box>
                                        }

                                        <Box textAlign='center'>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>

                                        </Box>

                                    </Form>
                                );
                            }}
                    </Formik>

                </DialogContent>
            </Dialog>
        </div>
    );
}
