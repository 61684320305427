import React,{useImperativeHandle} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import PanelOraganization from './panels/PanelOraganization';
import PanelUser from './panels/PanelUser';
import PanelDrivers from './panels/PanelDrivers';
import PanelCommunications from './panels/PanelCommunications';
import PanelDriverApp from './panels/PanelDriverApp';
import PanelAdmins from './panels/PanelAdmins';
import PanelDispatchers from './panels/PanelDispatchers';
import PanelTeams from './panels/PanelTeams';
import PanelHubs from './panels/PanelHubs';
import useGlobalContext from '../../hooks/useGlobalContext';
import TuneIcon from '@mui/icons-material/Tune';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import GroupsIcon from '@mui/icons-material/Groups';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DriverIcon from '../../images/healthicons_truck-driver.png';




const adminTabs = [
    {
        label: "Organization",
        component: <PanelOraganization />,
        icon: <BusinessIcon />,
    },
    {
        label: "User Settings",
        component: <PanelUser />,
        icon: <TuneIcon />
    },
    {
        label: "Admins",
        component: <PanelAdmins />,
        icon: <ManageAccountsIcon />,
    },
    {
        label: "Dispatchers",
        component: <PanelDispatchers />,
        icon: <LocalShippingIcon />,
    },
    {
        label: "Hubs",
        component: <PanelHubs />,
        icon: <DeviceHubIcon />,
    },
    {
        label: "Drivers",
        component: <PanelDrivers />,
        icon: <img src={DriverIcon} style={{ filter: "brightness(0) invert(0)" }} />,
    },

    {
        label: "Teams",
        component: <PanelTeams />,
        icon: <GroupsIcon />,
    },
   
    {
        label: "Driver App",
        component: <PanelDriverApp />,
        icon: <AppSettingsAltIcon />,
    },
    {
        label: "Communications",
        component: <PanelCommunications />,
        icon: <ConnectWithoutContactIcon />,
    },
    
]

const dispatcherTabs = [
    {
        label: "User Settings",
        component: <PanelUser />
    },
    {
        label: "Drivers",
        component: <PanelDrivers />
    }
]

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const VerticalTabs =() => {

  
    
    const [value, setValue] = React.useState(0);
    const { _globalDispatch, getOwner, isDispatcher } = useGlobalContext();


    const owner = getOwner();
    // const isDispatcher = isDispatcher();
    console.log("isDispatcher", isDispatcher())
    const __tabs = isDispatcher() ? dispatcherTabs : adminTabs;

    const handleChange = (event, newValue) => {
        setValue(newValue);

        _globalDispatch({
            type: "RESET_CHOSEN_DRIVER"
        })
    };

    return (
        <Box 
            sx={{ bgcolor: 'background.paper', display: 'flex',height:'inherit',overflow:'scroll' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{
                    '.MuiTabs-flexContainer': {
                      alignItems: 'flex-start',
                      width:'20%'  // Align tabs to the start
                    },
                  }}            >
                {
                    __tabs.map((t, i) => <Tab key={t.label} sx={{ justifyContent: 'flex-start', textAlign: 'left', pl: 1  }} icon={t.icon} 
                    iconPosition="start"  label={t.label} {...a11yProps(i)} />)
                }

            </Tabs>

            {
                __tabs.map((t, i) => <TabPanel key={t.label} value={value} index={i} style={{ width: "90%", overflow: "none" }} >
                    {t.component}
                </TabPanel>)
            }
        </Box>
    );
};
export default VerticalTabs;