import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Page from '../components/Page';
import 'react-toastify/dist/ReactToastify.css';

import TaskOptionsDailog from '../components/modals/TaskOptionsModal';
import EditTaskModal from '../components/modals/EditTaskModal';
import MultiTaskSelected from '../components/dashboard/MultiTaskSelected';
import TaskSelectionActionModal from '../components/modals/TaskSelectionActionModal';

import ViewTaskModal from '../components/modals/ViewTaskModal';
import useGlobalContext from '../hooks/useGlobalContext';
import DashboardWrap from './DashboardWrap';
import RouteOptimizationModal from '../components/modals/RouteOptimizationModal';
import RouteOptimizeContextProvider from '../contexts/RouteOptimizeContext';
import TimewindowModal from '../components/modals/TimewindowModal';
import BillingList from '../components/settings/billings/BillingList';


const BoxContainer = () => {
	const { _globalDispatch } = useGlobalContext();
	return <Box
		onKeyDown={(e) => {
			if (e.key === "Escape") {
				_globalDispatch({
					type: "CLEAR_SELECTED_TASKS"
				})

				_globalDispatch({
					type: "SET_POLYLINE_TASKS",
					payload: null
				})

				_globalDispatch({
					type: "SET_DIRECTION",
					payload: false
				})

				_globalDispatch({
					type: "SET_INFOWINDOW",
					payload: null
				})
			}
		}}
	>
		<Grid container  >
			<DashboardWrap />
		</Grid>

	</Box>

}

const DashboardContainer = () => {
	return (
		<Page title="Dashboard">
			<BoxContainer />
			<MultiTaskSelected />
			<TaskOptionsDailog />
			<TaskSelectionActionModal />
			<RouteOptimizeContextProvider>
				<RouteOptimizationModal />
			</RouteOptimizeContextProvider>
			<EditTaskModal />
			<TimewindowModal />
			<ViewTaskModal />
			<BillingList />
		</Page>

	);
};

export default DashboardContainer;